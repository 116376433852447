import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';

import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import Button from '../../components/Button';
import Stepper from '../../components/Stepper';
import Logo from '../../components/Logo';

import styles from './styles.module.sass';

const Walkthrough = ({ t, history }) => {
  const [currentPage, setCurrentPage] = useState(0);
  // const [showDescription, setShowDescription] = useState(true)
  const [showDescription] = useState(true);

  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
  }, [formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function handlePageChanged(index) {
    setCurrentPage(index);
  }

  const pages = [
    {
      title: t('onboarding.title1'),
      description: [t('onboarding.description1')],
      image: require('../../assets/images/splash/onboarding1.png'),
    },
  ];

  return (
    <Box className={styles.container}>
      <SwipeableViews
        className={styles.container_pages}
        index={currentPage}
        onChangeIndex={(index) => handlePageChanged(index)}
      >
        {pages.map((page, index) => (
          <Box className={styles.page} key={index}>
            <img src={page.image} alt="" />
          </Box>
        ))}
      </SwipeableViews>
      <Box className={styles.content_page} style={{ minHeight }}>
        <Box className={styles.container_logo}>
          <Logo />
        </Box>
        <Box className={styles.content} ref={formBox}>
          <Box className={styles.container_step_content}>
            <Box>
              <Box className="title" style={{ marginBottom: 16 }}>
                {pages[currentPage].title}
              </Box>
              {showDescription && (
                <Box className="subtitle">
                  {pages[currentPage].description.map((line, index) => (
                    <Box
                      key={index}
                      style={{ animationDelay: `${index * 0}s` }}
                      // className={clsx("animated", "slideInRight", styles.description)}>
                      className={clsx(styles.description)}
                    >
                      {line}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Box className={styles.container_actions}>
            <Button
              className={styles.button}
              style={{ marginBottom: isMobile ? 10 : 50 }}
              onPress={() => history.push('/create-account')}
            >
              {t('common.createAccount')}
            </Button>
            <Button
              className={styles.button}
              clean
              inverted
              fontSize="20px"
              onPress={() => history.push('/login')}
            >
              {t('common.login')}
            </Button>
          </Box>
        </Box>
        <Box className={styles.container_steps}>
          <Stepper activeStep={currentPage} steps={pages.length} />
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(Walkthrough);
