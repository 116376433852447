import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import AddIcon from '@material-ui/icons/Add';

import clsx from 'clsx';
import Button from '../Button';
import Progress from '../Progress';

import styles from './styles.module.sass';

const ProfileData = ({
  t,
  className,
  photoClassName,
  photoBorder,
  uploadPhotoClassName,
  color,
  initials,
  username,
  email,
  photo,
  onPress,
  uploadPhoto = false,
  onSelectImage,
  loadingPhoto,
  edit,
  onPressEdit,
  showData = true,
}) => {
  return (
    <Box className={clsx(styles.container, className)}>
      <Box onClick={() => (onPress ? onPress() : null)} className={photoClassName}>
        <Box
          style={{ cursor: onPress ? 'pointer' : 'auto' }}
          className={clsx(styles.photo_border, photoBorder)}
        >
          <Box className={styles.container_photo}>
            {photo ? (
              <Box className={styles.container_image}>
                <img src={photo} alt="" />
              </Box>
            ) : (
              <Box style={{ color }} className={styles.initials}>
                {initials}
              </Box>
            )}

            {loadingPhoto && (
              <Box className={styles.container_loading}>
                <Progress />
              </Box>
            )}
          </Box>
          {uploadPhoto && (
            <Box className={clsx(styles.container_upload_photo, uploadPhotoClassName)}>
              <Button clean>
                <label htmlFor="photo-file">
                  <AddIcon />
                </label>
                <input
                  accept="image/*"
                  id="photo-file"
                  type="file"
                  onChange={(event) => {
                    onSelectImage(event.target.files[0]);
                  }}
                  style={{ display: 'none' }}
                />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {showData && (
        <Box className={styles.container_data}>
          <Box className={styles.container_username}>
            <Box className={styles.names}>{username}</Box>
          </Box>
          <Box className={styles.view_profile}>
            <Button
              className={styles.edit}
              fontSize={13}
              clean
              inverted
              padding={0}
              onPress={onPressEdit}
            >
              {t('profile.viewProfile')}
            </Button>
          </Box>
        </Box>
      )}
      <Box className={styles.gradient} />
    </Box>
  );
};

export default withTranslation()(ProfileData);
