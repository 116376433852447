import Immutable from 'seamless-immutable';

const InitialState = Immutable({
  token: null,
  notification: null,
  pushNotification: false,
  notifications: null,
  pagination: null,
  getNotificationsCalled: false,
  getNotificationsLoading: false,
  getNotificationsError: null,
});

export default InitialState;
