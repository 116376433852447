import { createActions } from 'redux-actions';
import amplitude from 'amplitude-js';

import {
  getPayments as getPaymentsService,
  getPayment as getPaymentService,
  createPayment as createPaymentService,
  updatePayment as updatePaymentService,
  updatePaymentUser as updatePaymentUserService,
  postRequestPayment as postRequestPaymentService,
  restoreSelectedPayment as restoreSelectedPaymentService,
  storeSelectedPayment as storeSelectedPaymentService,
  removeSelectedPayment as removeSelectedPaymentService,
} from '../../services/payments';

import { GenericError } from '../../utils/errors';

const PaymentsActions = createActions({
  RESET_PAYMENTS: () => {},
  RESET_PAYMENTS_ERROR: () => {},

  GET_PAYMENTS_REQUEST: () => {},
  GET_PAYMENTS_SUCCESS: (payments) => ({ payments }),
  GET_PAYMENTS_FAILED: (error) => ({ error }),
  RESET_GET_PAYMENTS: () => {},
  RESET_GET_PAYMENTS_ERROR: () => {},

  GET_PAYMENT_REQUEST: () => {},
  GET_PAYMENT_SUCCESS: (payment) => ({ payment }),
  GET_PAYMENT_FAILED: (error) => ({ error }),
  RESET_GET_PAYMENT: () => {},
  RESET_GET_PAYMENT_ERROR: () => {},

  UPDATE_PAYMENT_SELECTION: (payment) => ({ payment }),
  SAVE_PAYMENT_USER_SELECTION: (paymentUser) => ({ paymentUser }),

  ACTION_PAYMENT_REQUEST: () => {},
  ACTION_PAYMENT_SUCCESS: () => {},
  ACTION_PAYMENT_FAILED: (error) => ({ error }),
  RESET_ACTION_PAYMENT: () => {},
  RESET_ACTION_PAYMENT_ERROR: () => {},

  UPDATE_PAYMENT_USER_REQUEST: () => {},
  UPDATE_PAYMENT_USER_SUCCESS: () => {},
  UPDATE_PAYMENT_USER_FAILED: (error) => ({ error }),
  RESET_UPDATE_PAYMENT_USER: () => {},
  RESET_UPDATE_PAYMENT_USER_ERROR: () => {},

  SET_FILTERS: (filters) => ({ filters }),

  SAVE_PAYMENT_CONFIRMATION_CHECK: (status) => ({ status }),

  SEND_REQUEST_PAYMENT_REQUEST: () => {},
  SEND_REQUEST_PAYMENT_SUCCESS: () => {},
  SEND_REQUEST_PAYMENT_FAILED: (error) => ({ error }),
  RESET_SEND_REQUEST_PAYMENT: () => {},
  RESET_SEND_REQUEST_PAYMENT_ERROR: () => {},
});

PaymentsActions.getPayments = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(PaymentsActions.getPaymentsRequest());
      const result = await getPaymentsService(userId);
      if (result.ok) {
        dispatch(PaymentsActions.getPaymentsSuccess(result.data.data));
        dispatch(PaymentsActions.setFilters(null));
      } else if (result.data) {
        dispatch(
          PaymentsActions.getPaymentsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(PaymentsActions.PaymentsActions(null));
      } else {
        dispatch(
          PaymentsActions.PaymentsActions(new GenericError('something went wrong on get payments'))
        );
      }
    } catch (err) {
      dispatch(PaymentsActions.getPaymentsFailed(new GenericError(err, err)));
    }
  };
};

PaymentsActions.getPayment = (id) => {
  return async (dispatch) => {
    try {
      dispatch(PaymentsActions.getPaymentRequest());
      const result = await getPaymentService(id);
      if (result.ok) {
        dispatch(PaymentsActions.savePaymentSelection(result.data.data));
        dispatch(PaymentsActions.getPaymentSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          PaymentsActions.getPaymentFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(PaymentsActions.getPaymentFailed(null));
      } else {
        dispatch(
          PaymentsActions.getPaymentFailed(new GenericError('something went wrong on get payment'))
        );
      }
    } catch (err) {
      dispatch(PaymentsActions.getPaymentFailed(new GenericError(err, err)));
    }
  };
};

PaymentsActions.createPayment = (payment) => {
  return async (dispatch) => {
    try {
      dispatch(PaymentsActions.actionPaymentRequest());
      const result = await createPaymentService({ ...payment });
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          amplitude.getInstance().logEvent(`Contracts Created`, {
            contractId: result.data.data.id,
            creationDate: result.data.data.createdAt,
          });
          const resultPayment = await getPaymentService(result.data.data.id);
          if (resultPayment.data.data.legalRepresentative) {
            amplitude.getInstance().logEvent(`Financier II`, {
              userId: resultPayment.data.data.legalRepresentative.id,
              contractId: resultPayment.data.data.contract.id,
              userRole: 'financier2',
            });
          }
        }
        dispatch(PaymentsActions.actionPaymentSuccess());
        dispatch(PaymentsActions.resetGetPayments());
      } else if (result.data) {
        dispatch(
          PaymentsActions.actionPaymentFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(PaymentsActions.actionPaymentFailed(null));
      } else {
        dispatch(
          PaymentsActions.actionPaymentFailed(
            new GenericError('something went wrong on create payment')
          )
        );
      }
    } catch (err) {
      dispatch(PaymentsActions.actionPaymentFailed(new GenericError(err, err)));
    }
  };
};

PaymentsActions.updatePayment = (payment) => {
  return async (dispatch) => {
    try {
      dispatch(PaymentsActions.actionPaymentRequest());
      const result = await updatePaymentService(payment.id, { ...payment });
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          const resultPayment = await getPaymentService(result.data.data.id);
          if (resultPayment.data.data.legalRepresentative) {
            amplitude.getInstance().logEvent(`Financier II`, {
              userId: resultPayment.data.data.legalRepresentative.id,
              contractId: resultPayment.data.data.contract.id,
              userRole: 'financier2',
            });
          }
        }

        dispatch(PaymentsActions.actionPaymentSuccess());
        dispatch(PaymentsActions.resetGetPayments());
      } else if (result.data) {
        dispatch(
          PaymentsActions.actionPaymentFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(PaymentsActions.actionPaymentFailed(null));
      } else {
        dispatch(
          PaymentsActions.actionPaymentFailed(
            new GenericError('something went wrong on update payment')
          )
        );
      }
    } catch (err) {
      dispatch(PaymentsActions.actionPaymentFailed(new GenericError(err, err)));
    }
  };
};

PaymentsActions.updatePaymentUser = (id, data, payload) => {
  return async (dispatch) => {
    try {
      dispatch(PaymentsActions.updatePaymentUserRequest());
      const result = await updatePaymentUserService(id, data);
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          amplitude
            .getInstance()
            .logEvent(`Agree payment instruction${payload?.financier ? ' by Financier' : ''}`, {
              userId: data.user,
              contractId: result.data.data.id,
              volume: payload?.totalVolume,
            });
        }

        dispatch(PaymentsActions.updatePaymentUserSuccess());
        dispatch(PaymentsActions.resetGetPayments());
      } else if (result.data) {
        dispatch(
          PaymentsActions.updatePaymentUserFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(PaymentsActions.updatePaymentUserFailed(null));
      } else {
        dispatch(
          PaymentsActions.updatePaymentUserFailed(
            new GenericError('something went wrong on create payment')
          )
        );
      }
    } catch (err) {
      const msgErr = typeof err === 'string' ? err : err?.message || JSON.stringify(err);
      dispatch(PaymentsActions.updatePaymentUserFailed(new GenericError(msgErr, msgErr)));
    }
  };
};

PaymentsActions.sendRequestPayment = (data) => {
  return async (dispatch) => {
    try {
      dispatch(PaymentsActions.sendRequestPaymentRequest());
      const result = await postRequestPaymentService(data);
      if (result.ok) {
        dispatch(PaymentsActions.sendRequestPaymentSuccess());
      } else if (result.data) {
        dispatch(
          PaymentsActions.sendRequestPaymentFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(PaymentsActions.sendRequestPaymentFailed(null));
      } else {
        dispatch(
          PaymentsActions.sendRequestPaymentFailed(
            new GenericError('something went wrong on send payment request')
          )
        );
      }
    } catch (err) {
      dispatch(PaymentsActions.sendRequestPaymentFailed(new GenericError(err, err)));
    }
  };
};
PaymentsActions.savePaymentSelection = (payment) => {
  return (dispatch) => {
    dispatch(PaymentsActions.updatePaymentSelection(payment));
  };
};

PaymentsActions.restoreSelectedPayment = () => {
  return async (dispatch) => {
    const selectedPayment = await restoreSelectedPaymentService();
    if (selectedPayment) {
      dispatch(PaymentsActions.getPayment(selectedPayment));
    }
  };
};

export default PaymentsActions;
