import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import NavigationBar from '../../../components/NavigationBar';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../../../components/ExpansionPanel';
import CheckBox from '../../../components/CheckBox';
import Button from '../../../components/Button';
import BasicDateRangePicker from '../../../components/BasicDateRangePicker';
import CalendarIcon from '../../../assets/images/icons/calendar.svg';

import PaymentsActions from '../../../redux/payments/actions';

import styles from './styles.module.sass';

const Filters = ({ t, history }) => {
  const dispatch = useDispatch();

  const [temporalFilters, setTemporalFilters] = useState(null);
  const { payments, filters } = useSelector((state) => state.payments);

  useEffect(() => {
    if (payments && filters === null) {
      const paymentTo = [
        ...new Set(
          payments.map((payment) => `${payment.owner?.firstName} ${payment.owner?.lastName}`)
        ),
      ].map((ownerName) => ({
        name: ownerName,
        count: payments.filter(
          (payment) => `${payment.owner?.firstName} ${payment.owner?.lastName}` === ownerName
        ).length,
        checked: true,
      }));
      const paymentFrom = [
        ...new Set(
          payments.map((payment) => `${payment.buyer?.firstName} ${payment.buyer?.lastName}`)
        ),
      ].map((buyerName) => ({
        name: buyerName,
        count: payments.filter(
          (payment) => `${payment.buyer?.firstName} ${payment.buyer?.lastName}` === buyerName
        ).length,
        checked: true,
      }));
      const pledgeTo = [
        ...new Set(
          payments.map(
            (payment) =>
              `${payment.legalRepresentative?.firstName || ''} ${
                payment.legalRepresentative?.lastName || ''
              }`
          )
        ),
      ].map((legalRepresentativeName) => ({
        name: legalRepresentativeName,
        count: payments.filter(
          (payment) =>
            `${payment.legalRepresentative?.firstName || ''} ${
              payment.legalRepresentative?.lastName || ''
            }` === legalRepresentativeName
        ).length,
        checked: true,
      }));
      const updatedFilters = {
        paymentFrom,
        paymentTo,
        pledgeTo,
      };
      dispatch(PaymentsActions.setFilters(updatedFilters));
    }
    if (temporalFilters === null) {
      setTemporalFilters(filters);
    }
  }, [dispatch, history, payments, filters, temporalFilters]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function applyFilters() {
    dispatch(PaymentsActions.setFilters(temporalFilters));
    history.goBack();
  }

  return (
    <Box className={styles.container}>
      <NavigationBar
        title={t('filters.title')}
        hasCloseButton
        history={history}
        pageContent={
          <Box className={clsx(styles.main_content)}>
            <Container maxWidth="md" className={styles.content}>
              <Box>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Box className="form_title">{t('filters.offTakeStatus')}</Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box className={styles.filter_group}>
                      <Box className={styles.filter_item}>
                        <CheckBox checked onChange={(event) => {}} />
                        {t('contracts.onTime')} ({0})
                      </Box>

                      <Box className={styles.filter_item}>
                        <CheckBox checked onChange={(event) => {}} />
                        {t('contracts.behind')} ({0})
                      </Box>

                      <Box className={styles.filter_item}>
                        <CheckBox checked onChange={(event) => {}} />
                        {t('contracts.ahead')} ({0})
                      </Box>
                      <Box className={styles.filter_item}>
                        <CheckBox checked onChange={(event) => {}} />
                        {t('contracts.completed')} ({0})
                      </Box>
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelDetails>
                    <Box className={styles.filter_group} style={{ marginTop: 20 }}>
                      <Box className={styles.filter_item}>
                        <CheckBox checked onChange={(event) => {}} />
                        {t('filters.currentlyNominated')} ({0})
                      </Box>
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelDetails>
                    <Box
                      className={styles.filter_group}
                      style={{ marginTop: 20, width: '100%', marginRight: 10 }}
                    >
                      <Box className={styles.filter_item}>
                        <BasicDateRangePicker
                          style={{ width: '100%', paddingRight: '10px' }}
                          value={[null, null]}
                          onChange={(value) => {}}
                          placeholder={t('filters.deliveryDateRange')}
                          label={t('filters.deliveryDateRange').toUpperCase()}
                          icon={<SvgIcon viewBox="0 0 22 22" component={CalendarIcon} />}
                          required
                        />
                      </Box>
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Box className="form_title">{t('filters.buyers')}</Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box className={styles.filter_group}>
                      {temporalFilters?.paymentFrom?.map((item, index) => (
                        <Box key={index} className={styles.filter_item}>
                          <CheckBox
                            checked={item.checked}
                            onChange={(event) => {
                              const updatedFilters = {
                                ...temporalFilters,
                                paymentFrom: [...temporalFilters.paymentFrom],
                              };
                              updatedFilters.paymentFrom[index] = {
                                ...temporalFilters.paymentFrom[index],
                                value: event.target.checked,
                              };
                              setTemporalFilters(updatedFilters);
                            }}
                          />
                          {item.name} ({item.count})
                        </Box>
                      ))}
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Box className="form_title">{t('filters.sellers')}</Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box className={styles.filter_group}>
                      {temporalFilters?.paymentFrom?.map((item, index) => (
                        <Box key={index} className={styles.filter_item}>
                          <CheckBox
                            checked={item.checked}
                            onChange={(event) => {
                              const updatedFilters = {
                                ...temporalFilters,
                                paymentFrom: [...temporalFilters.paymentFrom],
                              };
                              updatedFilters.paymentFrom[index] = {
                                ...temporalFilters.paymentFrom[index],
                                value: event.target.checked,
                              };
                              setTemporalFilters(updatedFilters);
                            }}
                          />
                          {item.name} ({item.count})
                        </Box>
                      ))}
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Box className="form_title">{t('filters.products')}</Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box className={styles.filter_group}>
                      {temporalFilters?.paymentFrom?.map((item, index) => (
                        <Box key={index} className={styles.filter_item}>
                          <CheckBox
                            checked={item.checked}
                            onChange={(event) => {
                              const updatedFilters = {
                                ...temporalFilters,
                                paymentFrom: [...temporalFilters.paymentFrom],
                              };
                              updatedFilters.paymentFrom[index] = {
                                ...temporalFilters.paymentFrom[index],
                                value: event.target.checked,
                              };
                              setTemporalFilters(updatedFilters);
                            }}
                          />
                          {item.name} ({item.count})
                        </Box>
                      ))}
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <Divider />
              </Box>
              <Box className={styles.container_submit}>
                <Button
                  className={styles.button}
                  minWidth={130}
                  maxWidth={270}
                  onPress={() => applyFilters()}
                >
                  {t('common.apply')}
                </Button>
              </Box>
            </Container>
          </Box>
        }
      />
    </Box>
  );
};

export default withTranslation()(Filters);
