import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import AuthActions from '../../../redux/auth/actions';

import Loading from '../../../components/loading';

import styles from './styles.module.sass';

import MessageModal from '../../../modals/message';

const FirebaseActions = ({ t, history }) => {
  const dispatch = useDispatch();

  const {
    emailVerificationLoading,
    emailVerificationError,
    emailVerificationCalled,
    loggedIn,
    tokenValid,
  } = useSelector((state) => state.auth);

  if (history?.location?.search) {
    const values = queryString.parse(history.location.search);
    const code = values.oobCode;
    if (values.mode === 'verifyEmail') {
      if (emailVerificationCalled === false) {
        dispatch(AuthActions.verifyEmail(code));
      }
    } else if (values.mode === 'resetPassword') {
      history.replace(`/reset-password/${code}`);
    }
  }

  return (
    <Box className={styles.container}>
      <Loading isLoading={emailVerificationLoading}>...</Loading>
      {emailVerificationCalled &&
        emailVerificationLoading === false &&
        emailVerificationError === null && (
          <MessageModal
            title={t('common.success')}
            message={t('emailVerification.successVerification')}
            primaryAction={t('common.done')}
            primaryEvent={() => {
              dispatch(AuthActions.resetEmailVerification());
              if (loggedIn && tokenValid) {
                window.location.reload();
              } else {
                history.replace('/');
              }
            }}
          />
        )}
      {emailVerificationCalled && emailVerificationError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={emailVerificationError.userMessage}
          primaryAction={t('common.ok')}
          primaryEvent={() => {
            history.replace('/');
            dispatch(AuthActions.resetEmailVerification());
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(FirebaseActions);
