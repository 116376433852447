import React, { useState, useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Divider from '@material-ui/core/Divider';
import { MobileDatePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns'; // import

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Formik } from 'formik';
import * as Yup from 'yup';
import NavigationBar from '../../../../components/NavigationBar';
import TextField from '../../../../components/TextField';
import MessageModal from '../../../../modals/message';
import Loading from '../../../../components/loading';
import Button from '../../../../components/Button';
import UserPhoto from '../../../../components/UserPhoto';
import Recipients from '../../../../components/Recipients';
import DeleteIcon from '../../../../assets/images/icons/delete.svg';
import CheckBox from '../../../../components/CheckBox';
import CalendarIcon from '../../../../assets/images/icons/calendar.svg';
import EditIcon from '../../../../assets/images/icons/edit_item.svg';
import CheckIcon from '../../../../assets/images/icons/check.svg';

import AuthActions from '../../../../redux/auth/actions';
import WorkflowActions from '../../../../redux/workflow/actions';
import InvitesActions from '../../../../redux/invites/actions';

import styles from './styles.module.sass';
import InviteCollaboratorsTaskModal from '../../../../modals/inviteCollaboratorsTask';

const CreateGeneralTask = ({ t, parentTask, task, history, desktop = false, onClose, taskId }) => {
  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        root: {
          backgroundColor: '#111',
        },
      },
      MuiPickersDateRangeDay: {
        rangeIntervalDayHighlight: {
          color: '#111',
          backgroundColor: '#f4f4f5',
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          color: '#111',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#111',
        },
      },
    },
  });

  const validationSchema = Yup.object({
    title: Yup.string().required(t('common.requiredField')),
    description: Yup.string().required(t('common.requiredField')),
    completionDate: Yup.string().required(t('common.requiredField')),
  });

  const dispatch = useDispatch();
  const [openCollaboratorsModal, setOpenCollaboratorsModal] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  // const [dataPoints, setDataPoints] = useState([]);
  // const [documents, setDocuments] = useState([]);
  const [editing, setEditing] = useState(true);
  const [updatingRequirement, setUpdatingRequirement] = useState(false);
  const [completing, setCompleting] = useState(false);
  const [creating, setCreating] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { contractSelection } = useSelector((state) => state.contracts);
  const {
    offTakeScheduleSelection,
    saveTaskLoading,
    saveTaskError,
    saveTaskCalled,
    usersInviteTask,
    deleteTaskLoading,
    deleteTaskError,
    deleteTaskCalled,
    subtaskSelection,
    parentTaskSelection,
    updateRequirementTaskLoading,
    updateRequirementTaskError,
    updateRequirementTaskCalled,
    getSubtaskCalled,
    getSubtaskLoading,
    getSubtaskError,
  } = useSelector((state) => state.workflow);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function deleteTask() {
    setUpdatingRequirement(false);
    dispatch(WorkflowActions.deleteTask(taskId || subtaskSelection?.id, 'general'));
  }

  const TabComponent = ({ values, handleChange }) => (
    <>
      {!isMobile && !isTablet && (
        <Box className={styles.row_tabs}>
          <Box
            className={[styles.tabBtn, values.tabIndex === 0 ? styles.tabSelected : ''].join(' ')}
            onClick={() => {
              const event = {
                persist: () => {},
                target: {
                  type: 'change',
                  id: 'tabIndex',
                  name: 'tabIndex',
                  value: 0,
                },
              };
              handleChange(event);
            }}
          >
            {t('contracts.details.workflow.dataPointRequired')}
          </Box>
          <Box
            className={[styles.tabBtn, values.tabIndex === 1 ? styles.tabSelected : ''].join(' ')}
            onClick={() => {
              const event = {
                persist: () => {},
                target: {
                  type: 'change',
                  id: 'tabIndex',
                  name: 'tabIndex',
                  value: 1,
                },
              };
              handleChange(event);
            }}
          >
            {t('contracts.details.workflow.documentRequired')}
          </Box>
        </Box>
      )}
    </>
  );

  const PageContent = () => {
    const [newDataPoint, setNewDataPoint] = useState({
      requirement: '',
      requiredForPayment: false,
    });
    const [newDocument, setNewDocument] = useState({
      requirement: '',
      requiredForPayment: false,
      requiredForReview: false,
    });
    const [dataPoints, setDataPoints] = useState([]);
    const [documents, setDocuments] = useState([]);

    function handleSubmit(values) {
      setUpdatingRequirement(false);

      const data = {};
      data.description = values.description;
      data.title = values.title;
      if (!(taskId || subtaskSelection?.id)) {
        data.type = 'general';
        data.taskParentId = parentTask || subtaskSelection?.taskParentId;
      }
      data.completionDate = values.completionDate;

      if (values.referenceNumber && values.referenceNumber !== '') {
        data.referenceNumber = values.referenceNumber;
      }

      if (subtaskSelection?.id) {
        if (completing) {
          data.status = 'completed';
          if (values.comments) {
            data.comments = values.comments;
          }
        }

        data.requirements = {
          added: [
            ...documents
              ?.filter((requirement) => !requirement.deleted && !requirement.id)
              ?.map((value) => {
                return {
                  type: 'documentation',
                  requirement: value.requirement,
                  requiredForPayment: value.requiredForPayment,
                  requiredForReview: value.requiredForReview,
                };
              }),
            ...dataPoints
              ?.filter((requirement) => !requirement.deleted && !requirement.id)
              ?.map((value) => {
                return {
                  type: 'dataPoint',
                  requirement: value.requirement,
                  requiredForPayment: value.requiredForPayment,
                };
              }),
          ],
          deleted: [
            ...documents
              ?.filter((requirement) => requirement.deleted)
              ?.map((value) => {
                return {
                  id: value.id,
                };
              }),
            ...dataPoints
              ?.filter((requirement) => requirement.deleted)
              ?.map((value) => {
                return {
                  id: value.id,
                };
              }),
          ],
          updated: [
            ...documents
              ?.filter((requirement) => requirement.updated)
              ?.map((value) => {
                return {
                  id: value.id,
                  requiredForPayment: value.requiredForPayment,
                  requiredForReview: value.requiredForReview,
                };
              }),
            ...dataPoints
              ?.filter((requirement) => requirement.updated)
              ?.map((value) => {
                return {
                  id: value.id,
                  requiredForPayment: value.requiredForPayment,
                };
              }),
          ],
        };

        if (data.requirements?.added?.length <= 0) {
          delete data.requirements?.added;
        }
        if (data.requirements?.deleted?.length <= 0) {
          delete data.requirements?.deleted;
        }
        if (data.requirements?.updated?.length <= 0) {
          delete data.requirements?.delupdatedeted;
        }
        if (
          !data.requirements?.deleted &&
          !data.requirements?.added &&
          !data.requirements?.updated
        ) {
          delete data.requirements;
        }

        dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'general'));
      } else {
        data.requirements = [
          ...documents
            ?.filter((requirement) => !requirement.deleted)
            ?.map((value) => {
              return {
                type: 'documentation',
                requirement: value.requirement,
                requiredForPayment: value.requiredForPayment,
                requiredForReview: value.requiredForReview,
              };
            }),
          ...dataPoints
            ?.filter((requirement) => !requirement.deleted)
            ?.map((value) => {
              return {
                type: 'dataPoint',
                requirement: value.requirement,
                requiredForPayment: value.requiredForPayment,
              };
            }),
        ];
        if (data.requirements.length === 0) {
          delete data.requirements;
        }

        data.schedule = offTakeScheduleSelection?.id;
        const userFound = contractSelection.collaborators.find(
          (collaborator) => collaborator.user === user.id
        );
        dispatch(
          WorkflowActions.saveTask(data, 'general', {
            contractId: contractSelection.id,
            role: userFound?.role || '',
            userId: user.id,
          })
        );
      }
    }

    useEffect(() => {
      if (!getSubtaskCalled && (taskId || subtaskSelection?.id)) {
        dispatch(WorkflowActions.getSubtask(taskId || subtaskSelection?.id, 'general'));
      }
      if (
        updateRequirementTaskCalled &&
        !updateRequirementTaskLoading &&
        !updateRequirementTaskError
      ) {
        dispatch(WorkflowActions.resetUpdateRequirementTask());
      }
      if (saveTaskCalled && !saveTaskLoading && !saveTaskError && updatingRequirement) {
        dispatch(WorkflowActions.resetGetTasks());
        dispatch(WorkflowActions.resetSaveTask());
      }
      if (saveTaskCalled && !saveTaskLoading && !saveTaskError && !updatingRequirement) {
        if (!isMobile && !isTablet) {
          dispatch(WorkflowActions.resetGetTasks());
          dispatch(WorkflowActions.resetSaveTask());
          onClose();
        }
      }
      if (subtaskSelection) {
        setCreating(false);
        // setEditing(false);
        if (subtaskSelection.requirements) {
          const dataPoints = subtaskSelection.requirements
            ?.filter((requirement) => requirement.type === 'dataPoint')
            .map((requirement) => {
              return {
                id: requirement.id,
                requirement: requirement.requirement,
                requiredForPayment: requirement.requiredForPayment,
              };
            });
          setDataPoints(dataPoints);

          const documents = subtaskSelection.requirements
            ?.filter((requirement) => requirement.type === 'documentation')
            .map((requirement) => {
              return {
                id: requirement.id,
                requirement: requirement.requirement,
                requiredForPayment: requirement.requiredForPayment,
                requiredForReview: requirement.requiredForReview,
              };
            });
          setDocuments(documents);
        }
      } else {
        setCreating(true);
        // setEditing(false);
      }
      if (usersInviteTask) {
        setEditing(true);
      }
      if (deleteTaskCalled && !deleteTaskLoading && !deleteTaskError) {
        dispatch(WorkflowActions.resetDeleteTask());
        !desktop && history.goBack();
      }
      return () => {};
    }, [
      dispatch,
      history,
      subtaskSelection,
      deleteTaskCalled,
      deleteTaskLoading,
      deleteTaskError,
      saveTaskCalled,
      saveTaskLoading,
      saveTaskError,
      updatingRequirement,
    ]);

    function updateRequirement(requirement) {
      setUpdatingRequirement(true);
      const data = {};
      data.requirements = { updated: [requirement] };
      if (subtaskSelection) {
        const newTaskSelection = JSON.parse(JSON.stringify(subtaskSelection));
        newTaskSelection.requirements[
          newTaskSelection.requirements.findIndex((el) => el.id === requirement.id)
        ].completed = requirement.completed;
        dispatch(WorkflowActions.updateTaskSelection(newTaskSelection));
        dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'general'));
      }
    }

    return (
      <Box
        className={clsx(styles.main_content)}
        style={isMobile || isTablet ? { paddingBottom: 120 } : {}}
      >
        <Container maxWidth="md" className={desktop ? styles.content_desktop : styles.content}>
          {!completing && (
            <Box>
              {desktop && (
                <Box className={styles.container_step_description}>
                  <Box width="100%" style={{ marginBottom: 10 }} className="subtitle_container">
                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                      <Box>
                        {t('contracts.details.workflow.generalPage.title', {
                          action: !(taskId || subtaskSelection?.id)
                            ? `${t('contracts.details.workflow.add')} `
                            : `${t('contracts.details.workflow.edit')} `,
                        })}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="description_container">
                    {t('contracts.details.workflow.generalPage.description')}
                  </Box>
                </Box>
              )}
              {/* <Box className={styles.collaborators}>
                {(!taskSelection || taskSelection.status !== 'completed') && (
                  <Button
                    onPress={() => {
                      dispatch(InvitesActions.resetGetRoles());
                      desktop
                        ? setOpenCollaboratorsModal(true)
                        : history.push('/contracts/workflows/invite-collaborators-task/general');
                    }}
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      maxWidth: 30,
                      maxHeight: 30,
                      margin: 5,
                    }}
                  >
                    <AddIcon style={{ fontSize: 10 }} />
                  </Button>
                )}
                {taskSelection?.collaborators &&
                  taskSelection?.collaborators?.map((item, index) => (
                    <UserPhoto
                      key={index}
                      className={styles.box_image}
                      initials={
                        (item?.firstName ?? '').slice(0, 1) + (item?.lastName ?? '').slice(0, 1)
                      }
                    />
                  ))}
                {usersInviteTask &&
                  usersInviteTask?.map((item, index) => (
                    <UserPhoto
                      key={index}
                      className={styles.box_image}
                      initials={(item?.email ?? '').slice(0, 1) + (item?.email ?? '').slice(1, 2)}
                    />
                  ))}
              </Box> */}
            </Box>
          )}
          {!creating && !editing && subtaskSelection && !completing && (
            <Box className={styles.see_box}>
              <Divider />
              <Box className={styles.volume}>
                {`${subtaskSelection?.title?.toUpperCase() || ''}`}
              </Box>
              {subtaskSelection?.referenceNumber && (
                <Box className={styles.row}>
                  {t('contracts.details.workflow.deliveryPage.referenceNumber')} {': '}
                  <Box className={styles.reference_number}>
                    {subtaskSelection?.referenceNumber || ''}
                  </Box>
                </Box>
              )}
              <Box className={styles.row}>
                <Box className={styles.description}>{subtaskSelection?.description || ''}</Box>
              </Box>
              <Box className={styles.row}>
                {t('contracts.details.workflow.generalPage.completionDate')} {': '}
                <Box className={styles.completion_date}>
                  {subtaskSelection?.completionDate
                    ? moment(subtaskSelection?.completionDate).format('L') || ''
                    : t('contracts.details.workflow.nominationPage.tbd')}
                </Box>
              </Box>
              {subtaskSelection?.requirements?.filter(
                (requirement) => requirement.type === 'dataPoint'
              ).length > 0 && (
                <Box>
                  <Box className={styles.requirements}>
                    {t('contracts.details.workflow.documentRequired').toUpperCase()}
                  </Box>
                  <Box className={styles.requirements_description}>
                    {t('contracts.details.workflow.documentRequiredDescription')}
                  </Box>
                </Box>
              )}
              <Box className={styles.requirement_list}>
                {subtaskSelection?.requirements &&
                  subtaskSelection?.requirements
                    ?.filter((requirement) => requirement.type === 'dataPoint')
                    .map((item, index) => (
                      <Box key={index} className={styles.requirement_item}>
                        <CheckBox
                          checked={item.completed}
                          onChange={(event) => {
                            updateRequirement({ id: item.id, completed: event.target.checked });
                          }}
                          disabled={subtaskSelection.status === 'completed'}
                        />
                        <Box className={styles.requirement}>
                          {item.requirement}
                          {item.requiredForPayment && (
                            <Box className={styles.required_for_payment}>
                              {`(${t('contracts.details.workflow.requiredForPayment')})`}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
              </Box>

              {subtaskSelection?.requirements?.filter(
                (requirement) => requirement.type === 'documentation'
              ).length > 0 && (
                <Box>
                  <Box className={styles.requirements}>
                    {t('contracts.details.workflow.documentRequired').toUpperCase()}
                  </Box>
                  <Box className={styles.requirements_description}>
                    {t('contracts.details.workflow.dataPointRequiredDescription')}
                  </Box>
                </Box>
              )}
              <Box className={styles.requirement_list}>
                {subtaskSelection?.requirements &&
                  subtaskSelection?.requirements
                    ?.filter((requirement) => requirement.type === 'documentation')
                    .map((item, index) => (
                      <Box key={index} className={styles.requirement_item}>
                        <CheckBox
                          checked={item.completed}
                          onChange={(event) => {
                            updateRequirement({ id: item.id, completed: event.target.checked });
                          }}
                          disabled={subtaskSelection.status === 'completed'}
                        />
                        <Box className={styles.requirement}>
                          {item.requirement}
                          {item.requiredForPayment && (
                            <Box className={styles.required_for_payment}>
                              {`(${t('contracts.details.workflow.requiredForPayment')})`}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
              </Box>

              {subtaskSelection?.status === 'completed' && (
                <Box>
                  <Box className={styles.requirements}>
                    {t('contracts.details.workflow.generalPage.comments').toUpperCase()}
                  </Box>
                  <Box className={styles.requirements_description}>
                    {subtaskSelection?.comments}
                  </Box>
                </Box>
              )}
              {subtaskSelection?.status !== 'completed' && (
                <Box className={styles.container_submit}>
                  <Button
                    disabled={
                      subtaskSelection?.requirements?.filter(
                        (requirement) => requirement.completed !== true
                      ).length > 0
                    }
                    className={styles.button_alone}
                    minWidth={130}
                    maxWidth={270}
                    onPress={() => setCompleting(true)}
                  >
                    {t('contracts.details.workflow.generalPage.markDone')}
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {(creating || editing || completing) && (
            <Box>
              <Formik
                initialValues={{
                  referenceNumber: subtaskSelection?.referenceNumber || task?.referenceNumber || '',
                  title: subtaskSelection?.title || '',
                  description: subtaskSelection?.description || '',
                  completionDate: subtaskSelection?.completionDate || '',
                  dataPoint: '',
                  documentRequired: '',
                  tabIndex: 0,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid
                      container
                      style={{ paddingBottom: isMobile && !isTablet ? 100 : 0 }}
                      spacing={2}
                    >
                      <Grid item xs={12} sm={12} md={6} className={styles.container_inputs}>
                        <Box className={styles.container_input}>
                          <TextField
                            name="referenceNumber"
                            value={values.referenceNumber}
                            onChange={handleChange}
                            helperText={
                              errors.referenceNumber &&
                              touched.referenceNumber &&
                              errors.referenceNumber
                            }
                            placeholder={t(
                              'contracts.details.workflow.deliveryPage.referenceNumber'
                            )}
                            label={t(
                              'contracts.details.workflow.deliveryPage.referenceNumber'
                            ).toUpperCase()}
                          />
                        </Box>

                        {!completing && (
                          <Box className={styles.container_input}>
                            <TextField
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              helperText={errors.title && touched.title && errors.title}
                              placeholder={t('contracts.details.workflow.generalPage.titleInput')}
                              label={t(
                                'contracts.details.workflow.generalPage.titleInput'
                              ).toUpperCase()}
                              required
                            />
                          </Box>
                        )}
                        {!completing && (
                          <Box className={styles.container_input}>
                            <TextField
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              helperText={
                                errors.description && touched.description && errors.description
                              }
                              placeholder={t(
                                'contracts.details.workflow.generalPage.descriptionInput'
                              )}
                              label={t(
                                'contracts.details.workflow.generalPage.descriptionInput'
                              ).toUpperCase()}
                              multiline
                              rows={7}
                              required
                            />
                          </Box>
                        )}
                        <Box className={styles.container_input}>
                          <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <MobileDatePicker
                                style={{ width: 'auto' }}
                                name="completionDate"
                                renderInput={(props) => {
                                  const rangeProps = {
                                    // ...props,
                                    helperText: '',
                                    inputProps: {
                                      ...props.inputProps,
                                      value:
                                        values.completionDate !== ''
                                          ? moment(values.completionDate).format('L')
                                          : '',
                                      onBlur: props.inputProps.onBlur,
                                      onFocus: props.inputProps.onFocus,
                                      onChange: props.inputProps.onChange,
                                    },
                                  };
                                  return (
                                    <Box style={{ width: '100%' }}>
                                      <TextField
                                        {...rangeProps}
                                        variant="filled"
                                        value=""
                                        helperText={
                                          errors.completionDate &&
                                          touched.completionDate &&
                                          errors.completionDate
                                        }
                                        placeholder={t(
                                          'contracts.details.workflow.generalPage.completionDate'
                                        )}
                                        label={t(
                                          'contracts.details.workflow.generalPage.completionDate'
                                        ).toUpperCase()}
                                        icon={
                                          <SvgIcon
                                            style={{ width: 18, height: 18 }}
                                            viewBox="0 0 22 22"
                                            component={CalendarIcon}
                                          />
                                        }
                                      />
                                    </Box>
                                  );
                                }}
                                value={values.completionDate}
                                onChange={(completionDate) => {
                                  values.completionDate = completionDate;
                                }}
                              />
                            </ThemeProvider>
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      {/* <Divider
                        style={{ marginRight: 40, marginLeft: 40 }}
                        orientation="vertical"
                        flexItem
                      /> */}

                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        spacing={2}
                        direction="row"
                        style={!isMobile || isTablet ? { overflow: 'scroll', maxHeight: 455 } : {}}
                      >
                        {!completing && (
                          <>
                            {(isMobile || isTablet || values.tabIndex === 0) && (
                              <Grid
                                item
                                xs={12}
                                sm={isTablet ? 6 : 12}
                                className={styles.container_inputs}
                              >
                                <TabComponent values={values} handleChange={handleChange} />
                                {(isMobile || isTablet) && (
                                  <Box
                                    className={styles.container_requirement_description}
                                    style={{ marginTop: 20 }}
                                  >
                                    <Box
                                      style={{ marginBottom: 10 }}
                                      className={styles.title_requirement}
                                    >
                                      {t('contracts.details.workflow.dataPointRequired')}
                                    </Box>
                                    <Box className="subtitle">
                                      {t('contracts.details.workflow.dataPointDescription')}
                                    </Box>
                                  </Box>
                                )}
                                <Box className={styles.container_input}>
                                  <Box className={styles.check_container}>
                                    <CheckBox
                                      checked={newDataPoint?.requiredForPayment}
                                      onChange={(event) => {
                                        setNewDataPoint({
                                          requirement: newDataPoint?.requirement || '',
                                          requiredForPayment: event.target.checked,
                                        });
                                      }}
                                    />
                                    <Box className={styles.label}>
                                      {t('contracts.details.workflow.requiredForPayment')}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={clsx(styles.container_input, styles.container_inputs2)}
                                >
                                  <TextField
                                    value={newDataPoint.requirement}
                                    onChange={(e) => {
                                      setNewDataPoint({
                                        requirement: e.target.value,
                                        requiredForPayment:
                                          newDataPoint?.requiredForPayment || false,
                                      });
                                    }}
                                  />
                                  <Box
                                    className={styles.buttonAdd}
                                    onClick={() => {
                                      if (!(saveTaskLoading || newDataPoint?.requirement === '')) {
                                        setDataPoints([...dataPoints, newDataPoint]);
                                        setNewDataPoint({
                                          requirement: '',
                                          requiredForPayment: false,
                                        });
                                      }
                                    }}
                                  >
                                    {t('contracts.addCollaborators.add')}
                                  </Box>
                                </Box>
                                <Divider />
                                <Box className={styles.list}>
                                  {dataPoints?.map((value, index) => {
                                    if (value.deleted) {
                                      return null;
                                    }
                                    return (
                                      <Box key={index}>
                                        <Box className={styles.item_container}>
                                          <Box className={styles.label}>
                                            {value.requirement.toUpperCase()}
                                            {value.requiredForPayment && (
                                              <Box className={styles.rfp}>
                                                (
                                                {t('contracts.details.workflow.requiredForPayment')}
                                                )
                                              </Box>
                                            )}
                                          </Box>
                                          <Box className={styles.item}>
                                            <Button
                                              align="left"
                                              padding="0 10px 0 10px"
                                              margin={0}
                                              clean
                                              className={styles.action}
                                              onPress={() => {
                                                const newData = { ...value };
                                                newData.deleted = true;
                                                setDataPoints((dataPoint) => [
                                                  ...dataPoint?.filter(
                                                    (el) => el.requirement !== value.requirement
                                                  ),
                                                ]);
                                                if (value.id) {
                                                  setDataPoints((dataPoint) => [
                                                    ...dataPoint,
                                                    newData,
                                                  ]);
                                                }
                                              }}
                                            >
                                              <SvgIcon
                                                style={{ height: 18, width: 18 }}
                                                component={DeleteIcon}
                                                viewBox="0 0 17 17"
                                              />
                                            </Button>
                                          </Box>
                                        </Box>
                                        <Divider />
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Grid>
                            )}
                            {(isMobile || isTablet || values.tabIndex === 1) && (
                              <Grid
                                item
                                xs={12}
                                sm={isTablet ? 6 : 12}
                                className={styles.container_inputs}
                              >
                                <TabComponent values={values} handleChange={handleChange} />
                                {(isMobile || isTablet) && (
                                  <Box
                                    className={styles.container_requirement_description}
                                    style={{ marginTop: 20 }}
                                  >
                                    <Box
                                      style={{ marginBottom: 10 }}
                                      className={styles.title_requirement}
                                    >
                                      {t('contracts.details.workflow.documentRequired')}
                                    </Box>
                                    <Box className="subtitle">
                                      {t('contracts.details.workflow.documentDescription')}
                                    </Box>
                                  </Box>
                                )}
                                <Box className={styles.container_inputs2}>
                                  <Box className={styles.container_input}>
                                    <Box className={styles.check_container}>
                                      <CheckBox
                                        checked={newDocument.requiredForPayment}
                                        onChange={(event) => {
                                          setNewDocument({
                                            requirement: newDocument.requirement,
                                            requiredForPayment: event.target.checked,
                                            requiredForReview: newDocument.requiredForReview,
                                          });
                                        }}
                                      />
                                      <Box className={styles.label}>
                                        {t('contracts.details.workflow.requiredForPayment')}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box className={styles.container_input}>
                                    <Box className={styles.check_container}>
                                      <CheckBox
                                        checked={newDocument.requiredForReview}
                                        onChange={(event) => {
                                          setNewDocument({
                                            requirement: newDocument.requirement,
                                            requiredForPayment: newDocument.requiredForPayment,
                                            requiredForReview: event.target.checked,
                                          });
                                        }}
                                      />
                                      <Box className={styles.label}>
                                        {t('contracts.details.workflow.requiredForReview')}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={clsx(styles.container_input, styles.container_inputs2)}
                                >
                                  <TextField
                                    value={newDocument.requirement}
                                    onChange={(e) => {
                                      setNewDocument({
                                        requirement: e.target.value,
                                        requiredForPayment: newDocument.requiredForPayment,
                                        requiredForReview: newDocument.requiredForReview,
                                      });
                                    }}
                                  />
                                  <Box
                                    className={styles.buttonAdd}
                                    onClick={() => {
                                      if (!(saveTaskLoading || newDocument?.requirement === '')) {
                                        setDocuments([...documents, newDocument]);
                                        setNewDocument({
                                          requirement: '',
                                          requiredForPayment: false,
                                          requiredForReview: false,
                                        });
                                      }
                                    }}
                                  >
                                    {t('contracts.addCollaborators.add')}
                                  </Box>
                                </Box>
                                <Box className={styles.list}>
                                  {documents?.map((value, index) => {
                                    if (value.deleted) {
                                      return null;
                                    }
                                    return (
                                      <Box key={index}>
                                        <Box className={styles.item_container}>
                                          <Box className={styles.label}>
                                            {value.requirement.toUpperCase()}
                                            {value.requiredForPayment && (
                                              <Box className={styles.rfp}>
                                                (
                                                {t('contracts.details.workflow.requiredForPayment')}
                                                )
                                              </Box>
                                            )}
                                          </Box>
                                          <Box className={styles.item}>
                                            <Button
                                              align="left"
                                              padding="0 10px 0 10px"
                                              margin={0}
                                              clean
                                              className={styles.action}
                                              onPress={() => {
                                                const newData = { ...value };
                                                newData.deleted = true;
                                                setDocuments((document) => [
                                                  ...document?.filter(
                                                    (el) => el.requirement !== value.requirement
                                                  ),
                                                ]);
                                                if (value.id) {
                                                  setDocuments((document) => [
                                                    ...document,
                                                    newData,
                                                  ]);
                                                }
                                              }}
                                            >
                                              <SvgIcon
                                                style={{ height: 18, width: 18 }}
                                                component={DeleteIcon}
                                                viewBox="0 0 17 17"
                                              />
                                            </Button>
                                          </Box>
                                        </Box>
                                        <Divider />
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                      {completing && (
                        <Box style={{ marginTop: 15, marginBottom: 15 }}>
                          <Box
                            style={{
                              fontFamily: 'FuturaPT-Medium',
                              fontSize: '16.5px',
                              fontWeight: 500,
                              letterSpacing: '0.92px',
                              textAlign: 'center',
                            }}
                          >
                            {t('contracts.details.workflow.recipients')}
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              marginTop: 10,
                            }}
                          >
                            {subtaskSelection?.collaborators?.map((item, index) => (
                              <Recipients key={index} user={item} />
                            ))}
                          </Box>
                        </Box>
                      )}
                      {completing && (
                        <Box className={styles.container_inputs}>
                          <Box className={styles.container_input}>
                            <TextField
                              name="comments"
                              value={values.comments}
                              onChange={handleChange}
                              helperText={errors.comments && touched.comments && errors.comments}
                              placeholder={t('contracts.details.workflow.generalPage.comments')}
                              label={t(
                                'contracts.details.workflow.generalPage.comments'
                              ).toUpperCase()}
                              multiline
                              rows={15}
                              required
                            />
                          </Box>
                        </Box>
                      )}
                      <Grid item xs={12} md={12} className={styles.container_inputs}>
                        <Box>
                          {!completing && (
                            <Box className={styles.container_submit}>
                              <Box className={styles.box_two_options}>
                                <Button
                                  inverted
                                  bordered
                                  style={{ marginRight: 10 }}
                                  className={styles.button}
                                  onPress={() => {
                                    if (isMobile || isTablet) {
                                      history.goBack();
                                    } else {
                                      onClose();
                                    }
                                  }}
                                >
                                  {t('common.cancel')}
                                </Button>
                                <Button
                                  type="submit"
                                  disabled={saveTaskLoading}
                                  loading={saveTaskLoading}
                                  style={{ marginLeft: 10 }}
                                  className={styles.button}
                                  data-info-submit="Submit"
                                  minWidth={130}
                                  maxWidth={270}
                                >
                                  {t('contracts.details.workflow.generalPage.add')}
                                </Button>
                              </Box>
                            </Box>
                          )}
                          {completing && (
                            <Box className={styles.container_submit}>
                              <Box className={styles.box_two_options}>
                                <Button
                                  inverted
                                  bordered
                                  className={styles.button}
                                  onPress={() => {
                                    setCompleting(false);
                                  }}
                                >
                                  {t('common.cancel')}
                                </Button>
                                <Box style={{ width: 20 }} />
                                <Button
                                  className={styles.button}
                                  onPress={() => {
                                    handleSubmit();
                                  }}
                                >
                                  {t('common.confirm')}
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Container>
      </Box>
    );
  };

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={
          saveTaskLoading || deleteTaskLoading || updateRequirementTaskLoading || getSubtaskLoading
        }
      />
      {desktop ? (
        <PageContent />
      ) : (
        <NavigationBar
          title={t('contracts.details.workflow.generalPage.title', {
            action: !(taskId || subtaskSelection?.id)
              ? `${t('contracts.details.workflow.add')} `
              : `${t('contracts.details.workflow.edit')} `,
          })}
          subtitle={t('contracts.details.workflow.subtaskSubtitle', {
            date: parentTaskSelection?.date
              ? moment(parentTaskSelection?.date).format('DD MMM')
              : '',
            volume: parentTaskSelection?.volume || '',
          })}
          hideTitle={!isMobile}
          hasMenuButton
          barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
          white={!isMobile || isTablet}
          hasNotificationButton={!isMobile && !isTablet}
          history={history}
          user={user}
          menuList={[
            {
              label: t('common.goHome'),
              action: () => {
                history.replace('/home');
              },
            },
          ]}
          onToggleDrawer={(opened) => {
            setDrawerOpened(opened);
          }}
          onLogout={() => {
            dispatch(AuthActions.logout());
          }}
          pageContent={<PageContent />}
        />
      )}

      {getSubtaskCalled && !getSubtaskLoading && getSubtaskError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getSubtaskError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(WorkflowActions.resetGetSubtaskError());
          }}
        />
      )}

      {saveTaskCalled && !saveTaskLoading && !saveTaskError && !updatingRequirement && (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.details.workflow.sentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(WorkflowActions.resetGetTasks());
            !desktop && history.goBack();
            dispatch(WorkflowActions.resetSaveTask());
          }}
        />
      )}
      {saveTaskCalled && !saveTaskLoading && saveTaskError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={saveTaskError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(WorkflowActions.resetSaveTaskError());
          }}
        />
      )}

      {deleteTaskCalled && !deleteTaskLoading && deleteTaskError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={deleteTaskError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(WorkflowActions.resetDeleteTaskError());
          }}
        />
      )}

      {updateRequirementTaskCalled && !updateRequirementTaskLoading && updateRequirementTaskError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={updateRequirementTaskError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(WorkflowActions.resetUpdateRequirementTaskError());
          }}
        />
      )}

      {deleteConfirmation && (
        <MessageModal
          title=""
          icon={null}
          message={t('common.areYouSure')}
          secundaryAction={t('common.no')}
          secundaryEvent={() => {
            setDeleteConfirmation(false);
          }}
          primaryAction={t('common.yes')}
          primaryEvent={() => {
            setDeleteConfirmation(false);
            deleteTask();
          }}
        />
      )}

      {openCollaboratorsModal && (
        <InviteCollaboratorsTaskModal
          history={history}
          type="general"
          onClose={() => setOpenCollaboratorsModal(false)}
        />
      )}
    </Box>
  );
};

export default withTranslation()(CreateGeneralTask);
