import React from 'react';
import Box from '@material-ui/core/Box';

import LogoImageDefault from '../assets/images/logo.png';
import LogoWhiteImageDefault from '../assets/images/logo-white.png';
import LogoSmallImageDefault from '../assets/images/logo-small.png';
import LogoWhiteSmallImageDefault from '../assets/images/logo-small-white.png';

import styles from './styles/logo.module.sass';

const Logo = ({ white = false, small = false, align = 'center', onClick }) => {
  let logoImage = null;
  if (small) {
    if (white) {
      logoImage = LogoWhiteSmallImageDefault;
    } else {
      logoImage = LogoSmallImageDefault;
    }
  } else if (white) {
    logoImage = LogoWhiteImageDefault;
  } else {
    logoImage = LogoImageDefault;
  }

  return (
    <Box
      style={{ justifyContent: align === 'center' ? 'center' : 'flex-start' }}
      className={styles.container_logo}
      onClick={onClick}
    >
      {/* <SvgIcon component={ logoImage } viewBox={ white ? "0 0 180 28" : "0 0 132 19" } /> */}
      <img src={logoImage} alt="" />
    </Box>
  );
};

export default Logo;
