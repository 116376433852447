import { createActions } from 'redux-actions';

import { registerNotifications, getNotifications } from '../../services/notifications';

import { GenericError } from '../../utils/errors';

// import {
//   putMe,
// } from '../../services/auth'

// import AuthActions from '../auth/actions'

const NotificationActions = createActions({
  REGISTER_PUSH_NOTIFICATION_REQUEST: () => {},
  REGISTER_PUSH_NOTIFICATION_SUCCESS: (token) => ({ token }),
  REGISTER_PUSH_NOTIFICATION_FAILED: (error) => ({ error }),

  GET_NOTIFICATIONS_REQUEST: () => {},
  GET_NOTIFICATIONS_SUCCESS: (notifications, page) => ({ notifications, page }),
  GET_NOTIFICATIONS_FAILED: (error) => ({ error }),

  ADD_PUSH_NOTIFICATION_SUCCESS: (notification) => ({ notification }),
  CLEAR_PUSH_NOTIFICATION_SUCCESS: () => {},

  CLEAR: () => {},
});

NotificationActions.registerNotifications = () => {
  return async (dispatch, getState) => {
    dispatch(NotificationActions.registerPushNotificationRequest());
    try {
      const token = await registerNotifications();

      if (token === undefined) {
        return dispatch(
          NotificationActions.registerPushNotificationFailed(
            new GenericError('Push notification token denied')
          )
        );
      }

      const currentUser = getState().auth.user;

      if (token === currentUser.fcm_token) {
        return dispatch(NotificationActions.registerPushNotificationSuccess(token));
      }

      // const response = await putMe({ fcm_token: token })
      // const newUser = currentUser.merge({ fcm_token: response?.data.data.fcm_token })
      // dispatch(AuthActions.updateUser(newUser))

      dispatch(NotificationActions.registerPushNotificationSuccess(token));
    } catch (e) {
      dispatch(NotificationActions.registerPushNotificationFailed(new GenericError(e)));
    }
  };
};

NotificationActions.getNotifications = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(NotificationActions.getNotificationsRequest());
      const result = await getNotifications(params);
      if (result.ok) {
        dispatch(NotificationActions.getNotificationsSuccess(result.data.data, params.page));
      } else if (result.data) {
        dispatch(
          NotificationActions.getNotificationsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else {
        dispatch(
          NotificationActions.getNotificationsFailed(
            new GenericError('something went wrong on get notifications')
          )
        );
      }
    } catch (err) {
      dispatch(NotificationActions.getNotificationsFailed(new GenericError(err, err)));
    }
  };
};

NotificationActions.addPushNotification = (notification) => {
  return async (dispatch, getState) => {
    try {
      return dispatch(
        NotificationActions.addPushNotificationSuccess({ ...notification, date: 'Today' })
      );
    } catch (e) {
      // dispatch(NotificationActions.registerPushNotificationFailed(new GenericError(e)));
      console.log('error add push notification: ', e);
    }
  };
};

NotificationActions.clearPushNotification = () => {
  return async (dispatch, getState) => {
    try {
      return dispatch(NotificationActions.clearPushNotificationSuccess());
    } catch (e) {
      // dispatch(NotificationActions.registerPushNotificationFailed(new GenericError(e)));
      console.log('error clear push notification: ', e);
    }
  };
};

export default NotificationActions;
