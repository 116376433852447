import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Menu,
  Grid,
  GridList,
  GridListTile,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isMobile, isTablet } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import MoreVert from '@material-ui/icons/MoreVert';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import moment from 'moment';
import _ from 'lodash';
import DocumentationCard from '../../../components/DocumentationCard';
import {
  getContractDocuments,
  uploadDocumentContract,
  getContractNominations,
  getContractDocTypes,
  deleteContractDoc,
} from '../../../services/contracts';
import AuthActions from '../../../redux/auth/actions';
import ContractsActions from '../../../redux/contracts/actions';

import NavigationBar from '../../../components/NavigationBar';
import Loading from '../../../components/loading';
import ModalView from '../../../modals/modalView';
import Button from '../../../components/Button';
import RowFilters from './rowFilters';
import MobileFilters from './mobileFilters';

import styles from './styles.module.sass';

const Documents = ({ history, t, width }) => {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('1920'));
  const lg = useMediaQuery(theme.breakpoints.up('800'));
  const md = useMediaQuery(theme.breakpoints.up('600'));
  const xs = useMediaQuery(theme.breakpoints.up('350'));

  const { contractSelection, getContractMeCalled, contractMe } = useSelector(
    (state) => state.contracts
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDocMenu, setShowDocMenu] = React.useState({});
  const [typeList, setTypeList] = useState('list');
  const [documents, setDocuments] = useState({
    data: [],
    pagination: { currentPage: 1, lastPage: 1 },
  });
  const [allDocuments, setAllDocuments] = useState({
    data: [],
    pagination: { currentPage: 1, lastPage: 1 },
  });
  const [loading, setLoading] = useState(true);
  const [nominations, setNominations] = useState([]);
  const [types, setTypes] = useState([]);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [filters, setFilters] = useState('');

  const acceptMimeTypesList = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];
  const acceptMimeTypes = acceptMimeTypesList.join(', ');

  const setOpenDeleteDocFun = (id) => {
    setOpenDeleteDoc(String(id));
    setAnchorEl(null);
  };

  const handleClick = (event, doc) => {
    setAnchorEl(event.currentTarget);
    setShowDocMenu(doc);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowDocMenu({});
  };

  const openDoc = (doc) => {
    setAnchorEl(null);
    window.open(doc?.documentPath, '_blank');
  };

  const deleteDoc = async () => {
    try {
      setOpenDeleteDoc(false);
      setLoading(true);
      const response = await deleteContractDoc(openDeleteDoc);
      setReloadData((ov) => !ov);
    } catch (e) {
      setLoading(false);
    }
  };

  const changeFile = async (e, id) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append('file', e.target ? e.target.files[0] : e);
      let response = await uploadDocumentContract(id, body);
      response = await getContractDocTypes(contractSelection.id);
      setTypes(_.get(response, 'data.data', []));
      setReloadData((ov) => !ov);
    } catch (e) {
      setLoading(false);
    }
  };

  const openFinder = (id) => {
    setAnchorEl(null);
    const refFinder = document.getElementById('filesearch');
    if (refFinder) {
      refFinder.click();
      refFinder.onchange = (e) => changeFile(e, id);
    }
  };

  const onActionDrop = (doc, action) => {
    if (action === 'replace') {
      openFinder(doc?.id);
    } else if (action === 'download') {
      openDoc(doc);
    } else if (action === 'delete') {
      setOpenDeleteDocFun(doc?.id);
    }
  };

  const renderItems = () => {
    const data = documents?.data || [];
    let returnItem = null;
    if (data.length < 1) {
      returnItem = <Box className={styles.emptyTxt}>There are no documents to show you</Box>;
    } else if (typeList === 'list') {
      returnItem = (
        <Box style={!isMobile || isTablet ? { padding: '0 0 0 20px' } : {}}>
          <TableContainer component={Paper} className={styles.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={styles.hdtable}>
                  <TableCell>{t('documents.documentName')}</TableCell>
                  {(!isMobile || isTablet) && (
                    <>
                      <TableCell>{t('documents.uploadDate')}</TableCell>
                      <TableCell>{t('documents.nominationVol')}</TableCell>
                      <TableCell>{t('documents.UploadedBy')}</TableCell>
                      <TableCell />
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((doc, index) => (
                  <TableRow key={`list-item-doc-${index}`}>
                    {isMobile && !isTablet ? (
                      <TableCell className={styles.rowMobile}>
                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                          <Box>
                            <Box className={styles.rowRfp}>
                              {doc?.requirement?.requiredForPayment ? (
                                <Box
                                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                  className={styles.badge}
                                >
                                  {t('common.rfp')}
                                </Box>
                              ) : null}
                            </Box>
                            <Box className={styles.rowName}>{doc?.title}</Box>
                            <Box className={styles.rowNomination}>
                              {doc?.task?.referenceNumber || '-'} / {doc?.task?.volume || '-'} MT
                            </Box>
                            <Box className={styles.rowDate}>
                              {moment(doc?.createdAt).format('MMM DD · YYYY')}
                            </Box>
                          </Box>
                          <Box className={styles.rowActionsDoc}>
                            <Box className={styles.actionBtn} onClick={(e) => handleClick(e, doc)}>
                              <MoreVert />
                            </Box>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              classes={{ paper: styles.ellipsisMenu }}
                              elevation={0}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              {showDocMenu?.documentPath && (
                                <MenuItem
                                  component="div"
                                  classes={{ root: styles.itemMenuEllipsis }}
                                  onClick={() => openDoc(showDocMenu)}
                                >
                                  {t('documents.download')}
                                </MenuItem>
                              )}
                              <MenuItem
                                component="div"
                                classes={{ root: styles.itemMenuEllipsis }}
                                onClick={() => openFinder(showDocMenu?.id)}
                              >
                                {t('documents.replaceDoc')}
                              </MenuItem>
                              {showDocMenu?.documentPath &&
                                showDocMenu?.updateUser?.id === contractMe?.user && (
                                  <MenuItem
                                    component="div"
                                    classes={{ root: styles.itemMenuEllipsis }}
                                    onClick={() => setOpenDeleteDocFun(showDocMenu?.id)}
                                  >
                                    {t('documents.deleteDoc')}
                                  </MenuItem>
                                )}
                            </Menu>
                          </Box>
                        </Box>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell className={styles.rowName}> {doc?.title} </TableCell>
                        <TableCell className={styles.rowDate}>
                          {moment(doc?.createdAt).format('MMM DD · YYYY')}
                        </TableCell>
                        <TableCell className={styles.rowNomination}>
                          {doc?.task?.referenceNumber || '-'} / {doc?.task?.volume || '-'} MT
                        </TableCell>
                        <TableCell>
                          <Box className={styles.rowProfileDoc}>
                            {doc?.createUser?.profileImage && (
                              <img src={doc?.createUser?.profileImage} alt="profile-doc-img" />
                            )}
                            <p>
                              {' '}
                              {doc?.createUser?.firstName} {doc?.createUser?.lastName}{' '}
                            </p>
                            {doc?.requirement?.requiredForPayment ? (
                              <Box className={styles.badge}>{t('common.rfp')}</Box>
                            ) : null}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className={styles.rowActionsDoc}>
                            <Box className={styles.actionBtn} onClick={(e) => handleClick(e, doc)}>
                              <MoreVert />
                            </Box>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              classes={{ paper: styles.ellipsisMenu }}
                              elevation={0}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              {showDocMenu?.documentPath && (
                                <MenuItem
                                  component="div"
                                  classes={{ root: styles.itemMenuEllipsis }}
                                  onClick={() => openDoc(showDocMenu)}
                                >
                                  {t('documents.download')}
                                </MenuItem>
                              )}
                              <MenuItem
                                component="div"
                                classes={{ root: styles.itemMenuEllipsis }}
                                onClick={() => openFinder(showDocMenu?.id)}
                              >
                                {t('documents.replaceDoc')}
                              </MenuItem>
                              {showDocMenu?.documentPath &&
                                showDocMenu?.updateUser?.id === contractMe?.user && (
                                  <MenuItem
                                    component="div"
                                    classes={{ root: styles.itemMenuEllipsis }}
                                    onClick={() => setOpenDeleteDocFun(showDocMenu?.id)}
                                  >
                                    {t('documents.deleteDoc')}
                                  </MenuItem>
                                )}
                            </Menu>
                          </Box>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    } else {
      returnItem = (
        <GridList
          cellHeight={255}
          cols={xl ? 6 : lg ? 4 : md ? 3 : xs ? 2 : 1}
          spacing={10}
          className={styles.gridContainer}
          style={isMobile && !isTablet ? { padding: '0 20px' } : {}}
        >
          {data.map((doc, index) => (
            <GridListTile key={index}>
              <Box
                style={{
                  padding: 5,
                  height: '100%',
                  width: '100%',
                }}
              >
                <DocumentationCard
                  key={`doc-card-list-item-${index}`}
                  customStyles={styles.docCard}
                  isRfp={doc?.requirement?.requiredForPayment}
                  name={doc?.title}
                  date={moment(doc?.createdAt).format('MMM DD · YYYY').toUpperCase()}
                  documentUrl={doc?.documentPath}
                  onUpload={(file) => changeFile(file, doc.id)}
                  menuOptions={[
                    { label: t('documents.download'), action: 'download' },
                    { label: t('documents.replaceDoc'), action: 'replace' },
                    { label: t('documents.deleteDoc'), action: 'delete' },
                  ]}
                  onAction={(action) => {
                    onActionDrop(doc, action);
                  }}
                />
              </Box>
            </GridListTile>
            // <DocumentationCard
            //   key={`doc-card-list-item-${index}`}
            //   customStyles={styles.docCard}
            //   isRfp={doc?.requirement?.requiredForPayment}
            //   name={doc?.title}
            //   date={moment(doc?.createdAt).format('MMM DD · YYYY').toUpperCase()}
            //   documentUrl={doc?.documentPath}
            //   onUpload={(file) => changeFile(file, doc.id)}
            // />
          ))}
        </GridList>
      );
    }
    return returnItem;
  };

  const onChangeView = () => {
    setTypeList(typeList === 'list' ? 'grid' : 'list');
  };

  const getData = async (reloadInf, resetPagination) => {
    try {
      setLoading(true);
      let filtersAux = filters ? `${filters}&` : '?';
      filtersAux = `${filtersAux}page=${
        resetPagination ? 1 : (allDocuments?.pagination?.currentPage || 1) + (reloadInf ? 1 : 0)
      }`;
      const response = await getContractDocuments(contractSelection.id, filtersAux);
      const auxData = _.get(response, 'data.data', {});
      setDocuments((ov) => ({
        ...auxData,
        data: resetPagination ? auxData.data : _.uniqBy([...ov.data, ...auxData.data], 'id'),
      }));
      setAllDocuments((ov) => ({
        ...auxData,
        data: resetPagination ? auxData.data : _.uniqBy([...ov.data, ...auxData.data], 'id'),
      }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage:
      (allDocuments?.pagination?.currentPage || 1) < (allDocuments?.pagination?.lastPage || 1),
    onLoadMore: () => getData(true),
    scrollContainer: 'parent',
  });

  const renderContent = () => (
    <Box style={{ paddingBottom: isTablet ? 130 : isMobile ? 100 : 0 }} ref={infiniteRef}>
      <RowFilters
        showAddFilterButton={isMobile && !isTablet}
        onChangeView={onChangeView}
        typeView={typeList}
        nominations={nominations}
        types={types}
        getData={getData}
        reloadData={reloadData}
        documents={documents?.data}
        allDocuments={allDocuments?.data}
        setDocuments={setDocuments}
        onAddFilters={() => {
          setShowMobileFilters(true);
        }}
        setFilters={setFilters}
      />
      <input type="file" id="filesearch" className={styles.filesearch} accept={acceptMimeTypes} />
      {renderItems()}
    </Box>
  );

  const firstLoad = async () => {
    try {
      let response = await getContractNominations(contractSelection.id);
      setNominations(
        _.get(response, 'data.data', []).map((nomination) => ({
          label: nomination.referenceNumber,
          value: nomination.id,
        }))
      );
      response = await getContractDocTypes(contractSelection.id);
      setTypes(_.get(response, 'data.data', []));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(false, true);
  }, [filters]);

  useEffect(() => {
    firstLoad();
  }, []);

  useEffect(() => {
    if (!getContractMeCalled && contractSelection) {
      dispatch(ContractsActions.getContractMe(contractSelection?.id));
    }
  }, [contractSelection, getContractMeCalled]);

  return (
    <Box className={styles.container}>
      <Loading isLoading={loading} />
      <NavigationBar
        titleDescription={
          showMobileFilters
            ? null
            : {
                title: t('common.menu.documents').toUpperCase(),
                description: '',
              }
        }
        title={showMobileFilters ? t('documents.documentFilter') : null}
        hasMenuButton={!showMobileFilters}
        hasBackButton={showMobileFilters}
        customBack={() => {
          setShowMobileFilters(false);
        }}
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        history={history}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          showMobileFilters ? (
            <MobileFilters
              onChangeView={onChangeView}
              typeView={typeList}
              nominations={nominations}
              types={types}
              getData={getData}
              reloadData={reloadData}
              documents={documents?.data}
              onCancel={() => {
                setShowMobileFilters(false);
              }}
            />
          ) : (
            renderContent()
          )
        }
      />
      {openDeleteDoc && (
        <ModalView
          view={
            <Box>
              <Box className="title" style={{ marginTop: 60, textAlign: 'center', color: 'white' }}>
                {t('documents.deleteDocTitle')}
              </Box>
              <Box
                className="description_container"
                style={{ marginTop: 30, textAlign: 'center', color: 'white' }}
              >
                {t('common.deleteContractDes1')}
              </Box>
              <Box
                className="description_container"
                style={{ marginTop: 30, textAlign: 'center', color: 'white' }}
              >
                {t('common.deleteContractDes2')}
              </Box>
              <Grid
                container
                spacing={6}
                direction="row"
                justify="space-between"
                style={{ marginTop: 60 }}
              >
                <Grid item xs={12} sm={6} container justify="flex-start">
                  <Box style={{ width: '100%' }}>
                    <Button
                      inverted
                      backgroundImage="linear-gradient(90deg, #ffffff 18%, #757e95 100%)"
                      style={{ whiteSpace: 'nowrap' }}
                      onPress={deleteDoc}
                    >
                      {t('common.yesDelete')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} container justify="flex-end">
                  <Box style={{ width: '100%' }}>
                    <Button
                      border="1px solid #000"
                      shading={false}
                      bordered
                      backgroundImage="rgba(255, 255, 255, 0.1)"
                      backgroundColor="transparent"
                      style={{ whiteSpace: 'nowrap', width: '100%' }}
                      onPress={() => setOpenDeleteDoc(false)}
                    >
                      {t('common.noChangeMyMind')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          }
          inverted
          onClose={() => setOpenDeleteDoc(false)}
        />
      )}
    </Box>
  );
};

export default withTranslation()(Documents);
