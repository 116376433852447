import firebase from 'firebase/app';
import 'firebase/messaging';
import api from './api';

export const registerNotifications = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await firebase.messaging().getToken();
      return token;
    }
  } catch (e) {
    console.log(e);
    console.log('Unable to get permission to notify.');
    throw e;
  }
};

export const getNotificationsToken = async () => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken({
      vapidKey:
        'BOrsXoyTHRAieZGVmqhIaS9TCloQy2_hcAZs3e0-EqJyI_ccraSjn6yjWk3cy_t6OBHmjKH4zF-ugdnVMf9wjzs',
    });
    return token;
  } catch (e) {
    console.log(e);
    console.log('Unable to get notifications.');
    throw e;
  }
};

export const getNotifications = (params) => {
  return api.get(`notifications`, params);
};
