import Immutable from 'seamless-immutable';

const initialState = Immutable({
  userContracts: null,
  contractSelection: null,
  contractSelectionStadistics: null,
  totalStadistics: null,
  contractMe: null,

  getUserContractsLoading: false,
  getUserContractsError: null,
  getUserContractsCalled: false,

  getContractMeLoading: false,
  getContractMeError: null,
  getContractMeCalled: false,

  saveUserContractsLoading: false,
  saveUserContractsError: null,
  saveUserContractsCalled: false,
  saveUserContractsSuccess: false,

  getUserContractLoading: false,
  getUserContractError: null,
  getUserContractCalled: false,

  deleteUserContractsLoading: false,
  deleteUserContractsError: null,
  deleteUserContractsCalled: false,

  selectedUserDenied: null,

  updateUserContractLoading: false,
  updateUserContractError: null,
  updateUserContractCalled: false,

  acceptInviteLoading: false,
  acceptInviteError: null,
  acceptInviteCalled: false,

  getTotalStadisticsLoading: false,
  getTotalStadisticsError: null,
  getTotalStadisticsCalled: false,

  filterDateRange: [null],
  filterBuyer: null,
  filterSeller: null,
  filterProduct: null,
  filterSort: null,
  filterStatistic: null,

  getContractsUsersLoading: false,
  getContractsUsersError: null,
  getContractsUsersCalled: false,
  contractsUsers: null,

  getContractsProductsLoading: false,
  getContractsProductsError: null,
  getContractsProductsCalled: false,
  contractsProducts: null,

  autcomplete: null,
  getAutocompleteCalled: false,
  getAutocompleteLoading: false,
  getAutocompleteError: null,
});

export default initialState;
