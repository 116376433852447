import React, { Fragment, useState } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import theme from './theme';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontFamily: 'FuturaPT',
    fontSize: 18,
  },
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundColor: '#fff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    boxShadow: 'inset 3px 3px 8px 0 #ececef, inset -5px -4px 10px 0 #fafbfc',
    border: 'solid 1px #ececec',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#111',
      border: 'solid 1px #ececec',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    boxShadow: 'inset 3px 3px 8px 0 #ececef, inset -5px -4px 10px 0 #fafbfc',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: 'radial-gradient(#111,#111 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#333',
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const INQRadio = ({ title, values, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  function handleChange(event) {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <FormControl>
      <RadioGroup value={value} row onChange={handleChange}>
        <FormLabel
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: 20,
            textAlign: 'center',
            fontFamily: 'FuturaPT',
            fontSize: 18,
            color: '#111',
          }}
        >
          {title}
        </FormLabel>
        {values?.map((value, index) => (
          <FormControlLabel
            key={index}
            value={value}
            control={<StyledRadio />}
            label={
              <Typography style={{ fontFamily: 'FuturaPT', fontSize: 18 }}>{value}</Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default INQRadio;
