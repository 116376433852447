import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

const StepperWithoutBackground = withStyles((theme) => ({
  root: {
    background: 'transparent',
  },
  dot: {
    width: '16px',
    height: '16px',
    objectFit: 'contain',
    borderRadius: '40px',
    boxShadow: 'inset 2px 2px 3px 0 #bdc1d1, inset -2px -2px 6px 0 var(--white)',
    backgroundColor: '#ebecf0',
  },
  dotActive: {
    width: '16px',
    height: '16px',
    objectFit: 'contain',
    borderRadius: '40px',
    boxShadow: '1px 1px 5px 0 #b1b5c6, -2px -3px 8px 0 #fafafc',
    backgroundBlendMode: 'soft-light, normal',
    backgroundColor: 'black',
  },
}))(MobileStepper);

const Stepper = ({ steps, activeStep }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <StepperWithoutBackground
          variant="dots"
          steps={steps}
          position="static"
          activeStep={activeStep}
          color="primary"
        />
      </ThemeProvider>
    </>
  );
};

Stepper.propTypes = {
  steps: PropTypes.number,
};

export default Stepper;
