import { useEffect, useState } from 'react';

export const useOnScreen = ({ ref, rootMargin = '0px' }) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        if (isMounted) {
          setIntersecting(entry.isIntersecting);
        }
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};
