import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Walkthrough from './pages/walkthrough';

import ChangePassword from './pages/profile/changePassword';
import ForgotPassword from './pages/auth/forgotPassword';
import ResetPassword from './pages/auth/resetPassword';
import EmailVerification from './pages/auth/emailVerification';
import setupTOTP from './pages/auth/setupTOTP';
import CreateAccount from './pages/auth/createAccount';
import ValidateToken from './pages/auth/validateToken';
import FirebaseAction from './pages/auth/firebaseActions';
import Login from './pages/auth/login';

import EditProfile from './pages/profile/edit';
import Home from './pages/contracts/home';
import GeneralInformation from './pages/contracts/generalInformation';
import DeniedComment from './pages/contracts/deniedComment';
import ContractSpecs from './pages/contracts/specs';
import OffTakeSchedule from './pages/contracts/offTakeSchedule';
import OtherTerms from './pages/contracts/otherTerms';
import Invites from './pages/contracts/invites';
import RolePermissions from './pages/contracts/rolePermissions';
import ContractDetails from './pages/contracts/details';

import ListOffTakeSchedules from './pages/contracts/workflows/offTakeSchedules';
import OffTakeScheduleDetail from './pages/contracts/workflows/offTakeScheduleDetail';
import CreateGeneralTask from './pages/contracts/workflows/createGeneralTask';
import CreateNominationTask from './pages/contracts/workflows/createNominationTask';
import CreateDeliveryTask from './pages/contracts/workflows/createDeliveryTask';
import InviteCollaboratorsTask from './pages/contracts/workflows/inviteCollaboratorsTask';

import Filters from './pages/contracts/filters';
import PaymentInstructions from './pages/contracts/paymentInstructions';
import PaymentConfirmation from './pages/contracts/paymentConfirmation';
import SeeComment from './pages/contracts/seeComment';

import Documents from './pages/contracts/documents';

import Loading from './pages/loading';
import ExternalContent from './pages/misc/externalContent';

// Create router
const AppRouter = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const checkingUser = useSelector((state) => state.auth.checkingUser);
  const emailVerified = useSelector((state) => state.auth.emailVerified);
  const tokenValid = useSelector((state) => state.auth.tokenValid);

  if (checkingUser) {
    console.log('Rendering loading screen');
    return (
      <BrowserRouter>
        <Switch>
          <Route component={Loading} />
        </Switch>
      </BrowserRouter>
    );
  }

  if (!loggedIn) {
    console.log('Rendering not user authenticated screens');
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Walkthrough} />
          <Route path="/create-account" exact component={CreateAccount} />
          <Route path="/setup-totp" exact component={setupTOTP} />
          <Route path="/email-verification" exact component={EmailVerification} />
          <Route path="/setup-totp" exact component={setupTOTP} />
          <Route path="/validate-token" exact component={ValidateToken} />
          <Route path="/login" exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/reset-password/:code" exact component={ResetPassword} />
          <Route path="/firebase-action" exact component={FirebaseAction} />
          <Route path="/terms" exact component={ExternalContent} />
          <Route path="/privacy" exact component={ExternalContent} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
    );
  }
  if (loggedIn && !emailVerified) {
    console.log('Rendering not email verified screens');
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Walkthrough} />
          <Route path="/login" exact component={Login} />
          <Route path="/setup-totp" exact component={setupTOTP} />
          <Route path="/validate-token" exact component={ValidateToken} />
          <Route path="/email-verification" exact component={EmailVerification} />
          <Route path="/email-verification:code" exact component={EmailVerification} />
          <Route path="/create-account" exact component={CreateAccount} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/reset-password/:code" exact component={ResetPassword} />
          <Route path="/firebase-action" exact component={FirebaseAction} />
          <Route path="/terms" exact component={ExternalContent} />
          <Route path="/privacy" exact component={ExternalContent} />
          <Route render={() => <Redirect to="/email-verification" />} />
        </Switch>
      </BrowserRouter>
    );
  }
  if (loggedIn && !tokenValid) {
    console.log('Rendering validate token');
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Walkthrough} />
          <Route path="/login" exact component={Login} />
          <Route path="/setup-totp" exact component={setupTOTP} />
          <Route path="/validate-token" exact component={ValidateToken} />
          <Route path="/email-verification" exact component={EmailVerification} />
          <Route path="/email-verification:code" exact component={EmailVerification} />
          <Route path="/create-account" exact component={CreateAccount} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/reset-password/:code" exact component={ResetPassword} />
          <Route path="/firebase-action" exact component={FirebaseAction} />
          <Route path="/terms" exact component={ExternalContent} />
          <Route path="/privacy" exact component={ExternalContent} />
          <Route render={() => <Redirect to="/validate-token" />} />
        </Switch>
      </BrowserRouter>
    );
  }

  console.log('Rendering user authenticated screens');

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/setup-totp" exact component={setupTOTP} />
        <Route path="/validate-token/:type" exact component={ValidateToken} />
        <Route path="/home" exact component={Home} />
        <Route path="/filters" exact component={Filters} />
        <Route path="/contracts/details" exact component={ContractDetails} />
        <Route path="/contracts/general-information" exact component={GeneralInformation} />
        <Route path="/contracts/specs" exact component={ContractSpecs} />
        <Route path="/contracts/denied/:type" exact component={DeniedComment} />
        <Route path="/contracts/see-comment" exact component={SeeComment} />
        <Route path="/contracts/payment-instructions" exact component={PaymentInstructions} />
        <Route path="/contracts/payment-confirmation" exact component={PaymentConfirmation} />
        <Route path="/contracts/off-take-schedule" exact component={OffTakeSchedule} />
        <Route path="/contracts/other-terms" exact component={OtherTerms} />
        <Route path="/contracts/invites" exact component={Invites} />
        <Route path="/contracts/invites/role-permissions" exact component={RolePermissions} />
        <Route
          path="/contracts/workflows/list-off-take-schedules"
          exact
          component={ListOffTakeSchedules}
        />
        <Route
          path="/contracts/workflows/off-take-schedule-detail"
          exact
          component={OffTakeScheduleDetail}
        />
        <Route path="/contracts/workflows/general-task" exact component={CreateGeneralTask} />
        <Route path="/contracts/workflows/nomination-task" exact component={CreateNominationTask} />
        <Route path="/contracts/workflows/delivery-task" exact component={CreateDeliveryTask} />
        <Route
          path="/contracts/workflows/invite-collaborators-task/:type"
          exact
          component={InviteCollaboratorsTask}
        />
        <Route path="/contracts/documents/list" exact component={Documents} />
        <Route path="/profile" exact component={EditProfile} />
        <Route path="/change-password" exact component={ChangePassword} />
        <Route path="/terms" exact component={ExternalContent} />
        <Route path="/privacy" exact component={ExternalContent} />
        <Route render={() => <Redirect to="/home" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
