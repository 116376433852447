import { createActions } from 'redux-actions';
import moment from 'moment';
import amplitude from 'amplitude-js';
import firebase from 'firebase/app';

import {
  forgotPassword as forgotPasswordService,
  changePassword as changePasswordService,
  createAccount as createAccountService,
  deleteAccount as deleteAccountService,
  postMe as postMeService,
  getMe as getMeService,
  putMe as putMeService,
  getUsers as getUsersService,
  putValidate2FAToken,
  updateProfile,
  sendEmailVerification,
  verifyEmail as verifyEmailService,
  storeCredentials,
  removeCredentials,
  restoreCredentials,
  checkFirebaseUserSession,
  login as loginService,
  getUserToken,
  setAnalyticsUserId,
  storeTokenValid,
  restoreTokenValid,
  removeTokenValid,
  resetPassword as resetPasswordService,
  sendNotificationsTokenToServer,
  getNotificationsTokenToServer,
} from '../../services/auth';

import reduxStore from '../store';
import { axios } from '../../services/api';
import { uploadFile } from '../../services/files';
import { GenericError } from '../../utils/errors';
import NotificationActions from '../notifications/actions';
import { getNotificationsToken } from '../../services/notifications';

const AuthActions = createActions({
  LOGIN_REQUEST: () => {},
  LOGIN_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  LOGIN_FAILED: (error) => ({ error }),
  RESET_LOGIN_STATE: () => {},

  FORGOT_PASSWORD_REQUEST: () => {},
  FORGOT_PASSWORD_SUCCESS: () => {},
  FORGOT_PASSWORD_FAILED: (error) => ({ error }),
  RESET_FORGOT_PASSWORD: () => {},

  EMAIL_VERIFICATION_REQUEST: () => {},
  EMAIL_VERIFICATION_SUCCESS: () => {},
  EMAIL_VERIFICATION_FAILED: (error) => ({ error }),
  RESET_EMAIL_VERIFICATION: () => {},

  RESET_PASSWORD_REQUEST: () => {},
  RESET_PASSWORD_SUCCESS: () => {},
  RESET_PASSWORD_FAILED: (error) => ({ error }),
  CLEAR_RESET_PASSWORD: () => {},

  CHANGE_PASSWORD_REQUEST: () => {},
  CHANGE_PASSWORD_SUCCESS: () => {},
  CHANGE_PASSWORD_FAILED: (error) => ({ error }),
  CLEAR_CHANGE_PASSWORD: () => {},

  CREATE_ACCOUNT_REQUEST: () => {},
  CREATE_ACCOUNT_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  CREATE_ACCOUNT_FAILED: (error) => ({ error }),
  CLEAR_CREATE_ACCOUNT: () => {},

  UPDATE_ACCOUNT_REQUEST: () => {},
  UPDATE_ACCOUNT_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  UPDATE_ACCOUNT_FAILED: (error) => ({ error }),
  CLEAR_UPDATE_ACCOUNT: () => {},

  UPLOAD_PROFILE_PICTURE_REQUEST: () => {},
  UPLOAD_PROFILE_PICTURE_SUCCESS: () => {},
  UPLOAD_PROFILE_PICTURE_FAILED: (error) => ({ error }),

  STORE_PERSONAL_INFORMATION: (firstName, lastName, email) => ({
    firstName,
    lastName,
    email,
  }),

  LOGGED_IN: () => {},
  LOGGED_OUT: () => {},

  UPDATE_USER: (user, emailVerified) => ({ user, emailVerified }),

  VALIDATE_TOKEN_REQUEST: () => {},
  VALIDATE_TOKEN_SUCCESS: (loginToken, paymentToken) => ({
    loginToken,
    paymentToken,
  }),
  VALIDATE_TOKEN_FAILED: (error) => ({ error }),
  VALIDATE_PAYMENT_TOKEN_FAILED: (error) => ({ error }),
  RESET_VALIDATE_TOKEN: () => {},

  UPDATE_PAYMENT_TOKEN: (paymentToken) => ({ paymentToken }),
  RESET_PAYMENT_TOKEN: () => {},

  GET_USERS_REQUEST: () => {},
  GET_USERS_SUCCESS: (users) => ({ users }),
  GET_USERS_FAILED: (error) => ({ error }),
  RESET_GET_USERS: () => {},
  PAYMENT_REQUIRED: (paymentRequired) => ({ paymentRequired }),
  FORBIDDEN_MESSAGE: (forbidden) => ({ forbidden }),
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorResponse = error.response;
    const { dispatch } = reduxStore;

    /**
     * handle 400 bad request.
     * handle 500 internal server error.
     */
    if (errorResponse?.status === 400 || errorResponse?.status === 500) {
      return Promise.reject(error);
    }

    /**
     * handle 403 forbidden.
     */
    if (errorResponse?.status === 403) {
      dispatch(AuthActions.forbiddenMessage(true));
      return Promise.reject(error);
    }

    /**
     * handle 401 unauthorized.
     */
    if (errorResponse?.status === 401) {
      const canRetry = error?.config?.canRetry === undefined;

      /**
       * try again just once, but updating the token.
       */
      if (canRetry) {
        const userToken = await getUserToken();
        storeCredentials(userToken);
        const retryRequest = {
          ...error?.config,
          canRetry: false,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${userToken}`,
          },
        };
        return axios.request(retryRequest);
      }

      /**
       * if refreshing the token does not work => then => logout
       */
      if (!canRetry) {
        dispatch(AuthActions.logout());
      }
    }
    return Promise.reject(error);
  }
);

AuthActions.loginWithEmailAndPassword = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.resetValidateToken());
      dispatch(AuthActions.loginRequest());
      const firebaseUser = await loginService(email, password);
      const userToken = await getUserToken();
      setAnalyticsUserId(firebaseUser.uid);
      await storeCredentials(userToken);
      try {
        const token = await getNotificationsToken();
        // get stored token
        const storedNotificationsToken = localStorage.getItem('notifications_token');

        // if new token is != to stored token, replace it and send to server
        if (token !== storedNotificationsToken) {
          localStorage.setItem('notifications_token', token);
          const resp = await sendNotificationsTokenToServer(token);
        }
      } catch (err) {
        console.log('error on token: ', err);
      }
      const result = await getMeService(firebaseUser.uid);
      if (result.ok) {
        const user = result.data?.data;
        if (user) {
          const isCurrentTokenValid = restoreTokenValid(firebaseUser.uid);
          const localDate = moment.utc(isCurrentTokenValid);
          const userDate = moment.utc(user.last2FAValid);
          if (localDate.isValid() && userDate.isValid()) {
            if (localDate.isSame(userDate)) {
              if (moment().diff(userDate, 'days') === 0) {
                dispatch(AuthActions.validateTokenSuccess(true, false));
              } else {
                dispatch(AuthActions.validateTokenFailed());
              }
            } else {
              dispatch(AuthActions.validateTokenFailed());
            }
          }
          dispatch(AuthActions.resetValidateToken());
          dispatch(AuthActions.loginSuccess(user, firebaseUser.emailVerified));
          dispatch(AuthActions.loggedIn());

          if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
            console.log('Amplitude initialized');
            amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, user.id);
          }

          return;
        }
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(AuthActions.loginFailed(null));
      } else if (result.data) {
        dispatch(AuthActions.loginFailed(new GenericError(result.data.error, result.data.message)));
      }
      dispatch(AuthActions.loginFailed(new GenericError('something went wrong on login')));
    } catch (err) {
      dispatch(AuthActions.loginFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.createAccount = (params, secret) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.createAccountRequest());
      const firebaseUser = await createAccountService({ ...params });
      const result = await postMeService({
        ...params,
        uid: firebaseUser.uid,
        secret,
      });
      if (result.ok && result.data?.data) {
        const userToken = await getUserToken();
        await updateProfile(params);
        await sendEmailVerification();
        const user = result.data.data;
        setAnalyticsUserId(firebaseUser.uid);
        storeCredentials(userToken);
        dispatch(NotificationActions.registerNotifications());
        dispatch(AuthActions.createAccountSuccess(user, firebaseUser.emailVerified));
      } else if (result.data) {
        await deleteAccountService();
        dispatch(
          AuthActions.createAccountFailed(new GenericError(result.data.error, result.data.message))
        );
      } else {
        await deleteAccountService();
        dispatch(
          AuthActions.createAccountFailed(new GenericError('something went wrong on sign up'))
        );
      }
    } catch (err) {
      dispatch(AuthActions.createAccountFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.sendEmailVerification = () => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.emailVerificationRequest());
      await sendEmailVerification();
      dispatch(AuthActions.emailVerificationSuccess());
    } catch (err) {
      dispatch(AuthActions.emailVerificationFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.verifyEmail = (code) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.emailVerificationRequest());
      await verifyEmailService(code);
      checkFirebaseUserSession();
      dispatch(AuthActions.emailVerificationSuccess());
    } catch (err) {
      dispatch(AuthActions.emailVerificationFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.forgotPasswordRequest());
      await forgotPasswordService(email);
      dispatch(AuthActions.forgotPasswordSuccess());
    } catch (err) {
      dispatch(AuthActions.forgotPasswordFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.changePassword = (email, oldPassword, password) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.changePasswordRequest());
      await loginService(email, oldPassword);
      await changePasswordService(password);
      dispatch(AuthActions.changePasswordSuccess());
    } catch (err) {
      dispatch(AuthActions.changePasswordFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.resetPassword = (code, password) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.resetPasswordRequest());
      await resetPasswordService(code, password);
      dispatch(AuthActions.resetPasswordSuccess());
    } catch (err) {
      dispatch(AuthActions.resetPasswordFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.restoreUserAccess = () => {
  return async (dispatch) => {
    try {
      const firebaseUser = await checkFirebaseUserSession();
      if (firebaseUser) {
        await restoreCredentials();
        const isCurrentTokenValid = restoreTokenValid(firebaseUser.uid);
        dispatch(AuthActions.resetValidateToken());
        const result = await getMeService(firebaseUser.uid);
        if (result.ok) {
          const user = result.data?.data;
          if (user) {
            const localDate = moment.utc(isCurrentTokenValid);
            const userDate = moment.utc(user.last2FAValid);
            if (localDate.isSame(userDate)) {
              if (moment().diff(userDate, 'days') === 0) {
                dispatch(AuthActions.validateTokenSuccess(true, false));
              } else {
                dispatch(AuthActions.validateTokenFailed());
              }
            } else {
              dispatch(AuthActions.validateTokenFailed());
            }
            dispatch(AuthActions.resetValidateToken());
            dispatch(AuthActions.updateUser(user, firebaseUser.emailVerified));
            setAnalyticsUserId(firebaseUser.uid);
            dispatch(AuthActions.loggedIn());
            return;
          }
        }
      }
      await removeCredentials();
      dispatch({ type: 'RESET_STORE' });
      return dispatch(AuthActions.loggedOut());
    } catch (err) {
      dispatch(AuthActions.loggedOut());
    }
  };
};

AuthActions.updateAccount = (params) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.updateAccountRequest());
      const firebaseUser = await updateProfile(params);
      const result = await putMeService({ ...params, uid: firebaseUser.uid });
      if (result.ok) {
        const user = result.data?.data;
        if (user) {
          dispatch(AuthActions.updateAccountSuccess(user, firebaseUser.emailVerified));
          return;
        }
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(AuthActions.updateAccountFailed(null));
      } else if (result.data) {
        dispatch(
          AuthActions.updateAccountFailed(new GenericError(result.data.error, result.data.message))
        );
      }
      AuthActions.updateAccountFailed(new GenericError('something went wrong on update account'));
    } catch (err) {
      dispatch(AuthActions.updateAccountFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.uploadProfilePicture = (file) => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.uploadProfilePictureRequest());
      const url = await uploadFile(file);
      await dispatch(AuthActions.updateAccount({ profile_url: url }));
      dispatch(AuthActions.uploadProfilePictureSuccess());
    } catch (err) {
      dispatch(AuthActions.uploadProfilePictureFailed(err));
      throw err;
    }
  };
};

AuthActions.validateTotpToken = (token, type) => {
  return async (dispatch) => {
    try {
      const firebaseUser = await checkFirebaseUserSession();
      dispatch(AuthActions.validateTokenRequest());
      const result = await putValidate2FAToken(firebaseUser.uid, { token: `${token}` });
      if (result.ok) {
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          amplitude.getInstance().logEvent('Pass 2FA');
        }
        const user = result.data?.data;
        if (user) {
          AuthActions.updateAccountSuccess(user, firebaseUser.emailVerified);
        }
        storeTokenValid(firebaseUser.uid, user.last2FAValid);
        if (type === 'payment') {
          dispatch(AuthActions.updatePaymentToken(true));
        } else {
          dispatch(AuthActions.validateTokenSuccess(true, false));
        }
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(AuthActions.updateAccountFailed(null));
      } else {
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          amplitude.getInstance().logEvent('Fail 2FA');
        }
        if (type === 'payment') {
          dispatch(
            AuthActions.validatePaymentTokenFailed(
              new GenericError(
                'The token is invalid for payments',
                'The token is invalid for payments'
              )
            )
          );
        } else {
          removeTokenValid(firebaseUser.uid);
          dispatch(
            AuthActions.validateTokenFailed(
              new GenericError('The token is invalid', 'The token is invalid')
            )
          );
        }
      }
    } catch (err) {
      dispatch(AuthActions.validateTokenFailed(new GenericError(err, err)));
    }
  };
};

AuthActions.logout = () => {
  return async (dispatch) => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      console.log('Amplitude initialized');
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, null);
    }
    dispatch(AuthActions.resetValidateToken());
    await removeCredentials();
    await dispatch({ type: 'RESET_STORE' });
    dispatch(AuthActions.loggedOut());
  };
};

AuthActions.getUsers = () => {
  return async (dispatch) => {
    try {
      dispatch(AuthActions.getUsersRequest());
      const result = await getUsersService();
      if (result.ok) {
        dispatch(AuthActions.getUsersSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          AuthActions.getUsersFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(AuthActions.getUsersFailed(null));
      } else {
        dispatch(AuthActions.getUsersFailed(new GenericError('something went wrong on get users')));
      }
    } catch (err) {
      dispatch(AuthActions.getUsersFailed(new GenericError(err, err)));
    }
  };
};
export default AuthActions;
