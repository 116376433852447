import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import UserPhoto from '../UserPhoto';
import styles from './styles.module.sass';

const Recipients = ({ t, user }) => {
  return (
    <Box
      style={{
        display: 'flex',
        borderRadius: 23,
        border: 'solid 1px #111',
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        height: 30,
        maxWidth: 130,
        margin: 10,
      }}
    >
      <UserPhoto
        className={styles.box_image}
        style={{ width: 23, height: 23 }}
        initials={(user?.firstName ?? '').slice(0, 1) + (user?.lastName ?? '').slice(0, 1)}
      />
      <Box
        style={{
          overflow: 'hidden',
          fontFamily: 'FuturaPT',
          fontSize: 20,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {`${user?.firstName || ''} ${user?.lastName || ''}`}
      </Box>
    </Box>
  );
};

export default withTranslation()(Recipients);
