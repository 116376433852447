import React, { Fragment, useState } from 'react';

import SelectMaterial from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import PropTypes from 'prop-types';
import theme from './theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

// values must be an object with value and name properties

const Select = ({
  values,
  value,
  id,
  label,
  displayKey,
  valueKey,
  onChange,
  width = 100,
  helperText,
  required,
  disabled,
  color,
  defaultValue,
  disableDefault = true,
  isFilled = true,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value || defaultValue || '');

  if (id != null && id !== '' && values && selectedValue === '') {
    const selected = values.find((x) => x.id === id);
    setSelectedValue(selected);
    if (onChange) {
      onChange(selected);
    }
  }
  if (value != null && value !== '' && values && selectedValue === '') {
    let selected;
    if (valueKey) {
      selected = values.find((x) => x[valueKey] === value);
      if (selected) {
        selected = selected[valueKey];
      }
    } else {
      selected = values.find((x) => x === value);
    }
    setSelectedValue(selected);
    if (onChange) {
      onChange(selected);
    }
  }

  if (value !== null && value !== selectedValue) {
    setSelectedValue(value);
  }

  const classes = useStyles();
  const StyledFormControl = withStyles((theme) => ({
    root: {},
  }))(FormControl);

  const StyledSelect = withStyles((theme) => ({
    root: {
      overflow: 'hidden',
      borderRadius: 5,
      backgroundImage: ' linear-gradient(to right, #fff 20%, #f3f3f5 80%)',
      boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
      transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
      paddingLeft: isFilled ? 'none' : 10,
      paddingRight: isFilled ? 'none' : 10,
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&:focus': {
        borderRadius: 5,
        boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
        border: 'solid 1px #000000',
      },
    },
    disabled: {
      backgroundImage: 'linear-gradient(to bottom, #fff 97%, #f3f3f5 80%)',
      boxShadow: 'inset 0px 0px 0px 0 #fff',
      border: 'solid 0px #fff',
      transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
    },
  }))(SelectMaterial);

  const items = values?.map((item, index) => {
    return (
      <MenuItem
        style={{
          fontFamily: 'FuturaPT-Medium',
          color: '#111',
          fontSize: 16,
          fontWeight: 500,
        }}
        key={index}
        value={valueKey ? item[valueKey] : item}
      >
        {displayKey ? item[displayKey] : item}
      </MenuItem>
    );
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledFormControl
          variant={isFilled ? 'filled' : 'standard'}
          className={isFilled ? classes.margin : null}
          style={{ width: '100%' }}
          required={required}
          disabled={disabled}
        >
          {label && label !== '' && (
            <InputLabel style={{ color: disabled ? '#111' : color }} id="select-filled-label">
              {label}
            </InputLabel>
          )}
          <StyledSelect
            labelId="select-filled-label"
            id="select-filled"
            disableUnderline
            style={{
              backgroundColor: 'transparent',
              width: '100%',
              color: isFilled ? 'none' : 'black',
            }}
            value={selectedValue}
            onChange={(event) => {
              setSelectedValue(event.target.value);
              if (onChange) {
                onChange(event.target.value);
              }
            }}
          >
            {defaultValue && (
              <MenuItem
                disabled={disableDefault}
                style={{
                  fontFamily: 'FuturaPT-Medium',
                  color: '#111',
                  fontSize: 16,
                  fontWeight: 500,
                }}
                key="default-value"
                value={defaultValue}
              >
                {defaultValue}
              </MenuItem>
            )}
            {items}
          </StyledSelect>
          <FormHelperText>{helperText}</FormHelperText>
        </StyledFormControl>
      </ThemeProvider>
    </>
  );
};

Select.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.number,
};

export default Select;
