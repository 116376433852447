import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#fff',
    },
    third: {
      main: '#757e95',
    },
    disabled: {
      main: 'rgba(0, 0, 0, 0.15)',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
          border: 'solid 1px #000000',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'FuturaPT !important',
        fontSize: 16,
        color: '#757e95',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'FuturaPT !important',
        fontSize: 12,
        color: '#757e95',
      },
    },
    MuiRating: {
      iconEmpty: {
        fill: '#efeff4',
      },
      iconFilled: {
        fill: '#a84600',
      },
    },
  },
});
