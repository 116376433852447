import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';

import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Grid } from '@material-ui/core';

import NavigationBar from '../../../../components/NavigationBar';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button';
import MessageModal from '../../../../modals/message';
import Select from '../../../../components/Select';
import UserPhoto from '../../../../components/UserPhoto';
import CheckBox from '../../../../components/CheckBox';
import Loading from '../../../../components/loading';
import DeleteIcon from '../../../../assets/images/icons/delete.svg';
import EditIcon from '../../../../assets/images/icons/edit_item.svg';
import InvitesActions from '../../../../redux/invites/actions';
import WorkflowActions from '../../../../redux/workflow/actions';
import AuthActions from '../../../../redux/auth/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../../components/ContractSpecsCard';
import ContractInfoCard from '../../../../components/ContractInfoCard';

const InviteCollaboratorsTask = ({
  t,
  history,
  match,
  desktop = false,
  type = '',
  onFinish,
  taskId,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { getRolesLoading } = useSelector((state) => state.invites);
  const { saveTaskLoading, getSubtaskLoading } = useSelector((state) => state.workflow);
  const [drawerOpened, setDrawerOpened] = useState(false);

  const PageContent = () => {
    const { getRolesError, getRolesCalled, roles } = useSelector((state) => state.invites);

    const {
      usersInviteTask,
      saveTaskError,
      saveTaskCalled,
      getSubtaskError,
      getSubtaskCalled,
      subtaskSelection,
    } = useSelector((state) => state.workflow);
    const [tempInviteCollaboratorsTask, setTempInviteCollaboratorsTask] = useState([]);
    const { invites } = useSelector((state) => state.invites);

    useEffect(() => {
      if (saveTaskCalled && !saveTaskLoading && !saveTaskError) {
        dispatch(WorkflowActions.resetGetTasks());
        dispatch(WorkflowActions.resetGetTask());
        dispatch(WorkflowActions.resetSaveTask());
        desktop ? onFinish && onFinish() : history.goBack();
      }
      if (!getRolesCalled && !roles) {
        dispatch(InvitesActions.getRoles(''));
      }
      if (usersInviteTask) {
        setTempInviteCollaboratorsTask(usersInviteTask);
      }
      return () => {};
    }, [
      dispatch,
      getRolesCalled,
      roles,
      usersInviteTask,
      saveTaskCalled,
      saveTaskLoading,
      saveTaskError,
    ]);

    useEffect(() => {
      if (!getSubtaskCalled) {
        dispatch(WorkflowActions.getSubtask(taskId));
      }
    }, [dispatch, getSubtaskCalled]);

    function handleSubmit(values) {
      const invite = {
        email: values.email,
        role: 'taskCollaborator',
      };
      setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
        ...tempInviteCollaboratorsTask,
        invite,
      ]);
    }

    function send() {
      if (tempInviteCollaboratorsTask?.length > 0) {
        if (subtaskSelection?.id) {
          const addedList = tempInviteCollaboratorsTask?.filter((el) => {
            return el.deleted === undefined || el.deleted === false;
          });
          const deletedList = tempInviteCollaboratorsTask
            ?.filter((el) => {
              return el.deleted === true;
            })
            .map((item) => {
              return { id: item.id };
            });
          const data = {
            collaborators: {
              added: addedList,
              deleted: deletedList,
            },
          };

          if (data?.collaborators?.added?.length <= 0) {
            delete data?.collaborators?.added;
          }
          if (data?.collaborators?.deleted?.length <= 0) {
            delete data?.collaborators?.deleted;
          }
          const typeToSend = desktop ? type : match?.params.type;
          dispatch(WorkflowActions.updateTask(subtaskSelection?.id, data, typeToSend));
        } else {
          dispatch(WorkflowActions.updateUsersInviteTask(tempInviteCollaboratorsTask));
          desktop ? onFinish && onFinish() : history.goBack();
        }
      }
    }

    useEffect(() => {
      if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
        amplitude.getInstance().logEvent(`page${window.location.pathname}`);
      }
    }, []);

    function drawItem(value, index) {
      if (value.deleted || value.id) {
        return null;
      }
      return (
        <Box key={index}>
          <Box className={styles.item_box}>
            <Box className={styles.data}>
              <Box className={styles.email}>{value.email}</Box>
              <Box className={styles.role}>
                <Box
                  style={{
                    borderRadius: 23,
                    paddingRight: 10,
                    paddingLeft: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    border: 'solid 1px #4c488f',
                    fontFamily: 'FuturaPT',
                    fontSize: 16,
                    textAlign: 'center',
                    color: '#4c488f',
                    width: 'fit-content',
                  }}
                >
                  {t(`contracts.addCollaborators.roles.taskCollaborator`)}
                </Box>
              </Box>
            </Box>
            <Box className={styles.actions}>
              <Button
                align="left"
                padding="0 0 0 5px"
                margin={0}
                clean
                className={styles.icon_container}
                onPress={() => {
                  if (value.id) {
                    const editedValue = { ...value };
                    editedValue.deleted = true;
                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask.filter((el) => el !== value),
                    ]);
                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask,
                      editedValue,
                    ]);
                  } else {
                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask.filter((el) => el !== value),
                    ]);
                  }
                }}
              >
                <SvgIcon
                  style={{ height: 18, width: 18 }}
                  component={DeleteIcon}
                  viewBox="0 0 17 17"
                />
              </Button>
            </Box>
          </Box>
          <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
        </Box>
      );
    }

    function getUserItem(item, index) {
      const role = roles?.find((role) => role.originalName === item.role) || {
        name: t(
          `contracts.addCollaborators.roles.${item.role.replace(/\s/g, '').firstLowerCase()}`
        ),
      };

      const found = subtaskSelection?.users?.filter((collaborator) => collaborator.id === item.id);
      const status = found?.length > 0;
      return (
        <Box key={index} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box style={{ marginTop: 20, marginBottom: 20, display: 'flex' }}>
            <Box style={{ marginTop: -5 }}>
              <CheckBox
                checked={status}
                onChange={(event) => {
                  if (event.target.checked) {
                    const invite = {
                      id: item.id,
                      email: item.email,
                      role: item.roleSlug,
                    };
                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask,
                      invite,
                    ]);
                  } else if (item.id) {
                    const invite = { ...item };
                    invite.deleted = true;
                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask.filter((el) => el !== item),
                    ]);

                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask,
                      invite,
                    ]);
                  } else {
                    setTempInviteCollaboratorsTask((tempInviteCollaboratorsTask) => [
                      ...tempInviteCollaboratorsTask.filter((el) => el !== item),
                    ]);
                  }
                }}
              />
            </Box>
            <Box style={{ padding: 5 }}>
              {' '}
              <UserPhoto
                className={styles.box_image}
                initials={(item?.firstName ?? '').slice(0, 1) + (item?.lastName ?? '').slice(0, 1)}
              />
            </Box>
            <Box style={{ adding: 5, display: 'flex', flexDirection: 'column' }}>
              <Box
                style={{
                  marginTop: 2,
                  marginBottom: 5,
                  fontFamily: 'FuturaPT',
                  fontSize: 20,
                }}
              >
                {`${item.firstName ?? ''} ${item.lastName ?? ''}`}
              </Box>
              <Box
                style={{
                  marginBottom: 5,
                  fontFamily: 'FuturaPT-Light',
                  fontSize: 20,
                }}
              >
                {`${item.email ?? ''}`}
              </Box>
              <Box
                style={{
                  borderRadius: 23,
                  paddingRight: 10,
                  paddingLeft: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  border: 'solid 1px #4c488f',
                  fontFamily: 'FuturaPT',
                  fontSize: 16,
                  textAlign: 'center',
                  color: '#4c488f',
                  width: 'fit-content',
                }}
              >
                {role?.name || ''}
              </Box>
            </Box>
          </Box>
          <Divider />
        </Box>
      );
    }

    const validationSchema = Yup.object({
      email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
    });

    const TitleComponent = () => (
      <>
        <Grid item xs={12} />
        <Grid item xs={12}>
          {t('contracts.details.workflow.invites.title').toUpperCase()}
        </Grid>
        <Grid container justify="flex-end">
          {!desktop && (
            <Button
              style={{
                fontFamily: 'FuturaPT-Demi',
                lineHeight: 0.93,
                letterSpacing: 0.75,
                textAlign: 'right',
              }}
              fontSize={15}
              clean
              inverted
              onPress={() => history.push('/contracts/InviteCollaboratorsTask/role-permissions')}
            >
              {t('contracts.addCollaborators.seeRolePermissions')}
            </Button>
          )}
        </Grid>
      </>
    );

    return (
      <Grid container className={desktop ? styles.grid_container_desktop : styles.grid_container}>
        {!isMobile && !desktop && (
          <Grid item xs={12} sm={3}>
            <ContractInfoCard />
            <ContractSpecsCard />
          </Grid>
        )}
        <Grid className={styles.grid_page} item xs={12} sm={desktop ? 12 : 9}>
          <Box className={clsx(styles.main_content)}>
            <Container
              maxWidth="md"
              className={desktop ? styles.content_desktop : styles.content}
              style={!isMobile ? { minWidth: 650 } : {}}
            >
              {!isMobile && (
                <Box className={styles.container_title}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    component="span"
                    style={{ color: '#2f2f2f' }}
                    className={styles.title}
                  >
                    <TitleComponent />
                  </Box>
                </Box>
              )}

              <Box className={styles.container_step_description}>
                <Box className="description">
                  {t('contracts.details.workflow.invites.description')}
                </Box>
              </Box>
              {subtaskSelection?.users?.length > 0 && (
                <Box style={{ maxHeight: '30%', position: 'relative' }}>
                  <Box
                    style={{
                      height: '100%',
                      overflow: 'scroll',
                      position: 'relative',
                    }}
                  >
                    {subtaskSelection?.users?.map((item, index) => {
                      return getUserItem(item, index);
                    })}
                    <Box style={{ width: '100%', height: 40 }} />
                  </Box>
                  <Box
                    style={{
                      zIndex: 99,
                      position: 'absolute',
                      width: '100%',
                      height: 100,
                      bottom: 0,
                      backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), white)',
                    }}
                  />
                </Box>
              )}
              <Box className={styles.container_step_description}>
                <Box className="description2">{t('contracts.details.workflow.invites.title2')}</Box>
                <Box className="description">
                  {t('contracts.details.workflow.invites.description2')}
                </Box>
              </Box>
              <Box>
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm({ values: { email: '' } });
                  }}
                >
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Box className={styles.container_inputs}>
                        <Box className={styles.container_names}>
                          <Box
                            className={clsx(styles.container_first_field, styles.container_input)}
                          >
                            <TextField
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              helperText={errors.email && touched.email && errors.email}
                              placeholder={t('common.email')}
                              label={t('common.email').toUpperCase()}
                              required
                            />
                          </Box>
                          <Box
                            className={clsx(styles.container_last_field, styles.container_input)}
                          >
                            <Box className={styles.container_submit}>
                              <Button type="submit" data-info-submit="Submit">
                                {t('contracts.addCollaborators.add')}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
              {(tempInviteCollaboratorsTask?.length > 0 || InviteCollaboratorsTask?.length > 0) && (
                <Box className={styles.list_content}>
                  <Box className={styles.added_content}>
                    <Box className={styles.added_list}>
                      <Divider style={{ marginTop: 30 }} />

                      {tempInviteCollaboratorsTask?.map((value, index) => {
                        return drawItem(value, index);
                      })}
                    </Box>
                  </Box>

                  <>
                    <Box className={styles.container_submit}>
                      <Box className={styles.box_two_options}>
                        <Button
                          inverted
                          bordered
                          className={styles.button}
                          onPress={() => {
                            desktop ? onFinish && onFinish() : history.goBack();
                          }}
                        >
                          {t('common.cancel')}
                        </Button>
                        <Box style={{ width: 20 }} />
                        <Button
                          disabled={!tempInviteCollaboratorsTask?.length > 0}
                          className={styles.button}
                          onPress={() => {
                            send();
                          }}
                        >
                          {t('common.assigne')}
                        </Button>
                      </Box>
                    </Box>
                  </>
                </Box>
              )}
            </Container>
            {saveTaskCalled && !saveTaskLoading && saveTaskError && (
              <MessageModal
                icon={null}
                title={t('common.error')}
                message={saveTaskError.userMessage}
                primaryAction={t('common.close')}
                primaryEvent={() => {
                  dispatch(WorkflowActions.resetSaveTaskError());
                }}
              />
            )}
            {getRolesCalled && !getRolesLoading && getRolesError && (
              <MessageModal
                icon={null}
                title={t('common.error')}
                message={getRolesError.userMessage}
                primaryAction={t('common.close')}
                primaryEvent={() => {
                  dispatch(InvitesActions.resetGetRolesError());
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={styles.container}>
      <Loading isLoading={getRolesLoading || saveTaskLoading || getSubtaskLoading} />
      {desktop ? (
        <PageContent />
      ) : (
        <NavigationBar
          title={t('contracts.details.workflow.invites.title').toUpperCase()}
          hideTitle={!isMobile}
          hasMenuButton={!isMobile}
          barColor={isMobile ? '#fff' : '#111'}
          white={!isMobile}
          hasBackButton
          history={history}
          user={user}
          onToggleDrawer={(opened) => {
            setDrawerOpened(opened);
          }}
          onLogout={() => {
            dispatch(AuthActions.logout());
          }}
          pageContent={<PageContent />}
        />
      )}
    </Box>
  );
};

export default withTranslation()(InviteCollaboratorsTask);
