import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable';
import reducers from '.';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  whitelist: [
    'modals',
    'notifications',
    'payments',
    'contracts',
    'offTakeSchedule',
    'terms',
    'invites',
    'workflow',
  ],
  blacklist: ['auth'],
  transforms: [seamlessImmutableTransformCreator({})],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const useReduxDevTools = process.env.NODE_ENV === 'development';

const resetEnhancer = (rootReducer) => (state, action) => {
  if (action.type === 'RESET_STORE') {
    delete state.notifications;
    delete state.style;
    delete state.modals;
    state = undefined;
    const keep = [];
    for (let i = 0; i < localStorage.length; i += 1) {
      if (localStorage.key(i).includes('lastTokenValid')) {
        keep.push({ key: localStorage.key(i), item: localStorage.getItem(localStorage.key(i)) });
      }
    }
    localStorage.clear();
    keep.forEach((element) => {
      localStorage.setItem(element.key, element.item);
    });
    localStorage.removeItem('persist:root');
  }
  return rootReducer(state, action);
};

const middlewares = [thunk];

const enhancers = [];

enhancers.push(applyMiddleware(...middlewares));

const composer = useReduxDevTools ? composeWithDevTools : compose;

const store = createStore(resetEnhancer(persistedReducer), composer(...enhancers));

export const persistor = persistStore(store);

export default store;
