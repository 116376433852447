import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import SuccessIcon from '../../assets/images/icons/success.svg';
import InviteCollaboratorsTask from '../../pages/contracts/workflows/inviteCollaboratorsTask';
import Button from '../../components/Button';
import styles from './styles.module.sass';

const InviteCollaboratorsTaskModal = ({
  shouldHide,
  history,
  center = false,
  type,
  onClose = () => {},
}) => {
  useEffect(() => {
    if (shouldHide) {
      setTimeout(() => shouldHide(), 3000);
    }
  });

  return (
    <Box className={styles.container}>
      <Box className={styles.box}>
        <InviteCollaboratorsTask history={history} desktop type={type} onFinish={onClose} />
      </Box>
    </Box>
  );
};

export default InviteCollaboratorsTaskModal;
