import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import SwipeableViews from 'react-swipeable-views';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';

import ReactPlayer from 'react-player';
import Stepper from '../Stepper';
import Button from '../Button';
import FirstAddArrow from '../../assets/images/icons/first-add-arrow.svg';
import FirstAddArrowDesktop from '../../assets/images/icons/first-add-arrow-desktop.svg';

import PlayIcon from '../../assets/images/icons/play.svg';

import { useOnScreen } from '../../utils/onScreen';

import styles from './styles.module.sass';

const ContractsEmpty = ({ t, className }) => {
  const firstAddRef = useRef();
  const visible = useOnScreen({ ref: firstAddRef, rootMargin: '70px' });
  const [showPlayer, setShowPlayer] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    {
      image: isMobile
        ? require('../../assets/images/empty.png')
        : require('../../assets/images/empty-desktop.png'),
    },
  ];

  return (
    <Box>
      {showPlayer && (
        <Box className={styles.container_player}>
          <Button
            className={styles.close}
            align="center"
            clean
            onPress={() => {
              setShowPlayer(false);
            }}
          >
            <CloseIcon style={{ color: 'white' }} />
          </Button>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=oZ7ZIiIChGU"
            className={styles.player}
            playing
          />
        </Box>
      )}
      <Box className={styles.container_swipper}>
        <SwipeableViews
          className={styles.container_pages}
          index={currentPage}
          onChangeIndex={(index) => handlePageChanged(index)}
        >
          {pages.map((page, index) => (
            <Box className={styles.page} key={index}>
              {!isMobile && (
                <Box className={styles.container_step_description}>
                  <Box className={styles.welcome_title} style={{ marginTop: 83 }}>
                    {t('home.empty.welcome')}
                  </Box>
                  <Box className={styles.step_description} style={{ marginTop: 10 }}>
                    {t('home.empty.welcomeDescription')}
                  </Box>
                </Box>
              )}
              <img src={page.image} alt="" />
            </Box>
          ))}
        </SwipeableViews>
        <Box className={styles.container_steps}>
          <Stepper activeStep={currentPage} steps={pages.length} />
        </Box>
      </Box>
      <Box className={styles.container_step_description}>
        <Box className={styles.step_title}>{t('home.empty.step1Title')} </Box>
        <Box className={styles.step_description} style={{ marginTop: 10 }}>
          {t('home.empty.step1Description')}
        </Box>
        <Box className={styles.step_title}>{t('home.empty.step2Title')}</Box>
        <Box style={{ marginTop: isMobile ? 10 : 20 }}>
          <Typography className={styles.step_description_with_link}>
            {t('home.empty.step2Description')}
            <Link
              className={styles.link}
              onClick={() => {
                setShowPlayer(true);
              }}
            >
              {t('home.empty.step2Link')}
              <SvgIcon
                style={{ marginLeft: 1, height: 20, width: 20, marginBottom: '-4px' }}
                component={PlayIcon}
                viewBox="0 0 22 22"
              />
            </Link>
          </Typography>
        </Box>

        <Box className={styles.hidden_box} ref={firstAddRef} />
      </Box>
      {visible && (
        <Box
          className={styles.first_add_description}
          style={{ justifyContent: isMobile ? 'center' : 'flex-end' }}
        >
          <Box className={styles.container_first_add_description}>
            <Box className={styles.container_first_add_text}>
              <Box className={styles.first_add_title}>{t('home.empty.endStepTitle')}</Box>
              <Box className={styles.add_your_first_contract}>
                {t('home.empty.endStepDescription')}
              </Box>
            </Box>
            <Box className={styles.icon_container}>
              <Box style={{ width: 'calc(50% + 80px)' }} />
              <Box className={styles.first_add_arrow}>
                <SvgIcon
                  style={isMobile ? { height: 92, width: 103 } : { width: 112, height: 56 }}
                  component={isMobile ? FirstAddArrow : FirstAddArrowDesktop}
                  viewBox={isMobile ? '0 0 103 92' : '0 0 120 65'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(ContractsEmpty);
