import Immutable from 'seamless-immutable';

const initialState = Immutable({
  getInvitesLoading: false,
  getInvitesError: null,
  getInvitesCalled: false,
  invites: null,

  createInvitesLoading: false,
  createInvitesError: null,
  createInvitesCalled: false,

  updateInvitesLoading: false,
  updateInvitesError: null,
  updateInvitesCalled: false,

  deleteInvitesLoading: false,
  deleteInvitesError: null,
  deleteInvitesCalled: false,

  getRolesLoading: false,
  getRolesError: null,
  getRolesCalled: false,
  roles: null,
});

export default initialState;
