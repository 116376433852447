import { create } from 'apisauce';

const api = create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 40000,
});

export const axios = api.axiosInstance;

export default api;
