import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';

import moment from 'moment';

import UserPhoto from '../UserPhoto';
import CheckIcon from '../../assets/images/icons/check.svg';
import DenyIcon from '../../assets/images/icons/denied.svg';
import PedingIcon from '../../assets/images/icons/pending.svg';
import NextIcon from '../../assets/images/icons/next.svg';
import styles from './styles.module.sass';

const StatusBox = ({ t, user, userStatus, counterpartName, onClick, date, type }) => {
  function getStatusIcon(userAgree) {
    if (userAgree === null || userAgree === undefined) {
      return (
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={PedingIcon}
          viewBox="0 0 32 32"
        />
      );
    }
    if (userAgree === true) {
      return (
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={CheckIcon}
          viewBox="0 0 32 32"
        />
      );
    }
    if (userAgree === false) {
      return (
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={DenyIcon}
          viewBox="0 0 32 32"
        />
      );
    }
    return null;
  }
  function statusBox(userAgree, date) {
    const agree = userAgree === undefined || userAgree === null ? null : userAgree;
    return (
      <Box className={styles.box_status_data}>
        <Box className={styles.box_status}>
          {getStatusIcon(agree)}
          <Box className={styles.box_status_text} style={{ opacity: agree === null ? 0.5 : 1 }}>
            {agree !== null
              ? agree === true
                ? t('paymentInstructions.confirmed')
                : t('paymentInstructions.denied')
              : t('paymentInstructions.pending')}
          </Box>
        </Box>
        {agree !== null && <Box className={styles.box_status_date}>{date}</Box>}
      </Box>
    );
  }

  function userCollaboratorStatus(user) {
    return (
      <Box
        className={styles.box_item}
        onClick={() => {
          if (type === 'default' && userStatus === false) {
            if (onClick) {
              onClick();
            }
          }
        }}
      >
        <Box className={styles.box_row}>
          <Box className={styles.box_personal_data}>
            <Box className={styles.box_photo_name}>
              <UserPhoto
                className={styles.box_image}
                initials={(user?.firstName || '').slice(0, 1) + (user?.lastName || '').slice(0, 1)}
              />
              <Box className={styles.box_name}>
                {`${user?.firstName || ''} ${user?.lastName || ''}`}
              </Box>
            </Box>
            <Box className={styles.box_email}>{user?.email}</Box>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {statusBox(userStatus, moment(date).format('L'))}
            {userStatus === false && (
              <SvgIcon
                style={{ height: 15, width: 8, marginLeft: 10 }}
                component={NextIcon}
                viewBox="0 0 10 17"
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  function reviewChangesBox() {
    return (
      <Box className={styles.box_state_description}>
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={PedingIcon}
          viewBox="0 0 32 32"
        />
        <Box className={styles.box_text}>
          <Box className={styles.title}>
            {t('contracts.reviewChanges', { counterpart: counterpartName || '' })}
          </Box>
        </Box>
      </Box>
    );
  }

  function waitingBox() {
    return (
      <Box className={styles.box_state_description}>
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={PedingIcon}
          viewBox="0 0 32 32"
        />
        <Box className={styles.box_text}>
          <Box className={styles.title}>{t('contracts.waiting')}</Box>
        </Box>
      </Box>
    );
  }

  function confirmedBox() {
    return (
      <Box className={styles.box_state_description}>
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={CheckIcon}
          viewBox="0 0 32 32"
        />
        <Box className={styles.box_text}>
          <Box className={styles.title}>{t('contracts.changesConfirmed')}</Box>
          <Box className={styles.subtitle}>{moment(date).format('L')}</Box>
        </Box>
      </Box>
    );
  }

  function deniedBox() {
    return (
      <Box className={styles.box_state_description}>
        <SvgIcon
          style={{ height: 20, width: 20, marginRight: 5 }}
          component={PedingIcon}
          viewBox="0 0 32 32"
        />
        <Box className={styles.box_text}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box className={styles.title}>{t('contracts.deniedTitle')}</Box>
            <Box className={styles.subtitle} style={{ marginLeft: 5 }}>
              {moment(date).format('L')}
            </Box>
          </Box>
          <Box className={styles.title2}>
            {t('contracts.deniedMessage', { counterpart: counterpartName })}
          </Box>
        </Box>
      </Box>
    );
  }

  function displayBox() {
    switch (type) {
      case 'default':
        return userCollaboratorStatus(user);
      case 'review':
        return reviewChangesBox();
      case 'waiting':
        return waitingBox();
      case 'denied':
        return deniedBox();
      case 'confirmed':
        return confirmedBox();
      default:
        return userCollaboratorStatus(user);
    }
  }

  return <Box className={styles.box_collaborators}>{displayBox()}</Box>;
};

StatusBox.propTypes = {
  type: PropTypes.oneOf(['default', 'review', 'denied', 'confirmed', 'waiting']).isRequired,
};

export default withTranslation()(StatusBox);
