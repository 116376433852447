import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';

import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import moment from 'moment';

import { Grid } from '@material-ui/core';
import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import MessageModal from '../../../modals/message';
import ModalView from '../../../modals/modalView';
import BasicDateRangePicker from '../../../components/BasicDateRangePicker';
import Loading from '../../../components/loading';
import StatusBox from '../../../components/StatusBox';
import CalendarIcon from '../../../assets/images/icons/calendar.svg';
import DeleteIcon from '../../../assets/images/icons/delete.svg';
import EditIcon from '../../../assets/images/icons/edit_item.svg';
import PendingIcon from '../../../assets/images/icons/pending-small.svg';

import DeniedComment from '../deniedComment';
import SeeComment from '../seeComment';

import OffTakeScheduleActions from '../../../redux/offTakeSchedule/actions';
import ContractActions from '../../../redux/contracts/actions';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const OffTakeSchedule = ({ t, history }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const {
    contractSelection,
    getUserContractLoading,
    getUserContractError,
    getUserContractCalled,

    acceptInviteLoading,
    acceptInviteError,
    acceptInviteCalled,
  } = useSelector((state) => state.contracts);

  const {
    getOffTakeSchedulesLoading,
    getOffTakeSchedulesError,
    getOffTakeSchedulesCalled,
    offTakeSchedules,

    createOffTakeScheduleLoading,
    createOffTakeScheduleError,
    createOffTakeScheduleCalled,

    updateOffTakeScheduleLoading,
    updateOffTakeScheduleError,
    updateOffTakeScheduleCalled,

    deleteOffTakeScheduleLoading,
    deleteOffTakeScheduleError,
    deleteOffTakeScheduleCalled,

    updateUserOffTakeScheduleLoading,
    updateUserOffTakeScheduleError,
    updateUserOffTakeScheduleCalled,
  } = useSelector((state) => state.offTakeSchedule);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [editing, setEditing] = useState(null);
  const [creating, setCreating] = useState(null);
  const [actionRequired, setActionRequired] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tempOffTakeSchedules, setTempOffTakeSchedules] = useState([]);
  const [tempDeleteOffTakeSchedules, setTempDeleteOffTakeSchedules] = useState([]);
  const [tempUpdateOffTakeSchedules, setTempUpdateOffTakeSchedules] = useState([]);
  const [updateOffTakeSchedule, setUpdateOffTakeSchedule] = useState(null);
  const [addDisagreeComments, setAddDisagreeComments] = useState(false);
  const [seeDisagreeComments, setSeeDisagreeComments] = useState(false);

  useEffect(() => {
    if (!getUserContractCalled && contractSelection) {
      dispatch(ContractActions.getUserContract(contractSelection?.id));
    }
  }, [getUserContractCalled]);

  useEffect(() => {
    if (!getOffTakeSchedulesCalled && contractSelection) {
      dispatch(OffTakeScheduleActions.getOffTakeSchedules(contractSelection?.id));
    }
  }, [getOffTakeSchedulesCalled]);

  useEffect(() => {
    if (contractSelection?.status === 'created' && contractSelection?.owner?.id !== user.id) {
      if (!acceptInviteCalled && !acceptInviteLoading && !acceptInviteError) {
        dispatch(ContractActions.acceptInvite(contractSelection?.id));
      }
    }
    if (contractSelection?.id) {
      if (offTakeSchedules?.length > 0) {
        if (contractSelection?.status === 'created') {
          setEditing(true);
        } else {
          setEditing(false);
          setCreating(false);
        }
      } else {
        setEditing(false);
        setCreating(true);
      }
      if (
        contractSelection?.schedulesStatus?.requestedBy !== undefined &&
        contractSelection?.schedulesStatus?.requestedBy !== null
      ) {
        if (contractSelection?.schedulesStatus?.requestedBy !== user.id) {
          if (
            contractSelection?.buyer?.id === user?.id &&
            contractSelection?.schedulesStatus?.buyerAgree === null
          ) {
            setActionRequired(true);
          } else if (
            contractSelection?.seller?.id === user?.id &&
            contractSelection?.schedulesStatus?.sellerAgree === null
          ) {
            setActionRequired(true);
          } else {
            setActionRequired(false);
          }
        } else {
          setActionRequired(false);
        }
      } else {
        setActionRequired(false);
      }
    } else {
      setActionRequired(false);
    }
  }, [contractSelection, updateUserOffTakeScheduleCalled, deleteOffTakeScheduleCalled]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(ContractActions.resetGetUserContract());
      dispatch(ContractActions.resetAcceptInvite());
      dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
      dispatch(OffTakeScheduleActions.resetCreateOffTakeSchedule());
      dispatch(OffTakeScheduleActions.resetUpdateOffTakeSchedule());
      dispatch(OffTakeScheduleActions.resetDeleteOffTakeSchedule());
    };
  }, []);

  function handleSubmit(values) {
    if (updateOffTakeSchedule) {
      if (updateOffTakeSchedule.id) {
        const offTakeSchedule = {
          id: updateOffTakeSchedule.id,
          volume: values.volume,
          startDate: values.dateRange[0],
          endDate: values.dateRange[1],
        };
        setTempUpdateOffTakeSchedules((tempUpdateOffTakeSchedules) => [
          ...tempUpdateOffTakeSchedules,
          offTakeSchedule,
        ]);
        setUpdateOffTakeSchedule(null);
      } else {
        const offTakeSchedule = {
          contract: contractSelection?.id,
          volume: values.volume,
          startDate: values.dateRange[0],
          endDate: values.dateRange[1],
        };
        setTempOffTakeSchedules((tempOffTakeSchedules) => [
          ...tempOffTakeSchedules.filter((el) => el !== updateOffTakeSchedule),
        ]);
        setTempOffTakeSchedules((tempOffTakeSchedules) => [
          ...tempOffTakeSchedules,
          offTakeSchedule,
        ]);
        setUpdateOffTakeSchedule(null);
      }
    } else {
      const offTakeSchedule = {
        contract: contractSelection?.id,
        volume: values.volume,
        startDate: values.dateRange[0],
        endDate: values.dateRange[1],
      };
      setTempOffTakeSchedules((tempOffTakeSchedules) => [...tempOffTakeSchedules, offTakeSchedule]);
    }
  }

  function send() {
    if (tempOffTakeSchedules.length > 0) {
      dispatch(OffTakeScheduleActions.createOffTakeSchedule({ schedules: tempOffTakeSchedules }));
    }
    if (tempDeleteOffTakeSchedules.length > 0) {
      dispatch(
        OffTakeScheduleActions.deleteOffTakeSchedule({
          schedules: tempDeleteOffTakeSchedules.map((element) => ({ id: element.id })),
        })
      );
    }
    if (tempUpdateOffTakeSchedules.length > 0) {
      dispatch(
        OffTakeScheduleActions.updateOffTakeSchedule({ schedules: tempUpdateOffTakeSchedules })
      );
    }
  }

  async function handleAgree() {
    const data = { agree: true };
    await dispatch(OffTakeScheduleActions.updateUserOffTakeSchedule(contractSelection?.id, data));
    dispatch(ContractActions.resetGetUserContract());
  }

  function maxAvailable() {
    const total = contractSelection?.totalVolume;
    const contractTotalScheduled = contractSelection?.statistics?.totalScheduled || 0;
    const contractTotalDelivered = contractSelection?.statistics?.totalDelivered || 0;

    const totalTemp = tempOffTakeSchedules?.reduce((prev, next) => prev + next.volume, 0);
    const totalDelete = tempDeleteOffTakeSchedules?.reduce((prev, next) => prev + next.volume, 0);
    const totalUpdate = tempUpdateOffTakeSchedules?.reduce((prev, next) => prev + next.volume, 0);

    const maxVolume =
      total -
      contractTotalScheduled -
      contractTotalDelivered -
      totalTemp -
      totalUpdate +
      totalDelete +
      (updateOffTakeSchedule?.volume || 0);

    return maxVolume;
  }

  function drawItem(value, index, isTemp) {
    if (!isTemp) {
      if (tempDeleteOffTakeSchedules.includes(value)) {
        return null;
      }
      if (tempUpdateOffTakeSchedules.find((item) => item.id === value.id)) {
        return null;
      }
    }
    let background = index % 2 ? 'rgba(0, 0, 0, 0.02)' : '#fff';
    let borderColor = 'none';
    if (updateOffTakeSchedule === value) {
      background = 'rgba(76, 72, 143, 0.15)';
      borderColor = '1px solid rgba(76, 72, 143, 0.5)';
    }
    return (
      <Box key={index}>
        <Box
          className={styles.item_box}
          style={{
            backgroundColor: isTemp ? 'rgba(76, 72, 143, 0.15)' : background,
            border: borderColor,
          }}
        >
          <Box className={styles.volume}>
            <Box className={styles.title}>{value.volume} MT</Box>
          </Box>
          <Box className={styles.date_range}>
            <Box className={styles.title}>
              {`${moment(value.startDate).format('MMM DD')} to ${moment(value.endDate).format(
                'MMM DD'
              )}`}
            </Box>
          </Box>
          {!actionRequired && (creating === true || editing === true) ? (
            <Box className={styles.actions}>
              <Button
                align="left"
                padding="0 10px 0 10px"
                margin={0}
                clean
                className={styles.icon_container}
                onPress={() => {
                  setUpdateOffTakeSchedule(null);
                  if (isTemp) {
                    setTempOffTakeSchedules((tempOffTakeSchedules) => [
                      ...tempOffTakeSchedules.filter((el) => el !== value),
                    ]);
                    setTempUpdateOffTakeSchedules((tempUpdateOffTakeSchedules) => [
                      ...tempUpdateOffTakeSchedules.filter((el) => el !== value),
                    ]);
                  } else {
                    setTempDeleteOffTakeSchedules((tempDeleteOffTakeSchedules) => [
                      ...tempDeleteOffTakeSchedules,
                      value,
                    ]);
                  }
                }}
              >
                <SvgIcon
                  style={{ height: 18, width: 18 }}
                  component={DeleteIcon}
                  viewBox="0 0 17 17"
                />
              </Button>
              <Button
                align="left"
                padding="0 10px 0 10px"
                margin={0}
                clean
                className={styles.icon_container}
                onPress={() => {
                  setUpdateOffTakeSchedule(value);
                }}
              >
                <SvgIcon
                  style={{ height: 18, width: 18 }}
                  component={EditIcon}
                  viewBox="0 0 17 17"
                />
              </Button>
            </Box>
          ) : (
            <Box className={styles.actions}>
              <Button
                padding="0 0 0 0"
                margin={0}
                clean
                onPress={() => {
                  history.push('/contracts/workflows/off-take-schedule-detail');
                }}
              >
                <p className={styles.lblManage}>{t('contracts.manageWorkflow')}</p>
              </Button>
            </Box>
          )}
        </Box>
        <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
      </Box>
    );
  }

  function showError() {
    if (
      (createOffTakeScheduleError || updateOffTakeScheduleError || deleteOffTakeScheduleError) &&
      !createOffTakeScheduleLoading &&
      !updateOffTakeScheduleLoading &&
      !deleteOffTakeScheduleLoading
    ) {
      return (
        <MessageModal
          icon={null}
          title={t('common.error')}
          messages={[
            createOffTakeScheduleError?.userMessage,
            updateOffTakeScheduleError?.userMessage,
            deleteOffTakeScheduleError?.userMessage,
          ]}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(OffTakeScheduleActions.resetCreateOffTakeScheduleError());
            dispatch(OffTakeScheduleActions.resetDeleteOffTakeScheduleError());
            dispatch(OffTakeScheduleActions.resetUpdateOffTakeScheduleError());
          }}
        />
      );
    }
    return null;
  }

  function showSuccess() {
    if (
      (!createOffTakeScheduleCalled ||
        (createOffTakeScheduleCalled &&
          !createOffTakeScheduleLoading &&
          !createOffTakeScheduleError)) &&
      (!updateOffTakeScheduleCalled ||
        (updateOffTakeScheduleCalled &&
          !updateOffTakeScheduleLoading &&
          !updateOffTakeScheduleError)) &&
      (!deleteOffTakeScheduleCalled ||
        (deleteOffTakeScheduleCalled &&
          !deleteOffTakeScheduleLoading &&
          !deleteOffTakeScheduleError))
    ) {
      return (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.offTakeSchedule.sentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractActions.resetGetUserContract());
            dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
            dispatch(OffTakeScheduleActions.resetCreateOffTakeSchedule());
            dispatch(OffTakeScheduleActions.resetDeleteOffTakeSchedule());
            dispatch(OffTakeScheduleActions.resetUpdateOffTakeSchedule());
            // TODO: check this, clear state vars after submit to avoid duplicates
            setTempOffTakeSchedules([]);
            setTempDeleteOffTakeSchedules([]);
            setTempUpdateOffTakeSchedules([]);
            setUpdateOffTakeSchedule(null);
          }}
        />
      );
    }
    return null;
  }

  function showStatusBox() {
    const isSeller = contractSelection?.seller?.id === user?.id;
    if (contractSelection?.status !== 'created') {
      if (
        contractSelection?.schedulesStatus?.buyerAgree === true &&
        contractSelection?.schedulesStatus?.sellerAgree
      ) {
        return <StatusBox type="confirmed" />;
      }

      if (
        contractSelection?.schedulesStatus?.requestedBy &&
        contractSelection?.schedulesStatus?.requestedBy !== user.id
      ) {
        if (isSeller) {
          // SELLER
          if (contractSelection?.schedulesStatus?.sellerAgree === null) {
            return (
              <StatusBox
                type="review"
                counterpartName={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
              />
            );
          }
          if (contractSelection?.schedulesStatus?.sellerAgree === false) {
            return (
              <StatusBox
                type="denied"
                counterpartName={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
              />
            );
          }
        } else {
          // BUYER
          if (contractSelection?.schedulesStatus?.buyerAgree === null) {
            return (
              <StatusBox
                type="review"
                counterpartName={`${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`}
              />
            );
          }
          if (contractSelection?.schedulesStatus?.buyerAgree === false) {
            return (
              <StatusBox
                type="denied"
                counterpartName={`${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`}
              />
            );
          }
        }
      } else if (contractSelection?.schedulesStatus?.requestedBy === user.id) {
        return (
          <StatusBox
            type="default"
            user={isSeller ? contractSelection?.buyer : contractSelection?.seller}
            userStatus={
              isSeller
                ? contractSelection?.schedulesStatus?.buyerAgree
                : contractSelection?.schedulesStatus?.sellerAgree
            }
            onClick={() => {
              let user = {};
              if (isSeller) {
                user = {
                  ...contractSelection?.buyer,
                  agree: contractSelection?.schedulesStatus?.buyerAgree,
                  comment: contractSelection?.schedulesStatus?.buyerComment,
                };
              } else {
                user = {
                  ...contractSelection?.seller,
                  agree: contractSelection?.schedulesStatus?.sellerAgree,
                  comment: contractSelection?.schedulesStatus?.sellerComment,
                };
              }
              dispatch(ContractActions.updateSelectedUserDenied(user));
              setSeeDisagreeComments(true);
            }}
          />
        );
      }
    }
  }

  const validationSchema = Yup.object({
    volume: Yup.number()
      .required(t('common.requiredField'))
      .positive()
      .moreThan(0, t('contracts.offTakeSchedule.minVolume'))
      .max(
        maxAvailable(),
        t('contracts.offTakeSchedule.volumeExceeded', {
          totalVolume: contractSelection?.totalVolume,
        })
      ),
    dateRange: Yup.array().test(
      'dateRange',
      t('common.requiredField'),
      (value) => value !== null && value[0] !== null && value[1] !== null
    ),
  });

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={
          getOffTakeSchedulesLoading ||
          createOffTakeScheduleLoading ||
          updateOffTakeScheduleLoading ||
          deleteOffTakeScheduleLoading ||
          getUserContractLoading ||
          acceptInviteLoading
        }
      />
      <NavigationBar
        titleDescription={{
          title: t('common.deliverySchedule').toUpperCase(),
          description: t('contracts.offTakeSchedule.description'),
        }}
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        history={history}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid
            container
            className={styles.grid_container}
            justify="flex-start"
            style={{ paddingBottom: isTablet ? 130 : isMobile ? 100 : 0 }}
          >
            <Grid item xs={12} sm={12} container justify="flex-start">
              {!isMobile && (
                <Box className={styles.title_container}>
                  {!actionRequired && creating === false && editing === false && (
                    <Box className={styles.container_propose_changes}>
                      <Button
                        inverted
                        bordered
                        className={styles.button_alone}
                        minWidth={130}
                        maxWidth={270}
                        onPress={() => {
                          setEditing(true);
                        }}
                      >
                        {t('contracts.manageDeliverySchedule')}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              <Container className={styles.content}>
                {showStatusBox()}
                {!actionRequired && (creating === true || editing === true) && (
                  <Box style={{ paddingTop: 30 }}>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        volume: updateOffTakeSchedule?.volume || '',
                        dateRange: [
                          updateOffTakeSchedule?.startDate || null,
                          updateOffTakeSchedule?.endDate || null,
                        ],
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm({ values: { volume: '', dateRange: [null, null] } });
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box className={styles.container_inputs}>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(
                                  styles.container_first_field,
                                  styles.container_input
                                )}
                              >
                                <TextField
                                  autoFocus
                                  name="volume"
                                  value={values.volume}
                                  onChange={handleChange}
                                  helperText={errors.volume && touched.volume && errors.volume}
                                  placeholder={t('contracts.offTakeSchedule.volume')}
                                  label={t('contracts.offTakeSchedule.volume').toUpperCase()}
                                  disabled={!editing && !creating}
                                  required
                                  icon="MT"
                                  type="number"
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <BasicDateRangePicker
                                  name="dateRange"
                                  value={values.dateRange}
                                  onChange={(value) => {
                                    values.dateRange = value;
                                  }}
                                  helperText={
                                    errors.dateRange && touched.dateRange && errors.dateRange
                                  }
                                  placeholder={t('contracts.offTakeSchedule.dateRange')}
                                  label={t('contracts.offTakeSchedule.dateRange').toUpperCase()}
                                  disabled={!editing && !creating}
                                  icon={<SvgIcon viewBox="0 0 22 22" component={CalendarIcon} />}
                                  required
                                />
                              </Box>
                              {(creating || editing) && (!isMobile || isTablet) && (
                                <Button
                                  type="submit"
                                  disabled={(!editing && !creating) || createOffTakeScheduleLoading}
                                  loading={createOffTakeScheduleLoading}
                                  className={styles.submitRow}
                                  data-info-submit="Submit"
                                >
                                  {updateOffTakeSchedule
                                    ? t('contracts.offTakeSchedule.edit')
                                    : t('contracts.offTakeSchedule.add')}
                                </Button>
                              )}
                            </Box>
                            {(creating || editing) && isMobile && !isTablet && (
                              <Box className={styles.container_submit}>
                                <Button
                                  type="submit"
                                  disabled={(!editing && !creating) || createOffTakeScheduleLoading}
                                  loading={createOffTakeScheduleLoading}
                                  className={styles.button_alone}
                                  data-info-submit="Submit"
                                  minWidth={130}
                                  maxWidth={270}
                                >
                                  {updateOffTakeSchedule
                                    ? t('contracts.offTakeSchedule.edit')
                                    : t('contracts.offTakeSchedule.add')}
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                )}
                {(tempOffTakeSchedules?.length > 0 || offTakeSchedules?.length > 0) && (
                  <Box className={styles.list_content}>
                    <Divider style={{ margin: 30 }} />
                    <Box className={styles.added_content}>
                      <Box className="subtitle_container">
                        {t('contracts.offTakeSchedule.title2').toUpperCase()}
                      </Box>
                      <Box
                        className="description_container"
                        style={{ textAlign: 'center', marginBottom: 40 }}
                      >
                        {t('contracts.offTakeSchedule.description2')}
                      </Box>
                      <Box className={styles.added_list}>
                        <Box className={styles.header_box}>
                          <Box className={styles.volume}>
                            <Box className={styles.title}>
                              {t('contracts.offTakeSchedule.volume').toUpperCase()}
                            </Box>
                          </Box>
                          <Box className={styles.date_range}>
                            <Box className={styles.title}>
                              {t('contracts.offTakeSchedule.dateRange').toUpperCase()}
                            </Box>
                          </Box>
                        </Box>
                        {offTakeSchedules?.map((value, index) => drawItem(value, index, false))}
                        {tempUpdateOffTakeSchedules?.map((value, index) =>
                          drawItem(value, index, true)
                        )}
                        {tempOffTakeSchedules?.map((value, index) => drawItem(value, index, true))}
                      </Box>
                    </Box>
                    {actionRequired ? (
                      <Box className={styles.container_submit}>
                        <Box className={styles.box_two_options}>
                          <Button
                            loading={updateUserOffTakeScheduleLoading}
                            disabled={updateUserOffTakeScheduleLoading}
                            inverted
                            bordered
                            className={styles.button}
                            onPress={() => {
                              setAddDisagreeComments(true);
                            }}
                          >
                            {t('common.dontAgree')}
                          </Button>
                          <Box style={{ width: 20 }} />
                          <Button
                            loading={updateUserOffTakeScheduleLoading}
                            disabled={updateUserOffTakeScheduleLoading}
                            className={styles.button}
                            onPress={() => {
                              setShowConfirmation(true);
                            }}
                          >
                            {t('common.agree')}
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        {(editing || creating) &&
                          (tempUpdateOffTakeSchedules.length > 0 ||
                            tempOffTakeSchedules.length > 0 ||
                            tempDeleteOffTakeSchedules.length > 0) && (
                            <Box className={styles.container_submit}>
                              <Button
                                disabled={createOffTakeScheduleLoading}
                                loading={
                                  createOffTakeScheduleLoading ||
                                  updateOffTakeScheduleLoading ||
                                  deleteOffTakeScheduleLoading
                                }
                                className={styles.button_alone}
                                minWidth={130}
                                maxWidth={270}
                                onPress={() => {
                                  send();
                                }}
                              >
                                {t('common.send')}
                              </Button>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                )}
              </Container>
            </Grid>
          </Grid>
        }
      />
      {(createOffTakeScheduleCalled ||
        updateOffTakeScheduleCalled ||
        deleteOffTakeScheduleCalled) &&
        showSuccess()}
      {(createOffTakeScheduleCalled ||
        updateOffTakeScheduleCalled ||
        deleteOffTakeScheduleCalled) &&
        showError()}
      {getOffTakeSchedulesCalled && !getOffTakeSchedulesLoading && getOffTakeSchedulesError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getOffTakeSchedulesError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(OffTakeScheduleActions.resetGetOffTakeSchedulesError());
          }}
        />
      )}
      {showConfirmation && (
        <MessageModal
          title={t('common.agreeConfirmationTitle')}
          secundaryAction={t('common.no')}
          secundaryEvent={() => {
            setShowConfirmation(false);
          }}
          primaryAction={t('common.yes')}
          primaryEvent={() => {
            setShowConfirmation(false);
            handleAgree();
          }}
        />
      )}
      {updateUserOffTakeScheduleCalled &&
        !updateUserOffTakeScheduleLoading &&
        updateUserOffTakeScheduleError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={updateUserOffTakeScheduleError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(OffTakeScheduleActions.resetUpdateUserOffTakeScheduleError());
            }}
          />
        )}

      {addDisagreeComments && (
        <ModalView
          view={
            <DeniedComment
              isModal
              formType="offTakeSchedule"
              onClose={() => {
                setAddDisagreeComments(false);
              }}
            />
          }
          onClose={() => setAddDisagreeComments(false)}
        />
      )}

      {seeDisagreeComments && (
        <ModalView
          view={
            <SeeComment
              isModal
              formType="offTakeSchedule"
              onClose={() => setSeeDisagreeComments(false)}
            />
          }
          onClose={() => setSeeDisagreeComments(false)}
        />
      )}
    </Box>
  );
};

export default withTranslation()(OffTakeSchedule);
