import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';

import FilterButtonBG from '../../assets/images/background/filterButtonBG.svg';

import styles from './styles.module.sass';

const FilterButton = ({ t, className, active = false, label, number, onPress }) => {
  return (
    <Box
      className={styles.content}
      onClick={() => {
        if (onPress) {
          onPress();
        }
      }}
    >
      <Box className={styles.button}>
        <SvgIcon className={styles.background} viewBox="0 0 69 78" component={FilterButtonBG} />
        <Box className={styles.number}>{number || ''}</Box>
        <Box className={styles.text}>{label || ''}</Box>
      </Box>
      <Box
        style={{ backgroundColor: active ? 'black' : 'transparent' }}
        className={styles.bottom_line}
      />
    </Box>
  );
};

export default withTranslation()(FilterButton);
