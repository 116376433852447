const verifyEmail = function (text) {
  const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  return regex.test(text);
};

const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return isMobile.Android() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  },
};

const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

// const upperWord = (value = '') => {
//   const lowerword = value.toLocaleLowerCase();
//   return lowerword[0].toUpperCase() + lowerword.slice(1);
// };

export { verifyEmail, isMobile, isInStandaloneMode };
