import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import NavigationBar from '../../../components/NavigationBar';
import Button from '../../../components/Button';
import PaymentConfirmBox from '../../../components/PaymentConfirmBox';
import CheckBox from '../../../components/CheckBox';
import MessageModal from '../../../modals/message';
import Loading from '../../../components/loading';

import AuthActions from '../../../redux/auth/actions';
import PaymentsActions from '../../../redux/payments/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import ContractInfoCard from '../../../components/ContractInfoCard';

const validationSchema = Yup.object({});

const PaymentConfirmation = ({ t, history }) => {
  const dispatch = useDispatch();

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [agreementsChecked, setAgreementsChecked] = useState(false);
  const {
    actionPaymentLoading,
    actionPaymentError,
    actionPaymentCalled,
    selectedPayment,
    paymentConfirmationCheck,
  } = useSelector((state) => state.payments);

  const { user } = useSelector((state) => state.auth);
  const { paymentTokenValid } = useSelector((state) => state.auth);

  useEffect(() => {
    if (paymentTokenValid) {
      if (selectedPayment?.id) {
        dispatch(PaymentsActions.updatePayment(selectedPayment));
      } else {
        dispatch(PaymentsActions.createPayment(selectedPayment));
      }
    }
    setAgreementsChecked(paymentConfirmationCheck);
    return () => {
      dispatch(AuthActions.resetPaymentToken());
    };
  }, [dispatch, history, paymentTokenValid, selectedPayment, paymentConfirmationCheck]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function handleSubmit() {
    dispatch(AuthActions.resetPaymentToken());
    if (agreementsChecked) {
      history.push('/validate-token/payment');
    }
  }

  function userCollaboratorBox(user) {
    return (
      <Box className={styles.box_item}>
        <Box className={styles.box_row}>
          <Box className={styles.box_user_data}>
            <Box className={styles.box_name}>
              {`${user.firstName || ''} ${user.lastName || ''}`}
            </Box>
            <Box className={styles.box_email}>{user.email}</Box>
          </Box>
        </Box>
        <Divider style={{ marginTop: 20 }} />
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <NavigationBar
        titleDescription={{
          title: t('paymentInstructions.title').toUpperCase(),
        }}
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        history={history}
        hasNotificationButton={!isMobile && !isTablet}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid
            container
            className={styles.grid_container}
            justify="flex-start"
            style={{ paddingBottom: isTablet ? 86 : isMobile ? 73 : 0 }}
          >
            <Grid item xs={12} sm={12} container justify="flex-start">
              <Container
                className={styles.content}
                style={isMobile || isTablet ? { marginTop: 0, boxShadow: 'none' } : {}}
              >
                {!isMobile && (
                  <Box className={styles.container_title}>
                    <Box component="span" style={{ color: '#2f2f2f' }} className={styles.title}>
                      {t('paymentInstructions.confirmation.title')}
                    </Box>
                  </Box>
                )}
                <Loading isLoading={actionPaymentLoading} />
                <PaymentConfirmBox
                  contract={selectedPayment?.contract?.contractReference}
                  buyerEmail={selectedPayment?.buyer?.email}
                  buyerName={`${selectedPayment?.buyer?.firstName || ''} ${
                    selectedPayment?.buyer?.lastName || ''
                  }`}
                  accountHolderName={selectedPayment?.accountHolderName}
                  bankName={selectedPayment?.bankName}
                  routingNumber={selectedPayment?.routingNumber}
                  accountNumber={selectedPayment?.accountNumber}
                  beneficiaryName={selectedPayment?.beneficiaryName || ''}
                  beneficiaryAddress={selectedPayment?.beneficiaryAddress || ''}
                  beneficiaryComments={selectedPayment?.beneficiaryComments || ''}
                  financier={selectedPayment?.legalRepresentative?.financier || ''}
                  legalEmail={selectedPayment?.legalRepresentative?.email || ''}
                  legalName={`${selectedPayment?.legalRepresentative?.firstName || ''} ${
                    selectedPayment?.legalRepresentative?.lastName || ''
                  }`}
                />
                <Box className={styles.confirmation_form_card}>
                  {(selectedPayment?.buyer?.email !== undefined ||
                    selectedPayment?.legalRepresentative?.email !== undefined) && (
                    <>
                      <Box className={styles.container_step_description}>
                        <Box className={styles.title}>
                          {selectedPayment?.legalRepresentative
                            ? t('paymentInstructions.confirmation.acknowledgmentTitle')
                            : t('paymentInstructions.confirmation.collaborationTitle')}
                        </Box>
                        <Box className="subtitle" style={{ marginTop: 10 }}>
                          {t('paymentInstructions.confirmation.description')}
                        </Box>
                      </Box>
                      <Box className={styles.container_step_description}>
                        <Box className={styles.title_pledge}>
                          {t('paymentInstructions.confirmation.collaborationSubitle')}
                        </Box>
                        <Box className={styles.contract_pledge} style={{ marginTop: 10 }}>
                          {t('paymentInstructions.confirmation.collaborationContract', {
                            contractReference: selectedPayment?.contract?.contractReference,
                          })}
                        </Box>
                        <Box className={styles.title_pledge} style={{ marginTop: 10 }}>
                          {t('paymentInstructions.confirmation.to')}
                        </Box>
                      </Box>
                      <Box>
                        {selectedPayment?.buyer && userCollaboratorBox(selectedPayment.buyer)}
                        {selectedPayment?.legalRepresentative &&
                          userCollaboratorBox(selectedPayment.legalRepresentative)}
                      </Box>
                    </>
                  )}
                  <Box>
                    <Formik
                      initialValues={{}}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box className={styles.container_agreements}>
                            {agreementsChecked === true && (
                              <CheckBox
                                checked
                                onChange={(event) =>
                                  dispatch(
                                    PaymentsActions.savePaymentConfirmationCheck(
                                      event.target.checked
                                    )
                                  )
                                }
                              />
                            )}
                            {agreementsChecked === false && (
                              <CheckBox
                                checked={false}
                                onChange={(event) =>
                                  dispatch(
                                    PaymentsActions.savePaymentConfirmationCheck(
                                      event.target.checked
                                    )
                                  )
                                }
                              />
                            )}
                            {selectedPayment?.legalRepresentative
                              ? t('paymentInstructions.confirmation.agreements2', {
                                  bankName: selectedPayment?.legalRepresentative?.financier || '',
                                })
                              : t('paymentInstructions.confirmation.agreements1')}
                          </Box>
                          <Box className={styles.container_submit}>
                            <Box className={styles.box_two_options}>
                              <Button
                                inverted
                                bordered
                                className={styles.button}
                                onPress={() => history.goBack()}
                              >
                                {t('common.cancel')}
                              </Button>
                              <Box style={{ width: 20 }} />
                              <Button
                                type="submit"
                                disabled={!agreementsChecked || actionPaymentLoading}
                                loading={actionPaymentLoading}
                                className={styles.button}
                                data-info-submit="Submit"
                              >
                                {t('common.submit')}
                              </Button>
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        }
      />
      {actionPaymentCalled && !actionPaymentLoading && !actionPaymentError && (
        <MessageModal
          title={t('common.success')}
          message={
            selectedPayment?.id
              ? t('paymentInstructions.confirmation.updated')
              : t('paymentInstructions.confirmation.created')
          }
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetActionPayment());
            isMobile
              ? history.replace('/contracts/specs')
              : history.replace('/contracts/payment-instructions');
          }}
        />
      )}
      {actionPaymentCalled && !actionPaymentLoading && actionPaymentError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={actionPaymentError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetActionPayment());
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(PaymentConfirmation);
