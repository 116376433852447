import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import SvgIcon from '@material-ui/core/SvgIcon';

import { useDispatch, useSelector } from 'react-redux';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import jwt from 'jwt-decode';
import queryString from 'query-string';
import { authenticator } from 'otplib';
import QRCode from 'qrcode';
import NavigationBar from '../../../components/NavigationBar';
import MessageModal from '../../../modals/message';
import Button from '../../../components/Button';
import ValidateKey from '../../../components/ValidateKey';
import HelpIcon from '../../../assets/images/icons/help.svg';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const SetupTOTP = ({ t, history, match }) => {
  const dispatch = useDispatch();
  const {
    validateTokenLoading,
    validateTokenError,
    validateTokenCalled,
    tokenValid,
    emailVerified,
    user,
  } = useSelector((state) => state.auth);

  const [qrImage, setQrImage] = useState(null);

  const [showHelp, setShowHelp] = useState(false);

  const [minHeight, setMinHeight] = useState(0);

  const [tokenPayload, setTokenPayload] = useState(null);

  const [tokenExpired, setTokenExpired] = useState(true);

  const formBox = React.createRef();

  useEffect(() => {
    if (history?.location?.search && tokenPayload === null) {
      const values = queryString.parse(history.location.search);
      const { token } = values;
      try {
        const decoded = jwt(token);
        const currentTimestamp = new Date().getTime() / 1000;
        const tokenIsNotExpired = decoded.exp > currentTimestamp;
        if (tokenIsNotExpired) {
          setTokenPayload(decoded?.payload);
          setTokenExpired(false);
        } else {
          setTokenExpired(true);
          history.replace('/');
        }
      } catch {
        setTokenExpired(true);
        history.replace('/');
      }
    }
  }, [dispatch, history, tokenPayload]);

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
    if (user) {
      const otpauth = authenticator.keyuri(
        user.email,
        process.env.REACT_APP_ENV === 'prod' ? 'Inquari' : `${process.env.REACT_APP_ENV} Inquari`,
        user.secret
      );
      QRCode.toDataURL(otpauth, function (err, image_data) {
        setQrImage(image_data);
      });
    }
  }, [dispatch, history, user, validateTokenCalled, tokenValid, emailVerified, formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function validateKey(token) {
    if (user?.secret) {
      dispatch(
        AuthActions.validateTotpToken(
          token,
          user.secret,
          match?.params.type === 'payment' ? 'payment' : null
        )
      );
    }
  }

  function handleContinue() {
    if (validateTokenCalled && tokenValid) {
      if (emailVerified === false) {
        history.replace('/email-verification');
      } else {
        history.push('/home');
      }
      dispatch(AuthActions.resetValidateToken());
    }
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <NavigationBar
        title={t('validateToken.title')}
        hasBackButton={!isMobile}
        hasCloseButton={isMobile}
        hasMenuButton={!isMobile}
        hideFirstBar={!isMobile}
        history={history}
        customBack={
          user?.secret
            ? null
            : () => {
                dispatch(AuthActions.logout());
                history.replace('/login');
              }
        }
      />
      {showHelp && (
        <MessageModal
          icon={null}
          title={t('setupGoogleAutheticator.modalTitle')}
          messages={[
            t('setupGoogleAutheticator.modalInstructions1'),
            t('setupGoogleAutheticator.modalInstructions2'),
            t('setupGoogleAutheticator.modalInstructions3'),
            t('setupGoogleAutheticator.modalInstructions4'),
          ]}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            setShowHelp(false);
          }}
          center
        />
      )}

      {(tokenValid || !user?.last2FAValid || !tokenExpired) && (
        <Container maxWidth="md" className={styles.content} ref={formBox}>
          <Box className={styles.container_step_description}>
            <Box className={styles.container_title}>
              <Box className={styles.title}>{t('setupGoogleAutheticator.title')}</Box>
              <Button
                maxWidth={25}
                inverted
                clean
                style={{ marginLeft: 5 }}
                labelStyle={{ justifyContent: 'center' }}
                onPress={() => {
                  setShowHelp(true);
                }}
              >
                <SvgIcon viewBox="0 0 25 25" component={HelpIcon} />
              </Button>
            </Box>
            <Box className={styles.container_step_instructions}>
              <Box className={styles.container_instruction}>
                <Typography className={styles.description}>
                  {t(
                    'setupGoogleAutheticator.Please verify your identity using the code generated by the'
                  )}
                  <br />
                  <Link
                    className={styles.link}
                    onClick={() => {
                      if (isIOS) {
                        window.open(
                          'https://apps.apple.com/app/google-authenticator/id388497605',
                          '_blank'
                        );
                      } else if (isAndroid) {
                        window.open(
                          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
                          '_blank'
                        );
                      } else {
                        window.open('https://support.google.com/accounts/answer/1066447', '_blank');
                      }
                    }}
                  >
                    {t('setupGoogleAutheticator.Google Authenticator App')}
                  </Link>
                </Typography>
              </Box>
            </Box>
            {!isMobile && (
              <Box className={styles.container_image}>
                <img src={qrImage} alt="" />
              </Box>
            )}
          </Box>
          <Box className={styles.container_form}>
            <Box className={styles.container_copy}>
              <CopyToClipboard text={user?.secret ?? ''}>
                <Button
                  className={styles.button}
                  minWidth={90}
                  maxWidth={isMobile ? 230 : 200}
                  inverted
                  bordered
                  shading={false}
                >
                  {isMobile
                    ? t('setupGoogleAutheticator.copyKeyMobile')
                    : t('setupGoogleAutheticator.copyKeyDesktop')}
                </Button>
              </CopyToClipboard>
            </Box>
            <ValidateKey
              style={{ maxWidth: 100 }}
              loading={validateTokenLoading}
              disabled={validateTokenLoading}
              tokenIsValid={validateTokenCalled && !validateTokenLoading ? tokenValid : null}
              error={validateTokenError}
              showResetSecret={false}
              onValidateKey={validateKey}
            />
          </Box>
          <Box className={styles.container_submit}>
            <Button
              disabled={!tokenValid}
              className={styles.button}
              onClick={handleContinue}
              minWidth={130}
              maxWidth={270}
            >
              {t('common.continue')}
            </Button>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default withTranslation()(SetupTOTP);
