import React, { useState, useEffect } from 'react';
// import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns'; // import
import moment from 'moment';
import {
  DateRangePicker,
  DateRange,
  DateRangeDelimiter,
  LocalizationProvider,
} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import ClearIcon from '@material-ui/icons/Clear';

import TextField from './TextField';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      root: {
        backgroundColor: '#111',
      },
    },
    MuiPickersDateRangeDay: {
      rangeIntervalDayHighlight: {
        color: '#111',
        backgroundColor: '#f4f4f5',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue['400'],
      },
    },
  },
});

function BasicDateRangePicker({ value, onChange, placeholder, clearable, ...props }) {
  const [selectedDate, setSelectedDate] = useState([null, null]);

  useEffect(() => {
    if (value === null || (value[0] === null && value[1] === null)) {
      setSelectedDate([null, null]);
    } else {
      setSelectedDate(value);
    }
  }, [value]);

  function getTextField(startProps) {
    let textFormated;
    if (
      selectedDate[0] !== null &&
      moment(selectedDate[0]).isValid() &&
      (selectedDate[1] === null || !moment(selectedDate[1]).isValid())
    ) {
      textFormated = moment(selectedDate[0]).format('MMM DD');
    } else if (
      selectedDate[0] !== null &&
      moment(selectedDate[0]).isValid() &&
      selectedDate[1] !== null &&
      moment(selectedDate[1]).isValid()
    ) {
      textFormated = `${moment(selectedDate[0]).format('MMM DD')} to ${moment(
        selectedDate[1]
      ).format('MMM DD')}`;
    }
    const rangeProps = {
      ...props,
      helperText: '',
      inputProps: {
        ...startProps.inputProps,
        value: textFormated || '',
        placeholder,
        onBlur: startProps.inputProps.onBlur,
        onFocus: startProps.inputProps.onFocus,
        onChange: startProps.inputProps.onChange,
      },
    };
    return (
      <Box style={{ width: '100%' }}>
        <TextField
          variant="filled"
          {...rangeProps}
          value=""
          button={
            clearable ? (
              <IconButton
                onClick={() => {
                  setSelectedDate([null, null]);
                  if (onChange) {
                    onChange([null, null]);
                  }
                }}
              >
                <ClearIcon color="#111111" fontSize="small" />
              </IconButton>
            ) : null
          }
        />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <ThemeProvider theme={materialTheme}>
        <DateRangePicker
          startText="startDate"
          endText="endDate"
          value={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
            if (onChange) {
              onChange(date);
            }
          }}
          renderInput={(startProps) => <>{getTextField(startProps)}</>}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default BasicDateRangePicker;
