import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loginLoading: false,
  loginError: null,
  loginCalled: false,

  createAccountLoading: false,
  createAccountError: null,
  createdAccount: false,

  createGuestAccountLoading: false,
  createGuestAccountError: null,
  createdGuestAccount: false,

  updateAccountLoading: false,
  updateAccountError: null,
  updateAccountCalled: false,

  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordCalled: false,

  emailVerificationLoading: false,
  emailVerificationError: null,
  emailVerificationCalled: false,

  resetPasswordLoading: false,
  resetPasswordError: null,
  resetPasswordCalled: false,

  changePasswordLoading: false,
  changePasswordError: null,
  changedPassword: false,

  uploadProfilePictureLoading: false,
  uploadProfilePictureError: null,
  uploadedProfilePicture: false,

  validateTokenLoading: false,
  validateTokenError: null,
  validateTokenCalled: false,

  getUsersLoading: false,
  getUsersError: null,
  getUsersCalled: false,

  loggedIn: false,
  user: null,
  users: null,
  checkingUser: true,
  emailVerified: false,
  firebaseAuthId: null,
  tokenValid: false,
  paymentTokenValid: false,
  paymentRequired: false,
  showForbiddenMessage: false,
});

export default initialState;
