import PaymentActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case PaymentActions.getPaymentsRequest().type:
      return state.merge({
        getPaymentsLoading: true,
        getPaymentsError: null,
        getPaymentsCalled: false,
      });

    case PaymentActions.getPaymentsSuccess().type:
      return state.merge({
        getPaymentsLoading: false,
        getPaymentsError: null,
        getPaymentsCalled: true,

        payments: action.payload.payments,
      });

    case PaymentActions.getPaymentsFailed().type:
      return state.merge({
        getPaymentsLoading: false,
        getPaymentsError: action.payload.error,
        getPaymentsCalled: true,
      });

    case PaymentActions.resetGetPayments().type:
      return state.merge({
        getPaymentsLoading: false,
        getPaymentsError: null,
        getPaymentsCalled: false,
      });

    case PaymentActions.resetGetPaymentsError().type:
      return state.merge({
        getPaymentsLoading: false,
        getPaymentsError: null,
        getPaymentsCalled: true,
      });

    case PaymentActions.getPaymentRequest().type:
      return state.merge({
        getPaymentLoading: true,
        getPaymentError: null,
        getPaymentCalled: true,
      });

    case PaymentActions.getPaymentSuccess().type:
      return state.merge({
        getPaymentLoading: false,
        getPaymentError: null,
        getPaymentCalled: true,

        selectedPayment: action.payload.payment,
      });

    case PaymentActions.getPaymentFailed().type:
      return state.merge({
        getPaymentLoading: false,
        getPaymentError: action.payload.error,
        getPaymentCalled: true,
      });

    case PaymentActions.resetGetPayment().type:
      return state.merge({
        getPaymentLoading: false,
        getPaymentError: null,
        getPaymentCalled: false,
      });

    case PaymentActions.resetGetPaymentError().type:
      return state.merge({
        getPaymentLoading: false,
        getPaymentError: null,
        getPaymentCalled: true,
      });

    case PaymentActions.updatePaymentSelection().type:
      return state.merge({
        selectedPayment: action.payload.payment,
      });

    case PaymentActions.savePaymentUserSelection().type:
      return state.merge({
        selectedPaymentUser: action.payload.paymentUser,
      });

    case PaymentActions.actionPaymentRequest().type:
      return state.merge({
        actionPaymentLoading: true,
        actionPaymentError: null,
        actionPaymentCalled: false,
      });

    case PaymentActions.actionPaymentSuccess().type:
      return state.merge({
        actionPaymentLoading: false,
        actionPaymentError: null,
        actionPaymentCalled: true,
      });

    case PaymentActions.actionPaymentFailed().type:
      return state.merge({
        actionPaymentLoading: false,
        actionPaymentError: action.payload.error,
        actionPaymentCalled: true,
      });

    case PaymentActions.resetActionPayment().type:
      return state.merge({
        actionPaymentLoading: false,
        actionPaymentError: null,
        actionPaymentCalled: false,
      });

    case PaymentActions.resetActionPaymentError().type:
      return state.merge({
        actionPaymentLoading: false,
        actionPaymentError: null,
        actionPaymentCalled: true,
      });

    case PaymentActions.updatePaymentUserRequest().type:
      return state.merge({
        updatePaymentUserLoading: true,
        updatePaymentUserError: null,
        updatePaymentUserCalled: false,
      });

    case PaymentActions.updatePaymentUserSuccess().type:
      return state.merge({
        updatePaymentUserLoading: false,
        updatePaymentUserError: null,
        updatePaymentUserCalled: true,
      });

    case PaymentActions.updatePaymentUserFailed().type:
      return state.merge({
        updatePaymentUserLoading: false,
        updatePaymentUserError: action.payload.error,
        updatePaymentUserCalled: true,
      });

    case PaymentActions.resetUpdatePaymentUser().type:
      return state.merge({
        updatePaymentUserLoading: false,
        updatePaymentUserError: null,
        updatePaymentUserCalled: false,
      });

    case PaymentActions.resetUpdatePaymentUserError().type:
      return state.merge({
        updatePaymentUserLoading: false,
        updatePaymentUserError: null,
        updatePaymentUserCalled: true,
      });

    case PaymentActions.setFilters().type:
      return state.merge({
        filters: action.payload.filters,
      });
    case PaymentActions.resetPayments().type:
      return state.merge({
        actionPaymentLoading: false,
        actionPaymentError: null,
        actionPaymentCalled: false,

        selectedPayment: null,
        payments: null,
      });

    case PaymentActions.resetPaymentsError().type:
      return state.merge({
        actionPaymentLoading: false,
        actionPaymentError: null,
        actionPaymentCalled: true,
      });

    case PaymentActions.savePaymentConfirmationCheck().type:
      return state.merge({
        paymentConfirmationCheck: action.payload.status,
      });

    case PaymentActions.sendRequestPaymentRequest().type:
      return state.merge({
        sendRequestPaymentLoading: true,
        sendRequestPaymentError: null,
        sendRequestPaymentCalled: false,
      });

    case PaymentActions.sendRequestPaymentSuccess().type:
      return state.merge({
        sendRequestPaymentLoading: false,
        sendRequestPaymentError: null,
        sendRequestPaymentCalled: true,
      });

    case PaymentActions.sendRequestPaymentFailed().type:
      return state.merge({
        sendRequestPaymentLoading: false,
        sendRequestPaymentError: action.payload.error,
        sendRequestPaymentCalled: true,
      });

    case PaymentActions.resetSendRequestPayment().type:
      return state.merge({
        sendRequestPaymentLoading: false,
        sendRequestPaymentError: null,
        sendRequestPaymentCalled: false,
      });

    case PaymentActions.resetSendRequestPaymentError().type:
      return state.merge({
        sendRequestPaymentLoading: false,
        sendRequestPaymentError: null,
        sendRequestPaymentCalled: true,
      });

    default:
      return state;
  }
};
