import api from './api';

export const getInvites = (id) => {
  return api.get(`contracts/${id}/collaborators`);
};

export const postInvites = (data) => {
  return api.post('contracts/collaborators', data);
};

export const putInvites = (data) => {
  return api.put('contracts/collaborators', data);
};

export const deleteInvites = (payload) => {
  return api.delete('contracts/collaborators', {}, { data: payload });
};

export const getRoles = (query) => {
  return api.get(`contracts/roles${query}`);
};
