import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import PasswordStrengthBar from 'react-password-strength-bar';
import { isMobile } from 'react-device-detect';
import NavigationBar from '../../components/NavigationBar';
import TextField from '../../components/TextField';
import Button from '../../components/Button';

import MessageModal from '../../modals/message';

import AuthActions from '../../redux/auth/actions';

import styles from './styles/changePassword.module.sass';

const ChangePassword = ({ t, history }) => {
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t('common.requiredField')).min(8, t('common.minPassword')),

    password: Yup.string().required(t('common.requiredField')).min(8, t('common.minPassword')),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('common.passwordMustMatch'))
      .required(t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
  }, [formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  const { changePasswordLoading, changePasswordError, changedPassword, user } = useSelector(
    (state) => state.auth
  );

  function handleSubmit(values) {
    dispatch(AuthActions.changePassword(user.email, values.oldPassword, values.password));
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <NavigationBar
        title={t('common.changePassword')}
        hideTitle={!isMobile}
        hideFirstBar={!isMobile}
        hasNotificationButton={false}
        hasMenuButton={!isMobile}
        hasFilterButton={false}
        hasBackButton
        history={history}
      />
      {changedPassword && changePasswordLoading === false && changePasswordError === null && (
        <MessageModal
          title={t('common.success')}
          message={t('resetPassword.success')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(AuthActions.clearChangePassword());
            return history.push('/profile');
          }}
        />
      )}
      <Container maxWidth="md" className={styles.content} ref={formBox}>
        <Box className={styles.container_step_description}>
          <Box className="subtitle" style={{ marginTop: 20 }}>
            {t('resetPassword.description')}
          </Box>
        </Box>
        <Box className={styles.container_inputs}>
          <Formik
            initialValues={{
              oldPassword: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box className={styles.container_inputs}>
                  <Box className={styles.container_input}>
                    <TextField
                      value={values.oldPassword}
                      onChange={handleChange}
                      className={styles.input}
                      helperText={errors.oldPassword && touched.oldPassword && errors.oldPassword}
                      name="oldPassword"
                      type="password"
                      required
                      placeholder={t('common.oldPassword')}
                      label={t('common.oldPassword').toUpperCase()}
                    />
                  </Box>
                  <Box className={styles.container_input}>
                    <TextField
                      value={values.password}
                      onChange={handleChange}
                      className={styles.input}
                      helperText={errors.password && touched.password && errors.password}
                      name="password"
                      type="password"
                      required
                      placeholder={t('common.password')}
                      label={t('common.password').toUpperCase()}
                    />
                  </Box>
                  <PasswordStrengthBar
                    style={{ marginLeft: 0, marginTop: 0 }}
                    password={values.password}
                    minLength={8}
                  />
                  <Box className={styles.container_input}>
                    <TextField
                      value={values.confirmPassword}
                      onChange={handleChange}
                      className={styles.input}
                      helperText={
                        errors.confirmPassword && touched.confirmPassword && errors.confirmPassword
                      }
                      name="confirmPassword"
                      type="password"
                      required
                      placeholder={t('common.confirmPassword')}
                      label={t('common.confirmPassword').toUpperCase()}
                    />
                  </Box>
                </Box>
                {changePasswordError && (
                  <Box className="error" style={{ marginTop: 15 }}>
                    {changePasswordError?.userMessage}
                  </Box>
                )}

                <Box className={styles.container_submit}>
                  <Button
                    className={styles.button}
                    type="submit"
                    loading={changePasswordLoading}
                    disabled={changePasswordLoading}
                    data-info-submit="Submit"
                    minWidth={130}
                    maxWidth={270}
                  >
                    {t('resetPassword.savePassword')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default withTranslation()(ChangePassword);
