import amplitude from 'amplitude-js';

import { createActions } from 'redux-actions';

import {
  getUserContracts as getUserContractsService,
  getUserContract as getUserContractService,
  saveUserContracts as saveUserContractsService,
  updateUserContracts as updateUserContractsService,
  deleteUserContracts as deleteUserContractsService,
  putUserContract as putUserContractService,
  putAcceptInvite as putAcceptInviteService,
  restoreSelectedContract as restoreSelectedContractService,
  storeSelectedContract as storeSelectedContractService,
  removeSelectedContract as removeSelectedContractService,
  getTotalStatistics as getTotalStadisticsService,
  getContractsUsers as getContractsUsersService,
  getContractsProducts as getContractsProductsService,
  getContractMe as getContractMeService,
  getAutocomplete as getAutocompleteService,
} from '../../services/contracts';

import { GenericError } from '../../utils/errors';
import AuthActions from '../auth/actions';

const ContractsActions = createActions({
  GET_USER_CONTRACTS_REQUEST: () => {},
  GET_USER_CONTRACTS_SUCCESS: (userContracts, page) => ({ userContracts, page }),
  GET_USER_CONTRACTS_FAILED: (error) => ({ error }),
  RESET_GET_USER_CONTRACTS: () => {},
  RESET_GET_USER_CONTRACTS_ERROR: () => {},

  GET_CONTRACT_ME_REQUEST: () => {},
  GET_CONTRACT_ME_SUCCESS: (contractMe) => ({ contractMe }),
  GET_CONTRACT_ME_FAILED: (error) => ({ error }),
  RESET_GET_CONTRACT_ME: () => {},
  RESET_GET_CONTRACT_ME_ERROR: () => {},

  GET_USER_CONTRACT_REQUEST: () => {},
  GET_USER_CONTRACT_SUCCESS: (contractSelection) => ({ contractSelection }),
  GET_USER_CONTRACT_FAILED: (error) => ({ error }),
  RESET_GET_USER_CONTRACT: () => {},
  RESET_GET_USER_CONTRACT_ERROR: () => {},

  UPDATE_CONTRACT_SELECTION: (contractSelection) => ({ contractSelection }),
  UPDATE_CONTRACT_SELECTION_STADISTICS: (stadistics) => ({ stadistics }),

  SAVE_USER_CONTRACTS_REQUEST: () => {},
  SAVE_USER_CONTRACTS_SUCCESS: (contractSelection) => ({ contractSelection }),
  SAVE_USER_CONTRACTS_FAILED: (error) => ({ error }),
  RESET_SAVE_USER_CONTRACTS: () => {},
  RESET_SAVE_USER_CONTRACTS_ERROR: () => {},

  DELETE_USER_CONTRACTS_REQUEST: () => {},
  DELETE_USER_CONTRACTS_SUCCESS: () => {},
  DELETE_USER_CONTRACTS_FAILED: (error) => ({ error }),
  RESET_DELETE_USER_CONTRACTS: () => {},
  RESET_DELETE_USER_CONTRACTS_ERROR: () => {},

  UPDATE_SELECTED_USER_DENIED: (user) => ({ user }),

  UPDATE_USER_CONTRACT_REQUEST: () => {},
  UPDATE_USER_CONTRACT_SUCCESS: () => {},
  UPDATE_USER_CONTRACT_FAILED: (error) => ({ error }),
  RESET_UPDATE_USER_CONTRACT: () => {},
  RESET_UPDATE_USER_CONTRACT_ERROR: () => {},

  ACCEPT_INVITE_REQUEST: () => {},
  ACCEPT_INVITE_SUCCESS: () => {},
  ACCEPT_INVITE_FAILED: (error) => ({ error }),
  RESET_ACCEPT_INVITE: () => {},
  RESET_ACCEPT_INVITE_ERROR: () => {},

  GET_TOTAL_STADISTICS_REQUEST: () => {},
  GET_TOTAL_STADISTICS_SUCCESS: (totalStadistics) => ({ totalStadistics }),
  GET_TOTAL_STADISTICS_FAILED: (error) => ({ error }),
  RESET_GET_TOTAL_STADISTICS: () => {},
  RESET_GET_TOTAL_STADISTICS_ERROR: () => {},

  GET_CONTRACTS_USERS_REQUEST: () => {},
  GET_CONTRACTS_USERS_SUCCESS: (users) => ({ users }),
  GET_CONTRACTS_USERS_FAILED: (error) => ({ error }),
  RESET_GET_CONTRACTS_USERS: () => {},
  RESET_GET_CONTRACTS_USERS_ERROR: () => {},

  GET_CONTRACTS_PRODUCTS_REQUEST: () => {},
  GET_CONTRACTS_PRODUCTS_SUCCESS: (products) => ({ products }),
  GET_CONTRACTS_PRODUCTS_FAILED: (error) => ({ error }),
  RESET_GET_CONTRACTS_PRODUCTS: () => {},
  RESET_GET_CONTRACTS_PRODUCTS_ERROR: () => {},

  FILTER_UPDATE: (dateRange, buyer, seller, product, sort, statistic) => ({
    dateRange,
    buyer,
    seller,
    product,
    sort,
    statistic,
  }),

  GET_AUTOCOMPLETE_REQUEST: () => {},
  GET_AUTOCOMPLETE_SUCCESS: (autocomplete) => ({ autocomplete }),
  GET_AUTOCOMPLETE_FAILED: (error) => ({ error }),
  RESET_GET_AUTOCOMPLETE: () => {},
  RESET_GET_AUTOCOMPLETE_ERROR: () => {},
});

ContractsActions.getUserContracts = (userId, limit, page, filters) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getUserContractsRequest());
      const result = await getUserContractsService(userId, limit, page, filters);
      if (result.ok) {
        dispatch(ContractsActions.getUserContractsSuccess(result.data.data, page));
      } else if (result.data) {
        dispatch(
          ContractsActions.getUserContractsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getUserContractsFailed(null));
      } else {
        dispatch(
          ContractsActions.getUserContractsFailed(
            new GenericError('something went wrong on get user contracts')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getUserContractsFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.getContractMe = (id) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getContractMeRequest());
      const result = await getContractMeService(id);
      if (result.ok) {
        dispatch(ContractsActions.getContractMeSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          ContractsActions.getContractMeFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getContractMeFailed(null));
      } else {
        dispatch(
          ContractsActions.getContractMeFailed(
            new GenericError('something went wrong on get contract me')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getContractMeFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.getUserContract = (id) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getUserContractRequest());
      const result = await getUserContractService(id);
      if (result.ok) {
        dispatch(ContractsActions.saveContractSelection(result.data.data));
        dispatch(ContractsActions.getUserContractSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          ContractsActions.getUserContractFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getUserContractFailed(null));
      } else {
        dispatch(
          ContractsActions.getUserContractFailed(
            new GenericError('something went wrong on get user contract')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getUserContractFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.saveUserContracts = (id, data, payload) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.saveUserContractsRequest());
      let result;
      if (id) {
        result = await updateUserContractsService(id, data);
      } else {
        result = await saveUserContractsService(data);
      }
      if (result.ok) {
        const owner = result?.data?.data?.collaborators?.find(
          (collaborator) => collaborator.user === result?.data?.data?.owner?.id
        );
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          if (!id) {
            amplitude.getInstance().logEvent('Total Contracts', {
              userId: result?.data?.data?.owner?.email,
              ContractId: data.name,
            });
          }

          amplitude.getInstance().logEvent('Contracts Creation Role', {
            userRole: owner?.roleSlug || '',
            ContractId: result?.data?.data?.id || '',
          });

          if (result?.data?.data?.buyer?.id && result?.data?.data?.seller?.id) {
            amplitude.getInstance().logEvent('Contracts in Two Team Mode', {
              userId: owner.user || '',
              ContractId: result?.data?.data?.id || '',
            });

            amplitude.getInstance().logEvent('Contracts in Two Team Mode v2', {
              userId: result?.data?.data?.sellerSide
                ? result?.data?.data?.buyer?.email || ''
                : result?.data?.data?.seller?.email || '',
              ContractId: data.name,
            });
          }
          if (payload?.withCounterpartContracts) {
            amplitude.getInstance().logEvent('Counterparty owns contract', {
              userId: owner.user,
              contractId: result?.data?.data?.id || '',
              userRole: owner?.roleSlug || '',
            });
          }
        }

        dispatch(ContractsActions.saveUserContractsSuccess(result.data.data));
        if (id) {
          dispatch(ContractsActions.getUserContract(id));
        }
      } else if (result?.status === 402) {
        dispatch(AuthActions.paymentRequired(true));
        dispatch(ContractsActions.saveUserContractsFailed(null));
      } else if (result.data) {
        dispatch(
          ContractsActions.saveUserContractsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.saveUserContractsFailed(null));
      } else {
        dispatch(
          ContractsActions.saveUserContractsFailed(
            new GenericError('something went wrong on save user contracts')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.saveUserContractsFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.deleteUserContracts = (id) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.deleteUserContractsRequest());
      const result = await deleteUserContractsService(id);
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          amplitude.getInstance().logEvent(`Contracts Deleted`, {
            contractId: id,
            deleteDate: new Date(),
          });
        }
        dispatch(ContractsActions.deleteUserContractsSuccess());
      } else if (result.data) {
        dispatch(
          ContractsActions.deleteUserContractsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.deleteUserContractsFailed(null));
      } else {
        dispatch(
          ContractsActions.deleteUserContractsFailed(
            new GenericError('something went wrong on delete user contract')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.deleteUserContractsFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.updateUserContract = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.updateUserContractRequest());
      const result = await putUserContractService(id, data);
      if (result.ok) {
        dispatch(ContractsActions.updateUserContractSuccess());
      } else if (result.data) {
        dispatch(
          ContractsActions.updateUserContractFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.updateUserContractFailed(null));
      } else {
        dispatch(
          ContractsActions.updateUserContractFailed(
            new GenericError('something went wrong on update user contract')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.updateUserContractFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.acceptInvite = (id) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.acceptInviteRequest());
      const result = await putAcceptInviteService(id);
      if (result.ok) {
        dispatch(ContractsActions.acceptInviteSuccess());
      } else if (result.data) {
        dispatch(
          ContractsActions.acceptInviteFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.acceptInviteFailed(null));
      } else {
        dispatch(
          ContractsActions.acceptInviteFailed(
            new GenericError('something went wrong on accept invitation')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.acceptInviteFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.saveContractSelection = (contract) => {
  return async (dispatch) => {
    if (contract) {
      await storeSelectedContractService(contract);
    } else {
      await removeSelectedContractService();
    }
    dispatch(ContractsActions.updateContractSelection(contract));
  };
};

ContractsActions.restoreSelectedContract = () => {
  return async (dispatch) => {
    const selectedContract = await restoreSelectedContractService();
    if (selectedContract) {
      dispatch(ContractsActions.getUserContract(selectedContract));
    }
  };
};

ContractsActions.getTotalStadistics = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getTotalStadisticsRequest());
      const result = await getTotalStadisticsService(userId);
      if (result.ok) {
        dispatch(ContractsActions.getTotalStadisticsSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          ContractsActions.getTotalStadisticsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getTotalStadisticsFailed(null));
      } else {
        dispatch(
          ContractsActions.getTotalStadisticsFailed(
            new GenericError('something went wrong on get total stadistics')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getTotalStadisticsFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.getContractsUsers = () => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getContractsUsersRequest());
      const result = await getContractsUsersService();
      if (result.ok) {
        dispatch(ContractsActions.getContractsUsersSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          ContractsActions.getContractsUsersFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getContractsUsersFailed(null));
      } else {
        dispatch(
          ContractsActions.getContractsUsersFailed(
            new GenericError('something went wrong on get contracts users')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getContractsUsersFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.getContractsProducts = () => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getContractsProductsRequest());
      const result = await getContractsProductsService();
      if (result.ok) {
        dispatch(ContractsActions.getContractsProductsSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          ContractsActions.getContractsProductsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getContractsProductsFailed(null));
      } else {
        dispatch(
          ContractsActions.getContractsProductsFailed(
            new GenericError('something went wrong on get contracts products')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getContractsProductsFailed(new GenericError(err, err)));
    }
  };
};

ContractsActions.getAutocomplete = () => {
  return async (dispatch) => {
    try {
      dispatch(ContractsActions.getAutocompleteRequest());
      const result = await getAutocompleteService();
      if (result.ok) {
        dispatch(ContractsActions.getAutocompleteSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          ContractsActions.getAutocompleteFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(ContractsActions.getAutocompleteFailed(null));
      } else {
        dispatch(
          ContractsActions.getAutocompleteFailed(
            new GenericError('something went wrong on get autocomplete')
          )
        );
      }
    } catch (err) {
      dispatch(ContractsActions.getAutocompleteFailed(new GenericError(err, err)));
    }
  };
};

export default ContractsActions;
