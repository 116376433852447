import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { isMobile } from 'react-device-detect';

export const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    height: '3px',
    borderRadius: '5px',
    backgroundColor: isMobile ? 'black' : 'white',
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: 10,
    marginLeft: 10,
    fontFamily: 'FuturaPT-Demi',
    fontSize: 16,
    color: isMobile ? 'rgba(0, 0, 0, 0.3)' : 'white',
    '&:hover': {
      color: isMobile ? 'black' : 'white',
      opacity: 1,
    },
    '&$selected': {
      color: isMobile ? 'black' : 'white',
    },
    '&:focus': {
      color: isMobile ? 'black' : 'white',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
