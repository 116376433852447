import api from './api';

export const getPayments = (userId) => {
  return api.get(`payments/instructions?user=${userId}`);
};

export const createPayment = (payment) => {
  delete payment.owner;
  delete payment.financier;
  delete payment.legalName;
  delete payment.buyerName;
  delete payment.createdAt;
  delete payment.updatedAt;
  delete payment.createdAtUser;
  delete payment.updatedAtUser;

  return api.post('payments/instructions', payment);
};

export const updatePayment = (id, data) => {
  delete data.owner;
  delete data.financier;
  delete data.legalName;
  delete data.buyerName;
  delete data.createdAt;
  delete data.updatedAt;
  delete data.createdAtUser;
  delete data.updatedAtUser;

  delete data.id;
  return api.put(`payments/${id}/instructions`, data);
};

export const updatePaymentUser = (id, data) => {
  return api.put(`payments/${id}/instructions/users`, data);
};

export const getPayment = (id) => {
  return api.get(`payments/${id}/instructions`);
};

export const postRequestPayment = (data) => {
  return api.post('payments/instructions/request', data);
};

export const storeSelectedPayment = (payment) => {
  localStorage.setItem('selected_payment', payment.id);
};

export const removeSelectedPayment = async () => {
  localStorage.removeItem('selected_payment');
};

export const restoreSelectedPayment = () => {
  try {
    return localStorage.getItem('selected_payment');
  } catch {
    return null;
  }
};
