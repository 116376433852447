import React from 'react';

import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialTextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

const CustomTextField = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: 5,
    backgroundImage: 'linear-gradient(to right, #fff 20%, #f3f3f5 80%)',
    boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
      border: 'solid 1px #000000',
    },
  },
  focused: {},
  disabled: {
    backgroundImage: 'linear-gradient(to bottom, #fff 97%, #f3f3f5 80%)',
    boxShadow: 'inset 0px 0px 0px 0 #fff',
    border: 'solid 0px #fff',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
  },
}))(CustomizedInputs);

function CustomizedInputs(props) {
  const { classes, ...other } = props;
  return (
    <MaterialTextField
      autoComplete="off"
      InputProps={{ classes, disableUnderline: true }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  error: {
    border: '1px solid #d70000',
    boxShadow: 'none',
  },
  input: {
    overflow: 'hidden',
    borderRadius: 5,
    backgroundImage: 'linear-gradient(to right, #fff 20%, #f3f3f5 80%)',
    boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  inputDisabled: {
    backgroundImage: 'linear-gradient(to bottom, #fff 97%, #f3f3f5 80%)',
    boxShadow: 'inset 0px 0px 0px 0 #fff',
    border: 'solid 0px #fff',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
  },
  inputLabel: {
    '&.disabled': {
      color: '#111',
    },
  },
}));

const TextField = ({ filled = true, error = false, maxLength, ...props }) => {
  const classes = useStyles();

  return (
    <Box>
      <ThemeProvider theme={theme}>
        {props.icon === undefined && props.button === undefined ? (
          <CustomTextField
            classes={error ? { root: classes.error } : {}}
            className={classes.margin}
            style={{ backgroundColor: 'transparent', width: '100%' }}
            variant={filled ? 'filled' : 'standard'}
            inputProps={{
              style: {
                borderRadius: 5,
                textTransform: props.lowercased ? 'lowercase' : 'none',
              },
              maxLength,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                disabled: 'disabled',
              },
            }}
            {...props}
          />
        ) : (
          <CustomTextField
            classes={error ? { root: classes.error } : {}}
            className={classes.margin}
            style={{ backgroundColor: 'transparent', width: '100%' }}
            {...props}
            variant={filled ? 'filled' : 'standard'}
            InputProps={{
              style: {
                borderRadius: 5,
                textTransform: props.lowercased ? 'lowercase' : 'none',
                paddingLeft: filled ? 'none' : 10,
                paddingRight: filled ? 'none' : 10,
              },
              maxLength,
              className: props.disabled ? classes.inputDisabled : classes.input,
              inputMode: 'numeric',
              endAdornment: (
                <InputAdornment position="end">
                  {props.icon && (
                    <Box
                      style={{
                        fontFamily: 'FuturaPT-Medium',
                        color: '#111',
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {props.icon}
                    </Box>
                  )}

                  {props.button && <Box style={{ marginRight: -15 }}>{props.button}</Box>}
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                disabled: 'disabled',
              },
            }}
          />
        )}
      </ThemeProvider>
    </Box>
  );
};

export default TextField;
