import { combineReducers } from 'redux';

export default combineReducers({
  auth: require('./auth/reducer').reducer,
  contracts: require('./contracts/reducer').reducer,
  payments: require('./payments/reducer').reducer,
  offTakeSchedule: require('./offTakeSchedule/reducer').reducer,
  terms: require('./terms/reducer').reducer,
  invites: require('./invites/reducer').reducer,
  workflow: require('./workflow/reducer').reducer,
  serviceWorker: require('./serviceWorker/reducer').reducer,
  modals: require('./modals/reducer').reducer,
  notifications: require('./notifications/reducer').reducer,
});
