import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Link from '@material-ui/core/Link';

import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import clsx from 'clsx';

import jwt from 'jwt-decode';
import queryString from 'query-string';
import { authenticator } from 'otplib';
import PasswordStrengthBar from 'react-password-strength-bar';
import { isMobile } from 'react-device-detect';
import styles from './styles.module.sass';
import AuthActions from '../../../redux/auth/actions';
import Button from '../../../components/Button';
import CheckBox from '../../../components/CheckBox';
import TextField from '../../../components/TextField';
import NavigationBar from '../../../components/NavigationBar';
import CreateAccountBG from '../../../assets/images/background/createAccountBG.svg';

const CreateAccount = ({ t, history }) => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required(t('common.requiredField')),
    lastName: Yup.string().required(t('common.requiredField')),
    email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
    password: Yup.string().required(t('common.requiredField')).min(8, t('common.minPassword')),
    company: Yup.string().required(t('common.requiredField')),
    termsAgreement: Yup.boolean().required(t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const { createAccountLoading, createAccountError, createdAccount } = useSelector(
    (state) => state.auth
  );

  const [minHeight, setMinHeight] = useState(0);
  const [tokenPayload, setTokenPayload] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const formBox = React.createRef();

  useEffect(() => {
    if (history?.location?.search && tokenPayload === null) {
      const values = queryString.parse(history.location.search);
      const { token } = values;
      const decoded = jwt(token);
      setTokenPayload(decoded?.payload);
    }
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
    if (createdAccount) {
      dispatch(AuthActions.clearCreateAccount());
      history.replace('/setup-totp');
    }
  }, [dispatch, history, createdAccount, formBox, tokenPayload]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page/create-acccounts`);
    }
  }, []);

  function handleSubmit(values) {
    const secret = authenticator.generateSecret();
    console.log('values:', values);
    dispatch(AuthActions.createAccount(values, secret));
  }

  const ErrorText = ({ text }) => (
    <p style={{ display: 'flex', alignItems: 'center', padding: 0, margin: 0 }}>
      <ErrorOutlineIcon style={{ width: 20, color: '#d70000', marginRight: 5 }} />
      {text}
    </p>
  );

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <Box className={styles.top_bg}>
        <SvgIcon component={CreateAccountBG} viewBox="0 0 375 249" />
      </Box>
      <NavigationBar
        title={isMobile ? ' ' : ''}
        hasBackButton
        hasMenuButton={!isMobile}
        hideFirstBar={!isMobile}
        transparent
        history={history}
        customBack={() => history.replace('/')}
      />
      <Container maxWidth="md" className={styles.content} ref={formBox}>
        <Box className={styles.container_step_description}>
          <Box className="title">{t('common.createAccount')}</Box>
          <Box className="description" style={{ marginTop: 10 }}>
            {t('createAccount.description')}
          </Box>
          <Box className="description" style={{ marginTop: 10 }}>
            {t('createAccount.description2')}
          </Box>
        </Box>
        <Box>
          <Formik
            enableReinitialize
            initialValues={{
              email: tokenPayload?.email || '',
              firstName:
                tokenPayload?.firstName !== 'yourFirstName' ? tokenPayload?.firstName : '' || '',
              lastName:
                tokenPayload?.lastName !== 'yourLastName' ? tokenPayload?.lastName : '' || '',
              password: '',
              company: tokenPayload?.companyName || '',    
              termsAgreement: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box className={styles.container_inputs}>
                  <Box className={styles.container_names}>
                    <Box className={clsx(styles.container_firstname, styles.container_input)}>
                      <TextField
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        maxLength={30}
                        helperText={
                          errors.firstName &&
                          touched.firstName && <ErrorText text={errors.firstName} />
                        }
                        required
                        placeholder={t('common.firstName')}
                        label={t('common.firstName').toUpperCase()}
                        error={errors.firstName && touched.firstName}
                      />
                    </Box>
                    <Box className={clsx(styles.container_lastname, styles.container_input)}>
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        maxLength={30}
                        helperText={
                          errors.lastName &&
                          touched.lastName && <ErrorText text={errors.lastName} />
                        }
                        required
                        placeholder={t('common.lastName')}
                        label={t('common.lastName').toUpperCase()}
                        error={errors.lastName && touched.lastName}
                      />
                    </Box>
                  </Box>
                  <Box className={styles.container_input}>
                    <TextField
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      helperText={
                        errors.email && touched.email && <ErrorText text={errors.email} />
                      }
                      required
                      placeholder={t('common.email')}
                      label={t('common.email').toUpperCase()}
                      disabled={tokenPayload !== null}
                      error={errors.email && touched.email}
                    />
                  </Box>
                  <Box className={styles.container_input}>
                    <TextField
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      maxLength={30}
                      helperText={
                        errors.password && touched.password && <ErrorText text={errors.password} />
                      }
                      required
                      type="password"
                      placeholder={t('common.password')}
                      label={t('common.password').toUpperCase()}
                      error={errors.password && touched.password}
                    />
                  </Box>
                  <PasswordStrengthBar
                    style={{ marginLeft: 10, marginTop: -10 }}
                    password={values.password}
                    minLength={8}
                  />
                  <Box className={styles.container_input}>
                    <TextField
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                      maxLength={30}
                      helperText={
                        errors.company && touched.company && <ErrorText text={errors.company} />
                      }
                      required
                      placeholder={t('common.companyName')}
                      label={t('common.companyName').toUpperCase()}
                      error={errors.company && touched.company}
                    />
                  </Box>
                </Box>

                {createAccountError && (
                  <Box className={styles.container_error}>
                    <Box className="error">{createAccountError?.userMessage}</Box>
                  </Box>
                )}
                <Box className={styles.terms}>
                  <CheckBox
                    name="termsAgreement"
                    required
                    checked={acceptTerms}
                    onChange={(event) => {
                      console.log('event.target.checked:', event.target.checked);
                       setAcceptTerms(event.target.checked)
                    }}
                  />
                  <span className={styles.separator}>{t('common.byPushingCreate')}</span>
                  <Link
                    className={styles.link}
                    onClick={() => {
                      history.push('/privacy');
                    }}
                  >
                    {t('common.privacy')}
                  </Link>
                  <span className={styles.separator}>{t('common.andOur')}</span>
                  <Link
                    className={styles.link}
                    onClick={() => {
                      history.push('/terms');
                    }}
                  >
                    {t('common.terms')}
                  </Link>
                  <span className={styles.separator}>{t('common.ofInquari')}</span>
                </Box>
                <Box className={styles.container_submit}>
                  <Button
                    type="submit"
                    loading={createAccountLoading}
                    disabled={createAccountLoading || !acceptTerms}
                    className={styles.button}
                    data-info-submit="Submit"
                    minWidth={130}
                    maxWidth={270}
                  >
                    {t('common.create')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default withTranslation()(CreateAccount);
