import ServiceWorkerActions from './actions';
import InitialState from '.';
import I18n from '../../utils/I18n';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case ServiceWorkerActions.init().type:
      return {
        ...state,
        initialized: !state.initialized,
      };
    case ServiceWorkerActions.update().type:
      return {
        ...state,
        updated: !state.updated,
        registration: action.payload.registration,
      };
    case ServiceWorkerActions.changelang().type:
      localStorage.setItem('lang', action.payload.lang);
      I18n.changeLanguage(action.payload.lang);
      return {
        ...state,
        lang: action.payload.lang,
      };
    default:
      return state;
  }
};
