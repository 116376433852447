import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Box, Divider } from '@material-ui/core';
import OffTakeScheduleItem from '../OffTakeScheduleItem';
import WorkflowActions from '../../redux/workflow/actions';
import OffTakeScheduleActions from '../../redux/offTakeSchedule/actions';
import styles from './styles.module.sass';

const OffTakeSchedules = ({ t, option, contract, history }) => {
  const dispatch = useDispatch();
  const { getOffTakeSchedulesCalled, offTakeSchedules } = useSelector(
    (state) => state.offTakeSchedule
  );
  useEffect(() => {
    if (!getOffTakeSchedulesCalled && contract) {
      dispatch(OffTakeScheduleActions.getOffTakeSchedules(contract?.id));
    }
    return () => {};
  }, [dispatch, contract, OffTakeScheduleActions, getOffTakeSchedulesCalled]);

  return (
    <Box className={styles.list}>
      <Box className={styles.title}>{t('contracts.details.workflow.listTitle')}</Box>
      <Divider />
      {offTakeSchedules?.map((value, index) => (
        <OffTakeScheduleItem
          key={index}
          startDate={value?.startDate}
          endDate={value?.endDate}
          volume={value?.volume}
          active={option === value?.id}
          onClick={() => {
            dispatch(WorkflowActions.updateOffTakeScheduleSelection(value));
            dispatch(WorkflowActions.resetGetTasks());
            dispatch(WorkflowActions.resetGetTasksAll());
            history.push('/contracts/workflows/off-take-schedule-detail');
          }}
        />
      ))}
    </Box>
  );
};

export default withTranslation()(OffTakeSchedules);
