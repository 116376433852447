import TermsActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    //  GET
    case TermsActions.getTermsRequest().type:
      return state.merge({
        getTermsLoading: true,
        getTermsError: null,
        getTermsCalled: true,
      });

    case TermsActions.getTermsSuccess().type:
      return state.merge({
        getTermsLoading: false,
        getTermsError: null,
        getTermsCalled: true,

        terms: action.payload.terms,
      });

    case TermsActions.getTermsFailed().type:
      return state.merge({
        getTermsLoading: false,
        getTermsError: action.payload.error,
        getTermsCalled: true,
      });

    case TermsActions.resetGetTerms().type:
      return state.merge({
        getTermsLoading: false,
        getTermsError: null,
        getTermsCalled: false,
      });

    case TermsActions.resetGetTermsError().type:
      return state.merge({
        getTermsLoading: false,
        getTermsError: null,
        getTermsCalled: true,
      });

    //  CREATE
    case TermsActions.createTermsRequest().type:
      return state.merge({
        createTermsLoading: true,
        createTermsError: null,
        createTermsCalled: true,
      });

    case TermsActions.createTermsSuccess().type:
      return state.merge({
        createTermsLoading: false,
        createTermsError: null,
        createTermsCalled: true,
      });

    case TermsActions.createTermsFailed().type:
      return state.merge({
        createTermsLoading: false,
        createTermsError: action.payload.error,
        createTermsCalled: true,
      });

    case TermsActions.resetCreateTerms().type:
      return state.merge({
        createTermsLoading: false,
        createTermsError: null,
        createTermsCalled: false,
      });

    case TermsActions.resetCreateTermsError().type:
      return state.merge({
        createTermsLoading: false,
        createTermsError: null,
        createTermsCalled: true,
      });

    //  UPDATE
    case TermsActions.updateTermsRequest().type:
      return state.merge({
        updateTermsLoading: true,
        updateTermsError: null,
        updateTermsCalled: true,
      });

    case TermsActions.updateTermsSuccess().type:
      return state.merge({
        updateTermsLoading: false,
        updateTermsError: null,
        updateTermsCalled: true,
      });

    case TermsActions.updateTermsFailed().type:
      return state.merge({
        updateTermsLoading: false,
        updateTermsError: action.payload.error,
        updateTermsCalled: true,
      });

    case TermsActions.resetUpdateTerms().type:
      return state.merge({
        updateTermsLoading: false,
        updateTermsError: null,
        updateTermsCalled: false,
      });

      case TermsActions.resetUpdateTermsError().type:
        return state.merge({
          updateTermsLoading: false,
          updateTermsError: null,
          updateTermsCalled: true,
        });

    //  DELETE
    case TermsActions.deleteTermsRequest().type:
      return state.merge({
        deleteTermsLoading: true,
        deleteTermsError: null,
        deleteTermsCalled: true,
      });

    case TermsActions.deleteTermsSuccess().type:
      return state.merge({
        deleteTermsLoading: false,
        deleteTermsError: null,
        deleteTermsCalled: true,
      });

    case TermsActions.deleteTermsFailed().type:
      return state.merge({
        deleteTermsLoading: false,
        deleteTermsError: action.payload.error,
        deleteTermsCalled: true,
      });

    case TermsActions.resetDeleteTerms().type:
      return state.merge({
        deleteTermsLoading: false,
        deleteTermsError: null,
        deleteTermsCalled: false,
      });

      case TermsActions.resetDeleteTermsError().type:
        return state.merge({
          deleteTermsLoading: false,
          deleteTermsError: null,
          deleteTermsCalled: true,
        });

    //  UPDATE USER
    case TermsActions.updateUserTermsRequest().type:
      return state.merge({
        updateUserTermsLoading: true,
        updateUserTermsError: null,
        updateUserTermsCalled: true,
      });

    case TermsActions.updateUserTermsSuccess().type:
      return state.merge({
        updateUserTermsLoading: false,
        updateUserTermsError: null,
        updateUserTermsCalled: true,
      });

    case TermsActions.updateUserTermsFailed().type:
      return state.merge({
        updateUserTermsLoading: false,
        updateUserTermsError: action.payload.error,
        updateUserTermsCalled: true,
      });

    case TermsActions.resetUpdateUserTerms().type:
      return state.merge({
        updateUserTermsLoading: false,
        updateUserTermsError: null,
        updateUserTermsCalled: false,
      });

      case TermsActions.resetUpdateUserTermsError().type:
        return state.merge({
          updateUserTermsLoading: false,
          updateUserTermsError: null,
          updateUserTermsCalled: true,
        });
    default:
      return state;
  }
};
