import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../../assets/images/logo.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: '0px 20px',
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 50,
    paddingBottom: 50,
  },
  header: {
    flexDirection: 'row',
    height: 70,
  },
  logo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexGrow: 1,
    height: 50,
  },
  image: {
    marginTop: 20,
    height: 50,
  },
  contractInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  view: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginTop: 5,
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    marginRight: 5,
  },
  content: {
    marginTop: 5,
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  signaturesView: {
    marginTop: 'auto',
    padding: 50,
    display: 'flex',
    flexDirection: 'row',
  },
  signatureContent: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  signatureLine: {
    marginTop: 50,
    marginBottom: 5,
    width: '60%',
    height: 1,
    borderTop: '1px solid #111',
  },
  contentView: {
    marginLeft: 20,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  contentViewRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  contentViewColumnHeader: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentViewColumnValue: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const ContractPDF = ({ t, contract }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image style={styles.image} alt="Logo" src={Logo} />
          </View>
        </View>
        <View style={styles.contractInfo}>
          <View style={styles.view}>
            <Text style={styles.label}>{t('pdf.contractDate')}:</Text>
            <Text style={styles.content}>{moment(contract?.createdAt).format('DD/MM/YYYY')}</Text>
          </View>
          <View style={{ width: 20 }} />
          <View style={styles.view}>
            <Text style={styles.label}>{t('pdf.contractName')}:</Text>
            <Text style={styles.content}>{contract?.name || ''}</Text>
          </View>
          <View style={{ width: 20 }} />
          <View style={styles.view}>
            <Text style={styles.label}>{t('pdf.contractReference')}:</Text>
            <Text style={styles.content}>{contract?.contractReference || ''}</Text>
          </View>
        </View>
        <View style={styles.contentView}>
          <View style={styles.contentViewRow}>
            <View style={styles.contentViewColumnHeader}>
              <Text style={styles.label}>{t('pdf.buyer')}:</Text>
            </View>
            <View style={styles.contentViewColumnValue}>
              <Text style={styles.content}>
                {`${
                  contract?.sellerSide ? contract?.counterpartCompany : contract?.companyName || ''
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.contentViewRow}>
            <View style={styles.contentViewColumnHeader}>
              <Text style={styles.label}>{t('pdf.seller')}:</Text>
            </View>
            <View style={styles.contentViewColumnValue}>
              <Text style={styles.content}>
                {`${
                  contract?.sellerSide ? contract?.companyName : contract?.counterpartCompany || ''
                }`}
              </Text>
            </View>
          </View>

          <View style={styles.contentViewRow}>
            <View style={styles.contentViewColumnHeader}>
              <Text style={styles.label}>{t('pdf.totalVolume')}:</Text>
            </View>
            <View style={styles.contentViewColumnValue}>
              <Text style={styles.content}>{contract?.totalVolume || ''}</Text>
            </View>
          </View>

          <View style={styles.contentViewRow}>
            <View style={styles.contentViewColumnHeader}>
              <Text style={styles.label}>{t('pdf.packagingType')}:</Text>
            </View>
            <View style={styles.contentViewColumnValue}>
              <Text style={styles.content}>{contract?.packageType || ''}</Text>
            </View>
          </View>

          <View style={styles.contentViewRow}>
            <View style={styles.contentViewColumnHeader}>
              <Text style={styles.label}>{t('pdf.sugarSpec')}:</Text>
            </View>
            <View style={styles.contentViewColumnValue}>
              <Text style={styles.content}>{contract?.sugarSpec || ''}</Text>
            </View>
          </View>

          {contract?.terms?.map((term) => (
            <View style={styles.contentViewRow}>
              <View style={styles.contentViewColumnHeader}>
                <Text style={styles.label}>
                  {`${term.label.toUpperCase()} (${term.type.toUpperCase()})`}
                </Text>
              </View>
              <View style={styles.contentViewColumnValue}>
                <Text style={styles.content}>{term.description}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.signaturesView}>
          <View style={styles.signatureContent}>
            <Text style={styles.label}>{t('pdf.buyer')}</Text>
            <View style={styles.signatureLine} />
            <Text style={styles.content}>
              {`${contract?.buyer?.firstName || ''} ${contract?.buyer?.lastName || ''}\n${t(
                'pdf.behalf'
              )} ${
                contract?.sellerSide
                  ? contract?.counterpartCompany || ''
                  : contract?.companyName || ''
              }`}
            </Text>
          </View>
          <View style={styles.signatureContent}>
            <Text style={styles.label}>{t('pdf.seller')}</Text>
            <View style={styles.signatureLine} />
            <Text style={styles.content}>
              {`${contract?.seller?.firstName || ''} ${contract?.seller?.lastName || ''}\n${t(
                'pdf.behalf'
              )} ${
                contract?.sellerSide
                  ? contract?.companyName || ''
                  : contract?.counterpartCompany || ''
              }`}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

ContractPDF.propTypes = {
  contract: PropTypes.object,
};

export default withTranslation()(ContractPDF);
