import React from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Loading from '../../components/loading';

const LoadingPage = () => {
  return (
    <Box>
      <Loading isLoading />
    </Box>
  );
};

export default LoadingPage;
