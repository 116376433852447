import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import Button from '../Button';
import NextIcon from '../../assets/images/icons/next.svg';
import CheckIcon from '../../assets/images/icons/check.svg';
import PendingIcon from '../../assets/images/icons/pending.svg';

import ContractsActions from '../../redux/contracts/actions';
import PaymentsActions from '../../redux/payments/actions';
import OffTakeScheduleActions from '../../redux/offTakeSchedule/actions';
import TermsActions from '../../redux/terms/actions';
import InvitesActions from '../../redux/invites/actions';

import styles from './styles.module.sass';

const ContractSpecsCard = ({ t, history, match }) => {
  const dispatch = useDispatch();
  const [matchUrl] = useState('/contracts');
  const { user } = useSelector((state) => state.auth);

  const {
    contractSelection,
    deleteUserContractsLoading,
    deleteUserContractsError,
    deleteUserContractsCalled,
    getUserContractCalled,
    acceptInviteLoading,
    acceptInviteError,
    acceptInviteCalled,
    activeRole,
  } = useSelector((state) => state.contracts);

  useEffect(() => {
    if (!getUserContractCalled && contractSelection) {
      dispatch(ContractsActions.getUserContract(contractSelection?.id));
    }
    if (deleteUserContractsCalled && !deleteUserContractsLoading && !deleteUserContractsError) {
      history.goBack();
    }
    if (contractSelection?.status === 'created' && contractSelection?.owner?.id !== user.id) {
      if (!acceptInviteCalled && !acceptInviteLoading && !acceptInviteError) {
        dispatch(ContractsActions.acceptInvite(contractSelection?.id));
      }
    }
  }, [dispatch, contractSelection, user]);

  useEffect(() => {
    return function cleanup() {
      dispatch(ContractsActions.resetGetUserContract());
      dispatch(ContractsActions.resetDeleteUserContracts());
      dispatch(ContractsActions.resetAcceptInvite());
    };
  }, []);

  const menuItems = () => {
    if (activeRole?.roleSlug === 'financier1' || activeRole?.roleSlug === 'financier2') {
      return menuItemsFinancier;
    }
    return menuItemsComercial;
  };

  const menuItemsFinancier = [
    {
      title: t('contracts.paymentInstructions.title'),
      route: '/contracts/payment-instructions',
      status: getPaymentInstructionsStatus(contractSelection?.paymentInstructions),
      onAction: () => {
        dispatch(PaymentsActions.resetGetPayment());
        dispatch(PaymentsActions.savePaymentSelection(contractSelection?.paymentInstructions));
        history.push(`${matchUrl}/payment-instructions`);
      },
    },
  ];

  const menuItemsComercial = [
    {
      title: t('contracts.generalInformation.title2'),
      route: '/contracts/general-information',
      status: getContractStatus(),
      onAction: () => {
        dispatch(ContractsActions.resetSaveUserContracts());
        history.push(`${matchUrl}/general-information`);
      },
    },
    {
      title: t('contracts.paymentInstructions.title'),
      route: '/contracts/payment-instructions',
      status: getPaymentInstructionsStatus(contractSelection?.paymentInstructions),
      onAction: () => {
        dispatch(PaymentsActions.resetGetPayment());
        dispatch(PaymentsActions.savePaymentSelection(contractSelection?.paymentInstructions));
        history.push(`${matchUrl}/payment-instructions`);
      },
    },
    {
      title: t('contracts.offTakeSchedule.title'),
      route: '/contracts/off-take-schedule',
      status: getOffTakeSchedulesStatus(contractSelection?.schedulesStatus),
      onAction: () => {
        dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
        history.push(`${matchUrl}/off-take-schedule`);
      },
    },
    {
      title: t('contracts.addOtherTerms.title'),
      route: '/contracts/other-terms',
      status: getTermsStatus(contractSelection?.termsStatus),
      onAction: () => {
        dispatch(TermsActions.resetGetTerms());
        history.push(`${matchUrl}/other-terms`);
      },
    },
    {
      title: t('contracts.addCollaborators.title2'),
      route: '/contracts/invites',
      status: getCollaboratorsStatus(contractSelection?.collaborators),
      onAction: () => {
        dispatch(InvitesActions.resetGetRoles());
        dispatch(InvitesActions.resetGetInvites());
        history.push(`${matchUrl}/invites`);
      },
    },
  ];

  function ItemSpec({ title, status, onAction, route }) {
    const style = !isMobile && match.path.includes(route) ? styles.active : '';
    return (
      <Box
        className={`${styles.spec_item} ${style}`}
        onClick={() => {
          if (onAction) {
            onAction();
          }
        }}
      >
        <Box className={styles.title}>{title}</Box>
        {typeof status === 'number' && (
          <Box
            style={{
              borderRadius: '50%',
              backgroundColor: 'black',
              color: 'white',
              height: 23,
              width: 23,
              marginRight: 13,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'FuturaPT-Medium',
              fontSize: 13,
            }}
          >
            {status}
          </Box>
        )}
        {status === 'success' && (
          <SvgIcon
            style={{ height: 18, width: 18, marginRight: 13 }}
            component={CheckIcon}
            viewBox="0 0 32 32"
          />
        )}
        {status === 'pending' && (
          <SvgIcon
            style={{ height: 18, width: 18, marginRight: 13 }}
            component={PendingIcon}
            viewBox="0 0 32 32"
          />
        )}
        <SvgIcon style={{ height: 10, width: 10 }} component={NextIcon} viewBox="0 0 10 17" />
      </Box>
    );
  }

  function getContractStatus() {
    if (contractSelection === undefined) {
      return '';
    }
    if (contractSelection?.status === 'created') {
      return 'success';
    }
    if (contractSelection?.buyer?.agree === true && contractSelection?.seller?.agree === true) {
      return 'success';
    }
    return 'pending';
  }

  function getPaymentInstructionsStatus(paymentInstructions) {
    if (paymentInstructions === undefined) {
      return '';
    }
    if (contractSelection?.status === 'created') {
      return 'success';
    }
    if (
      paymentInstructions?.buyer?.agree === true &&
      (!paymentInstructions?.legalRepresentative ||
        paymentInstructions?.legalRepresentative.agree === true)
    ) {
      return 'success';
    }
    return 'pending';
  }

  function getTermsStatus(terms) {
    if (terms === undefined) {
      return '';
    }
    if (contractSelection?.status === 'created') {
      return 'success';
    }
    if (terms?.buyerAgree === true && terms?.sellerAgree === true) {
      return 'success';
    }
    return 'pending';
  }

  function getOffTakeSchedulesStatus(schedules) {
    if (schedules === undefined) {
      return '';
    }
    if (contractSelection?.status === 'created') {
      return 'success';
    }
    if (schedules?.buyerAgree === true && schedules?.sellerAgree === true) {
      return 'success';
    }
    return 'pending';
  }

  function getCollaboratorsStatus(collaborators) {
    if (collaborators === undefined) {
      return '';
    }
    if (collaborators.length > 0) {
      return collaborators.length;
    }
    return '';
  }
  return (
    <Box className={clsx(styles.main_content)}>
      <Container maxWidth="md" className={styles.content}>
        {isMobile && (
          <Box className={styles.container_step_description}>
            <Box className="subtitle">{t('contracts.contractSpecs.description')}</Box>
          </Box>
        )}

        {menuItems()?.map((item, index) => (
          <ItemSpec key={index} {...item} />
        ))}
        {isMobile && (
          <Box className={styles.container_submit}>
            <Button
              className={styles.button_alone}
              minWidth={130}
              maxWidth={270}
              onPress={() => {
                history.replace('/home');
              }}
            >
              {t('common.goHome')}
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};
export default withRouter(withTranslation()(ContractSpecsCard));
