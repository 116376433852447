export default {
  auth: {
    invalidCredentials: 'Su correo electrónico o la contraseña es incorrecta',
    getTokenError: 'Se produjo un error al intentar obtener el token de usuario',
    createAccountError: 'Se produjo un error al intentar crear la cuenta',
    deleteAccountError: 'Se produjo un error al intentar eliminar la cuenta',
    updateProfileError: 'Se produjo un error al intentar actualizar el perfil',
    sendEmailVerificationError: 'Se produjo un error al intentar enviar el correo electrónico',
    verifyEmailError: 'An error occurred trying to verify the email',
    forgotPasswordError: 'Se produjo un error al intentar restablecer la contraseña',
    changePasswordError: 'An error occurred trying to change the password',
    resetPasswordError: 'Se produjo un error al intentar restablecer la contraseña',
  },
  freemium: {
    title: 'Únase al piloto INQUARI para obtener acceso a:',
    description1: 'Creación de contratos ilimitados',
    description2: 'Onboarding personalizado para ti, tu equipo y colaboradores',
    description3:
      'Tres meses adicionales de acceso gratuito cuando la plataforma se lance oficialmente',
    action: 'ÚNASE AL PILOTO',
  },
  common: {
    help: 'AYUDA',
    success: 'TERMINADO',
    createAccount: 'CREAR UNA CUENTA',
    create: 'CREATE',
    login: 'INICIAR SESIÓN',
    signOut: 'DESCONECTAR',
    forgotPassword: '¿OLVIDASTE TU CONTRASEÑA?',
    send: 'ENVIAR',
    done: 'Hecho',
    ok: 'OK',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    password: 'Contraseña',
    confirmPassword: 'Confirmar Contraseña',
    oldPassword: 'Contraseña anterior',
    createYourPassword: 'Crea tu contraseña',
    companyName: 'Nombre de empresa',
    continue: 'SEGUIR',
    requiredField: 'Este es un campo obligatorio',
    enterValidEmail: 'Introduzca un correo electrónico válido',
    minPassword: '8 caracteres como mínimo',
    passwordMustMatch: 'Contraseña debe coincidir con',
    ownerEmail: 'Este correo electrónico debe ser diferente al tuyo',
    close: 'CERRAR',
    error: 'ERROR',
    reset: 'REINICIAR',
    confirm: 'CONFIRMAR',
    submit: 'ENVIAR',
    cancel: 'CANCELAR',
    assigne: 'ASIGNAR',
    apply: 'APLICAR',
    no: 'NO',
    yes: 'SI',
    changePassword: 'CAMBIAR LA CONTRASEÑA',
    saveChanges: 'GUARDAR CAMBIOS',
    back: 'Atrás',
    backToPortfolio: 'Volver a la cartera',
    edit: 'Editar',
    viewContract: 'VER CONTRATO',
    viewPayment: 'VER INSTRUCCIONES DE PAGO',
    goHome: 'IR AL PORTAFOLIO',
    delete: 'Eliminar',
    mustBeNumber: 'Este debe ser un número positivo',
    agree: 'ESTOY DE ACUERDO',
    dontAgree: 'NO ESTOY DE ACUERDO',
    agreeConfirmationTitle: '¿Listo para confirmar?',
    areYouSure: '¿Estas seguro?',
    freemiumErrorTitle: 'ERROR FREEMIUM',
    freemiumErrorMessage: 'Lo sentimos, alcanzaste el límite de contratos para tu cuenta gratuita.',
    notNow: 'AHORA NO',
    goPremium: 'IR A PREMIUM',
    new: 'Nuevo',
    forbidden: 'No tienes permiso suficiente para realizar esa operación.',
    notifications: 'NOTIFICACIONES',
    today: 'Hoy',
    yesterday: 'Ayer',
    deleteContractTitle: 'ESTÁ A PUNTO DE BORRAR ESTE CONTRATO',
    deleteContractDes1: 'Esta acción no se puede deshacer',
    deleteContractDes2: '¿Estás seguro de que harás esto?',
    noChangeMyMind: 'NO, HE CAMBIADO DE OPINIÓN',
    yesDelete: 'SI, BORRARLO',
    deliverySchedule: 'Calendario de entregas',
    resetOTP: 'CONFIGURAR AUTENTICADOR',
    menu: {
      deliverySchedule: 'Calendario de entregas',
      contractSummary: 'Resumen del contrato',
      contractTerms: 'Terminos y condiciones',
      paymentInstructions: 'Instrucciones de pago',
      documents: 'documentos',
      workflow: 'Flujo de trabajo',
    },
    menuShort: {
      deliverySchedule: 'Entregas',
      contractSummary: 'Resumen',
      contractTerms: 'Contract Terms',
      paymentInstructions: 'Payment Instructions',
      documents: 'documentos',
      workflow: 'Flujo',
      more: 'Mas',
    },
    upload: 'Subir',
    rfp: 'Requerido',
    document: 'Documento',
    default: 'Por Defecto',
    allBuyers: 'Todos los compradores',
    allSellers: 'Todos los vendedores',
    allProducts: 'Todos los productos',
    deliveryDateRange: 'Intervalo fechas de entrega',
    all: 'Todo',
    contracts: 'Contratos',
    deliveries: 'Entregas',
    clientSupport: 'Soporte al cliente',
    havenDefinedDeilvery: 'No ha definido su horario de entrega',
    needDeliveryCadence:
      'Debe establecer una cadencia de entrega antes de poder administrar su flujo de trabajo',
    setDeliverySchedule: 'Crear horario de entrega',
    privacy: 'POLITICA PRIVACIDAD',
    terms: 'TERMINOS Y CONDICIONES',
    byPushingCreate: 'He leido y acepto la',
    andOur: 'y los',
    ofInquari: 'de Inquari',
  },
  onboarding: {
    title1: 'BIENVENIDO',
    description1: 'Descargue la aplicación Google Authenticator para crear y verificar su cuenta.',
  },
  createAccount: {
    description: 'Por favor proporcione la siguiente información.',
    description2:
      'Después de crear la cuenta encontrará en su bandeja de entrada un enlace para verificar su dirección de correo electrónico.',
  },
  emailVerification: {
    successMessage:
      'Se envió correctamente un correo electrónico a {{emaill}} para su verificación.',
    successVerification: '¡Gracias! Su correo electrónico ha sido verificado.',
    title: 'VERIFICACION DE EMAIL',
    subtitle: 'TU CUENTA HA SIDO CREADA SATISFACTORIAMENTE',
    description:
      'Le enviamos un correo electrónico a {{email}} con un vínculo para verificar su cuenta.',
    sendNewEmail: 'REENVIAR EMAIL',
    refresh: 'HE VERIFICADO MI CORREO ELECTRÓNICO',
  },
  forgotPassword: {
    title: '¿OLVIDASTE TU CONTRASEÑA?',
    subtitle: 'RESTABLECER SU CONTRASEÑA',
    description:
      'Ingrese su correo electrónico y le enviaremos un enlace para que pueda restablecer su contraseña',
    sent: {
      title: 'ENVIADO!',
      description1: 'Se ha enviado un enlace para restablecer su contraseña.',
      description2:
        'Para cualquier pregunta o para informar un problema, envíe un correo electrónico a help@inquari.com',
    },
  },
  resetPassword: {
    description: 'Ingrese su nueva contraseña.',
    success: 'Se actualizó la contraseña.',
    savePassword: 'GUARDAR NUEVA CONTRASEÑA',
  },
  validateToken: {
    title: 'AUTENTICACIÓN',
    description:
      'Confirme su cuenta escribiendo el código proporcionado por el autenticador de Google',
    verifyCode: 'CÓDIGO DE VERIFICACIÓN',
    resetDescription:
      'Haga clic aquí si no tiene acceso a su Google Authenticator y desea restablecerlo por correo electrónico',
    invalid: 'Token inválido',
    resetSent:
      'Le enviamos un correo electrónico a {{email}} con un vínculo para reestablecer Google Authenticator.',
  },
  setupGoogleAutheticator: {
    title: 'CONFIGURAR AUTENTICADOR',
    'You need to download the': 'Necesitas descargar el',
    'Authenticator App': 'Aplicación Authenticator',
    'and paste this KEY there': 'y pega esta CLAVE ahí',
    'to get your access code': 'para obtener su código de acceso',
    'or scan the QR Code': 'o escanea el código QR',
    'Please verify your identity using the code generated by the':
      'Verifique su identidad utilizando el código generado por el',
    'Google Authenticator App': 'Aplicación Google Authenticator',
    copyKeyMobile: 'COPIAR CLAVE AL PORTAPAPELES',
    copyKeyDesktop: 'O COPIAR CLAVE',
    modalTitle: 'AUTENTICARSE',
    modalInstructions1:
      'Para maximizar la seguridad de la identidad en la plataforma INQUARI, utilizamos un sistema de autenticación de 2 factores que aprovecha la aplicación Google Authenticator.',
    modalInstructions2:
      'Una vez que descargue y se registre en esa aplicación, toque el botón Agregar (+) y copie la clave que le brindamos.',
    modalInstructions3:
      'Google Authenticator le mostrará un código numérico que puede escribir aquí para que podamos confirmar su cuenta.',
    modalInstructions4:
      'Deberá ingresar el código del Autenticador de Google cada vez que necesitemos confirmar su cuenta.',
  },
  home: {
    empty: {
      title: '',
      description: '',
      step1Title: '1. CONFIGURA TU CONTRATO',
      step1Description:
        'Sabemos que cada contrato tiene mucha información. Puede configurar su contrato en una o varias sesiones o asignar a alguien de su equipo para que lo ayude. ',
      step2Title: '',
      step2Description: 'NEED MORE HELP? ',
      step2Link: 'MIRA ESTE VIDEO',
      endStepTitle: 'NO TIENES CONTRATOS',
      endStepDescription: 'AÑADE TU PRIMER CONTRATO',
      welcome: 'BIENVENIDO',
      welcomeDescription: 'Configure su trato en sencillos pasos',
    },
    filters: 'FILTROS',
    sortBy: 'Ordenar por',
    // - Delivery status (Behind - On Time - Ahead) - Delivery status (Ahead - On Time - Behind) - Total contract volume - Total nominated volume - Total delivered volume
    deliveryStatusBehind: 'Estado de entrega (Atrasado - A tiempo - Adelantado)',
    deliveryStatusAhead: 'Estado de entrega (Adelante - A tiempo - Atrasado)',
    totalContract: 'Volumen total del contrato',
    totalNominated: 'Volumen total nominado',
    totalVolume: 'Volumen total a entregar',
    received: 'Entregado',
    nominated: 'Nominado',
    pending: 'Pendiente',
  },
  filters: {
    title: 'FILTROS',
    offTakeStatus: 'ESTADO FUERA DE TOMA',
    currentlyNominated: 'Actualmente nominado',
    deliveryDateRange: 'RANGO DE FECHA DE ENTREGA',
    buyers: 'COMPRADORES',
    sellers: 'VENDEDORES',
    products: 'PRODUCTOS',
  },
  paymentInstructions: {
    descriptionPayment: `Comunique de forma segura la información de su cuenta a (inserte el nombre del comprador) a través de este formulario.`,
    title: 'Instrucciones de pago',
    descriptionWithoutSeller:
      'Para habilitar la sección de instrucciones de pago, debe incluir información del vendedor en la información general del contrato.',
    description:
      'Esta información solo será visible para el Comprador y, si lo desea, su Financiero. Podrá editar esta información cuando lo desee; sin embargo, después de que un Comprador o Financiero haya confirmado, cualquier cambio requeriría que ellos volvieran a confirmar su acuerdo.',
    descriptionEditing:
      'Esta información solo será visible para {{nombre del comprador}} y, si lo desea, su financiero. Podrá editar esta información cuando lo desee; sin embargo, después de que {{buyerName}} o el Financiero lo hayan confirmado, cualquier cambio requeriría que ellos volvieran a confirmar su acuerdo.',
    descriptionEditingWithFinancier:
      'Esta información solo será visible para {{buyerName}} y {{financierName}}. Podrá editar esta información cuando lo desee; sin embargo, después de que {{buyerName}} o {{financierName}} haya confirmado, cualquier cambio requeriría que ellos volvieran a confirmar su acuerdo.',
    title2: 'ASIGNACIÓN COLATERAL',
    description2: `Si este contrato se ha comprometido como garantía, mantenga a todos informados al incluir la información del financista a continuación. Esto invitará al financista a ver y confirmar las instrucciones de pago.`,
    contractReference: 'Número de referencia del contrato',
    contractCounterpartReference: 'Número de referencia de la contraparte',
    buyerEmail: 'Correo electrónico del comprador',
    buyerName: 'Nombre del comprador',
    buyerFirstName: 'Primer nombre',
    buyerLastName: 'Apellido',
    accountHolderName: 'Nombre de la cuenta',
    bankName: 'Nombre del banco, país, ciudad, estado',
    routingNumber: 'CÓDIGO ABA o SWIFT',
    accountNumber: 'Número de cuenta',
    financier: 'Financiero',
    legalEmail: 'Correo electrónico del representante legal',
    legalName: 'Nombre del representante legal',
    legalFirstName: 'Primer nombre',
    legalLastName: 'Apellido',
    beneficiaryTitle: 'INFORMACIÓN DEL BENEFICIARIO DE LA CUENTA',
    beneficiaryDescription:
      'Proporcione la información solicitada a continuación exactamente como aparece en los registros de la institución financiera en la que se mantiene la cuenta.',
    beneficiaryName: 'Nombre del beneficiario de la cuenta',
    beneficiaryAddress: 'Dirección física del beneficiario',
    beneficiaryComments: 'Otros comentarios',
    confirmation: {
      title: 'CONFIRMACIÓN',
      collaborationTitle: 'SOLICITUD DE COLABORACIÓN',
      acknowledgmentTitle: 'RECONOCIMIENTO',
      titleBox: 'Instrucciones de pago',
      description:
        'Antes de que se puedan aceptar los cambios, todas las partes deben confirmar los términos.',
      collaborationSubitle: ' INSTRUCCIÓN DE PAGO DE',
      collaborationContract: 'CONTRATO #{{contractReference}}',
      to: 'PARA',
      agreements1: ' Confirmo que este contrato no se ha pignorado como garantía.',
      agreements2:
        'Me gustaría informarle que este contrato de venta se ha pignorado como garantía a {{bankName}} y que también solicitamos el reconocimiento de las instrucciones de pago por parte de su representante legal.',
      updated: 'Se actualizaron las instrucciones de pago.',
      created: 'Instrucciones de pago creadas.',
    },
    denied: 'Negado',
    confirmed: 'Confirmado',
    pending: 'Pendiente de aprobación',
    approval: 'Aprovado',
    accepted: 'Aceptado',
    actionRequired: 'Acción requerida',
    agree: 'ESTOY DE ACUERDO',
    dontAgree: 'NO ESTOY DE ACUERDO',
    agreeConfirmationTitle: '¿Listo para confirmar?',
    agreeConfirmationDescription:
      'Al confirmar, acepta que todos los pagos de este contrato se realizarán en la cuenta de referencia a menos que estas instrucciones se actualicen y confirmen.',
    agreeSuccess: 'Su actualización ha sido enviada con éxito',
    comment: 'COMENTARIO',
    commentDescription: 'Informe a {{sellerName}} por qué no está de acuerdo.',
    deniedCommentSuccess: 'Gracias por brindarnos sus comentarios.',
    lastEdited: 'ÚLTIMA EDICIÓN: ',
    requestSent: 'La solicitud ha sido enviada con éxito.',
  },
  profile: {
    title: 'PERFIL',
    successDescription: 'Perfil actualizado correctamente.',
    viewProfile: 'VER PERFIL',
  },
  contracts: {
    sumary: 'Resumen del contrato',
    portfolio: 'PORTAFOLIO',
    nominated: 'Nominado',
    behind: 'Atrasado',
    undefined: 'Sin horario de entrega',
    addOffTakeSchedule: 'Agregar programa de entrega',
    undefinedDeliver: 'Sin horario de entrega',
    active: 'Activo',
    onTime: 'A tiempo',
    ahead: 'Adelantado',
    completed: 'Completado',
    contractName: 'Nombre del contrato',
    contractReference: 'Referencia de contrato #',
    totalVolume: 'Volumen total entregado',
    totalVol: 'VOL. TOTAL',
    sugarSpec: 'Especificaciones de azúcar',
    buyerEmail: 'Correo electrónico del comprador',
    buyerEmailLabel:
      'Correo electrónico del comprador (opcional: inclúyalo aquí si desea iniciar el modo de dos equipos)',
    counterpartPlaceHolder: 'Incluir aquí si desea iniciar el modo de dos equipos',
    sellerEmail:
      'Correo electrónico del vendedor (opcional: inclúyalo aquí si desea iniciar el modo de dos equipos)',
    packagingType: 'Tipo de embalaje',
    createContract: 'CREAR CONTRATO',
    proposeChanges: 'PROYECTO DE NUEVA PROPUESTA',
    manageDeliverySchedule: 'ADMINISTRAR EL HORARIO DE ENTREGA',
    delete: 'BORRAR CONTRATO',
    archive: 'CONTRATO DE ARCHIVO',
    seeContract: 'VER CONTRATO',
    imcCmmercialExecutive: ' Soy el ejecutivo comercial',
    addCounterpart: 'Me gustaría invitar a mi contraparte comercial.',
    deny: {
      title: 'Agrega un comentario a {{title}}',
      description: 'Su comentario será revisado por su contraparte',
      successMessage: 'Tu comentario ha sido enviado exitosamente',
      deniedCommentSuccess: 'Gracias por brindarnos sus comentarios.',
    },
    reviewChanges: 'Revise los cambios propuestos por {{contraparte}}',
    waitingReview: 'Esperando la aprobación de la contraparte',
    waiting:
      'Esta información está siendo revisada por otras partes. Hasta que todas las partes hayan confirmado los nuevos cambios, no podrá proponer nuevos cambios.',
    changesConfirmed: 'Cambios confirmados por todas las partes',
    deniedTitle: 'Se denegó el cambio propuesto.',
    deniedMessage: 'Espere hasta que {{contraparte}} verifique la información',
    completeContractTerms: 'TÉRMINOS COMPLETOS DEL CONTRATO',
    manageWorkflow: 'ADMINISTRAR EL FLUJO DE TRABAJO',
    modifyTerms: 'EDITAR INFORMACIÓN GENERAL',
    generalInformation: {
      title: 'CREAR UN CONTRATO',
      title2: 'CONDICIONES',
      title3: 'TÉRMINOS CLAVE',
      description: 'Proporcione los siguientes términos clave para crear su contrato.',
      descriptionEdit: 'Complete esta información básica sobre su contrato',
      description2: '',
      description3: 'Comencemos con los términos básicos. Puedes incluir más más adelante.',
      iam: 'SOY EL',
      seller: 'VENDEDOR',
      buyer: 'COMPRADOR',
      sellSide: 'LADO DE LA VENTA',
      buySide: 'LADO DE COMPRA',
      noSeller: 'SIN VENDEDOR',
      noBuyer: 'SIN COMPRADOR',
      minVolume:
        'Hay programas de extracción que suman {{volumeScheduled}} MT, elimínelos antes de realizar cualquier cambio.',
      sellerRepresentativeFirstName: 'NOMBRE DEL REPRESENTANTE DEL VENDEDOR',
      sellerRepresentativeLastName: 'APELLIDO DEL REPRESENTANTE DEL VENDEDOR',
      sellerRepresentativeEmail: 'CORREO ELECTRÓNICO DEL REPRESENTANTE DEL VENDEDOR',
      sellerCompany: 'Compañía del vendedor',
      buyerRepresentativeFirstName: 'NOMBRE DEL REPRESENTANTE DEL COMPRADOR',
      buyerRepresentativeLastName: 'APELLIDO DEL REPRESENTANTE DEL COMPRADOR',
      buyerRepresentativeEmail: 'CORREO ELECTRÓNICO DEL REPRESENTANTE DEL COMPRADOR',
      buyerCompany: 'Compañía del comprador',
      counterpartHelpMessage:
        'Esta función le permite invitar a su contraparte comercial para que los equipos de compra y venta puedan gestionar sus propios flujos de trabajo relacionados con el contrato, así como colaborar fácilmente en tareas, como la nominación, la entrega y una lista de trabajo de los documentos necesarios para el pago, que preocupa a ambos equipos. Al invitar a su contraparte, los términos del contrato y los horarios de salida, las tareas de nominación y entrega requerirían la confirmación de ambos equipos. Puede invitar a su contraparte ahora o más tarde navegando a las invitaciones de contrato.',
      success: 'You created your contract',
      whatNext: {
        title: '¿Que sigue?',
        item1: 'Crear contrato',
        item2: 'Incluya el resto de sus términos',
        item3: 'Establezca su horario de retiro',
        item4: 'Invitar a colaboradores',
        item5: 'Solicite las instrucciones de pago a su contraparte',
      },
      goSummary: 'IR AL RESUMEN DEL CONTRATO',
      keepAddingTerms: 'MANTENGA AGREGANDO TÉRMINOS',
    },
    contractSpecs: {
      title: 'ESPECIFICACIONES DEL CONTRATO',
      description: '',
    },
    paymentInstructions: {
      title: 'Instrucciones de pago',
      buyerInformation: 'INFORMACION COMPRADORES',
      sellerInformation: 'INFORMACIÓN DEL VENDEDOR',
      sendRequestForPayments: 'ENVIAR SOLICITUD DE INSTRUCCIONES DE PAGO',
      addSellerInfo: 'AÑADIR INFORMACIÓN DEL VENDEDOR',
    },
    offTakeSchedule: {
      title: 'Flujo de trabajo',
      title2: 'Calendario de Entrega',
      description:
        'Personalice el flujo de trabajo y la supervisión de su contrato con volúmenes objetivo y rangos de fechas.',
      description2: 'Todos los cambios deben ser aprobados por su contraparte.',
      volume: 'Volumen',
      dateRange: 'Rango de fechas',
      add: 'SUMAR',
      edit: 'Editar',
      sentSuccess: 'Los términos de horarios fuera de take se han enviado con éxito',
      volumeExceeded: 'Excede el volumen total del contrato ({{totalVolume}}) MT',
      minVolume: 'El volumen debe ser superior a 0',
    },
    addOtherTerms: {
      addTerms: 'Términos agregados',
      title: 'OTROS TERMINOS',
      description: 'Agregue todos los términos de su contrato aquí individualmente',
      add: 'AÑADIR TÉRMINO',
      edit: 'EDITAR TÉRMINO',
      label: 'Etiqueta',
      type: 'Tipo',
      descriptionLabel: 'Descripción',
      sentSuccess: 'Los términos del contrato se han enviado con éxito',
      addFilters: 'Agregar Filtros',
    },
    addCollaborators: {
      title: 'INVITAR',
      title2: 'INVITACIONES',
      description:
        'Invite a su equipo, contraparte comercial y contactos externos que estarán involucrados con este contrato. Elija cuánta información compartir con cada persona asignando su función para este contrato.',
      add: 'SUMAR',
      edit: 'Editar',
      send: 'ENVIAR INVITACIONES',
      listTitle: 'SUS COLABORADORES',
      sentSuccess: 'Las invitaciones se han enviado correctamente.',
      seeRolePermissions: 'PERMISOS DE FUNCIONES',
      roles: {
        commercialOwner: 'Dueño de Contrato',
        contractOwner: 'Dueño de Contrato',
        operationsManager: 'Gerente de Operaciones',
        contractAgent: 'Agente Contractual',
        collaborationAgent: 'Agente Colaborador',
        taskAgent: 'Agente de Tarea',
        exportAgent: 'Agente de Exportacion',
        financier: 'Financiero I',
        financier2: 'Financiero II',
        taskCollaborator: 'Colaborador de Tarea',
        commercialExecutive: 'Ejecutivo Comercial',
      },
      rolePermissions: {
        title: 'PERMISOS DE FUNCIONES',
        description: '',
        commercialOwnerDescription: '',
        contractOwnerDescription: '',
        operationsManagerDescription:
          'Cualquier persona responsable del movimiento de la carga para el comercio, ya sea en el equipo del comprador o del vendedor',
        contractAgentDescription:
          'Cualquier persona que participe en el flujo operativo de un contrato que no forme necesariamente parte del equipo del comprador o del vendedor o de una agencia de exportación. Podría ser un agente de una empresa de transporte',
        collaborationAgentDescription: '',
        taskAgentDescription: '',
        exportAgentDescription:
          'Cualquier persona que participe activamente en el proceso de exportación de un comercio',
        financierDescription:
          'Tiene mayor visibilidad y acceso a más información que un financiero II. Los usuarios de esta categoría tienen acceso al estado del contrato, los términos del contrato, las instrucciones de pago, el calendario del flujo de trabajo y la posibilidad de ver a los usuarios que colaboran',
        financier2Description:
          'Esta función tiene una visibilidad más limitada de la información relativa a los contratos. En comparación con un financista I, solo pueden ver las instrucciones de pago y los usuarios colaboradores de ese contrato',
        taskCollaboratorDescription: '',
        commercialExecutiveDescription:
          'Se considera la persona que realiza la negociación del contrato y uno de los titulares del contrato',
      },
    },
    details: {
      invite: {
        contractTeam: 'EQUIPO CONTRATADO',
      },
      contractTerms: 'Términos y condiciones',
      contractTermsAction: '+AÑADIR O EDITAR TÉRMINOS',
      contractTeam: 'EQUIPO CONTRATADO',
      terms: 'CONDICIONES',
      obligations: 'OBLIGACIONES',
      options: 'OPCIONES',
      workflow: {
        add: 'AGREGAR TAREA',
        title: 'Flujo de trabajo',
        subtitle: 'Configure su trato en sencillos pasos',
        first_step_title: '1. Configura tu primera tarea',
        first_step_description:
          'Deberá seleccionar el tipo entre una nominación, entrega y tareas generales.',
        offTakeSchedule: {
          title: 'HORARIO FUERA DE TOMA',
        },
        offTakeScheduleDetail: {
          title: 'DETALLE DEL FLUJO DE TRABAJO',
        },
        empty: {
          endStepTitle: 'NO TIENES TAREAS',
          endStepDescription: 'AGREGUE SU PRIMERA TAREA',
        },
        listTitle: 'SU HORARIO DE ENTREGA',
        taskSubtitle: 'Para ENTREGA: {{date}} / volumen Total: {{volume}}MT',
        subtaskSubtitle: 'Para NOMINACIÓN: {{date}} - {{volume}}',
        general: 'GENERAL',
        delivery: 'ENTREGA',
        nomination: 'NOMINACIÓN',
        dataPointRequired: 'PUNTO DE DATOS',
        dataPointRequiredDescription: '',
        requirements: 'Requisitos',
        dataPointDescription: '',
        documentRequired: 'DOCUMENTACIÓN',
        documentRequiredDescription: '',
        documentDescription: '',
        recipients: 'DESTINATARIOS',
        generalPage: {
          title: 'DEFINIR TAREA',
          description: '',
          titleInput: 'Título',
          descriptionInput: 'Descripción',
          completionDate: 'Fecha de Terminación',
          markDone: 'MARCAR COMO HECHO',
          completingTitle: 'ESTÁS CONFIRMANDO',
          completingDescription: '',
          comments: 'Comentarios',
          done: 'Hecho',
        },
        deliveryPage: {
          title: 'DEFINIR TAREA',
          description: '',
          titleInput: 'Título',
          location: 'Localización',
          pickupLocation: 'Lugar de recogida',
          countryOrigin: 'País de origen',
          deliveryLocation: 'Lugar de entrega',
          completionDate: 'Fecha de Salida',
          date: 'Fecha',
          tbd: 'TBD',
          markDelivered: 'MARCAR COMO ENTREGADO',
          deliveringTitle: 'ESTÁ CONFIRMANDO ENTREGA FÍSICA',
          deliveringDescription: '',
          comments: 'Comentarios',
          sugarSpec: 'Especificaciones de azúcar',
          volume: 'Volumen',
          delivered: 'Entregado',
          referenceNumber: 'Número de referencia',
          optional: '(Opcional)',
          refNumberLabel: 'Ref #',
        },
        nominationPage: {
          title: 'DEFINIR TAREA',
          description: '',
          pickupLocation: 'Lugar de recogida',
          countryOrigin: 'País de origen',
          volume: 'Volumen',
          date: 'Fecha',
          tbd: 'TBD',
          from: 'Desde',
          till: 'Hasta',
          add: 'AÑADIR NOMINACIÓN',
          timeframe: 'Periodo de tiempo',
          markNominated: 'MARCAR COMO NOMINADO',
          nominatingTitle: 'ESTÁS A PUNTO DE NOMINAR',
          nominatingDescription: '',
          nominated: 'Nominado',
          eta: 'ETA',
          etd: 'ETD',
        },
        invites: {
          title: 'INVITAR A LA TAREA',
          description: '',
          title2:
            '¿No estás en la lista? No hay problema, ingrese el correo electrónico del usuario al que desea asignar esta tarea y haga clic en agregar, luego haga clic en el botón Asignar.',
          description2: 'Estos usuarios solo tendrán acceso a sus tareas asignadas',
        },
        sentSuccess: 'La tarea ha sido enviada con éxito',
        requiredForPayment: 'Requerido para el pago',
        requiredForReview: 'Requerido para revisión',
        assignTask: 'INVITAR',
        nominationStatusPending:
          'Una vez que cargue todos los requisitos, podrá establecer esta tarea como hecha',
        addComment: 'AGREGAR COMENTARIO',
      },
    },
    box: {
      type: 'Tipo',
      contractName: 'Nombre del contrato',
      totalVolume: 'Volumen total',
      contractCode: 'Código de contrato',
      incoTerm: 'Término INCO',
      packagingType: 'Tipo de embalaje',
      sugarSpec: 'Especificaciones de azúcar',
    },
    contractStatus: 'Estado del contrato',
    today: 'Hoy',
    haventAdded: 'No ha añadido ninguna fecha de entrega.',
    addDeliverySchedule: '+AGREGUE su horario de entrega',
  },
  documents: {
    documentName: 'Nombre del documento',
    uploadDate: 'Fecha de carga',
    nominationVol: 'Nominación # / VOL',
    UploadedBy: 'Enviado por',
    download: 'Descargar',
    replaceDoc: 'Reemplazar documento',
    deleteDoc: 'Eliminar documento',
    filterBy: 'Filtrar por',
    submitedDate: 'Fechas de envío',
    nomination: 'Nominación',
    docType: 'Tipo de documento',
    requiredPayment: 'Pago requerido',
    emptyTxt: 'No hay documentos para mostrarte',
    deleteDocTitle: 'ESTÁ A PUNTO DE BORRAR ESTE DOCUMENTO',
    addFilters: 'Agregar filtros',
    applyFilter: 'APLICAR FILTROS',
    documentFilter: 'FILTROS DE DOCUMENTOS',
    noDocuments: 'Ningún documento coincide con la búsqueda',
    search: 'Buscar',
  },
};
