import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';

import styles from './styles.module.sass';

const Progress = ({ size = 40, color = 'primary' }) => {
  return (
    <div className={styles.container_loader}>
      <ThemeProvider theme={theme}>
        <CircularProgress size={size} color={color} />
      </ThemeProvider>
    </div>
  );
};

export default Progress;
