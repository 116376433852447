import { createActions } from 'redux-actions';
import amplitude from 'amplitude-js';

import {
  getOffTakeSchedules as getOffTakeSchedulesService,
  postOffTakeSchedule as postOffTakeScheduleService,
  putOffTakeSchedule as putOffTakeScheduleService,
  deleteOffTakeSchedule as deleteOffTakeScheduleService,
  putUserOffTakeSchedule as putUserOffTakeScheduleService,
} from '../../services/offTakeSchedule';

import { GenericError } from '../../utils/errors';

const OffTakeScheduleActions = createActions({
  GET_OFF_TAKE_SCHEDULES_REQUEST: () => {},
  GET_OFF_TAKE_SCHEDULES_SUCCESS: (offTakeSchedules) => ({ offTakeSchedules }),
  GET_OFF_TAKE_SCHEDULES_FAILED: (error) => ({ error }),
  RESET_GET_OFF_TAKE_SCHEDULES: () => {},
  RESET_GET_OFF_TAKE_SCHEDULES_ERROR: () => {},

  CREATE_OFF_TAKE_SCHEDULE_REQUEST: () => {},
  CREATE_OFF_TAKE_SCHEDULE_SUCCESS: () => {},
  CREATE_OFF_TAKE_SCHEDULE_FAILED: (error) => ({ error }),
  RESET_CREATE_OFF_TAKE_SCHEDULE: () => {},
  RESET_CREATE_OFF_TAKE_SCHEDULE_ERROR: () => {},

  UPDATE_OFF_TAKE_SCHEDULE_REQUEST: () => {},
  UPDATE_OFF_TAKE_SCHEDULE_SUCCESS: () => {},
  UPDATE_OFF_TAKE_SCHEDULE_FAILED: (error) => ({ error }),
  RESET_UPDATE_OFF_TAKE_SCHEDULE: () => {},
  RESET_UPDATE_OFF_TAKE_SCHEDULE_ERROR: () => {},

  DELETE_OFF_TAKE_SCHEDULE_REQUEST: () => {},
  DELETE_OFF_TAKE_SCHEDULE_SUCCESS: () => {},
  DELETE_OFF_TAKE_SCHEDULE_FAILED: (error) => ({ error }),
  RESET_DELETE_OFF_TAKE_SCHEDULE: () => {},
  RESET_DELETE_OFF_TAKE_SCHEDULE_ERROR: () => {},

  UPDATE_USER_OFF_TAKE_SCHEDULE_REQUEST: () => {},
  UPDATE_USER_OFF_TAKE_SCHEDULE_SUCCESS: () => {},
  UPDATE_USER_OFF_TAKE_SCHEDULE_FAILED: (error) => ({ error }),
  RESET_UPDATE_USER_OFF_TAKE_SCHEDULE: () => {},
  RESET_UPDATE_USER_OFF_TAKE_SCHEDULE_ERROR: () => {},
});

OffTakeScheduleActions.getOffTakeSchedules = (id) => {
  return async (dispatch) => {
    try {
      dispatch(OffTakeScheduleActions.getOffTakeSchedulesRequest());
      const result = await getOffTakeSchedulesService(id);
      if (result.ok) {
        dispatch(OffTakeScheduleActions.getOffTakeSchedulesSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          OffTakeScheduleActions.getOffTakeSchedulesFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(OffTakeScheduleActions.getOffTakeSchedulesFailed(null));
      } else {
        dispatch(
          OffTakeScheduleActions.getOffTakeSchedulesFailed(
            new GenericError('something went wrong on get off take schedules')
          )
        );
      }
    } catch (err) {
      dispatch(OffTakeScheduleActions.getOffTakeSchedulesFailed(new GenericError(err, err)));
    }
  };
};

OffTakeScheduleActions.createOffTakeSchedule = (data) => {
  return async (dispatch) => {
    try {
      dispatch(OffTakeScheduleActions.createOffTakeScheduleRequest());
      const result = await postOffTakeScheduleService(data);
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          result.data.data.forEach((item) => {
            amplitude.getInstance().logEvent(`Offtake Number of days`, {
              ContractId: item.data.contract,
              OfftakeId: item.data.id,
              StartDate: item.data.startDate,
              EndDate: item.data.endDate,
            });

            amplitude.getInstance().logEvent('Offtake Volume', {
              ContractId: item.data.contract,
              OfftakeId: item.data.id,
              Volume: item.data.volume,
            });
          });
        }
        dispatch(OffTakeScheduleActions.createOffTakeScheduleSuccess());
      } else if (result.data) {
        dispatch(
          OffTakeScheduleActions.createOffTakeScheduleFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(OffTakeScheduleActions.createOffTakeScheduleFailed(null));
      } else {
        dispatch(
          OffTakeScheduleActions.createOffTakeScheduleFailed(
            new GenericError('something went wrong on create off take schedule')
          )
        );
      }
    } catch (err) {
      dispatch(OffTakeScheduleActions.createOffTakeScheduleFailed(new GenericError(err, err)));
    }
  };
};

OffTakeScheduleActions.updateOffTakeSchedule = (data) => {
  return async (dispatch) => {
    try {
      dispatch(OffTakeScheduleActions.updateOffTakeScheduleRequest());
      const result = await putOffTakeScheduleService(data);
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          result.data.data.forEach((item) => {
            amplitude.getInstance().logEvent(`Offtake Number of days`, {
              ContractId: item.data.contract,
              OfftakeId: item.data.id,
              StartDate: item.data.startDate,
              EndDate: item.data.endDate,
            });

            amplitude.getInstance().logEvent('Offtake Volume', {
              ContractId: item.data.contract,
              OfftakeId: item.data.id,
              Volume: item.data.volume,
            });
          });
        }
        dispatch(OffTakeScheduleActions.updateOffTakeScheduleSuccess());
      } else if (result.data) {
        dispatch(
          OffTakeScheduleActions.updateOffTakeScheduleFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(OffTakeScheduleActions.updateOffTakeScheduleFailed(null));
      } else {
        dispatch(
          OffTakeScheduleActions.updateOffTakeScheduleFailed(
            new GenericError('something went wrong on update off take schedule')
          )
        );
      }
    } catch (err) {
      dispatch(OffTakeScheduleActions.updateOffTakeScheduleFailed(new GenericError(err, err)));
    }
  };
};

OffTakeScheduleActions.deleteOffTakeSchedule = (data) => {
  return async (dispatch) => {
    try {
      dispatch(OffTakeScheduleActions.deleteOffTakeScheduleRequest());
      const result = await deleteOffTakeScheduleService(data);
      if (result.ok) {
        dispatch(OffTakeScheduleActions.deleteOffTakeScheduleSuccess());
      } else if (result.data) {
        dispatch(
          OffTakeScheduleActions.deleteOffTakeScheduleFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(OffTakeScheduleActions.deleteOffTakeScheduleFailed(null));
      } else {
        dispatch(
          OffTakeScheduleActions.deleteOffTakeScheduleFailed(
            new GenericError('something went wrong on delete off take schedule')
          )
        );
      }
    } catch (err) {
      dispatch(OffTakeScheduleActions.deleteOffTakeScheduleFailed(new GenericError(err, err)));
    }
  };
};

OffTakeScheduleActions.updateUserOffTakeSchedule = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(OffTakeScheduleActions.updateUserOffTakeScheduleRequest());
      const result = await putUserOffTakeScheduleService(id, data);
      if (result.ok) {
        dispatch(OffTakeScheduleActions.updateUserOffTakeScheduleSuccess());
      } else if (result.data) {
        dispatch(
          OffTakeScheduleActions.updateUserOffTakeScheduleFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(OffTakeScheduleActions.updateUserOffTakeScheduleFailed(null));
      } else {
        dispatch(
          OffTakeScheduleActions.updateUserOffTakeScheduleFailed(
            new GenericError('something went wrong on update user off take schedule')
          )
        );
      }
    } catch (err) {
      dispatch(OffTakeScheduleActions.updateUserOffTakeScheduleFailed(new GenericError(err, err)));
    }
  };
};
export default OffTakeScheduleActions;
