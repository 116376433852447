import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import React from 'react';

const useStyles = makeStyles({
  contLoader: {
    zIndex: '99999999999',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0px',
    left: '0px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
});

const LoadingComponent = ({ isLoading, error, isInternal }) => {
  const styles = useStyles();
  // Handle the loading state
  if (isLoading) {
    return (
      <div className={styles.contLoader}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  // Handle the error state
  if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  }

  return null;
};

export default LoadingComponent;
