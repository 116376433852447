import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import NavigationBar from '../../../components/NavigationBar';
import Button from '../../../components/Button';

import MessageModal from '../../../modals/message';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const EmailVerification = ({ t, history }) => {
  const dispatch = useDispatch();

  const {
    emailVerificationLoading,
    emailVerificationError,
    emailVerificationCalled,
    emailVerified,
  } = useSelector((state) => state.auth);

  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
    if (emailVerified) {
      history.push('/home');
      dispatch(AuthActions.resetEmailVerification());
    }
  }, [dispatch, history, emailVerified, formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  const { user } = useSelector((state) => state.auth);

  function sendNewEmail() {
    dispatch(AuthActions.sendEmailVerification());
  }

  function emailVerification() {
    window.location.reload();
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      {emailVerificationCalled &&
        emailVerificationLoading === false &&
        emailVerificationError === null && (
          <MessageModal
            title={t('common.success')}
            message={t('emailVerification.successMessage', { email: user?.email ?? '' })}
            primaryAction="CLOSE"
            primaryEvent={() => {
              dispatch(AuthActions.resetEmailVerification());
            }}
          />
        )}
      {emailVerificationError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={emailVerificationError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(AuthActions.resetEmailVerification());
          }}
        />
      )}
      <NavigationBar
        title={t('emailVerification.title')}
        hasBackButton
        hasMenuButton={!isMobile}
        hideFirstBar={!isMobile}
        customBack={() => {
          dispatch(AuthActions.logout());
          dispatch(AuthActions.resetEmailVerification());
          history.replace('/login');
        }}
        history={history}
      />
      <Container maxWidth="md" className={styles.content} ref={formBox}>
        <Box className={styles.container_step_description}>
          <Box className="title">{t('emailVerification.subtitle')}</Box>
          <Box className="description" style={{ marginTop: 10 }}>
            {t('emailVerification.description', { email: user?.email ?? '' })}
          </Box>
        </Box>
        <Box className={styles.container_inputs}>
          <Box className={styles.container_submit}>
            <Button
              className={styles.button}
              loading={emailVerificationLoading}
              disabled={emailVerificationLoading}
              onPress={sendNewEmail}
              minWidth={130}
              maxWidth={270}
            >
              {t('emailVerification.sendNewEmail')}
            </Button>
          </Box>
          <Box className={styles.container_submit}>
            <Button
              className={styles.button}
              onPress={emailVerification}
              minWidth={130}
              maxWidth={270}
            >
              {t('emailVerification.refresh')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withTranslation()(EmailVerification);
