import Immutable from 'seamless-immutable';

const initialState = Immutable({
  getPaymentsLoading: false,
  getPaymentsError: null,
  getPaymentsCalled: false,
  payments: null,

  getPaymentLoading: false,
  getPaymentError: null,
  getPaymentCalled: false,
  selectedPayment: null,

  selectedPaymentUser: null,

  actionPaymentLoading: false,
  actionPaymentError: null,
  actionPaymentCalled: false,

  updatePaymentUserLoading: false,
  updatePaymentUserError: null,
  updatePaymentUserCalled: false,

  filters: null,

  paymentConfirmationCheck: false,

  sendRequestPaymentsLoading: false,
  sendRequestPaymentsError: null,
  sendRequestPaymentsCalled: false,
});

export default initialState;
