import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import MessageModal from '../../../modals/message';

import ContractsActions from '../../../redux/contracts/actions';
import PaymentsActions from '../../../redux/payments/actions';
import OffTakeScheduleActions from '../../../redux/offTakeSchedule/actions';
import TermsActions from '../../../redux/terms/actions';
import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import HelpButton from '../../../components/HelpButton';
import ContractInfoCard from '../../../components/ContractInfoCard';

const DeniedComment = ({ t, history, match, onClose, isModal, formType, onSave }) => {
  const validationSchema = Yup.object({
    deniedReason: Yup.string().required(t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [type, setSetType] = useState(null);

  const {
    updatePaymentUserLoading,
    updatePaymentUserError,
    updatePaymentUserCalled,
    selectedPayment,
  } = useSelector((state) => state.payments);

  const {
    updateUserOffTakeScheduleLoading,
    updateUserOffTakeScheduleError,
    updateUserOffTakeScheduleCalled,
  } = useSelector((state) => state.offTakeSchedule);

  const {
    updateUserContractLoading,
    updateUserContractError,
    updateUserContractCalled,
  } = useSelector((state) => state.contracts);

  const { updateUserTermsLoading, updateUserTermsError, updateUserTermsCalled } = useSelector(
    (state) => state.terms
  );

  const { contractSelection } = useSelector((state) => state.contracts);

  const { user } = useSelector((state) => state.auth);

  function handleSubmit(values) {
    if (type === 'payment') {
      const data = { user: user.id, comment: values.deniedReason, agree: false };
      dispatch(PaymentsActions.updatePaymentUser(selectedPayment.id, data));
    } else if (type === 'offTakeSchedule') {
      const data = { comment: values.deniedReason, agree: false };
      dispatch(OffTakeScheduleActions.updateUserOffTakeSchedule(contractSelection?.id, data));
    } else if (type === 'general-information') {
      const data = { comment: values.deniedReason, agree: false };
      dispatch(ContractsActions.updateUserContract(contractSelection?.id, data));
    } else if (onSave) {
      onSave(values.deniedReason);
    }
  }

  useEffect(() => {
    setSetType(match?.params?.type);
    if (formType) {
      setSetType(formType);
    }
  }, [match]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function getTitle() {
    switch (type) {
      case 'payment':
        return t('contracts.deny.title', {
          title: t('paymentInstructions.title').toUpperCase(),
        }).toUpperCase();
      case 'offTakeSchedule':
        return t('contracts.deny.title', {
          title: t('contracts.offTakeSchedule.title').toUpperCase(),
        }).toUpperCase();
      case 'terms':
        return t('contracts.deny.title', {
          title: t('contracts.addOtherTerms.title').toUpperCase(),
        }).toUpperCase();
      case 'general-information':
        return t('contracts.deny.title', {
          title: t('contracts.generalInformation.title2').toUpperCase(),
        }).toUpperCase();
      case 'nomination':
        return t('contracts.deny.title', {
          title: t('contracts.details.workflow.nominationPage.title').toUpperCase(),
        }).toUpperCase();
      case 'general':
        return t('contracts.deny.title', {
          title: t('contracts.details.workflow.generalPage.title').toUpperCase(),
        }).toUpperCase();
      case 'delivery':
        return t('contracts.deny.title', {
          title: t('contracts.details.workflow.deliveryPage.title').toUpperCase(),
        }).toUpperCase();
      case 'document':
        return t('contracts.deny.title', {
          title: t('common.document').toUpperCase(),
        }).toUpperCase();
      default:
        return '';
    }
  }

  if (isModal) {
    return (
      <Box style={{ width: 700 }}>
        <Grid container className={styles.grid_container} justify="center">
          <Grid item xs={12} sm={12} container justify="center">
            <Container maxWidth="md" className={styles.content}>
              {!isMobile && (
                <Box className={styles.container_title}>
                  <Box className="subtitle_container">{getTitle()}</Box>
                </Box>
              )}
              <Box style={{ marginBottom: 40 }}>
                <Box className="description_container" style={{ textAlign: 'center' }}>
                  {t('contracts.deny.description')}
                </Box>
              </Box>
              <Box>
                <Formik
                  initialValues={{
                    reason: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Box className={styles.container_inputs}>
                        <Box className={styles.container_input}>
                          <TextField
                            name="deniedReason"
                            value={values.deniedReason}
                            onChange={handleChange}
                            helperText={
                              errors.deniedReason && touched.deniedReason && errors.deniedReason
                            }
                            multiline
                            rows={15}
                            required
                          />
                        </Box>
                      </Box>
                      {updatePaymentUserError && (
                        <Box className={styles.container_error}>
                          <Box className="error">{updatePaymentUserError?.userMessage}</Box>
                        </Box>
                      )}

                      {updateUserOffTakeScheduleError && (
                        <Box className={styles.container_error}>
                          <Box className="error">{updateUserOffTakeScheduleError?.userMessage}</Box>
                        </Box>
                      )}

                      {updateUserTermsError && (
                        <Box className={styles.container_error}>
                          <Box className="error">{updateUserTermsError?.userMessage}</Box>
                        </Box>
                      )}

                      {updateUserContractError && (
                        <Box className={styles.container_error}>
                          <Box className="error">{updateUserContractError?.userMessage}</Box>
                        </Box>
                      )}

                      {!(
                        updatePaymentUserCalled &&
                        !updatePaymentUserLoading &&
                        !updatePaymentUserError
                      ) && (
                        <Box className={styles.container_submit}>
                          <Box className={styles.box_two_options}>
                            <Button
                              type="submit"
                              loading={
                                updatePaymentUserLoading ||
                                updateUserOffTakeScheduleLoading ||
                                updateUserTermsLoading ||
                                updateUserContractLoading
                              }
                              disabled={
                                updatePaymentUserLoading ||
                                updateUserOffTakeScheduleLoading ||
                                updateUserTermsLoading ||
                                updateUserContractLoading
                              }
                              className={styles.button}
                              data-info-submit="Submit"
                              minWidth={130}
                              maxWidth={270}
                            >
                              {t('common.send')}
                            </Button>

                            <Box style={{ width: 20 }} />

                            <Button
                              loading={
                                updatePaymentUserLoading ||
                                updateUserOffTakeScheduleLoading ||
                                updateUserTermsLoading ||
                                updateUserContractLoading
                              }
                              disabled={
                                updatePaymentUserLoading ||
                                updateUserOffTakeScheduleLoading ||
                                updateUserTermsLoading ||
                                updateUserContractLoading
                              }
                              inverted
                              bordered
                              className={styles.button}
                              minWidth={130}
                              maxWidth={270}
                              onPress={() => onClose()}
                            >
                              {t('common.cancel')}
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </form>
                  )}
                </Formik>
              </Box>
            </Container>
          </Grid>
        </Grid>
        {updatePaymentUserCalled && !updatePaymentUserLoading && !updatePaymentUserError && (
          <MessageModal
            title={t('common.success')}
            message={t('contracts.deny.deniedCommentSuccess')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(ContractsActions.resetGetUserContract());
              dispatch(PaymentsActions.resetGetPayment());
              dispatch(PaymentsActions.resetUpdatePaymentUser());
              onClose();
            }}
          />
        )}
        {updateUserOffTakeScheduleCalled &&
          !updateUserOffTakeScheduleLoading &&
          !updateUserOffTakeScheduleError && (
            <MessageModal
              title={t('common.success')}
              message={t('contracts.deny.deniedCommentSuccess')}
              primaryAction={t('common.close')}
              primaryEvent={() => {
                dispatch(ContractsActions.resetGetUserContract());
                dispatch(OffTakeScheduleActions.resetUpdateUserOffTakeSchedule());
                onClose();
              }}
            />
          )}
        {updateUserTermsCalled && !updateUserTermsLoading && !updateUserTermsError && (
          <MessageModal
            title={t('common.success')}
            message={t('contracts.deny.deniedCommentSuccess')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(ContractsActions.resetGetUserContract());
              dispatch(TermsActions.resetUpdateUserTerms());
              onClose();
            }}
          />
        )}

        {updateUserContractCalled && !updateUserContractLoading && !updateUserContractError && (
          <MessageModal
            title={t('common.success')}
            message={t('contracts.deny.deniedCommentSuccess')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(ContractsActions.resetGetUserContract());
              dispatch(ContractsActions.resetUpdateUserContract());
              onClose();
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <NavigationBar
        title={getTitle()}
        hideTitle={!isMobile}
        hasMenuButton={!isMobile}
        barColor={isMobile ? '#fff' : '#111'}
        white={!isMobile}
        hasBackButton
        hasNotificationButton
        hasFilterButton={!isMobile}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        history={history}
        pageContent={
          <Grid container className={styles.grid_container} justify="center">
            {!isMobile && (
              <Grid item xs={12} sm={3}>
                <ContractInfoCard />
                <ContractSpecsCard />
              </Grid>
            )}
            <Grid item xs={12} sm={6} container justify="center">
              <Container maxWidth="md" className={styles.content}>
                {!isMobile && (
                  <Box className={styles.container_title}>
                    <Box component="span" style={{ color: '#2f2f2f' }} className={styles.title}>
                      {getTitle()}
                    </Box>
                  </Box>
                )}
                <Box className={styles.container_step_description}>
                  <Box className="subtitle">
                    {t('contracts.deny.description', {
                      sellerName: `${contractSelection?.seller?.firstName ?? ''} ${
                        contractSelection?.seller?.lastName ?? ''
                      }`,
                    })}
                  </Box>
                </Box>
                <Box>
                  <Formik
                    initialValues={{
                      reason: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleSubmit }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <Box className={styles.container_inputs}>
                          <Box className={styles.container_input}>
                            <TextField
                              name="deniedReason"
                              value={values.deniedReason}
                              onChange={handleChange}
                              helperText={
                                errors.deniedReason && touched.deniedReason && errors.deniedReason
                              }
                              multiline
                              rows={15}
                              required
                            />
                          </Box>
                        </Box>
                        {updatePaymentUserError && (
                          <Box className={styles.container_error}>
                            <Box className="error">{updatePaymentUserError?.userMessage}</Box>
                          </Box>
                        )}

                        {updateUserOffTakeScheduleError && (
                          <Box className={styles.container_error}>
                            <Box className="error">
                              {updateUserOffTakeScheduleError?.userMessage}
                            </Box>
                          </Box>
                        )}

                        {updateUserTermsError && (
                          <Box className={styles.container_error}>
                            <Box className="error">{updateUserTermsError?.userMessage}</Box>
                          </Box>
                        )}

                        {updateUserContractError && (
                          <Box className={styles.container_error}>
                            <Box className="error">{updateUserContractError?.userMessage}</Box>
                          </Box>
                        )}

                        <Box className={styles.container_submit}>
                          <Button
                            type="submit"
                            loading={
                              updatePaymentUserLoading ||
                              updateUserOffTakeScheduleLoading ||
                              updateUserTermsLoading ||
                              updateUserContractLoading
                            }
                            disabled={
                              updatePaymentUserLoading ||
                              updateUserOffTakeScheduleLoading ||
                              updateUserTermsLoading ||
                              updateUserContractLoading
                            }
                            className={styles.button_alone}
                            data-info-submit="Submit"
                            minWidth={130}
                            maxWidth={270}
                          >
                            {t('common.send')}
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Container>
            </Grid>
          </Grid>
        }
      />
      {updatePaymentUserCalled && !updatePaymentUserLoading && !updatePaymentUserError && (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.deny.deniedCommentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetUpdatePaymentUser());
            history.goBack();
          }}
        />
      )}
      {updateUserOffTakeScheduleCalled &&
        !updateUserOffTakeScheduleLoading &&
        !updateUserOffTakeScheduleError && (
          <MessageModal
            title={t('common.success')}
            message={t('contracts.deny.deniedCommentSuccess')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(OffTakeScheduleActions.resetUpdateUserOffTakeSchedule());
              history.goBack();
            }}
          />
        )}
      {updateUserTermsCalled && !updateUserTermsLoading && !updateUserTermsError && (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.deny.deniedCommentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(TermsActions.resetUpdateUserTerms());
            history.goBack();
          }}
        />
      )}

      {updateUserContractCalled && !updateUserContractLoading && !updateUserContractError && (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.deny.deniedCommentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetUpdateUserContract());
            history.goBack();
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(DeniedComment);
