import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import SuccessIcon from '../../assets/images/icons/success.svg';

import Button from '../../components/Button';
import styles from './styles.module.sass';

const MessageModal = ({
  inverted = false,
  icon = SuccessIcon,
  title,
  message,
  messages,
  primaryAction,
  secundaryAction,
  primaryEvent,
  secundaryEvent,
  shouldHide,
  center = false,
}) => {
  useEffect(() => {
    if (shouldHide) {
      setTimeout(() => shouldHide(), 3000);
    }
  });

  return (
    <Box className={styles.container}>
      <Box
        className={styles.box}
        style={{ transform: center ? 'translateY(0%)' : 'translateY(-15%)' }}
      >
        {icon && (
          <Box>
            <SvgIcon
              style={{ marginTop: 10, width: 80, height: 80 }}
              component={icon}
              viewBox="0 0 81 81"
            />
          </Box>
        )}
        <Box className={styles.title}>{title}</Box>
        {message && <Typography className={styles.message}>{message}</Typography>}
        {messages && (
          <Box>
            {messages.map((messageItem, index) => (
              <Typography key={index} className={styles.message}>
                {messageItem}
              </Typography>
            ))}
          </Box>
        )}
        {(primaryAction !== null || secundaryAction !== null) && (
          <Box className={styles.actions}>
            <Box className={styles.container_buttons}>
              {primaryAction && (
                <Box className={styles.container_button1}>
                  {secundaryAction ? (
                    <Button inverted onPress={primaryEvent}>
                      {primaryAction}
                    </Button>
                  ) : (
                    <Button inverted onPress={primaryEvent}>
                      {primaryAction}
                    </Button>
                  )}
                </Box>
              )}
              {secundaryAction && (
                <Box className={styles.container_button2}>
                  <Button bordered shading={false} onPress={secundaryEvent}>
                    {secundaryAction}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MessageModal;
