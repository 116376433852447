import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';

import styles from './styles.module.sass';

const ContractInfoCard = ({ t, history, match }) => {
  const dispatch = useDispatch();
  const [matchUrl] = useState('/contracts');
  const { user } = useSelector((state) => state.auth);
  const { contractSelection } = useSelector((state) => state.contracts);

  return (
    <Box className={clsx(styles.main_content)}>
      <Container maxWidth="md" className={styles.content}>
        <Tooltip title={contractSelection?.name}>
          <Box className={clsx(styles.name)}>{contractSelection?.name}</Box>
        </Tooltip>
        <Tooltip title={contractSelection?.contractReference}>
          <Box className={clsx(styles.reference)}>REF: {contractSelection?.contractReference}</Box>
        </Tooltip>
        {contractSelection?.buyer && (
          <Box className={clsx(styles.buyers)}>
            <Box className={clsx(styles.buyersTitle)}>
              {t('contracts.paymentInstructions.buyerInformation')}
            </Box>
            <Box display="flex" className={clsx(styles.buyersInfoContainer)}>
              <Avatar src={contractSelection?.buyer?.profileImage} className={clsx(styles.avatar)}>
                {contractSelection?.buyer?.firstName.substring(0, 1).toUpperCase() || 'H'}
              </Avatar>
              <Box className={clsx(styles.buyersInfo)}>
                <Tooltip
                  title={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
                >
                  <Box className={clsx(styles.buyersName)}>
                    {`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
                  </Box>
                </Tooltip>
                <Tooltip title={contractSelection?.buyer?.email}>
                  <Box className={clsx(styles.buyersEmail)}>{contractSelection?.buyer?.email}</Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};
export default withRouter(withTranslation()(ContractInfoCard));
