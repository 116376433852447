import { createActions } from 'redux-actions';
import amplitude from 'amplitude-js';

import {
  getInvites as getInvitesService,
  postInvites as postInvitesService,
  putInvites as putInvitesService,
  deleteInvites as deleteInvitesService,
  getRoles as getRolesService,
} from '../../services/invites';

import { GenericError } from '../../utils/errors';
import i18n from '../../utils/I18n';

const InvitesActions = createActions({
  GET_INVITES_REQUEST: () => {},
  GET_INVITES_SUCCESS: (invites) => ({ invites }),
  GET_INVITES_FAILED: (error) => ({ error }),
  RESET_GET_INVITES: () => {},
  RESET_GET_INVITES_ERROR: () => {},

  CREATE_INVITES_REQUEST: () => {},
  CREATE_INVITES_SUCCESS: () => {},
  CREATE_INVITES_FAILED: (error) => ({ error }),
  RESET_CREATE_INVITES: () => {},
  RESET_CREATE_INVITES_ERROR: () => {},

  UPDATE_INVITES_REQUEST: () => {},
  UPDATE_INVITES_SUCCESS: () => {},
  UPDATE_INVITES_FAILED: (error) => ({ error }),
  RESET_UPDATE_INVITES: () => {},
  RESET_UPDATE_INVITES_ERROR: () => {},

  DELETE_INVITES_REQUEST: () => {},
  DELETE_INVITES_SUCCESS: () => {},
  DELETE_INVITES_FAILED: (error) => ({ error }),
  RESET_DELETE_INVITES: () => {},
  RESET_DELETE_INVITES_ERROR: () => {},

  GET_ROLES_REQUEST: () => {},
  GET_ROLES_SUCCESS: (roles) => ({ roles }),
  GET_ROLES_FAILED: (error) => ({ error }),
  RESET_GET_ROLES: () => {},
  RESET_GET_ROLES_ERROR: () => {},
});

InvitesActions.getInvites = (id) => {
  return async (dispatch) => {
    try {
      dispatch(InvitesActions.getInvitesRequest());
      const result = await getInvitesService(id);
      if (result.ok) {
        dispatch(InvitesActions.getInvitesSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          InvitesActions.getInvitesFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(InvitesActions.getInvitesFailed(null));
      } else {
        dispatch(
          InvitesActions.getInvitesFailed(new GenericError('something went wrong on get invites'))
        );
      }
    } catch (err) {
      dispatch(InvitesActions.getInvitesFailed(new GenericError(err, err)));
    }
  };
};

InvitesActions.createInvites = (data) => {
  return async (dispatch) => {
    try {
      dispatch(InvitesActions.createInvitesRequest());
      const result = await postInvitesService(data);
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          result.data.data.forEach((item) => {
            if (item.data.roleSlug === 'financier' || item.data.roleSlug === 'financier2') {
              amplitude.getInstance().logEvent(item.data.role, {
                userId: item.data.id,
                contractId: item.data.contract,
                userRole: item.roleSlug,
              });
            }
          });
        }

        dispatch(InvitesActions.createInvitesSuccess());
      } else if (result.data) {
        dispatch(
          InvitesActions.createInvitesFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(InvitesActions.createInvitesFailed(null));
      } else {
        dispatch(
          InvitesActions.createInvitesFailed(
            new GenericError('something went wrong on create invites')
          )
        );
      }
    } catch (err) {
      dispatch(InvitesActions.createInvitesFailed(new GenericError(err, err)));
    }
  };
};

InvitesActions.updateInvites = (data) => {
  return async (dispatch) => {
    try {
      dispatch(InvitesActions.updateInvitesRequest());
      const result = await putInvitesService(data);
      if (result.ok) {
        /// Amplitude event
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          result.data.data.forEach((item) => {
            if (item.data.roleSlug === 'financier' || item.data.roleSlug === 'financier2') {
              amplitude.getInstance().logEvent(item.data.role, {
                userId: item.data.id,
                contractId: item.data.contract,
                userRole: item.roleSlug,
              });
            }
          });
        }
        dispatch(InvitesActions.updateInvitesSuccess());
      } else if (result.data) {
        dispatch(
          InvitesActions.updateInvitesFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(InvitesActions.updateInvitesFailed(null));
      } else {
        dispatch(
          InvitesActions.updateInvitesFailed(
            new GenericError('something went wrong on update invites')
          )
        );
      }
    } catch (err) {
      dispatch(InvitesActions.updateInvitesFailed(new GenericError(err, err)));
    }
  };
};

InvitesActions.deleteInvites = (data) => {
  return async (dispatch) => {
    try {
      dispatch(InvitesActions.deleteInvitesRequest());
      const result = await deleteInvitesService(data);
      if (result.ok) {
        dispatch(InvitesActions.deleteInvitesSuccess());
      } else if (result.data) {
        dispatch(
          InvitesActions.deleteInvitesFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(InvitesActions.deleteInvitesFailed(null));
      } else {
        dispatch(
          InvitesActions.deleteInvitesFailed(
            new GenericError('something went wrong on delete invites')
          )
        );
      }
    } catch (err) {
      dispatch(InvitesActions.deleteInvitesFailed(new GenericError(err, err)));
    }
  };
};

InvitesActions.getRoles = (query) => {
  return async (dispatch) => {
    try {
      dispatch(InvitesActions.getRolesRequest());
      const result = await getRolesService(query);
      if (result.ok) {
        const roles = result.data.data.map((role) => {
          return {
            ...role,
            originalName: role.name,
            name: i18n.t(`contracts.addCollaborators.roles.${role.slug}`),
          };
        });
        dispatch(InvitesActions.getRolesSuccess(roles));
      } else if (result.data) {
        dispatch(
          InvitesActions.getRolesFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(InvitesActions.getRolesFailed(null));
      } else {
        dispatch(
          InvitesActions.getRolesFailed(new GenericError('something went wrong on get invites'))
        );
      }
    } catch (err) {
      dispatch(InvitesActions.getRolesFailed(new GenericError(err, err)));
    }
  };
};
export default InvitesActions;
