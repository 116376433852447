import AuthActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case AuthActions.loginRequest().type:
      return state.merge({
        loginLoading: true,
        loginError: null,
        loginCalled: false,
      });

    case AuthActions.loginSuccess().type:
      return state.merge({
        loginLoading: false,
        loginError: null,
        loginCalled: true,

        user: action.payload.user || state.user,
        emailVerified: action.payload.emailVerified,
      });

    case AuthActions.loginFailed().type:
      return state.merge({
        loginLoading: false,
        loginError: action.payload.error,
        loginCalled: true,
      });

    case AuthActions.resetLoginState().type:
      return state.merge({
        loginError: null,
        loginLoading: false,
        loginCalled: false,
      });

    case AuthActions.createAccountRequest().type:
      return state.merge({
        createAccountLoading: true,
        createAccountError: null,
        createdAccount: false,
      });

    case AuthActions.createAccountSuccess().type:
      return state.merge({
        createAccountLoading: false,
        createAccountError: null,
        createdAccount: true,

        user: action.payload.user,
        emailVerified: action.payload.emailVerified,
      });

    case AuthActions.createAccountFailed().type:
      return state.merge({
        createAccountLoading: false,
        createAccountError: action.payload.error,
        createdAccount: false,
      });

    case AuthActions.clearCreateAccount().type:
      return state.merge({
        createAccountLoading: false,
        createAccountError: null,
        createdAccount: false,
      });

    case AuthActions.updateAccountRequest().type:
      return state.merge({
        updateAccountLoading: true,
        updateAccountError: null,
        updateAccountCalled: false,
      });

    case AuthActions.updateAccountSuccess().type:
      return state.merge({
        updateAccountLoading: false,
        updateAccountError: null,
        updateAccountCalled: true,

        user: action.payload.user,
        emailVerified: action.payload.emailVerified,
      });

    case AuthActions.updateAccountFailed().type:
      return state.merge({
        updateAccountLoading: false,
        updateAccountError: action.payload.error,
        updateAccountCalled: true,
      });

    case AuthActions.clearUpdateAccount().type:
      return state.merge({
        updateAccountLoading: false,
        updateAccountError: null,
        updateAccountCalled: false,
      });

    case AuthActions.forgotPasswordRequest().type:
      return state.merge({
        forgotPasswordLoading: true,
        forgotPasswordError: null,
        forgotPasswordCalled: false,
      });
    case AuthActions.forgotPasswordSuccess().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: null,
        forgotPasswordCalled: true,
      });
    case AuthActions.forgotPasswordFailed().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: action.payload.error,
        forgotPasswordCalled: true,
      });
    case AuthActions.resetForgotPassword().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: null,
        forgotPasswordCalled: false,
      });
    case AuthActions.emailVerificationRequest().type:
      return state.merge({
        emailVerificationLoading: true,
        emailVerificationError: null,
        emailVerificationCalled: true,
      });
    case AuthActions.emailVerificationSuccess().type:
      return state.merge({
        emailVerificationLoading: false,
        emailVerificationError: null,
        emailVerificationCalled: true,
      });
    case AuthActions.emailVerificationFailed().type:
      return state.merge({
        emailVerificationLoading: false,
        emailVerificationError: action.payload.error,
        emailVerificationCalled: true,
      });

    case AuthActions.resetEmailVerification().type:
      return state.merge({
        emailVerificationLoading: false,
        emailVerificationError: null,
        emailVerificationCalled: false,
      });
    case AuthActions.resetPasswordRequest().type:
      return state.merge({
        resetPasswordLoading: true,
        resetPasswordError: null,
        resetPasswordCalled: true,
      });
    case AuthActions.resetPasswordSuccess().type:
      return state.merge({
        resetPasswordLoading: false,
        resetPasswordError: null,
        resetPasswordCalled: true,
      });
    case AuthActions.resetPasswordFailed().type:
      return state.merge({
        resetPasswordLoading: false,
        resetPasswordError: action.payload.error,
        resetPasswordCalled: true,
      });

    case AuthActions.clearResetPassword().type:
      return state.merge({
        resetPasswordLoading: false,
        resetPasswordError: null,
        resetPasswordCalled: false,
      });
    case AuthActions.changePasswordRequest().type:
      return state.merge({
        changePasswordLoading: true,
        changePasswordError: null,
        changedPassword: false,
      });

    case AuthActions.changePasswordSuccess().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: null,
        changedPassword: true,
        user: { ...state.user, withTemporaryPassword: false },
      });

    case AuthActions.changePasswordFailed().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: action.payload.error,
        changedPassword: false,
      });
    case AuthActions.clearChangePassword().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: null,
        changedPassword: false,
      });

    case AuthActions.uploadProfilePictureRequest().type:
      return state.merge({
        uploadProfilePictureLoading: true,
        uploadProfilePictureError: null,
        uploadedProfilePicture: false,
      });

    case AuthActions.uploadProfilePictureSuccess().type:
      return state.merge({
        uploadProfilePictureLoading: false,
        uploadProfilePictureError: null,
        uploadedProfilePicture: true,
      });

    case AuthActions.uploadProfilePictureFailed().type:
      return state.merge({
        uploadProfilePictureLoading: false,
        uploadProfilePictureError: action.payload.error,
        uploadedProfilePicture: false,
      });

    case AuthActions.storePersonalInformation().type:
      return state.merge({
        user: { ...action.payload },
      });

    case AuthActions.loggedIn().type:
      return state.merge({
        loggedIn: true,
        checkingUser: false,
      });
    case AuthActions.loggedOut().type:
      return state.merge({
        loggedIn: false,
        checkingUser: false,
        user: null,
        tokenValid: false,
      });
    case AuthActions.updateUser().type:
      return state.merge({
        user: action.payload.user,
        emailVerified: action.payload.emailVerified,
      });
    case AuthActions.validateTokenRequest().type:
      return state.merge({
        validateTokenLoading: true,
        validateTokenError: null,
        validateTokenCalled: true,
      });
    case AuthActions.validateTokenSuccess().type:
      return state.merge({
        validateTokenLoading: false,
        validateTokenError: null,
        validateTokenCalled: true,
        tokenValid: action.payload.loginToken,
        paymentTokenValid: action.payload.paymentToken,
      });
    case AuthActions.validateTokenFailed().type:
      return state.merge({
        validateTokenLoading: false,
        validateTokenError: action.payload.error,
        validateTokenCalled: true,
        tokenValid: false,
        paymentTokenValid: false,
      });

    case AuthActions.validatePaymentTokenFailed().type:
      return state.merge({
        validateTokenLoading: false,
        validateTokenError: action.payload.error,
        validateTokenCalled: true,
        paymentTokenValid: false,
      });

    case AuthActions.resetValidateToken().type:
      return state.merge({
        validateTokenLoading: false,
        validateTokenError: null,
        validateTokenCalled: false,
      });

    case AuthActions.updatePaymentToken().type:
      return state.merge({
        validateTokenLoading: false,
        validateTokenError: null,
        validateTokenCalled: true,

        paymentTokenValid: action.payload.paymentToken,
      });

    case AuthActions.resetPaymentToken().type:
      return state.merge({
        validateTokenLoading: false,
        validateTokenError: null,
        validateTokenCalled: false,

        paymentTokenValid: false,
      });

    case AuthActions.getUsersRequest().type:
      return state.merge({
        getUsersLoading: true,
        getUsersError: null,
        getUsersCalled: false,
      });

    case AuthActions.getUsersSuccess().type:
      return state.merge({
        getUsersLoading: false,
        getUsersError: null,
        getUsersCalled: true,

        users: action.payload.users,
      });

    case AuthActions.getUsersFailed().type:
      return state.merge({
        getUsersLoading: false,
        getUsersError: action.payload.error,
        getUsersCalled: true,
      });

    case AuthActions.resetGetUsers().type:
      return state.merge({
        getUsersLoading: false,
        getUsersError: null,
        getUsersCalled: false,
      });

    case AuthActions.paymentRequired().type:
      return state.merge({
        paymentRequired: action.payload.paymentRequired,
      });
    case AuthActions.forbiddenMessage().type:
      return state.merge({
        showForbiddenMessage: action.payload.forbidden,
      });
    default:
      return state;
  }
};
