import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import NavigationBar from '../../../components/NavigationBar';
import MessageModal from '../../../modals/message';
import Loading from '../../../components/loading';

import ContractsActions from '../../../redux/contracts/actions';
import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';

const ContractSpecs = ({ t, history, match }) => {
  const dispatch = useDispatch();
  const [, setDrawerOpened] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const {
    contractSelection,
    deleteUserContractsLoading,
    deleteUserContractsError,
    deleteUserContractsCalled,
    getUserContractLoading,
    getUserContractError,
    getUserContractCalled,
    acceptInviteLoading,
    acceptInviteError,
    acceptInviteCalled,
  } = useSelector((state) => state.contracts);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function titleComponent() {
    return (
      <Box className={styles.container_title}>
        <Box className={styles.contract_reference}>
          {contractSelection?.contractReference || ''}
        </Box>
        <Box className={styles.contract_name}>{contractSelection?.name || ''}</Box>
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={deleteUserContractsLoading || getUserContractLoading || acceptInviteLoading}
      />
      <NavigationBar
        titleComponent={titleComponent()}
        hasMenuButton={!isMobile}
        barColor={isMobile ? '#fff' : '#111'}
        white={!isMobile}
        hasBackButton
        history={history}
        customBack={() => history.replace('/')}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        moreOptions={[t('contracts.delete')]}
        onMoreOption={(item) => {
          if (item === t('contracts.delete')) {
            if (contractSelection) {
              dispatch(ContractsActions.deleteUserContracts(contractSelection?.id));
            }
          }
        }}
        pageContent={<ContractSpecsCard />}
      />
      {getUserContractCalled && !getUserContractLoading && getUserContractError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getUserContractError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetGetUserContractError());
          }}
        />
      )}
      {acceptInviteCalled && !acceptInviteLoading && acceptInviteError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={acceptInviteError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetAcceptInviteError());
          }}
        />
      )}
      {deleteUserContractsCalled && !deleteUserContractsLoading && deleteUserContractsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={deleteUserContractsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetDeleteUserContractsError());
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(ContractSpecs);
