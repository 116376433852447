import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import MatButton from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';

import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import NavigationBar from '../../../../components/NavigationBar';
import OffTakeScheduleItem from '../../../../components/OffTakeScheduleItem';
import WorkflowEmpty from '../../../../components/WorkflowEmpty';
import MessageModal from '../../../../modals/message';
import ModalView from '../../../../modals/modalView';
import Loading from '../../../../components/loading';
import Popup from '../../../../components/Popup';
import TaskList from './taskList';

import MoreIcon from '../../../../assets/images/icons/more.svg';
import GeneralIcon from '../../../../assets/images/icons/general.svg';
import DeliveryIcon from '../../../../assets/images/icons/delivery.svg';
import NominationIcon from '../../../../assets/images/icons/nomination.svg';
import IconAlert from '../../../../assets/images/icons/alert-white.svg';
import WorkflowActions from '../../../../redux/workflow/actions';
import AuthActions from '../../../../redux/auth/actions';

import styles from './styles.module.sass';
import ContractBox from '../../../../components/ContractBox';
import OffTakeSchedules from '../../../../components/OffTakeSchedules';
import CreateGeneralTask from '../createGeneralTask';
import CreateNominationTask from '../createNominationTask';
import CreateDeliveryTask from '../createDeliveryTask';
import InviteCollaboratorsTask from '../inviteCollaboratorsTask';
import DeniedComment from '../../deniedComment';
import SeeComment from '../../seeComment';
import ContractsActions from '../../../../redux/contracts/actions';
import OffTakeScheduleActions from '../../../../redux/offTakeSchedule/actions';

const blackTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: 'black',
      dark: 'black',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#111',
      dark: '#1111',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  speedDial: {
    root: {
      backgroundColor: 'black',
      transform: 'translateZ(0px)',
    },
    marginRight: 27,
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const OffTakeScheduleDetail = ({ t, history }) => {
  const actions = [
    {
      icon: (
        <SvgIcon style={{ width: 15, height: 15 }} viewBox="0 0 17 17" component={DeliveryIcon} />
      ),
      name: t('contracts.details.workflow.delivery'),
    },
    {
      icon: (
        <SvgIcon style={{ width: 15, height: 15 }} viewBox="0 0 14 16" component={GeneralIcon} />
      ),
      name: t('contracts.details.workflow.general'),
    },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [activeTask, setActiveTask] = useState(null);
  const [parentTask, setParentTask] = useState(null);
  const [openDeleteContract, setOpenDeleteContract] = useState(null);
  const [editingTask, setEditingTask] = useState(false);
  const [addCollaborators, setAddCollaborators] = useState(null);
  const [addDisagreeComments, setAddDisagreeComments] = useState(null);
  const [seeRejectedComments, setSeeRejectedComments] = useState(null);
  const [oldTaskSelected, setOldTaskSelected] = useState(null);
  const [loadingItems, setLoadingItems] = useState({});
  const [openNotify, setOpenNotify] = useState(false);

  const [drawerOpened, setDrawerOpened] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const {
    offTakeScheduleSelection,
    getTasksLoading,
    getTasksError,
    getTasksCalled,
    deleteTaskSuccess,
    saveTaskSuccess,
    updateTaskSuccess,
    taskSelection,
    tasks,

    deleteTaskLoading,
    deleteTaskError,
    deleteTaskCalled,

    saveTaskLoading,
    saveTaskCalled,
    saveTaskError,

    updateTaskStateLoading,
    updateTaskStateError,
    updateTaskStateCalled,
    updateTaskStateSuccess,

    uploadDocumentLoading,
    uploadDocumentError,
    uploadDocumentCalled,
    uploadDocumentSuccess,

    updateDocumentStateLoading,
    updateDocumentStateError,
    updateDocumentStateCalled,
    updateDocumentStateSuccess,

    deleteDocumentLoading,
    deleteDocumentError,
    deleteDocumentCalled,
    deleteDocumentSuccess,
  } = useSelector((state) => state.workflow);
  const { offTakeSchedules, getOffTakeSchedulesLoading, getOffTakeSchedulesCalled } = useSelector(
    (state) => state.offTakeSchedule
  );
  const {
    contractSelection,
    getContractMeLoading,
    getContractMeError,
    getContractMeCalled,
    contractMe,
  } = useSelector((state) => state.contracts);
  const { invites } = useSelector((state) => state.invites);
  const allLoadings =
    getTasksLoading ||
    saveTaskLoading ||
    updateTaskStateLoading ||
    updateDocumentStateLoading ||
    uploadDocumentLoading ||
    deleteDocumentLoading ||
    deleteTaskLoading;

  useEffect(() => {
    if (!getContractMeCalled && contractSelection) {
      dispatch(ContractsActions.getContractMe(contractSelection?.id));
    }
  }, [getContractMeLoading, getContractMeError, getContractMeCalled]);

  useEffect(() => {
    if (deleteTaskSuccess || (!getTasksCalled && !getTasksLoading && offTakeScheduleSelection)) {
      dispatch(WorkflowActions.resetDeleteTask());
      dispatch(WorkflowActions.getTasks(offTakeScheduleSelection?.id));
      setOpenDetail(false);
      setActiveTask(null);
    }
    return () => {};
  }, [offTakeScheduleSelection, getTasksLoading, getTasksCalled, deleteTaskSuccess]);

  // use first offTakeSchedules option as default page on desktop
  useEffect(() => {
    if (!offTakeScheduleSelection) {
      const offTakeScheduleDefault = offTakeSchedules?.length ? offTakeSchedules[0] : null;
      dispatch(WorkflowActions.updateOffTakeScheduleSelection(offTakeScheduleDefault));
    }
    return () => {};
  }, [offTakeSchedules, offTakeScheduleSelection]);

  useEffect(() => {
    if (uploadDocumentCalled && !uploadDocumentLoading && uploadDocumentSuccess) {
      dispatch(WorkflowActions.resetGetTasks());
      dispatch(WorkflowActions.resetSaveTask());
      dispatch(WorkflowActions.resetDeleteTask());
    }
  }, [uploadDocumentCalled, uploadDocumentLoading, uploadDocumentSuccess]);

  useEffect(() => {
    if (updateTaskStateCalled && !updateTaskStateLoading && updateTaskStateSuccess) {
      setAddDisagreeComments(null);
      dispatch(WorkflowActions.resetGetTasks());
      dispatch(WorkflowActions.resetSaveTask());
      dispatch(WorkflowActions.resetDeleteTask());
    }
  }, [updateTaskStateCalled, updateTaskStateLoading, updateTaskStateSuccess]);

  useEffect(() => {
    if (updateDocumentStateCalled && !updateDocumentStateLoading && updateDocumentStateSuccess) {
      setAddDisagreeComments(null);
      dispatch(WorkflowActions.resetGetTasks());
      dispatch(WorkflowActions.resetSaveTask());
      dispatch(WorkflowActions.resetDeleteTask());
    }
  }, [updateDocumentStateCalled, updateDocumentStateLoading, updateDocumentStateSuccess]);

  useEffect(() => {
    if (deleteDocumentCalled && !deleteDocumentLoading && deleteDocumentSuccess) {
      setAddDisagreeComments(null);
      dispatch(WorkflowActions.resetGetTasks());
      dispatch(WorkflowActions.resetSaveTask());
      dispatch(WorkflowActions.resetDeleteTask());
    }
  }, [deleteDocumentCalled, deleteDocumentLoading, deleteDocumentSuccess]);

  useEffect(() => {
    if (!isMobile && (deleteTaskSuccess || (saveTaskSuccess && !updateTaskSuccess))) {
      dispatch(WorkflowActions.resetGetTasks());
      dispatch(WorkflowActions.resetSaveTask());
      dispatch(WorkflowActions.resetDeleteTask());
      setOpenDetail(false);
    }
    if (!isMobile && updateTaskSuccess && taskSelection && activeTask) {
      dispatch(WorkflowActions.resetGetTasks());
      dispatch(WorkflowActions.resetSaveTask());
      dispatch(WorkflowActions.resetGetTask());
      dispatch(WorkflowActions.updateTaskSelection({ id: taskSelection?.id }));
    }
    return () => {};
  }, [deleteTaskSuccess, saveTaskSuccess, updateTaskSuccess]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(ContractsActions.resetGetContractMe());
    };
  }, []);

  useEffect(() => {
    if (activeTask !== null) {
      const filterTasks =
        tasks && tasks.filter ? tasks.filter((task) => task?.id === activeTask?.id) : [];
      if (filterTasks.length > 0) {
        if (activeTask?.id === oldTaskSelected) {
          setOldTaskSelected(null);
        } else {
          setOldTaskSelected(activeTask?.id);
        }
      }
    }
    return () => {};
  }, [activeTask]);

  useEffect(() => {
    setOldTaskSelected(null);
    localStorage.removeItem('scrollRef');
    return () => {};
  }, [offTakeScheduleSelection?.id]);

  useEffect(() => {
    if (
      allLoadings &&
      ((activeTask === null && oldTaskSelected !== null) || activeTask?.id === oldTaskSelected)
    ) {
      setLoadingItems((ov) => ({ ...ov, [oldTaskSelected]: true }));
    } else if (!allLoadings && oldTaskSelected !== null) {
      setLoadingItems({});
    }
    return () => {};
  }, [allLoadings]);

  useEffect(() => {
    if (!getOffTakeSchedulesCalled && contractSelection?.id) {
      dispatch(OffTakeScheduleActions.getOffTakeSchedules(contractSelection?.id));
    }
    return () => {};
  }, [contractSelection, getOffTakeSchedulesCalled]);

  useEffect(() => {
    setOpenNotify(false);
    if (
      (!offTakeSchedules || offTakeSchedules.length < 1) &&
      !allLoadings &&
      getOffTakeSchedulesCalled
    ) {
      setOpenNotify(true);
    }
    return () => {};
  }, [offTakeSchedules, allLoadings]);

  const GetFooterIcon = () => {
    const iconStyle = { width: 15, height: 15, marginRight: 10 };
    switch (openDetail) {
      case 'general':
        return <SvgIcon style={iconStyle} viewBox="0 0 14 16" component={GeneralIcon} />;
      case 'nomination':
        return <SvgIcon style={iconStyle} viewBox="0 0 17 17" component={NominationIcon} />;
      case 'delivery':
        return <SvgIcon style={iconStyle} viewBox="0 0 17 17" component={DeliveryIcon} />;
      default:
        return <SvgIcon style={iconStyle} viewBox="0 0 14 16" component={GeneralIcon} />;
    }
  };
  const TasksList = ({ schedule }) => (
    <TaskList
      schedule={schedule}
      showLoading={
        getTasksLoading &&
        oldTaskSelected === null &&
        !(
          deleteTaskLoading ||
          (!isMobile && getOffTakeSchedulesLoading) ||
          saveTaskLoading ||
          updateTaskStateLoading ||
          updateDocumentStateLoading ||
          uploadDocumentLoading
        )
      }
      hideDescription={
        open ||
        openDetail ||
        (contractSelection?.buyer && contractMe?.side !== 'buy-side') ||
        !contractSelection?.schedules?.length > 0
      }
      openDetail={openDetail}
      tasks={tasks}
      loadingItems={loadingItems}
      oldTaskSelected={oldTaskSelected}
      contractSelection={contractSelection}
      contractMe={contractMe}
      onClick={(taskValue) => {
        if (openDetail === 'nomination' && oldTaskSelected === taskValue.id) {
          setOpenDetail(false);
          setActiveTask({ id: oldTaskSelected });
        } else {
          dispatch(WorkflowActions.updateTaskSelection({ id: taskValue.id }));
          dispatch(WorkflowActions.resetGetTask());
          setOpenDetail('nomination');
          setActiveTask({ type: 'nomination', id: taskValue.id });
        }
      }}
      onDelete={(taskId, taskType) => {
        setOpenDeleteContract({
          id: taskId || activeTask.id,
          type: taskType || activeTask.type,
        });
      }}
      onEdit={(taskId, taskType) => {
        setEditingTask(true);
        dispatch(WorkflowActions.updateTaskSelection({ id: taskId }));
        dispatch(WorkflowActions.resetGetSubtask());
        dispatch(WorkflowActions.resetGetTask());
        dispatch(WorkflowActions.updateParentTaskSelection(getParentTask(null, taskId)));
        setOpenDetail(taskType);
        setActiveTask({ type: taskType, id: taskId });
      }}
      onCreateTask={(taskValue, type) => {
        dispatch(WorkflowActions.updateTaskSelection(null));
        dispatch(WorkflowActions.updateSubtaskSelection(null));
        dispatch(WorkflowActions.resetGetSubtask());
        dispatch(WorkflowActions.updateParentTaskSelection(getParentTask(taskValue.id, null)));
        setActiveTask(null);
        setParentTask(taskValue.id);
        setOpenDetail({ type, task: taskValue });
      }}
      onAddCollaborator={(taskId) => {
        dispatch(WorkflowActions.resetGetSubtask());
        dispatch(WorkflowActions.resetSaveTask());
        setAddCollaborators({ id: taskId });
      }}
      onEditDataPoint={(taskToUpdate) => {
        dispatch(
          WorkflowActions.updateTask(
            taskToUpdate.id,
            taskToUpdate,
            null,
            offTakeScheduleSelection?.id
          )
        );
      }}
      onChangeState={(taskId, state, taskType) => {
        if (state === 'disagree') {
          setAddDisagreeComments({ type: taskType, id: taskId });
        } else if (state === 'agree') {
          dispatch(
            WorkflowActions.updateTaskState(
              taskId,
              { status: 'approved' },
              offTakeScheduleSelection?.id
            )
          );
        } else {
          dispatch(
            WorkflowActions.updateTask(
              taskId,
              { status: state },
              null,
              offTakeScheduleSelection?.id
            )
          );
        }
      }}
      onChangeDocumentState={(documentId, action) => {
        if (action === 'disagree') {
          setAddDisagreeComments({ type: 'document', id: documentId });
        } else if (action === 'agree') {
          dispatch(WorkflowActions.updateDocumentState(documentId, { status: 'approved' }));
        } else if (action === 'delete') {
          dispatch(WorkflowActions.deleteDocument(documentId));
        }
      }}
      onSeeRejectedComments={(rejectedComments, commentType) => {
        setSeeRejectedComments({ comment: rejectedComments, type: commentType });
      }}
      onUpload={(id, file) => {
        dispatch(WorkflowActions.uploadDocument(id, file));
      }}
    />
  );

  const TaskButtons = () => {
    if (
      (!contractSelection?.buyer ||
        (contractSelection?.buyer && contractMe?.side === 'buy-side')) &&
      contractSelection?.schedules?.length > 0
    ) {
      return (
        <Container
          className={styles.container_add_button}
          style={isMobile || isTablet ? { right: 27, bottom: 150 } : {}}
        >
          <Button
            className={styles.add_button}
            onPress={() => {
              dispatch(WorkflowActions.updateTaskSelection(null));
              if (isMobile || isTablet) {
                history.push('/contracts/workflows/nomination-task');
              } else {
                dispatch(WorkflowActions.updateTaskSelection(null));
                dispatch(WorkflowActions.updateSubtaskSelection(null));
                dispatch(WorkflowActions.resetGetSubtask());
                setActiveTask(null);
                setOpenDetail('nomination');
              }
            }}
          >
            <SpeedDialIcon />
          </Button>
        </Container>
      );
    }
    return null;
  };

  const getParentTask = (id, subTaskId) => {
    const found = tasks.find(
      (item) => item.id === id || item.subTasks.find((subItem) => subItem.id === subTaskId)
    );
    return found;
  };

  const TaskButtonsNomination = () => (
    <Container
      className={styles.container_add_button}
      style={isMobile || isTablet ? { right: 27, bottom: 150 } : {}}
    >
      {open && <Box className={styles.add_button} />}
      <MuiThemeProvider theme={blackTheme}>
        <SpeedDial
          style={{ flexDirection: 'row-reverse' }}
          color="primary"
          ariaLabel=""
          icon={<SpeedDialIcon />}
          onClose={() => setOpen(false)}
          onClick={() => setOpen(!open)}
          open={open}
          direction="up"
        >
          {actions.map((action, index) => (
            <SpeedDialAction
              key={index}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => {
                dispatch(WorkflowActions.updateTaskSelection(null));
                dispatch(WorkflowActions.updateSubtaskSelection(null));
                dispatch(WorkflowActions.resetGetSubtask());
                dispatch(WorkflowActions.updateParentTaskSelection(getParentTask(activeTask?.id)));
                setActiveTask(null);
                setParentTask(activeTask?.id);
                if (action.name === t('contracts.details.workflow.general')) {
                  // if (isMobile) {
                  // history.push('/contracts/workflows/general-task');
                  // } else {
                  setOpenDetail('general');
                  // }
                } else if (action.name === t('contracts.details.workflow.delivery')) {
                  // if (isMobile) {
                  // history.push('/contracts/workflows/delivery-task');
                  // } else {
                  setOpenDetail('delivery');
                  // }
                }
                setOpen(false);
              }}
            />
          ))}
        </SpeedDial>
      </MuiThemeProvider>
    </Container>
  );

  const DetailComponent = () => {
    const type = openDetail?.type || openDetail;
    if (isMobile || isTablet) {
      dispatch(
        WorkflowActions.updateSubtaskSelection({ taskParentId: parentTask, id: activeTask?.id })
      );
      switch (type) {
        case 'general':
          history.push('/contracts/workflows/general-task');
          break;
        case 'nomination':
          history.push('/contracts/workflows/nomination-task');
          break;
        case 'delivery':
          history.push('/contracts/workflows/delivery-task');
          break;
        default:
          history.push('/contracts/workflows/general-task');
          break;
      }
      return null;
    }

    switch (type) {
      case 'general':
        return (
          <ModalView
            onClose={() => {
              setOpenDetail(null);
              setEditingTask(false);
            }}
            view={
              <CreateGeneralTask
                parentTask={parentTask}
                history={history}
                desktop
                task={openDetail?.task}
                taskId={activeTask?.id}
                onClose={() => {
                  setEditingTask(false);
                  setOpenDetail(null);
                }}
              />
            }
          />
        );
      case 'nomination':
        return (
          <ModalView
            onClose={() => {
              setOpenDetail(null);
              setEditingTask(false);
              dispatch(WorkflowActions.resetTaskFormData());
            }}
            view={
              <CreateNominationTask
                history={history}
                desktop
                taskId={activeTask?.id}
                onClose={() => {
                  setOpenDetail(null);
                  setEditingTask(false);
                  dispatch(WorkflowActions.resetTaskFormData());
                }}
              />
            }
          />
        );
      case 'delivery':
        return (
          <ModalView
            onClose={() => {
              setOpenDetail(null);
              setEditingTask(false);
            }}
            view={
              <CreateDeliveryTask
                parentTask={parentTask}
                history={history}
                desktop
                task={openDetail?.task}
                taskId={activeTask?.id}
                onClose={() => {
                  setEditingTask(false);
                  setOpenDetail(null);
                }}
              />
            }
          />
        );
      default:
        return (
          <ModalView
            onClose={() => {
              setOpenDetail(null);
              setEditingTask(false);
            }}
            view={
              <CreateGeneralTask
                parentTask={parentTask}
                history={history}
                taskId={activeTask?.id}
                desktop
                onClose={() => {
                  setEditingTask(false);
                  setOpenDetail(null);
                }}
              />
            }
          />
        );
    }
  };

  const renderMsgError = () => {
    let message = '';
    if (getTasksCalled && !getTasksLoading && getTasksError) {
      message = getTasksError.userMessage;
    } else if (updateTaskStateCalled && !updateTaskStateLoading && updateTaskStateError) {
      message = updateTaskStateError.userMessage;
    } else if (
      updateDocumentStateCalled &&
      !updateDocumentStateLoading &&
      updateDocumentStateError
    ) {
      message = updateDocumentStateError.userMessage;
    } else if (saveTaskCalled && !saveTaskLoading && saveTaskError) {
      message = saveTaskError.userMessage;
    }

    return message ? (
      <MessageModal
        icon={null}
        title={t('common.error')}
        message={message}
        primaryAction={t('common.close')}
        primaryEvent={() => {
          if (openDetail === 'nomination') {
            dispatch(WorkflowActions.resetSaveTaskError());
            return;
          }
          dispatch(WorkflowActions.resetUpdateTaskStateError());
          dispatch(WorkflowActions.resetUpdateDocumentStateError());
          dispatch(WorkflowActions.resetGetTasksError());
          dispatch(WorkflowActions.resetUpdateTaskError());
        }}
      />
    ) : null;
  };

  const DesktopLayout = () => (
    <Grid
      container
      spacing={3}
      className={styles.grid_container}
      style={{ marginTop: 40, height: 'calc(100% - 40px)' }}
      justify="left"
    >
      <Grid item xs={12} sm={3} style={{ maxWidth: 400 }}>
        <Box className={styles.contract_details_card} p={3} mb={3}>
          <Box style={{ overflow: 'hidden' }}>
            <Box className={styles.title}>
              {t('contracts.portfolio').capitalize()} {'//'} {contractSelection?.name}
            </Box>
            <Box className={styles.reference}>
              {t('contracts.details.workflow.title').toUpperCase()}
            </Box>
          </Box>
        </Box>
        <ContractBox small contract={contractSelection} />
        <OffTakeSchedules
          option={offTakeScheduleSelection?.id}
          history={history}
          contract={contractSelection}
        />
      </Grid>
      <Grid item xs={12} sm={8} container justify="center" style={{ height: '100%' }}>
        <Container maxWidth="md" className={styles.content}>
          <Box className="title_container">
            {t('contracts.details.workflow.title').toUpperCase()}
          </Box>
          <Box className="description_container">{t('contracts.details.workflow.description')}</Box>
          <TasksList schedule={offTakeScheduleSelection} />
        </Container>
      </Grid>
      {openDetail && (!activeTask?.id || editingTask) ? (
        <DetailComponent />
      ) : !openDetail ? (
        <TaskButtons />
      ) : (
        <> </>
      )}
      {addCollaborators && (
        <ModalView
          onClose={() => setAddCollaborators(null)}
          view={
            <InviteCollaboratorsTask
              desktop
              type={openDetail}
              taskId={addCollaborators.id}
              onFinish={() => {
                setAddCollaborators(null);
              }}
            />
          }
        />
      )}
      {addDisagreeComments && (
        <ModalView
          view={
            <DeniedComment
              isModal
              formType={addDisagreeComments.type}
              onClose={() => setAddDisagreeComments(null)}
              onSave={(comment) => {
                if (addDisagreeComments.type === 'document') {
                  dispatch(
                    WorkflowActions.updateDocumentState(addDisagreeComments.id, {
                      status: 'rejected',
                      rejectedComments: comment,
                    })
                  );
                } else {
                  dispatch(
                    WorkflowActions.updateTaskState(addDisagreeComments.id, {
                      status: 'rejected',
                      rejectedComments: comment,
                    })
                  );
                }
              }}
            />
          }
          onClose={() => setAddDisagreeComments(null)}
        />
      )}

      {seeRejectedComments && (
        <ModalView
          view={
            <SeeComment
              isModal
              formType={seeRejectedComments.type}
              comments={seeRejectedComments.comment}
              onClose={() => setSeeRejectedComments(null)}
            />
          }
          onClose={() => setSeeRejectedComments(null)}
        />
      )}
    </Grid>
  );

  const MobileLayout = () => {
    const schedulesMapped = offTakeSchedules?.map((item) => {
      return {
        ...item,
        label: `${moment(item.startDate).format('DD MMM')} - ${moment(item.endDate).format(
          'DD MMM'
        )} / ${item.volume}`,
      };
    });
    return (
      <Box className={clsx(styles.main_content)} style={{ paddingBottom: 200 }}>
        <Grid container className={isTablet ? styles.mobile_box_contract : null}>
          <Grid
            item
            xs={12}
            sm={8}
            className={isMobile && !isTablet ? styles.mobile_box_contract : null}
          >
            <Box className={styles.label} display="inline">
              {contractSelection?.name}
              <Box style={{ fontWeight: 300, fontFamily: 'FuturaPT-Light' }} display="inline">
                {` · ${t('contracts.box.sugarSpec')}: `}
              </Box>
              {contractSelection?.sugarSpec}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box style={isMobile && !isTablet ? { padding: '10px 30px 0 20px' } : {}}>
              <Select
                displayKey="label"
                valueKey="id"
                values={schedulesMapped}
                value={offTakeScheduleSelection?.id}
                onChange={async (value) => {
                  const found = offTakeSchedules.find((item) => item.id === value);
                  await dispatch(WorkflowActions.updateOffTakeScheduleSelection(found));
                  dispatch(WorkflowActions.resetGetTasks());
                  dispatch(WorkflowActions.resetGetTasksAll());
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Container maxWidth="md" className={styles.content}>
          <TasksList schedule={offTakeScheduleSelection} />
        </Container>
        {openDetail && (!activeTask?.id || editingTask) ? (
          <DetailComponent />
        ) : !openDetail ? (
          <TaskButtons />
        ) : (
          <TaskButtonsNomination />
        )}
        {addCollaborators && (
          <ModalView
            onClose={() => setAddCollaborators(null)}
            view={
              <InviteCollaboratorsTask
                desktop
                type={openDetail}
                taskId={addCollaborators.id}
                onFinish={() => {
                  setAddCollaborators(null);
                }}
              />
            }
          />
        )}
        {addDisagreeComments && (
          <ModalView
            view={
              <DeniedComment
                isModal
                formType={addDisagreeComments.type}
                onClose={() => setAddDisagreeComments(null)}
                onSave={(comment) => {
                  if (addDisagreeComments.type === 'document') {
                    dispatch(
                      WorkflowActions.updateDocumentState(addDisagreeComments.id, {
                        status: 'rejected',
                        rejectedComments: comment,
                      })
                    );
                  } else {
                    dispatch(
                      WorkflowActions.updateTaskState(addDisagreeComments.id, {
                        status: 'rejected',
                        rejectedComments: comment,
                      })
                    );
                  }
                }}
              />
            }
            onClose={() => setAddDisagreeComments(null)}
          />
        )}

        {seeRejectedComments && (
          <ModalView
            view={
              <SeeComment
                isModal
                formType={seeRejectedComments.type}
                comments={seeRejectedComments.comment}
                onClose={() => setSeeRejectedComments(null)}
              />
            }
            onClose={() => setSeeRejectedComments(null)}
          />
        )}
      </Box>
    );
  };

  return (
    <Box className={styles.container}>
      {/* <Loading
        isLoading={getTasksLoading && !saveTaskLoading}
      /> */}
      <NavigationBar
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        history={history}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={isTablet ? <MobileLayout /> : isMobile ? <MobileLayout /> : <DesktopLayout />}
      />

      {renderMsgError()}

      {deleteDocumentSuccess && (
        <MessageModal
          center
          title={t('documents.modalDeleteTitle')}
          messages={[t('documents.modalDeleteMessage')]}
          primaryAction={t('common.ok')}
          primaryEvent={() => {
            dispatch(WorkflowActions.resetDeleteDocument());
          }}
        />
      )}

      {uploadDocumentError && (
        <MessageModal
          center
          icon={null}
          primaryAction={t('common.ok')}
          title={t(uploadDocumentError.title)}
          messages={[t(uploadDocumentError.message)]}
          primaryEvent={async () => {
            await dispatch(WorkflowActions.resetUploadDocument());
          }}
        />
      )}

      {openDeleteContract && (
        <MessageModal
          center
          title={t('common.deleteTaskTitle')}
          icon={null}
          messages={[t('common.deleteTaskDes1'), t('common.deleteTaskDes2')]}
          secundaryAction={isMobile ? t('common.no') : t('common.noChangeMyMind')}
          secundaryEvent={() => {
            setOpenDeleteContract(null);
          }}
          primaryAction={isMobile ? t('common.yes') : t('common.yesDelete')}
          primaryEvent={async () => {
            setOpenDeleteContract(null);
            await dispatch(
              WorkflowActions.deleteTask(openDeleteContract?.id, openDeleteContract?.type)
            );
          }}
        />
      )}

      {openNotify && (
        <Popup
          title={t('common.havenDefinedDeilvery')}
          description={t('common.needDeliveryCadence')}
          txtConfirm={t('common.setDeliverySchedule')}
          onConfirm={async () => {
            setOpenNotify(false);
            history.push('/contracts/off-take-schedule');
          }}
          txtCancel={t('common.close')}
          onCancel={() => {
            setOpenNotify(false);
            history.push('/contracts/details');
          }}
          onClose={() => {
            setOpenNotify(false);
            history.push('/contracts/details');
          }}
          icon={() => (
            <SvgIcon className={styles.iconAlert} component={IconAlert} viewBox="0 0 88 88" />
          )}
        />
      )}
    </Box>
  );
};

export default withTranslation()(OffTakeScheduleDetail);
