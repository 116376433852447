import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';

import Divider from '@material-ui/core/Divider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import EmailSentIcon from '../../../assets/images/icons/email-sent.svg';

import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const ForgotPassword = ({ t, history }) => {
  const validationSchema = Yup.object({
    email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const { forgotPasswordLoading, forgotPasswordError, forgotPasswordCalled } = useSelector(
    (state) => state.auth
  );

  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
  }, [formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function handleSubmit(values) {
    dispatch(AuthActions.forgotPassword(values.email));
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <NavigationBar
        title={t('forgotPassword.title')}
        hasBackButton
        hasMenuButton={!isMobile}
        hideFirstBar={!isMobile}
        customBack={() => {
          dispatch(AuthActions.resetForgotPassword());
          history.replace('/login');
        }}
        history={history}
      />
      {!forgotPasswordCalled && (
        <Container maxWidth="md" className={styles.content} ref={formBox}>
          <Box className={styles.container_step_description}>
            <Box className="title">{t('forgotPassword.subtitle')}</Box>
            <Box className="description" style={{ marginTop: 10 }}>
              {t('forgotPassword.description')}
            </Box>
          </Box>
          <Box className={styles.container_inputs}>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box className={styles.container_inputs}>
                    <Box className={styles.container_input}>
                      <TextField
                        value={values.email}
                        onChange={handleChange}
                        className={styles.input}
                        helperText={errors.email && touched.email && errors.email}
                        name="email"
                        type="email"
                        placeholder={
                          t('common.email').charAt(0).toUpperCase() + t('email').slice(1)
                        }
                        label={t('common.email').toUpperCase()}
                      />
                    </Box>
                  </Box>
                  {forgotPasswordError && (
                    <Box className="error" style={{ marginTop: 15 }}>
                      {forgotPasswordError?.userMessage}
                    </Box>
                  )}

                  <Box className={styles.container_submit}>
                    <Button
                      className={styles.button}
                      type="submit"
                      loading={forgotPasswordLoading}
                      disabled={forgotPasswordLoading}
                      data-info-submit="Submit"
                      minWidth={130}
                      maxWidth={270}
                    >
                      {t('common.send')}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      )}
      {forgotPasswordCalled && !forgotPasswordError && (
        <Container maxWidth="md" className={styles.content_sent} ref={formBox}>
          <Box className={styles.icon}>
            <SvgIcon component={EmailSentIcon} viewBox="0 0 72 72" />
          </Box>
          <Box className={styles.container_step_description}>
            <Box className="title" style={{ marginTop: 20 }}>
              {t('forgotPassword.sent.title')}
            </Box>
            <Box className="description" style={{ marginTop: 20 }}>
              {t('forgotPassword.sent.description1')}
            </Box>
          </Box>
          <Divider
            variant="middle"
            style={{ marginTop: 20, marginLeft: '20%', marginRight: '20%' }}
          />
          <Box className={styles.container_step_description}>
            <Box className="description" style={{ marginTop: 20 }}>
              {t('forgotPassword.sent.description2')}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default withTranslation()(ForgotPassword);
