import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import UserPhoto from '../../../components/UserPhoto';
import MessageModal from '../../../modals/message';
import ModalView from '../../../modals/modalView';
import Loading from '../../../components/loading';
import StatusBox from '../../../components/StatusBox';
import CheckIcon from '../../../assets/images/icons/check.svg';
import DenyIcon from '../../../assets/images/icons/denied.svg';
import NextIcon from '../../../assets/images/icons/next.svg';

import DeniedComment from '../deniedComment';
import SeeComment from '../seeComment';

import PaymentsActions from '../../../redux/payments/actions';
import ContractActions from '../../../redux/contracts/actions';
import AuthActions from '../../../redux/auth/actions';

import { verifyEmail } from '../../../utils/validations';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import HelpButton from '../../../components/HelpButton';
import ContractInfoCard from '../../../components/ContractInfoCard';

const PaymentInstructions = ({ t, history }) => {
  function requiredLegal(ref, msg) {
    return Yup.mixed().test({
      name: 'requiredLegal',
      exclusive: false,
      message: msg || 'Required',
      params: {
        reference: ref.path,
      },
      test(value) {
        if (this.resolve(ref) && !value) {
          return false;
        }
        return true;
      },
    });
  }
  Yup.addMethod(Yup.string, 'requiredLegal', requiredLegal);

  function requiredFinancier(ref, msg) {
    return Yup.mixed().test({
      name: 'requiredFinancier',
      exclusive: false,
      message: msg || 'Required',
      params: {
        reference: ref.path,
      },
      test(value) {
        if (this.resolve(ref) && !value) {
          return false;
        }
        return true;
      },
    });
  }
  Yup.addMethod(Yup.string, 'requiredFinancier', requiredFinancier);

  const validationSchema = Yup.object({
    accountHolderName: Yup.string().required(t('common.requiredField')),
    bankName: Yup.string().required(t('common.requiredField')),
    routingNumber: Yup.string().required(t('common.requiredField')),
    accountNumber: Yup.string().required(t('common.requiredField')),
    financier: Yup.string().requiredFinancier(Yup.ref('legalEmail'), t('common.requiredField')),
    legalEmail: Yup.string()
      .requiredLegal(Yup.ref('financier'), t('common.requiredField'))
      .test('validate-email', t('common.enterValidEmail'), function (value) {
        if (this.resolve(Yup.ref('financier')) !== undefined) {
          return verifyEmail(value);
        }
        return true;
      })
      .test('validate-owner', t('common.ownerEmail'), function (value) {
        return !(value === user?.email);
      }),
    legalFirstName: Yup.string().requiredLegal(Yup.ref('financier'), t('common.requiredField')),
    legalLastName: Yup.string().requiredLegal(Yup.ref('financier'), t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [editing, setEditing] = useState(null);
  const [buyerDisabled, setBuyerDisabled] = useState(false);
  const [legalDisabled, setLegalDisabled] = useState(false);
  const [creating, setCreating] = useState(null);
  const [actionRequired, setActionRequired] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [addDisagreeComments, setAddDisagreeComments] = useState(false);
  const [seeDisagreeComments, setSeeDisagreeComments] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const {
    updatePaymentUserLoading,
    updatePaymentUserError,
    updatePaymentUserCalled,
    selectedPayment,
    getPaymentLoading,
    getPaymentError,
    getPaymentCalled,
    sendRequestPaymentLoading,
    sendRequestPaymentError,
    sendRequestPaymentCalled,
  } = useSelector((state) => state.payments);

  const { contractSelection } = useSelector((state) => state.contracts);

  useEffect(() => {
    if (!getPaymentCalled && selectedPayment && selectedPayment?.id) {
      dispatch(PaymentsActions.getPayment(selectedPayment?.id));
    }
    if (contractSelection?.id) {
      if (contractSelection?.buyer?.email === user?.email) {
        setUserType('BUYER');
      } else if (contractSelection?.seller?.email === user?.email) {
        setUserType('SELLER');
      }
      if (contractSelection?.status === 'created') {
        setEditing(true);
      } else {
        setEditing(false);
      }
      setCreating(false);
    } else {
      setUserType('SELLER');
    }

    if (selectedPayment?.id) {
      setEditing(false);
      setCreating(false);
      if (selectedPayment?.owner?.id === user.id) {
        setActionRequired(false);
        if (selectedPayment.buyer?.agree === true || selectedPayment.buyer?.agree === false) {
          setBuyerDisabled(true);
        } else {
          setBuyerDisabled(false);
        }
        if (
          selectedPayment.legalRepresentative?.agree === true ||
          selectedPayment.legalRepresentative?.agree === false
        ) {
          setLegalDisabled(true);
        } else {
          setLegalDisabled(false);
        }
      } else if (
        selectedPayment.legalRepresentative?.id === user.id &&
        selectedPayment.legalRepresentative.agree === null
      ) {
        setActionRequired(true);
      } else if (selectedPayment.buyer?.id === user.id && selectedPayment.buyer.agree === null) {
        setActionRequired(true);
      } else {
        setActionRequired(false);
      }
    } else {
      setActionRequired(false);
      setCreating(true);
    }
    return () => {
      dispatch(PaymentsActions.resetSendRequestPayment());
    };
  }, [dispatch, history, selectedPayment, user, getPaymentCalled]);

  useEffect(() => {
    if (showConfirmation) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [history, showConfirmation]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
    if (contractSelection?.id && !contractSelection?.paymentInstructions) {
      dispatch(ContractActions.getUserContract(contractSelection?.id));
    } else if (contractSelection?.paymentInstructions) {
      dispatch(PaymentsActions.savePaymentSelection(contractSelection?.paymentInstructions));
      setEditing(false);
      setCreating(false);
    }
  }, [contractSelection?.paymentInstructions]);

  function handleSubmit(values) {
    const newPayment = {};
    if (selectedPayment) {
      newPayment.id = selectedPayment?.id;
    }
    newPayment.contract = {
      id: contractSelection?.id,
      contractReference: contractSelection?.contractReference,
    };
    if (selectedPayment?.contract?.id) {
      newPayment.contract.id = selectedPayment?.contract?.id;
    }

    newPayment.buyer = {
      ...contractSelection?.buyer,
    };

    newPayment.beneficiaryName = values.beneficiaryName;
    newPayment.beneficiaryAddress = values.beneficiaryAddress;
    newPayment.beneficiaryComments = values.beneficiaryComments;

    newPayment.accountHolderName = values.accountHolderName;
    newPayment.bankName = values.bankName;
    newPayment.routingNumber = values.routingNumber;
    newPayment.accountNumber = values.accountNumber;
    if (values.financier !== '') {
      newPayment.legalRepresentative = {
        financier: values.financier,
        firstName: values.legalFirstName,
        lastName: values.legalLastName,
        email: values.legalEmail,
      };
    }
    dispatch(PaymentsActions.savePaymentSelection(newPayment));
    dispatch(PaymentsActions.savePaymentConfirmationCheck(false));
    history.push('/contracts/payment-confirmation');
  }

  async function handleAgree() {
    const data = { user: user.id, agree: true };
    await dispatch(
      PaymentsActions.updatePaymentUser(selectedPayment.id, data, {
        financier: selectedPayment?.legalRepresentative?.id === user.id,
        totalVolume: contractSelection.totalVolume,
      })
    );
    dispatch(PaymentsActions.resetGetPayment());
  }

  function titleComponent() {
    if (actionRequired) {
      return (
        <Box className={styles.container_title}>
          <Box className={styles.first_row}>
            <UserPhoto
              className={styles.box_image}
              initials={
                selectedPayment?.owner?.firstName?.slice(0, 1) +
                selectedPayment?.owner?.lastName?.slice(0, 1)
              }
            />
            <Box className={styles.title}>{t('paymentInstructions.title').toUpperCase()}</Box>
          </Box>
          {selectedPayment?.updatedAt && (
            <Box className={styles.subtitle}>
              {t('paymentInstructions.lastEdited') + moment(selectedPayment?.updatedAt).format('L')}
            </Box>
          )}
        </Box>
      );
    }
    return (
      <Box className={styles.container_title}>
        <Box className={styles.first_row}>
          <Box className={styles.title}>{t('paymentInstructions.title').toUpperCase()}</Box>
        </Box>
        {selectedPayment?.updatedAt && (
          <Box className={styles.subtitle}>
            {t('paymentInstructions.lastEdited') + moment(selectedPayment?.updatedAt).format('L')}
          </Box>
        )}
      </Box>
    );
  }

  function getDescription() {
    if (!contractSelection?.seller) {
      return t('paymentInstructions.descriptionWithoutSeller');
    }
    if (!creating && !actionRequired) {
      if (selectedPayment?.legalRepresentative?.financier) {
        return t('paymentInstructions.descriptionEditingWithFinancier', {
          buyerName: `${contractSelection?.buyer?.firstName || ''} ${
            contractSelection?.buyer?.lastName || ''
          }`,
          financierName: selectedPayment?.legalRepresentative?.financier || '',
        });
      }
      return t('paymentInstructions.descriptionEditing', {
        buyerName: `${contractSelection?.buyer?.firstName || ''} ${
          contractSelection?.buyer?.lastName || ''
        }`,
      });
    }
    return t('paymentInstructions.description');
  }

  function showStatusBox() {
    const isSeller = contractSelection?.seller?.id === user?.id;
    if (contractSelection?.status !== 'created') {
      if (
        (!selectedPayment?.buyer || selectedPayment?.buyer?.agree === true) &&
        (!selectedPayment?.legalRepresentative ||
          selectedPayment?.legalRepresentative?.agree === true)
      ) {
        return <StatusBox type="confirmed" date={selectedPayment?.updatedAt} />;
      }
      if (!isSeller) {
        if (contractSelection?.buyer?.id === user?.id) {
          if (selectedPayment?.buyer?.agree === null) {
            return (
              <StatusBox
                type="review"
                counterpartName={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
              />
            );
          }
          if (contractSelection?.buyer?.agree === false) {
            return (
              <StatusBox
                type="denied"
                counterpartName={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
                date={contractSelection?.buyer?.updatedAtUser}
              />
            );
          }
          if (selectedPayment?.buyer?.agree === true) {
            return <StatusBox type="waiting" />;
          }
        }
        if (selectedPayment?.legalRepresentative?.id === user?.id) {
          if (selectedPayment?.legalRepresentative?.agree === null) {
            return (
              <StatusBox
                type="review"
                counterpartName={`${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`}
              />
            );
          }
          if (selectedPayment?.legalRepresentative?.agree === false) {
            return (
              <StatusBox
                type="denied"
                counterpartName={`${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`}
                date={selectedPayment?.legalRepresentative?.updatedAtUser}
              />
            );
          }
          if (selectedPayment?.legalRepresentative?.agree === true) {
            return <StatusBox type="waiting" />;
          }
        }
      } else {
        //  SELLER
        return (
          <Box>
            {selectedPayment?.buyer && (
              <StatusBox
                type="default"
                user={selectedPayment?.buyer}
                userStatus={selectedPayment?.buyer?.agree}
                onClick={() => {
                  dispatch(ContractActions.updateSelectedUserDenied(selectedPayment?.buyer));
                  setSeeDisagreeComments(true);
                }}
                date={selectedPayment?.buyer?.updatedAtUser}
              />
            )}
            {selectedPayment?.legalRepresentative && (
              <StatusBox
                type="default"
                user={selectedPayment?.legalRepresentative}
                userStatus={selectedPayment?.legalRepresentative?.agree}
                onClick={() => {
                  dispatch(
                    ContractActions.updateSelectedUserDenied(selectedPayment?.legalRepresentative)
                  );
                  setSeeDisagreeComments(true);
                }}
                date={selectedPayment?.legalRepresentative?.updatedAtUser}
              />
            )}
          </Box>
        );
      }
    }
  }

  const Title = () => titleComponent();
  return (
    <Box className={styles.container}>
      <Loading isLoading={getPaymentLoading} />
      <NavigationBar
        titleDescription={{
          title: t('paymentInstructions.title').toUpperCase(),
          description: getDescription(),
        }}
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        hasFilterButton={!isMobile}
        backButtonText={t('common.backToPortfolio').toUpperCase()}
        customBack={!isMobile ? () => history.replace('/home') : null}
        hasEditButton={!creating && !actionRequired && contractSelection?.status === 'created'}
        editing={editing}
        history={history}
        onPressEditButton={() => setEditing(!editing)}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid
            container
            className={styles.grid_container}
            justify="flex-start"
            style={{ paddingBottom: isTablet ? 86 : isMobile ? 73 : 0 }}
          >
            <Grid item xs={12} sm={12} container justify="flex-start">
              {!isMobile && (
                <Box className={styles.title_container}>
                  {userType === 'SELLER' &&
                    creating === false &&
                    editing === false &&
                    contractSelection?.status !== 'created' && (
                      <Box className={styles.container_propose_changes}>
                        <Button
                          inverted
                          bordered
                          className={styles.button_alone}
                          minWidth={130}
                          maxWidth={270}
                          onPress={() => {
                            setEditing(true);
                          }}
                        >
                          {t('contracts.proposeChanges')}
                        </Button>
                      </Box>
                    )}
                </Box>
              )}
              <Container className={styles.content}>
                {selectedPayment?.id && showStatusBox()}
                {!isMobile && (
                  <Box className={styles.container_title2}>
                    <Box component="span" style={{ color: '#2f2f2f' }} className={styles.title}>
                      <Title />
                    </Box>
                  </Box>
                )}
                <Box className={styles.container_step_description}>
                  <Box className="subtitle">{t('paymentInstructions.descriptionPayment')}</Box>
                </Box>
                <Box className={styles.box_contract_information}>
                  <Box className={styles.title}>{t('contracts.contractReference')}</Box>
                  <Box>{contractSelection?.contractReference}</Box>
                  <Divider style={{ marginTop: 10 }} />
                  {(userType === 'SELLER' && contractSelection?.buyer !== undefined) ||
                    (userType === 'BUYER' && contractSelection?.seller !== undefined && (
                      <>
                        <Box className={styles.title}>
                          {userType === 'SELLER'
                            ? t('contracts.paymentInstructions.buyerInformation')
                            : t('contracts.paymentInstructions.sellerInformation')}
                        </Box>
                        <Box className={styles.box_row}>
                          <Box className={styles.box_personal_data}>
                            <Box className={styles.box_photo_name}>
                              <UserPhoto
                                className={styles.box_image}
                                initials={
                                  userType === 'SELLER'
                                    ? (contractSelection?.buyer?.firstName || '').slice(0, 1) +
                                      (contractSelection?.buyer?.lastName || '').slice(0, 1)
                                    : (contractSelection?.seller?.firstName || '').slice(0, 1) +
                                      (contractSelection?.seller?.lastName || '').slice(0, 1)
                                }
                              />
                              <Box className={styles.box_name}>
                                {userType === 'SELLER'
                                  ? `${contractSelection?.buyer?.firstName || ''} ${
                                      contractSelection?.buyer?.lastName || ''
                                    }`
                                  : `${contractSelection?.seller?.firstName || ''} ${
                                      contractSelection?.seller?.lastName || ''
                                    }`}
                              </Box>
                            </Box>
                            <Box className={styles.box_email}>
                              {userType === 'SELLER'
                                ? contractSelection?.buyer?.email
                                : contractSelection?.seller?.email}
                            </Box>
                          </Box>
                        </Box>
                      </>
                    ))}
                </Box>

                {!selectedPayment?.id &&
                  userType === 'BUYER' &&
                  contractSelection?.seller !== undefined && (
                    <Box className={styles.container_submit}>
                      <Button
                        className={styles.button_alone}
                        loading={sendRequestPaymentLoading}
                        disabled={sendRequestPaymentLoading}
                        fontSize={11}
                        minWidth={130}
                        maxWidth={270}
                        onPress={() => {
                          dispatch(
                            PaymentsActions.sendRequestPayment({
                              contractId: contractSelection?.id,
                            })
                          );
                        }}
                      >
                        {t('contracts.paymentInstructions.sendRequestForPayments')}
                      </Button>
                    </Box>
                  )}

                {!selectedPayment?.id &&
                  userType === 'BUYER' &&
                  !contractSelection?.seller !== undefined && (
                    <Box className={styles.container_submit}>
                      <Button
                        className={styles.button_alone}
                        fontSize={11}
                        minWidth={130}
                        maxWidth={270}
                        onPress={() => {
                          history.push('/contracts/general-information');
                        }}
                      >
                        {t('contracts.paymentInstructions.addSellerInfo')}
                      </Button>
                    </Box>
                  )}

                {(selectedPayment?.id || userType === 'SELLER') && (
                  <Box>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        accountHolderName: selectedPayment?.accountHolderName || '',
                        bankName: selectedPayment?.bankName || '',
                        routingNumber: selectedPayment?.routingNumber || '',
                        accountNumber: selectedPayment?.accountNumber || '',
                        beneficiaryName: selectedPayment?.beneficiaryName || '',
                        beneficiaryAddress: selectedPayment?.beneficiaryAddress || '',
                        beneficiaryComments: selectedPayment?.beneficiaryComments || '',
                        financier: selectedPayment?.legalRepresentative?.financier || '',
                        legalEmail: selectedPayment?.legalRepresentative?.email || '',
                        legalFirstName: selectedPayment?.legalRepresentative?.firstName || '',
                        legalLastName: selectedPayment?.legalRepresentative?.lastName || '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box className={styles.container_inputs}>
                            <Box className={styles.container_input}>
                              <TextField
                                name="accountHolderName"
                                value={values.accountHolderName}
                                onChange={handleChange}
                                helperText={
                                  errors.accountHolderName &&
                                  touched.accountHolderName &&
                                  errors.accountHolderName
                                }
                                placeholder={t('paymentInstructions.accountHolderName')}
                                label={t('paymentInstructions.accountHolderName').toUpperCase()}
                                disabled={!editing && !creating}
                                required
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="bankName"
                                value={values.bankName}
                                onChange={handleChange}
                                helperText={errors.bankName && touched.bankName && errors.bankName}
                                placeholder={t('paymentInstructions.bankName')}
                                label={t('paymentInstructions.bankName').toUpperCase()}
                                disabled={!editing && !creating}
                                required
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="routingNumber"
                                value={values.routingNumber}
                                onChange={handleChange}
                                helperText={
                                  errors.routingNumber &&
                                  touched.routingNumber &&
                                  errors.routingNumber
                                }
                                placeholder={t('paymentInstructions.routingNumber')}
                                label={t('paymentInstructions.routingNumber').toUpperCase()}
                                disabled={!editing && !creating}
                                required
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="accountNumber"
                                value={values.accountNumber}
                                onChange={handleChange}
                                helperText={
                                  errors.accountNumber &&
                                  touched.accountNumber &&
                                  errors.accountNumber
                                }
                                placeholder={t('paymentInstructions.accountNumber')}
                                label={t('paymentInstructions.accountNumber').toUpperCase()}
                                disabled={!editing && !creating}
                                required
                              />
                            </Box>

                            <Box className={styles.container_step_description}>
                              <Box className={styles.title_form}>
                                {t('paymentInstructions.beneficiaryTitle')}
                              </Box>
                              <Box className={styles.subtitle_form}>
                                {t('paymentInstructions.beneficiaryDescription')}
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="beneficiaryName"
                                value={values.beneficiaryName}
                                onChange={handleChange}
                                helperText={
                                  errors.beneficiaryName &&
                                  touched.beneficiaryName &&
                                  errors.beneficiaryName
                                }
                                placeholder={t('paymentInstructions.beneficiaryName')}
                                label={t('paymentInstructions.beneficiaryName').toUpperCase()}
                                disabled={legalDisabled || (!editing && !creating)}
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="beneficiaryAddress"
                                value={values.beneficiaryAddress}
                                onChange={handleChange}
                                helperText={
                                  errors.beneficiaryAddress &&
                                  touched.beneficiaryAddress &&
                                  errors.beneficiaryAddress
                                }
                                placeholder={t('paymentInstructions.beneficiaryAddress')}
                                label={t('paymentInstructions.beneficiaryAddress').toUpperCase()}
                                disabled={legalDisabled || (!editing && !creating)}
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="beneficiaryComments"
                                value={values.beneficiaryComments}
                                onChange={handleChange}
                                helperText={
                                  errors.beneficiaryComments &&
                                  touched.beneficiaryComments &&
                                  errors.beneficiaryComments
                                }
                                placeholder={t('paymentInstructions.beneficiaryComments')}
                                label={t('paymentInstructions.beneficiaryComments').toUpperCase()}
                                disabled={legalDisabled || (!editing && !creating)}
                              />
                            </Box>

                            <Box className={styles.container_step_description}>
                              <Box className={styles.title_form}>
                                {t('paymentInstructions.title2')}
                              </Box>
                              <Box className={styles.subtitle_form}>
                                {t('paymentInstructions.description2')}
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="financier"
                                value={values.financier}
                                onChange={handleChange}
                                helperText={
                                  errors.financier && touched.financier && errors.financier
                                }
                                placeholder={t('paymentInstructions.financier')}
                                label={t('paymentInstructions.financier').toUpperCase()}
                                disabled={legalDisabled || (!editing && !creating)}
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="legalEmail"
                                value={values.legalEmail}
                                onChange={handleChange}
                                helperText={
                                  errors.legalEmail && touched.legalEmail && errors.legalEmail
                                }
                                placeholder={t('paymentInstructions.legalEmail')}
                                label={t('paymentInstructions.legalEmail').toUpperCase()}
                                disabled={legalDisabled || (!editing && !creating)}
                                required
                              />
                            </Box>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(styles.container_firstname, styles.container_input)}
                              >
                                <TextField
                                  name="legalFirstName"
                                  value={values.legalFirstName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.legalFirstName &&
                                    touched.legalFirstName &&
                                    errors.legalFirstName
                                  }
                                  placeholder={t('paymentInstructions.legalFirstName')}
                                  label={t('paymentInstructions.legalFirstName').toUpperCase()}
                                  disabled={legalDisabled || (!editing && !creating)}
                                  required
                                />
                              </Box>
                              <Box
                                className={clsx(styles.container_lastname, styles.container_input)}
                              >
                                <TextField
                                  name="legalLastName"
                                  value={values.legalLastName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.legalLastName &&
                                    touched.legalLastName &&
                                    errors.legalLastName
                                  }
                                  placeholder={t('paymentInstructions.legalLastName')}
                                  label={t('paymentInstructions.legalLastName').toUpperCase()}
                                  disabled={legalDisabled || (!editing && !creating)}
                                  required
                                />
                              </Box>
                            </Box>
                          </Box>
                          {actionRequired ? (
                            <Box className={styles.container_submit}>
                              <Box className={styles.box_two_options}>
                                <Button
                                  loading={updatePaymentUserLoading}
                                  disabled={updatePaymentUserLoading}
                                  inverted
                                  bordered
                                  className={styles.button}
                                  onPress={() => {
                                    setAddDisagreeComments(true);
                                  }}
                                >
                                  {t('common.dontAgree')}
                                </Button>
                                <Box style={{ width: 20 }} />
                                <Button
                                  loading={updatePaymentUserLoading}
                                  disabled={updatePaymentUserLoading}
                                  className={styles.button}
                                  onPress={() => {
                                    setShowConfirmation(true);
                                  }}
                                >
                                  {t('common.agree')}
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              {(editing || creating) && (
                                <Box className={styles.container_submit}>
                                  <Button
                                    type="submit"
                                    disabled={!editing && !creating}
                                    className={styles.button_alone}
                                    data-info-submit="Submit"
                                    minWidth={130}
                                    maxWidth={270}
                                  >
                                    {t('common.confirm')}
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          )}
                        </form>
                      )}
                    </Formik>
                  </Box>
                )}
              </Container>
            </Grid>
          </Grid>
        }
      />
      {updatePaymentUserCalled && !updatePaymentUserLoading && updatePaymentUserError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={String(updatePaymentUserError.userMessage)}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetUpdatePaymentUser());
          }}
        />
      )}

      {showConfirmation && (
        <MessageModal
          title={t('common.agreeConfirmationTitle')}
          message={t('paymentInstructions.agreeConfirmationDescription')}
          secundaryAction={t('common.no')}
          secundaryEvent={() => {
            setShowConfirmation(false);
          }}
          primaryAction={t('common.yes')}
          primaryEvent={() => {
            setShowConfirmation(false);
            handleAgree();
          }}
        />
      )}
      {getPaymentCalled && !getPaymentLoading && getPaymentError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getPaymentError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetGetPayment());
          }}
        />
      )}
      {sendRequestPaymentCalled && !sendRequestPaymentLoading && sendRequestPaymentError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={sendRequestPaymentError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetSendRequestPayment());
          }}
        />
      )}
      {sendRequestPaymentCalled && !sendRequestPaymentLoading && !sendRequestPaymentError && (
        <MessageModal
          title={t('common.success')}
          message={t('paymentInstructions.requestSent')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(PaymentsActions.resetSendRequestPayment());
            history.goBack();
          }}
        />
      )}

      {addDisagreeComments && (
        <ModalView
          view={
            <DeniedComment
              isModal
              formType="payment"
              onClose={() => setAddDisagreeComments(false)}
            />
          }
          onClose={() => setAddDisagreeComments(false)}
        />
      )}

      {seeDisagreeComments && (
        <ModalView
          view={
            <SeeComment isModal formType="payment" onClose={() => setSeeDisagreeComments(false)} />
          }
          onClose={() => setSeeDisagreeComments(false)}
        />
      )}
    </Box>
  );
};

export default withTranslation()(PaymentInstructions);
