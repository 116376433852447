import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.sass';
import 'animate.css/animate.min.css';
import './utils/I18n';
import TagManager from 'react-gtm-module';
import amplitude from 'amplitude-js';
import * as Sentry from '@sentry/browser';

import firebase from 'firebase/app';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'firebase/auth';

import store from './redux/store';
import ServiceWorkerActions from './redux/serviceWorker/actions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
let messaging;
firebase.initializeApp(firebaseConfig);
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
console.log('Firebase initialized');

if (process.env.REACT_APP_SENTRY !== 'disable') {
  console.log('Sentry initialized');
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
  });
}

if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
  console.log('Amplitude initialized');
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, null);
}

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER !== 'disable') {
  console.log('GTM initialized');
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register({
  onSuccess: () => store.dispatch(ServiceWorkerActions.init()),
  onUpdate: (registration) => store.dispatch(ServiceWorkerActions.update(registration)),
});
