import api from './api';

export const getTerms = (id) => {
  return api.get(`contracts/terms?contract=${id}`);
};

export const postTerms = (data) => {
  return api.post('contracts/terms', data);
};

export const putTerms = (data) => {
  return api.put('contracts/terms', data);
};

export const deleteTerms = (payload) => {
  return api.delete('contracts/terms', {}, { data: payload });
};

export const putUserTerms = (id, termId, data) => {
  return api.put(`contracts/${id}/terms/${termId}/status`, data);
};
