import { fi } from 'date-fns/locale';
import moment from 'moment';

import api from './api';
import i18n from '../utils/I18n';

export const getUserContracts = (userId, limit, page, filters) => {
  let filter = '';
  if (filters) {
    if (filters.dateRange?.length === 2) {
      if (filters.dateRange[0] && moment(filters.dateRange[0]).isValid()) {
        filter += `&startDate=${moment(filters.dateRange[0]).format('YYYY-MM-DD')}`;
      }
      if (filters.dateRange[1] && moment(filters.dateRange[1]).isValid()) {
        filter += `&endDate=${moment(filters.dateRange[1]).format('YYYY-MM-DD')}`;
      }
    }
    if (filters.statistic) {
      filter += `&statistic=${filters.statistic}`;
    }
    if (filters.buyer) {
      filter += `&buyer=${filters.buyer}`;
    }
    if (filters.seller) {
      filter += `&seller=${filters.seller}`;
    }
    if (filters.product) {
      filter += `&sugarSpec=${filters.product}`;
    }
    if (filters.sort) {
      if (filters.sort === i18n.t('home.totalContract')) {
        filter += `&totalVolume=DESC`;
      }
      if (filters.sort === i18n.t('home.totalNominated')) {
        filter += `&nominatedVolume=DESC`;
      }
      if (filters.sort === i18n.t('home.totalVolume')) {
        filter += `&scheduledVolume=DESC`;
      }
      if (filters.sort === i18n.t('home.deliveryStatusBehind')) {
        filter += `&completionStatus=ASC`;
      }
      if (filters.sort === i18n.t('home.deliveryStatusAhead')) {
        filter += `&completionStatus=DESC`;
      }
    }
  }
  return api.get(`contracts/statistics?user=${userId}&limit=${limit}&page=${page}${filter}`);
};

export const getUserContract = (id) => {
  return api.get(`contracts/${id}`);
};

export const saveUserContracts = (data) => {
  return api.post('contracts', data);
};

export const updateUserContracts = (id, data) => {
  return api.put(`contracts/${id}`, data);
};

export const deleteUserContracts = (id) => {
  return api.delete(`contracts/${id}`);
};

export const putUserContract = (id, data) => {
  return api.put(`contracts/${id}/users`, data);
};

export const putAcceptInvite = (id) => {
  return api.put(`contracts/${id}/acceptInvite`);
};

export const getTotalStatistics = (userId) => {
  return api.get(`contracts/totalStatistics?user=${userId}`);
};

export const getContractsUsers = () => {
  return api.get(`/contracts/users`);
};

export const getContractsProducts = () => {
  return api.get(`/contracts/products`);
};

export const storeSelectedContract = (contract) => {
  // localStorage.setItem('selected_contract', contract.id);
};

export const removeSelectedContract = async () => {
  localStorage.removeItem('selected_contract');
};

export const getContractDocuments = (contractId, filters = '') => {
  return api.get(`/documents/contract/${contractId}${filters}`);
};

export const uploadDocumentContract = (taskDocumentId, body) => {
  return api.post(`/documents/${taskDocumentId}`, body);
};

export const getContractNominations = (contractId) => {
  return api.get(`/documents/contract/${contractId}/nominations`);
};

export const getContractDocTypes = (contractId) => {
  return api.get(`/documents/contract/${contractId}/file-types`);
};

export const deleteContractDoc = (documentId) => {
  return api.delete(`/documents/${documentId}`);
};

export const restoreSelectedContract = () => {
  try {
    return localStorage.getItem('selected_contract');
  } catch {
    return null;
  }
};

export const getContractMe = (id) => {
  return api.get(`/contracts/${id}/me`);
};

export const getAutocomplete = () => {
  return api.get(`/contracts/autocomplete`);
};
