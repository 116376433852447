import React from 'react';
import Box from '@material-ui/core/Box';

import { SvgIcon } from '@material-ui/core';
import Button from './Button';
import HelpIcon from '../assets/images/icons/help.svg';
import styles from './styles/logo.module.sass';

const HelpButton = ({ onHelp }) => {
  return (
    <Box className={styles.icon_container}>
      <Button
        data-help="Clicked Help Info"
        inverted
        clean
        labelStyle={{ justifyContent: 'center' }}
        onPress={() => {
          if (onHelp) {
            onHelp();
          }
        }}
      >
        <SvgIcon viewBox="0 0 25 25" component={HelpIcon} style={{ color: '#000000' }} />
      </Button>
    </Box>
  );
};

export default HelpButton;
