import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/Close';

import Button from '../../components/Button';
import styles from './styles.module.sass';

import FreemiumBG from '../../assets/images/background/freemiumBG.svg';

const ModalView = ({
  inverted = false,
  shouldHide,
  view,
  hideClose = false,
  onClose = () => {},
}) => {
  useEffect(() => {
    if (shouldHide) {
      setTimeout(() => shouldHide(), 3000);
    }
  });

  return (
    <Box className={styles.container}>
      <Box
        className={styles.background}
        style={{
          backgroundImage: inverted
            ? 'none'
            : 'linear-gradient(128deg, #757e95 20%, #000000 87%, #000000 87%)',
          backgroundColor: inverted ? 'rgba(255, 255, 255, 0.97)' : 'none',
        }}
      />
      <Box
        className={styles.box}
        style={{
          transform: 'translateY(0%)',
          backgroundImage: !inverted
            ? 'none'
            : 'linear-gradient(128deg, #757e95 20%, #000000 87%, #000000 87%)',
          backgroundColor: !inverted ? 'white' : 'none',
        }}
      >
        {inverted && (
          <SvgIcon
            className={styles.background_image}
            component={FreemiumBG}
            viewBox="0 0 688 633"
          />
        )}
        <Box
          className={styles.box_content}
          style={isMobile && !isTablet ? { padding: '10px 10px 10px 10px' } : { padding: '50px' }}
        >
          {view && view}
          {!hideClose && (
            <Button className={styles.close} align="center" clean onPress={onClose}>
              <CloseIcon style={{ color: inverted ? 'white' : 'black', fontSize: 30 }} />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(ModalView);
