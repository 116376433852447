import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isMobile } from 'react-device-detect';

import clsx from 'clsx';
import WorkflowEmpty from '../../../../components/WorkflowEmpty';
import TaskItem from '../../../../components/TaskItem';

import styles from './styles.module.sass';

const TasksList = ({
  schedule,
  showLoading,
  hideDescription,
  openDetail,
  tasks = [],
  loadingItems,
  oldTaskSelected,
  contractSelection,
  contractMe,
  onClick,
  onDelete,
  onEdit,
  onCreateTask,
  onAddCollaborator,
  onEditDataPoint,
  onChangeState,
  onChangeDocumentState,
  onSeeRejectedComments,
  onUpload,
  t,
}) => {
  const scrollRef = React.useRef(null);
  const [numScroll, setNumScroll] = React.useState(0);

  const onScroll = () => {
    if (scrollRef?.current?.scrollTop) {
      localStorage.setItem('scrollRef', scrollRef?.current?.scrollTop);
    }
  };

  React.useEffect(() => {
    if (!scrollRef?.current?.scrollTop) {
      scrollRef.current.scrollTop = localStorage.getItem('scrollRef');
    }
    return () => {};
  }, []);

  return (
    <Box
      className={clsx(styles.list, openDetail ? styles.scroll_hidden : null)}
      onScrollCapture={onScroll}
      ref={scrollRef}
    >
      {showLoading ? (
        <Box className={styles.loaderList}>
          <CircularProgress color="inherit" />
        </Box>
      ) : !openDetail && (!tasks || tasks.length === 0) ? (
        <WorkflowEmpty hideDescription={hideDescription} />
      ) : null}
      {tasks?.length > 0 &&
        tasks?.map((value, index) => {
          return (
            <Box
              style={{
                paddingBottom: 20,
                paddingRight: isMobile ? 0 : 40,
                position: 'relative',
              }}
              key={index}
            >
              <TaskItem
                showLoading={loadingItems[value.id]}
                showNextIcon={oldTaskSelected !== value.id}
                date={value?.date || value?.completionDate}
                referenceNumber={value?.referenceNumber}
                type={t('contracts.details.workflow.nomination')}
                location={value?.location}
                startTime={value?.startTime}
                endTime={value?.endTime}
                eta={value?.eta}
                etd={value?.etd}
                title={`${value?.volume || ''} MT`}
                collaborators={value?.users}
                requirements={value?.requirements}
                status={value?.status}
                subTasks={value?.subTasks}
                schedule={schedule}
                withCounterpart={contractSelection?.seller && contractSelection?.buyer}
                meCollaborator={contractMe}
                onClick={() => onClick(value)}
                onDelete={onDelete}
                onEdit={onEdit}
                onCreateTask={(type) => onCreateTask(value, type)}
                onAddCollaborator={onAddCollaborator}
                onEditDataPoint={onEditDataPoint}
                onChangeState={onChangeState}
                onChangeDocumentState={onChangeDocumentState}
                onSeeRejectedComments={onSeeRejectedComments}
                onUpload={onUpload}
              />
            </Box>
          );
        })}
      {/* {openDetail && !activeTask && (
        <Box className={styles.taskFooterList}>
          <GetFooterIcon />
          {(openDetail || 'Task').toUpperCase()}
        </Box>
      )} */}
    </Box>
  );
};

export default withTranslation()(TasksList);
