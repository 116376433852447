import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import clsx from 'clsx';
import styles from './styles.module.sass';

const PaymentConfirmBox = ({
  t,
  className,
  contract = '',
  buyerEmail = '',
  buyerName = '',
  accountHolderName = '',
  bankName = '',
  routingNumber = '',
  accountNumber = '',
  beneficiaryName = '',
  beneficiaryAddress = '',
  beneficiaryComments = '',
  financier = '',
  legalEmail = '',
  legalName = '',
}) => {
  return (
    <Box className={clsx(styles.container, className)}>
      <Box className={styles.content_box}>
        <Box className={styles.box_title}>{t('paymentInstructions.confirmation.titleBox')}</Box>
        <Box className={styles.box_line} />
        <Box className={styles.box_data}>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.contractReference')}:</Box>
            <Box className={styles.data}>{contract}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.buyerEmail')}:</Box>
            <Box className={styles.data}>{buyerEmail}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.buyerName')}:</Box>
            <Box className={styles.data}>{buyerName}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.accountHolderName')}:</Box>
            <Box className={styles.data}>{accountHolderName}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.bankName')}:</Box>
            <Box className={styles.data}>{bankName}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.routingNumber')}</Box>
            <Box className={styles.data}>{routingNumber}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.accountNumber')}</Box>
            <Box className={styles.data}>{accountNumber}</Box>
          </Box>
          {beneficiaryName !== '' && (
            <Box className={styles.box_row}>
              <Box className={styles.label}>{t('paymentInstructions.beneficiaryName')}:</Box>
              <Box className={styles.data}>{beneficiaryName}</Box>
            </Box>
          )}
          {beneficiaryAddress !== '' && (
            <Box className={styles.box_row}>
              <Box className={styles.label}>{t('paymentInstructions.beneficiaryAddress')}:</Box>
              <Box className={styles.data}>{beneficiaryAddress}</Box>
            </Box>
          )}
          {beneficiaryComments !== '' && (
            <Box className={styles.box_row}>
              <Box className={styles.label}>{t('paymentInstructions.beneficiaryComments')}:</Box>
              <Box className={styles.data}>{beneficiaryComments}</Box>
            </Box>
          )}
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.financier')}:</Box>
            <Box className={styles.data}>{financier}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.legalEmail')}:</Box>
            <Box className={styles.data}>{legalEmail}</Box>
          </Box>
          <Box className={styles.box_row}>
            <Box className={styles.label}>{t('paymentInstructions.legalName')}:</Box>
            <Box className={styles.data}>{legalName}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(PaymentConfirmBox);
