import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { isMobile, isTablet } from 'react-device-detect';

import ModalView from '../modalView';
import FreemiumItem from '../../assets/images/icons/freemium-item.svg';
import Logo from '../../components/Logo';
import Button from '../../components/Button';
import styles from './styles.module.sass';

const FremiumMessage = ({ shouldHide, t, onClose = () => {}, onJoin = () => {} }) => {
  useEffect(() => {
    if (shouldHide) {
      setTimeout(() => shouldHide(), 3000);
    }
  });

  return (
    <ModalView
      inverted
      onClose={onClose}
      view={
        <Box
          className={styles.modal_box_content}
          style={
            isMobile && !isTablet
              ? { paddingTop: 56, paddingBottom: 73, paddingRight: 10, paddingLeft: 10 }
              : {}
          }
        >
          <Logo white />
          <Box className={styles.title}>{t('freemium.title').toUpperCase()}</Box>
          <Box className={styles.items}>
            <Box className={styles.item}>
              <SvgIcon component={FreemiumItem} viewBox="0 0 30 30" style={{ marginRight: 20 }} />
              {t('freemium.description1')}
            </Box>
            <Box className={styles.item}>
              <SvgIcon component={FreemiumItem} viewBox="0 0 30 30" style={{ marginRight: 20 }} />
              {t('freemium.description2')}
            </Box>
            <Box className={styles.item}>
              <SvgIcon component={FreemiumItem} viewBox="0 0 30 30" style={{ marginRight: 20 }} />
              {t('freemium.description2')}
            </Box>
          </Box>

          <Button
            inverted
            backgroundImage="linear-gradient(90deg, #ffffff 18%, #757e95 100%)"
            className={styles.button}
            onPress={onJoin}
          >
            {t('freemium.action')}
          </Button>
        </Box>
      }
    />
  );
};

export default withTranslation()(FremiumMessage);
