import Immutable from 'seamless-immutable';

const initialState = Immutable({
  getOffTakeSchedulesLoading: false,
  getOffTakeSchedulesError: null,
  getOffTakeSchedulesCalled: false,
  offTakeSchedules: null,

  createOffTakeScheduleLoading: false,
  createOffTakeScheduleError: null,
  createOffTakeScheduleCalled: false,

  updateOffTakeScheduleLoading: false,
  updateOffTakeScheduleError: null,
  updateOffTakeScheduleCalled: false,

  deleteOffTakeScheduleLoading: false,
  deleteOffTakeScheduleError: null,
  deleteOffTakeScheduleCalled: false,

  updateUserOffTakeScheduleLoading: false,
  updateUserOffTakeScheduleError: null,
  updateUserOffTakeScheduleCalled: false,
});

export default initialState;
