import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import NavigationBar from '../../../components/NavigationBar';
import UserPhoto from '../../../components/UserPhoto';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import DenyIcon from '../../../assets/images/icons/denied.svg';

import AuthActions from '../../../redux/auth/actions';
import ContractActions from '../../../redux/contracts/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import HelpButton from '../../../components/HelpButton';
import ContractInfoCard from '../../../components/ContractInfoCard';

const SeeComment = ({ t, history, isModal, onClose, comments, formType }) => {
  const dispatch = useDispatch();

  const { selectedUserDenied } = useSelector((state) => state.contracts);
  const { user } = useSelector((state) => state.auth);

  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(ContractActions.updateSelectedUserDenied(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function nameComponent() {
    return (
      <Box className={styles.box_item} style={{ marginLeft: isMobile ? 0 : 10 }}>
        <Box className={styles.box_row}>
          <Box className={styles.box_personal_data}>
            <Box className={styles.box_photo_name}>
              <UserPhoto
                className={styles.box_image}
                initials={
                  (selectedUserDenied?.firstName ?? '').slice(0, 1) +
                  (selectedUserDenied?.lastName ?? '').slice(0, 1)
                }
              />
              <Box className={styles.box_name}>
                {`${selectedUserDenied?.firstName || ''} ${selectedUserDenied?.lastName || ''}`}
              </Box>
            </Box>
            <Box className={styles.box_email}>{selectedUserDenied?.email}</Box>
          </Box>
        </Box>
      </Box>
    );
  }

  function statusComponent() {
    return (
      <Box className={styles.box_item} style={{ marginRight: 10 }}>
        <Box className={styles.box_row}>
          <Box className={styles.box_status_data}>
            <Box className={styles.box_status}>
              <SvgIcon
                style={{ height: 20, width: 20, marginRight: 5 }}
                component={DenyIcon}
                viewBox="0 0 32 32"
              />
              <Box className={styles.box_status_text}>{t('paymentInstructions.denied')}</Box>
            </Box>
            <Box className={styles.box_status_date}>
              {moment(selectedUserDenied?.updatedAt).format('L')}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  if (isModal) {
    return (
      <Box style={{ width: 700 }}>
        <Grid container className={styles.grid_container} justify="center">
          <Grid item xs={12} sm={12} container justify="center">
            <Container maxWidth="md" className={styles.content}>
              <Box
                className={clsx(styles.main_content, drawerOpened ? 'container_with_drawer' : null)}
              >
                {!isMobile && (
                  <>
                    <Box className={styles.container_title}>
                      <Box className="subtitle_container">
                        {selectedUserDenied
                          ? t('contracts.seeComment.title', {
                              name: `${selectedUserDenied?.firstName || ''} ${
                                selectedUserDenied?.lastName || ''
                              }`,
                            }).toUpperCase()
                          : t('contracts.deniedTitle').toUpperCase()}
                      </Box>
                    </Box>
                    <Box>
                      <Box className="description_container" style={{ textAlign: 'center' }}>
                        {selectedUserDenied
                          ? moment(selectedUserDenied?.updatedAt).format('L')
                          : ''}
                      </Box>
                    </Box>
                  </>
                )}

                <Box className={styles.container_inputs}>
                  <Box className={styles.container_input}>
                    <TextField
                      value={selectedUserDenied?.comment ?? comments ?? ''}
                      multiline
                      rows={15}
                    />
                  </Box>
                </Box>

                <Box className={styles.container_submit}>
                  <Button
                    className={styles.button_alone}
                    minWidth={130}
                    maxWidth={270}
                    onPress={() => onClose()}
                  >
                    {t('common.cancel')}
                  </Button>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <NavigationBar
        hideTitle
        hasMenuButton={!isMobile}
        barColor={isMobile ? '#fff' : '#111'}
        white={!isMobile}
        hasBackButton
        hasNotificationButton
        hasFilterButton={!isMobile}
        hasLeftComponent={isMobile ? nameComponent() : null}
        hasRightComponent={isMobile ? statusComponent() : null}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        history={history}
        pageContent={
          <Grid container className={styles.grid_container} justify="center">
            {!isMobile && (
              <Grid item xs={12} sm={3}>
                <ContractInfoCard />
                <ContractSpecsCard />
              </Grid>
            )}
            <Grid item xs={12} sm={6} container justify="center">
              <Container maxWidth="md" className={styles.content}>
                <Box
                  className={clsx(
                    styles.main_content,
                    drawerOpened ? 'container_with_drawer' : null
                  )}
                >
                  {!isMobile && (
                    <>
                      <Box className={styles.container_title}>
                        <Box component="span" style={{ color: '#2f2f2f' }} className={styles.title}>
                          {t('paymentInstructions.comment').toUpperCase()}
                        </Box>
                      </Box>
                      <Box style={{ marginTop: 20 }} display="flex" justifyContent="space-between">
                        {nameComponent()}
                        {statusComponent()}
                      </Box>
                    </>
                  )}

                  <Box className={styles.container_step_description}>
                    <Box className={styles.comment}>
                      {selectedUserDenied?.comment ?? comments ?? ''}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default withTranslation()(SeeComment);
