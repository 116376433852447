import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import MatButton from '@material-ui/core/Button';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import clsx from 'clsx';
import { flatten, groupBy } from 'lodash';
import { Avatar, Grid } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Laptop from '@material-ui/icons/Laptop';
import Button from '../Button';
import { AntTab, AntTabs } from '../AntTabs';
import AuthActions from '../../redux/auth/actions';
import ServsActions from '../../redux/serviceWorker/actions';
import NotificationActions from '../../redux/notifications/actions';
import ContractsActions from '../../redux/contracts/actions';
import InvitesActions from '../../redux/invites/actions';
import ProfileData from '../ProfileData';
import Logo from '../Logo';

import styles from './styles.module.sass';

import HelpIcon from '../../assets/images/icons/help.svg';
import BackIcon from '../../assets/images/icons/back.svg';
import MenuIcon from '../../assets/images/icons/menu.svg';
import MenuIconWhite from '../../assets/images/icons/menu-white.svg';
import NotificationIcon from '../../assets/images/icons/notifications.svg';
import NotificationIconWhite from '../../assets/images/icons/notifications-white.svg';
import MoreIcon from '../../assets/images/icons/more.svg';
import DropdownIcon from '../../assets/images/icons/dropdown.svg';
import DropdownUpIcon from '../../assets/images/icons/dropdown-up.svg';

import MenuDrawer from '../MenuDrawer';
import SidebarNav from '../SidebarNav';

const NavigationBar = ({
  t,
  history,
  customBack,
  hasBackButton = false,
  hasBreadcrums = false,
  backButtonText = t('common.back'),
  hasCloseButton = false,
  hasHelpButton = false,
  hasHelpButtonOnTitle = false,
  hasMenuButton = false,
  hasEditButton = false,
  customTextButton,
  hasFilterButton = false,
  hasNotificationButton = false,
  moreOptions,
  onMoreOption,
  hasRightComponent,
  hasLeftComponent,
  user,
  onPressProfile,
  onPressEditButton,
  editing,
  onFilter,
  onNotification,
  onHelp,
  onLogout,
  onToggleDrawer,
  onCustomTextButton,
  title,
  subtitle,
  titleComponent,
  hideTitle = false,
  hideFirstBar = false,
  white,
  transparent = false,
  barColor = '#fff',
  topGapBar = false,
  menuList,
  onPressEditProfile,
  tabs,
  pageContent,
  titleDescription,
  xl,
  lg,
  md,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentMenuDrawerWidth, setCurrentMenuDrawerWidth] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contentButtonsWidth, setContentButtonsWidth] = useState(null);
  const [contentSecondBarButtonsWidth, setContentSecondBarButtonsWidth] = useState(null);
  const { openSidebar } = useSelector((state) => state.modals);
  const inContracts =
    (history?.location?.pathname || '').indexOf('/contracts') > -1 &&
    (history?.location?.pathname || '').indexOf('general-information') <= 0;
  const globalLang = useSelector((state) => state.serviceWorker.lang);

  const { contractSelection } = useSelector((state) => state.contracts);

  useEffect(() => {
    const rightWidth = 0;
    let leftWidth = 0;

    let secondRightWidth = 0;
    let secondLeftWidth = 0;

    if (hasBackButton) {
      secondLeftWidth += 50;
    }
    if (hasCloseButton) {
      secondLeftWidth += 50;
    }
    if (hasMenuButton) {
      leftWidth += 50;
    }
    if (hasHelpButton) {
      secondRightWidth += 50;
    }
    if (hasEditButton) {
      secondRightWidth += 100;
    }
    if (customTextButton) {
      secondRightWidth += 150;
    }
    // if (hasFilterButton) {
    //   rightWidth += 50;
    // }
    // if (hasNotificationButton) {
    //   rightWidth += 50;
    // }
    if (moreOptions) {
      secondRightWidth += 50;
    }
    setContentButtonsWidth(rightWidth > leftWidth ? rightWidth : leftWidth);
    setContentSecondBarButtonsWidth(
      secondRightWidth > secondLeftWidth ? secondRightWidth : secondLeftWidth
    );
  }, [
    contentButtonsWidth,
    hasBackButton,
    hasCloseButton,
    hasHelpButton,
    hasMenuButton,
    hasEditButton,
    customTextButton,
    hasFilterButton,
    hasNotificationButton,
    moreOptions,
  ]);

  useEffect(() => {
    if (onToggleDrawer) {
      onToggleDrawer(openDrawer);
    }
  }, [openDrawer, onToggleDrawer, currentMenuDrawerWidth]);

  useEffect(() => {
    /**
     * Function to get the initial value
     * of contract ID to know if it's
     * necessary call the endpoint to get
     * info of contract.
     */
    if (inContracts) {
      const searchParams = new URLSearchParams(history?.location?.search);
      const idContractSearch = searchParams.get('contractId');
      localStorage.removeItem('selected_contract');
      localStorage.removeItem('persist:root');
      if (idContractSearch) {
        dispatch(ContractsActions.resetGetUserContract());
        dispatch(ContractsActions.getUserContract(idContractSearch));
        dispatch(InvitesActions.getInvites(idContractSearch));
      } else if (contractSelection) {
        history.replace(`${history?.location?.pathname}?contractId=${contractSelection.id}`);
      }
    }
  }, [history?.location?.search]);

  const onLogoutAction = () => {
    if (onLogout) {
      onLogout();
    } else {
      dispatch(AuthActions.logout());
    }
  };

  const clickNotification = (notification) => {
    if (
      notification?.type?.includes('Nomination Task') ||
      notification?.type?.includes('Delivery Task') ||
      notification?.type?.includes('General Task')
    ) {
      history.push(
        `/contracts/workflows/off-take-schedule-detail?contractId=${notification.modelId}`
      );
    } else if (notification.type.includes('other terms')) {
      history.push(`/contracts/other-terms?contractId=${notification.modelId}`);
    } else if (notification.type.includes('Off-Take Schedule')) {
      history.push(`/contracts/off-take-schedule?contractId=${notification.modelId}`);
    } else if (notification.type.includes('payment instructions')) {
      history.push(`/contracts/payment-instructions?contractId=${notification.modelId}`);
    } else if (notification?.modelId) {
      history.push(`/contracts/details?contractId=${notification.modelId}`);
    }
  };

  const onPressEditProfileAction = () => {
    if (onPressEditProfile) {
      onPressEditProfile();
    } else {
      history.push('/profile');
    }
  };

  const changeLang = (event) => {
    dispatch(ServsActions.changelang(event.target.value));
  };

  const drawHelp = () => {
    return (
      <Box className={styles.icon_container}>
        <Button
          data-help="Clicked Help Info"
          inverted
          clean
          labelStyle={{ justifyContent: 'center' }}
          onPress={() => {
            if (onHelp) {
              onHelp();
            }
          }}
        >
          <SvgIcon viewBox="0 0 25 25" component={HelpIcon} style={{ color: '#000000' }} />
        </Button>
      </Box>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, item) => {
    setAnchorEl(null);
    if (onMoreOption) {
      onMoreOption(item);
    }
  };

  const drawMore = () => {
    return (
      <Box className={styles.icon_container}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {moreOptions.map((item, index) => (
            <MenuItem key={index} onClick={(event) => handleClose(event, item)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
        {isMobile && (
          <MatButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            data-help="Clicked More"
            onClick={handleClick}
          >
            <SvgIcon viewBox="-5 0 5 23" component={MoreIcon} style={{ color: '#000000' }} />
          </MatButton>
        )}
      </Box>
    );
  };

  const secondBar = () => {
    if (
      hasBackButton ||
      hasBreadcrums ||
      hasCloseButton ||
      hasEditButton ||
      customTextButton ||
      hasHelpButton ||
      isMobile
    ) {
      return (
        <Box
          className={styles.second_bar}
          style={{
            backgroundColor: 'red', //  transparent ? 'transparent' : '#fff',
            width: `calc(100% - ${contentButtonsWidth * 2}px)`,
            paddingLeft: isMobile ? 0 : openSidebar && inContracts ? 215 : inContracts ? 36 : 0,
            pointerEvents: 'none',
          }}
        >
          <Box className={styles.left_buttons} style={{ width: contentSecondBarButtonsWidth }}>
            {hasBreadcrums && (
              <Box className={styles.breadcrums}>
                {hasBreadcrums.map((item, index) => {
                  if (item.active) {
                    return <Box className={styles.active}>{item.title}</Box>;
                  }

                  return (
                    <>
                      <Button clean inverted onPress={item.action}>
                        {item.title}
                      </Button>
                      <Box>{'\\\\'}</Box>
                    </>
                  );
                })}
              </Box>
            )}

            {hasBackButton && (
              <Box className={styles.icon_container}>
                <Button
                  align="center"
                  padding="20px"
                  margin={0}
                  clean
                  inverted
                  style={{ width: !isMobile ? 'max-content' : '100%' }}
                  onPress={() => {
                    customBack ? customBack() : history.goBack();
                  }}
                >
                  <SvgIcon component={BackIcon} />
                  {!isMobile && backButtonText}
                </Button>
              </Box>
            )}
            {hasCloseButton && (
              <Box className={styles.icon_container}>
                <Button
                  align="left"
                  clean
                  onPress={() => {
                    customBack ? customBack() : history.goBack();
                  }}
                >
                  <CloseIcon style={{ color: '#000000' }} />
                </Button>
              </Box>
            )}
            {hasLeftComponent && hasLeftComponent}
          </Box>
          {!hideTitle && (
            <Box
              className={styles.container_logo}
              style={isMobile && subtitle ? { position: 'relative' } : {}}
            >
              {titleComponent || title ? (
                <Box className={styles.container_title}>
                  <Box
                    component="span"
                    className={styles.title}
                    style={{ display: 'flex', flexDirection: 'column', color: '#2f2f2f' }}
                  >
                    {title}
                    {isMobile && subtitle && (
                      <Box
                        component="span"
                        style={{
                          color: '#757e95',
                          fontFamily: 'FuturaPT',
                          fontSize: 15,
                          fontWeight: 'normal',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          letterSpacing: 'normal',
                          textAlign: 'center',
                        }}
                        className={styles.subtitle}
                      >
                        {subtitle}
                      </Box>
                    )}
                  </Box>
                  {hasHelpButtonOnTitle && drawHelp()}
                </Box>
              ) : (
                <Logo small={!!isMobile} />
              )}
            </Box>
          )}
          <Box className={styles.right_buttons} style={{ width: contentSecondBarButtonsWidth }}>
            {hasRightComponent && hasRightComponent}
            {hasEditButton && (
              <Box className={styles.icon_container}>
                <Button
                  data-suggestions-edit="Edit profile"
                  clean
                  inverted
                  onPress={() => {
                    if (onPressEditButton) {
                      onPressEditButton();
                    }
                  }}
                >
                  {!editing ? t('common.edit') : <ClearIcon style={{ color: '#000000' }} />}
                </Button>
              </Box>
            )}
            {customTextButton && (
              <Box className={styles.icon_container}>
                <Button
                  style={{
                    fontFamily: 'FuturaPT-Demi',
                    lineHeight: 0.93,
                    letterSpacing: 0.75,
                    textAlign: 'right',
                  }}
                  fontSize={15}
                  clean
                  inverted
                  onPress={() => {
                    if (onCustomTextButton) {
                      onCustomTextButton();
                    }
                  }}
                >
                  {customTextButton}
                </Button>
              </Box>
            )}
            {hasHelpButton && drawHelp()}
            {moreOptions && drawMore()}
          </Box>
        </Box>
      );
    }
  };

  const tabBar = () => {
    if (tabs?.length > 0) {
      return (
        <Box
          className={styles.tab_bar}
          style={{ backgroundColor: isMobile ? '#fff' : 'transparent' }}
        >
          <Box>
            <AntTabs value={0} centered>
              {tabs?.map((item, index) => (
                <AntTab key={index} label={item} />
              ))}
            </AntTabs>
          </Box>
        </Box>
      );
    }
  };

  const ProfileMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const menuItems = [
      { label: 'My portfolio', onClick: () => history.push('/home') },
      { label: 'Archived contracts', onClick: () => history.push('/home') },
      { label: 'Sign out', onClick: () => dispatch(AuthActions.logout()) },
    ];
    return (
      <>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          variant="menu"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          classes={{ paper: styles.profile_menu_top }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box className={styles.profile_menu}>
            <Grid container spacing={2}>
              <Grid
                style={{ borderBottom: '1px solid #e5e5e5' }}
                item
                xs={12}
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <ProfileData
                  edit
                  uploadPhoto
                  photoBorder={styles.profile_data_photo_border}
                  uploadPhotoClassName={styles.upload_photo}
                  initials={user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
                  username={`${user.firstName} ${user.lastName}`}
                  email={user.email}
                  photo={user.profile_url}
                  loadingPhoto={false}
                  color="#fff"
                  onPressEdit={() => history.push('/profile')}
                  onSelectImage={(file) => dispatch(AuthActions.uploadProfilePicture(file))}
                  showData={false}
                />
                <Box mt={3} className={styles.item}>
                  {`${user.firstName} ${user.lastName}`.toUpperCase()}
                </Box>
                <Box className={styles.view_profile} onClick={() => history.push('/profile')}>
                  {'view profile'.toUpperCase()}
                </Box>
                <Box className={styles.version}>Version {global.appVersion}</Box>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="column"
                alignItems="center"
                justify="space-around"
              >
                {menuItems?.map((item, index) => (
                  <Box
                    key={`profile-menu-item-${index}`}
                    className={styles.item}
                    onClick={item.onClick}
                  >
                    {item.label?.toUpperCase()}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Menu>
        <MatButton
          size="small"
          className={styles.more_button}
          aria-controls="profile-menu"
          aria-haspopup="true"
          data-help="Clicked More"
          onClick={handleClick}
        >
          <Avatar src={user?.profileImage} className={styles.avatar}>
            {user?.firstName?.substring(0, 1).toUpperCase() || 'H'}
          </Avatar>
          <SvgIcon
            viewBox="0 0 7 4"
            component={anchorEl ? DropdownUpIcon : DropdownIcon}
            style={{ width: 7, height: 4, marginLeft: 10 }}
          />
        </MatButton>
      </>
    );
  };

  const getDateLabel = (date) => {
    const mDate = moment(date);
    if (mDate.isSame(moment(), 'day')) {
      return t('common.today').toUpperCase();
    }
    if (mDate.isSame(moment().subtract(1, 'day'), 'day')) {
      return t('common.yesterday').toUpperCase();
    }
    return mDate.format('DD MMM');
  };

  const NotificationsMenu = () => {
    const tabs = [t('common.all'), t('common.contracts'), t('common.deliveries')];
    const [anchorEl, setAnchorEl] = useState(null);
    const [params, setParams] = useState({ page: 1, limit: 10 });
    const [selectedTab, setSelectedTab] = useState(t('common.all'));
    const [currentNotifications, setCurrentNotifications] = useState([]);
    const [push, setPush] = useState(false);

    const {
      pushNotification,
      notification,
      pagination,
      notifications,
      getNotificationsCalled,
      getNotificationsLoading,
      getNotificationsError,
    } = useSelector((state) => state.notifications);

    const infiniteRef = useInfiniteScroll({
      getNotificationsLoading,
      hasNextPage: true,
      onLoadMore: handleLoadMore,
      scrollContainer: 'parent',
    });

    function handleLoadMore() {
      if (!getNotificationsLoading && !getNotificationsError) {
        setParams({ page: pagination.page + 1, limit: 10 });
        dispatch(NotificationActions.getNotifications({ page: pagination.page + 1, limit: 10 }));
      }
    }

    useEffect(() => {
      setPush(pushNotification);
      if (!getNotificationsLoading && !notifications) {
        dispatch(NotificationActions.getNotifications(params));
      }
    }, [notifications, getNotificationsCalled, getNotificationsLoading, pushNotification]);

    useEffect(() => {}, [selectedTab, getNotificationsLoading]);

    useEffect(() => {
      if (notifications && notifications.length) {
        const dateFunc = (item) => moment(item.createdAt).format('DD MMM YYYY');
        const mutableNotifications = Array.from(notifications);
        if (notification) {
          mutableNotifications.unshift({
            ...notification,
            push: true,
          });
        }
        const n = groupBy(
          mutableNotifications.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          ),
          dateFunc
        );
        const values = Object.entries(n).map((entry) => {
          return { date: entry[0], values: entry[1] };
        });
        setCurrentNotifications(values);
      }
    }, [notifications, selectedTab, notification]);

    useEffect(() => {
      currentNotifications;
    }, [currentNotifications]);

    const handleClick = (event) => {
      dispatch(NotificationActions.getNotifications(params));
      dispatch(NotificationActions.clearPushNotification());
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const selectTab = (tab) => {
      setSelectedTab(tab);
      if (tab !== 'All') {
        params.category = tab.toLowerCase();
      } else {
        delete params.category;
      }
      params.page = 1;
      setParams({ ...params });
      if (!getNotificationsLoading) {
        dispatch(NotificationActions.getNotifications(params));
      }
    };

    return (
      <>
        <Menu
          id="notifications-menu"
          anchorEl={anchorEl}
          keepMounted
          variant="menu"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          classes={{ paper: styles.notifications_menu_top, list: styles.notifications_list_margin }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box className={styles.notifications_menu}>
            <Grid container style={{ height: '100%' }}>
              <Grid
                style={{ borderBottom: '1px solid #e5e5e5' }}
                item
                xs={12}
                container
                direction="column"
                alignItems="center"
              />

              <Grid
                item
                xs={12}
                container
                justify="center"
                style={{
                  padding: 20,
                  fontWeight: 'bold',
                  borderBottom: '0.5px solid lightgray',
                }}
              >
                {t('common.notifications')}
              </Grid>
              <Grid item xs={12} className={styles.notificationsTabs}>
                {tabs?.map((tab, index) => (
                  <Box
                    key={`tab-${index}`}
                    className={clsx(styles.tab, selectedTab === tab ? styles.active_tab : '')}
                    onClick={() => selectTab(tab)}
                  >
                    {tab}
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} style={{ height: 'calc(100% - 121px)' }}>
                {getNotificationsLoading && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="inherit" />
                  </div>
                )}
                <Box style={{ height: '100%', overflow: 'scroll' }}>
                  <Box ref={infiniteRef}>
                    {currentNotifications?.map((item, index) => (
                      <Box key={`notification-container-${index}`}>
                        <Box
                          className={styles.date_label}
                          display="flex"
                          justifyContent="center"
                          py={2}
                        >
                          {getDateLabel(item?.date)}
                        </Box>
                        {item?.values?.map((value, index) => (
                          <Box
                            key={`notification-${index}`}
                            className={styles.notificationsItemCard}
                            style={{
                              backgroundColor: value.push ? 'rgba(76, 72, 143, 0.15)' : 'white',
                            }}
                            onClick={() => {
                              clickNotification(value);
                            }}
                          >
                            <Avatar src={value?.fromUser?.profileImage} className={styles.avatar}>
                              {value?.fromUser?.firstName?.substring(0, 1).toUpperCase() || 'H'}
                            </Avatar>
                            <Box className={styles.notificationsItemCardInfo}>
                              <Box className={styles.contractName}>
                                {value?.title || 'Contract Name'}
                                <Box className={styles.time}>
                                  {moment(value?.createdAt).format('HH:mm')}
                                </Box>
                              </Box>
                              <Box className={styles.name}>
                                {`${value?.fromUser?.firstName || ''} ${
                                  value?.fromUser?.lastName || ''
                                }`}
                              </Box>
                              <Box className={styles.description}>{value?.description}</Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Menu>
        <button
          type="button"
          aria-controls="notifications-menu"
          aria-haspopup="true"
          data-help="Clicked More"
          className={styles.notificationsButton}
          onClick={handleClick}
        >
          {white || !isMobile ? (
            <Box>
              <SvgIcon component={NotificationIconWhite} viewBox="0 0 22 22" />
              {push && (
                <Box
                  style={{
                    top: 19,
                    right: 18,
                    position: 'absolute',
                    borderRadius: '50%',
                    width: 8,
                    height: 8,
                    backgroundColor: '#4c488f',
                    border: 'solid 1px #fff',
                  }}
                />
              )}
            </Box>
          ) : (
            <SvgIcon component={NotificationIcon} viewBox="0 0 22 22" />
          )}
        </button>
      </>
    );
  };

  const NotificationsComponent = () => {
    const {
      pushNotification,
      notification,
      notifications,
      getNotificationsCalled,
      getNotificationsLoading,
    } = useSelector((state) => state.notifications);
    const [params, setParams] = useState({ page: 1, limit: 8 });
    const [currentNotifications, setCurrentNotifications] = useState([]);

    useEffect(() => {
      if (!getNotificationsCalled && !getNotificationsLoading) {
        dispatch(NotificationActions.getNotifications(params));
      }
    }, [params]);

    useEffect(() => {
      if (notifications && notifications.length) {
        const dateFunc = (item) => moment(item.createdAt).format('DD MMM YYYY');
        const mutableNotifications = Array.from(notifications);
        if (notification) {
          mutableNotifications.unshift({
            ...notification,
            push: pushNotification,
          });
        }
        const n = groupBy(
          mutableNotifications
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .slice(0, params?.limit),
          dateFunc
        );
        const values = Object.entries(n).map((entry) => {
          return { date: entry[0], values: entry[1] };
        });
        setCurrentNotifications(values);
      }
    }, [notifications, notification, pushNotification]);

    const NotificationsComponentItem = ({ push, open = false, notification }) => {
      const [openCard, setOpenCard] = useState(open);
      const [animation, setAnimation] = useState(push);

      const handleOpen = () => {
        setOpenCard((prevstate) => !prevstate);
        if (animation) {
          setAnimation(false);
        }
      };

      const UserName = ({ user = {} }) => {
        return user?.firstName && user?.lastName
          ? `${user?.firstName} ${user?.lastName}`
          : 'Contract Buyer';
      };
      return (
        <Box className={styles.notificationsItemContainer}>
          {openCard ? (
            <Box className={clsx(styles.notificationsItemCardTimeLine)}>
              <Avatar src={notification?.fromUser?.profileImage} className={styles.avatar}>
                {notification?.fromUser?.firstName?.substring(0, 1).toUpperCase() || 'H'}
              </Avatar>
              <Box
                className={styles.notificationsItemCardInfo}
                onClick={() => {
                  clickNotification(notification);
                }}
              >
                <Box className={styles.contractName}>
                  {notification?.title}
                  <Box className={styles.time}>
                    {moment(notification?.createdAt).format('HH:mm')}
                  </Box>
                </Box>
                <Box className={styles.name}>
                  <UserName user={notification?.fromUser} />
                </Box>
                <Box className={styles.description}>{notification?.description}</Box>
              </Box>
            </Box>
          ) : (
            <Box className={styles.notificationsItemText}>{notification?.type}</Box>
          )}
          <Box
            className={clsx(
              styles.notificationsItemIcon,
              push && animation ? styles.notificationsItemIconPush : ''
            )}
            onClick={handleOpen}
          >
            {openCard ? <ClearIcon /> : <ChevronLeftIcon />}
          </Box>
        </Box>
      );
    };
    return (
      <Box className={styles.notificationsComponent}>
        {currentNotifications?.map((n, nIndex) => (
          <Box className={styles.notificationsItem} key={`date-index-${nIndex}`}>
            <Box className={styles.notificationsItemTitle}>{getDateLabel(n?.date)}</Box>
            {n?.values.map((value, vIndex) => (
              <NotificationsComponentItem
                key={`value-index-${vIndex}`}
                push={value?.push}
                animation={value?.animation}
                notification={value}
              />
            ))}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className={pageContent ? styles.container_full : null}>
      <Box
        className={styles.container}
        style={{
          transform: openDrawer ? 'scale(0.7)' : 'none',
          WebkitTransformOriginX: openDrawer
            ? currentMenuDrawerWidth + (isMobile ? 706 : 1020)
            : 'none',
        }}
      >
        <Box
          className={styles.full_bar}
          style={{
            backgroundColor: transparent ? 'transparent' : 'none',
          }}
        >
          <Box
            style={{
              backgroundColor: transparent ? 'transparent' : barColor,
            }}
            className={styles.first_bar}
          >
            <Box
              style={{
                marginTop: topGapBar ? 43 : 0,
                backgroundColor: transparent ? 'transparent' : barColor,
              }}
              className={styles.bar}
            >
              {hasMenuButton && !hideFirstBar && (
                <Box
                  className={styles.left_buttons}
                  style={{ width: isTablet ? '' : contentButtonsWidth }}
                >
                  {(isMobile || isTablet) && (
                    <Button
                      align="left"
                      padding={0}
                      margin={0}
                      clean
                      className={styles.icon_container}
                      onPress={() => setOpenDrawer(!openDrawer)}
                    >
                      {white ? (
                        <SvgIcon component={MenuIconWhite} viewBox="0 0 30 19" />
                      ) : (
                        <SvgIcon component={MenuIcon} viewBox="0 0 30 19" />
                      )}
                    </Button>
                  )}
                  {!isMobile && (
                    <Box className={styles.icon_container} style={{ marginLeft: 30 }}>
                      {(!inContracts || isTablet) && (
                        <Box className={styles.container_logo}>
                          {titleComponent || title ? (
                            <Box className={styles.container_title}>
                              <Box
                                component="span"
                                style={{ display: 'flex', flexDirection: 'column', color: 'white' }}
                                className={styles.title}
                              >
                                {title}
                                {isTablet && subtitle && (
                                  <Box
                                    component="span"
                                    style={{ color: '#c9ccda' }}
                                    className={styles.subtitle}
                                  >
                                    {subtitle}
                                  </Box>
                                )}
                              </Box>
                              {hasHelpButtonOnTitle && drawHelp()}
                            </Box>
                          ) : (
                            <Logo
                              small
                              white={white}
                              style={{ cursor: 'pointer' }}
                              onClick={(event) => {
                                history.replace('/');
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
              {isMobile && secondBar()}
              {!isMobile && tabBar()}
              <Box className={styles.right_buttons} style={{ width: contentButtonsWidth }}>
                {/* {hasFilterButton && (
                  <Button
                    align="left"
                    padding={0}
                    margin={0}
                    clean
                    className={styles.icon_container}
                    onPress={() => {
                      if (onFilter) {
                        onFilter();
                      }
                    }}
                  >
                    {white ? (
                      <SvgIcon component={SearchIconWhite} viewBox="0 0 24 24" />
                    ) : (
                      <SvgIcon component={SearchIcon} />
                    )}
                  </Button>
                )} */}
                {!isMobile && !isTablet && (
                  <>
                    <Button
                      align="left"
                      padding={0}
                      margin={0}
                      clean
                      className={styles.supportButton}
                      onPress={() => {
                        window.open(
                          'https://inquari.zendesk.com/hc/en-us/sections/360008545253-FAQ',
                          '_blank'
                        );
                      }}
                    >
                      <Laptop />
                      {t('common.clientSupport')}
                    </Button>
                    <Select
                      value={globalLang}
                      onChange={changeLang}
                      className={styles.selectLang}
                      disableUnderline
                      IconComponent={ExpandMore}
                    >
                      <MenuItem value="en">English</MenuItem>
                      <MenuItem value="pt">Portuguese</MenuItem>
                      <MenuItem value="es">Spanish</MenuItem>
                    </Select>
                  </>
                )}

                {!isMobile && !isTablet && user && <ProfileMenu />}
                {!isMobile && hasNotificationButton && (
                  <>
                    <NotificationsMenu />
                    <NotificationsComponent />
                  </>
                )}
              </Box>
            </Box>
          </Box>
          {!isMobile && secondBar()}
          {isMobile && tabBar()}
        </Box>
        {pageContent && (
          <Box
            className={styles.container_page}
            style={{
              marginTop: (isMobile && tabs?.length) > 0 ? 120 : 70,
              paddingRight: isMobile ? 0 : isTablet ? 10 : md && !lg ? 50 : lg || xl ? 120 : 320,
              paddingLeft: isMobile ? 0 : isTablet ? 10 : openSidebar && inContracts ? 320 : 50,
            }}
          >
            {titleDescription && (
              <Box
                className={styles.titleDescriptionCont}
                style={{
                  alignItems: titleDescription?.position
                    ? titleDescription?.position
                    : 'flex-start',
                }}
              >
                {inContracts && (isMobile || isTablet) && (
                  <Box className={styles.breadcrums} display="inline">
                    Portfolio // {contractSelection?.name}
                  </Box>
                )}
                <p className="title_container">{titleDescription.title}</p>
                <p className="description_container">{titleDescription.description}</p>
              </Box>
            )}
            {pageContent}
          </Box>
        )}
      </Box>
      {hasMenuButton && user && (
        <MenuDrawer
          list={menuList}
          user={user}
          open={openDrawer}
          onLogout={onLogoutAction}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
          onPressProfile={onPressProfile}
          onPressEditProfile={onPressEditProfileAction}
          currentWidthChanged={(width) => setCurrentMenuDrawerWidth(width)}
        />
      )}
      {inContracts && contractSelection && (
        <SidebarNav openSidebar={openSidebar} history={history} />
      )}
    </Box>
  );
};

NavigationBar.propTypes = {
  customBack: PropTypes.func,
};

export default withTranslation()(NavigationBar);
