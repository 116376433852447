import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Divider } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import DoneIcon from '@material-ui/icons/Done';

import clsx from 'clsx';
import moment from 'moment';

import NextIcon from '../../assets/images/icons/next.svg';

import styles from './styles.module.sass';

const OffTakeScheduleItem = ({
  t,
  className,
  startDate,
  endDate,
  volume,
  success = false,
  active = false,
  onClick,
}) => {
  return (
    <Box
      className={clsx(active ? styles.offTakeItemActive : styles.offTakeItem, className)}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Box className={styles.date_range}>
        <Box className={styles.date}>
          <Box className={styles.month}>{startDate ? moment(startDate).format('MMM') : ''}</Box>
          <Box className={styles.day}>{startDate ? moment(startDate).format('DD') : ''}</Box>
          <Box className={styles.year}>{startDate ? moment(startDate).format('YYYY') : ''}</Box>
        </Box>
        <SvgIcon className={styles.next} viewBox="0 0 10 17" component={NextIcon} />
        <Box className={styles.date}>
          <Box className={styles.month}>{endDate ? moment(endDate).format('MMM') : ''}</Box>
          <Box className={styles.day}>{endDate ? moment(endDate).format('DD') : ''}</Box>
          <Box className={styles.year}>{endDate ? moment(endDate).format('YYYY') : ''}</Box>
        </Box>
      </Box>
      <Box className={styles.volume}>
        <Box className={styles.status}>
          <Divider className={styles.divider} orientation="vertical" flexItem light />
          {success && (
            <Box className={styles.circle} style={{ backgroundColor: active ? 'white' : 'black' }}>
              <DoneIcon style={{ color: active ? 'black' : 'white', height: 15, width: 15 }} />
            </Box>
          )}
        </Box>
        <Box className={styles.data}>
          <Box className={styles.label}>Volume:</Box>
          <Box className={styles.text}>{volume || ''}</Box>
          <Box className={styles.label}>MT</Box>
        </Box>
        <SvgIcon className={styles.next} viewBox="0 0 10 17" component={NextIcon} />
      </Box>
    </Box>
  );
};

export default withTranslation()(OffTakeScheduleItem);
