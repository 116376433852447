import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import NavigationBar from '../../../components/NavigationBar';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import ContractInfoCard from '../../../components/ContractInfoCard';

const RolePermissions = ({ t, history }) => {
  const dispatch = useDispatch();

  const [drawerOpened, setDrawerOpened] = useState(false);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  return (
    <Box className={styles.container}>
      <NavigationBar
        title={t('contracts.addCollaborators.rolePermissions.title').toUpperCase()}
        hideTitle={!isMobile}
        hasMenuButton={!isMobile}
        barColor={isMobile ? '#fff' : '#111'}
        white={!isMobile}
        hasBackButton
        hasNotificationButton
        hasFilterButton={!isMobile}
        history={history}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid container className={styles.grid_container} justify="center">
            {!isMobile && (
              <Grid item xs={12} sm={3}>
                <ContractInfoCard />
                <ContractSpecsCard />
              </Grid>
            )}
            <Grid item xs={12} sm={6} container justify="center">
              <Container maxWidth="md" className={styles.content}>
                {!isMobile && (
                  <Box className={styles.container_title}>
                    <Box component="span" style={{ color: '#2f2f2f' }} className={styles.title}>
                      {t('contracts.addCollaborators.rolePermissions.title').toUpperCase()}
                    </Box>
                  </Box>
                )}
                <Box className={styles.container_step_description}>
                  <Box className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.description')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.rolePermissions.commercialOwner').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.commercialOwnerDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t(
                      'contracts.addCollaborators.rolePermissions.commercialExecutive'
                    ).toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.commercialExecutiveDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.rolePermissions.contractAgent').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.contractAgentDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.rolePermissions.exportAgent').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.exportAgentDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.rolePermissions.financier').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.financierDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.rolePermissions.financier2').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.financier2Description')}
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default withTranslation()(RolePermissions);
