import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Link from '@material-ui/core/Link';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import LoginBG from '../../../assets/images/background/loginBG.svg';
import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import Autocomplete from '../../../components/Autocomplete';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const Login = ({ t, history }) => {
  const validationSchema = Yup.object({
    email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
    password: Yup.string().required(t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const { loginCalled, loginLoading, loginError } = useSelector((state) => state.auth);

  const emailVerified = useSelector((state) => state.auth.emailVerified);

  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
    if (loginCalled && loginError === null) {
      if (emailVerified === false) {
        history.replace('/email-verification');
      } else {
        history.replace('/validate-token');
      }
      dispatch(AuthActions.resetLoginState());
    }
  }, [history, loginCalled, loginError, emailVerified, dispatch, formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function handleSubmit(values) {
    dispatch(AuthActions.loginWithEmailAndPassword(values.email, values.password));
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <NavigationBar
        title={isMobile ? ' ' : ''}
        hasBackButton
        hasMenuButton={!isMobile}
        hideFirstBar={!isMobile}
        transparent
        history={history}
        customBack={() => history.replace('/')}
      />
      <Container maxWidth="md" className={styles.content} ref={formBox}>
        <Box className={styles.container_step_description}>
          <Box className="title">{t('common.login')}</Box>
        </Box>
        <Box className={styles.container_form}>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  className={styles.input}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  helperText={errors.email && touched.email && errors.email}
                  required
                  placeholder={t('common.email').charAt(0).toUpperCase() + t('email').slice(1)}
                  label={t('common.email').toUpperCase()}
                />
                <TextField
                  className={styles.input}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  helperText={errors.password && touched.password && errors.password}
                  required
                  type="password"
                  placeholder={
                    t('common.password').charAt(0).toUpperCase() + t('password').slice(1)
                  }
                  label={t('common.password').toUpperCase()}
                />
                {loginError && (
                  <Box className={styles.container_error}>
                    <Box className="error">{loginError?.userMessage}</Box>
                  </Box>
                )}

                <Box className={styles.container_forgot_password}>
                  <Button
                    padding={0}
                    inverted
                    align="right"
                    clean
                    fontSize="14px"
                    onPress={() => history.push('/forgot-password')}
                  >
                    {t('common.forgotPassword')}
                  </Button>
                </Box>
                <Box className={styles.container_submit}>
                  <Button
                    type="submit"
                    loading={loginLoading}
                    disabled={loginLoading}
                    className={styles.button}
                    data-info-submit="Submit"
                    minWidth={130}
                    maxWidth={270}
                  >
                    {t('common.submit')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
      <Box className={styles.version}>
        <Link
          className={styles.link}
          onClick={() => {
            history.push('/privacy');
          }}
        >
          {t('common.privacy')}
        </Link>
        <span className={styles.separator}>&nbsp;</span>
        <Link
          className={styles.link}
          onClick={() => {
            history.push('/terms');
          }}
        >
          {t('common.terms')}
        </Link>
      </Box>
      <Box className={styles.version}>Version {global.appVersion}</Box>

      <Box className={styles.bottom_bg}>
        <SvgIcon component={LoginBG} viewBox="0 0 414 503" />
      </Box>
    </Box>
  );
};

export default withTranslation()(Login);
