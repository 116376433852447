/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import _ from 'lodash';

import styles from './styles.module.sass';

const MiniChart = ({ t, subTasks }) => {
  const [totalDelivered, setTotalDelivered] = useState(0);
  const [totalNominated, setTotalNominated] = useState(0);
  const [completionStatusSlug, setCompletionStatusSlug] = useState('');

  useEffect(() => {
    if (subTasks?.length > 0) {
      const nomination = subTasks?.find((item) => item.type === 'nomination');
      const totalNominated = nomination?.volume;
      const deliveries = subTasks?.filter((item) => item.type === 'delivery');
      const sortDeliveries = [...deliveries].sort((a, b) => {
        const adate = moment(a?.date?.split('T')[0] || '1972-01-01', 'YYYY-MM-DD');
        const bdate = moment(b?.date?.split('T')[0] || '1972-01-01', 'YYYY-MM-DD');
        return adate.diff(bdate, 'days');
      });

      const totalDelivered = deliveries?.reduce((total, item) => {
        return total + item.volume;
      }, 0);
      setTotalDelivered(totalDelivered);
      setTotalNominated(totalNominated);

      if (
        sortDeliveries.length > 0 &&
        moment(sortDeliveries[sortDeliveries.length - 1].completionDate).isBefore(
          moment(nomination.etd)
        )
      ) {
        setCompletionStatusSlug('onTime');
      } else if (totalDelivered === totalNominated) {
        setCompletionStatusSlug('onTime');
      } else if (totalDelivered < totalNominated) {
        setCompletionStatusSlug('behind');
      } else if (
        sortDeliveries.length > 0 &&
        moment(sortDeliveries[sortDeliveries.length - 1].completionDate).isAfter(
          moment(nomination.etd)
        )
      ) {
        setCompletionStatusSlug('behind');
      } else if (totalDelivered > totalNominated) {
        setCompletionStatusSlug('ahead');
      } else {
        setCompletionStatusSlug('onTime');
      }
    }
  }, [subTasks]);

  function getRealNominated() {
    const delivered = totalDelivered || 0;
    const nominated = totalNominated || 0;
    if (delivered > nominated) {
      return 0;
    }
    return nominated - delivered;
  }

  function getStatus() {
    if (completionStatusSlug === 'behind') {
      return t('contracts.behind');
    }
    if (completionStatusSlug === 'onTime') {
      return t('contracts.onTime');
    }
    if (completionStatusSlug === 'ahead') {
      return t('contracts.ahead');
    }
    if (completionStatusSlug === 'completed') {
      return t('contracts.completed');
    }
    return '';
  }

  const renderStatus = () => {
    return <Box className={styles.status}>{getStatus()}</Box>;
  };

  function getBar() {
    let widthDelivered = 0;
    widthDelivered = (totalDelivered * 100) / totalNominated;
    const deliveredPercentage = `${
      widthDelivered > 100 ? 100 : widthDelivered < 0 ? 0 : widthDelivered
    }%`;

    return (
      <Box className={styles.flexRow}>
        <Box
          style={{
            backgroundColor: '#757e95',
            width: deliveredPercentage,
          }}
        />
      </Box>
    );
  }

  const renderDates = () => {
    return (
      <>
        <Box className={styles.rowDates} />
        <Box className={styles.bar}>{getBar()}</Box>
      </>
    );
  };

  return (
    <Box className={styles.chart}>
      {renderStatus()}
      <Box className={styles.time_line} style={{ width: '90%' }}>
        {renderDates()}
      </Box>
      <Box className={[styles.labels_box].join(' ')}>
        <Box className={styles.label_item}>
          <Box className={styles.label_box}>
            <Box className={styles.received_circle} />
          </Box>
          <Box className={styles.label}>{`${totalDelivered} MT`}</Box>
        </Box>
        <Box className={styles.label_item}>
          <Box className={styles.label_box}>
            <Box className={styles.white_circle} />
          </Box>
          <Box className={styles.label}>{`${getRealNominated()} MT`}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(MiniChart);
