import Immutable from 'seamless-immutable';

const initialState = Immutable({
  getTasksLoading: false,
  getTasksError: null,
  getTasksCalled: false,
  tasks: null,

  getTaskLoading: false,
  getTaskError: null,
  getTaskCalled: false,
  subtaskSelection: null,
  parentTaskSelection: null,

  getSubtaskLoading: false,
  getSubtaskError: null,
  getSubtaskCalled: false,

  offTakeScheduleSelection: null,
  offTaskSelection: null,
  usersInviteTask: null,

  saveTaskFormData: null,
  saveTaskLoading: false,
  saveTaskError: null,
  saveTaskCalled: false,
  saveTaskSuccess: false,
  updateTaskSuccess: false,

  deleteTaskLoading: false,
  deleteTaskError: null,
  deleteTaskCalled: false,
  deleteTaskSuccess: false,

  updateRequirementTaskLoading: false,
  updateRequirementTaskError: null,
  updateRequirementTaskCalled: false,
  updateRequirementTaskSuccess: false,

  updateTaskStateLoading: false,
  updateTaskStateError: null,
  updateTaskStateCalled: false,
  updateTaskStateSuccess: false,

  updateDocumentStateLoading: false,
  updateDocumentStateError: null,
  updateDocumentStateCalled: false,
  updateDocumentStateSuccess: false,

  uploadDocumentLoading: false,
  uploadDocumentError: null,
  uploadDocumentCalled: false,
  uploadDocumentSuccess: false,

  deleteDocumentLoading: false,
  deleteDocumentError: null,
  deleteDocumentCalled: false,
  deleteDocumentSuccess: false,
});

export default initialState;
