import WorkflowActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    //  GET
    case WorkflowActions.getTasksRequest().type:
      return state.merge({
        getTasksLoading: true,
        getTasksError: null,
        getTasksCalled: true,
      });

    case WorkflowActions.getTasksSuccess().type:
      return state.merge({
        getTasksLoading: false,
        getTasksError: null,
        getTasksCalled: true,

        tasks: action.payload.tasks,
      });

    case WorkflowActions.getTasksFailed().type:
      return state.merge({
        getTasksLoading: false,
        getTasksError: action.payload.error,
        getTasksCalled: true,
      });

    case WorkflowActions.resetGetTasks().type:
      return state.merge({
        getTasksLoading: false,
        getTasksError: null,
        getTasksCalled: false,
        // tasks: null,
      });

    case WorkflowActions.resetGetTasksError().type:
      return state.merge({
        getTasksLoading: false,
        getTasksError: null,
        getTasksCalled: true,
      });

    case WorkflowActions.resetGetTasksAll().type:
      return state.merge({
        tasks: null,
      });

    //  GET
    case WorkflowActions.getTaskRequest().type:
      return state.merge({
        getTaskLoading: true,
        getTaskError: null,
        getTaskCalled: true,
      });

    case WorkflowActions.getTaskSuccess().type:
      return state.merge({
        getTaskLoading: false,
        getTaskError: null,
        getTaskCalled: true,

        taskSelection: action.payload.task,
      });

    case WorkflowActions.getTaskFailed().type:
      return state.merge({
        getTaskLoading: false,
        getTaskError: action.payload.error,
        getTaskCalled: true,
      });

    case WorkflowActions.resetGetTask().type:
      return state.merge({
        getTaskLoading: false,
        getTaskError: null,
        getTaskCalled: false,
      });

    case WorkflowActions.resetGetTaskError().type:
      return state.merge({
        getTaskLoading: false,
        getTaskError: null,
        getTaskCalled: true,
      });

    //  GET SUBTASK
    case WorkflowActions.getSubtaskRequest().type:
      return state.merge({
        getSubtaskLoading: true,
        getSubtaskError: null,
        getSubtaskCalled: true,
      });

    case WorkflowActions.getSubtaskSuccess().type:
      return state.merge({
        getSubtaskLoading: false,
        getSubtaskError: null,
        getSubtaskCalled: true,

        subtaskSelection: action.payload.task,
      });

    case WorkflowActions.getSubtaskFailed().type:
      return state.merge({
        getSubtaskLoading: false,
        getSubtaskError: action.payload.error,
        getSubtaskCalled: true,
      });

    case WorkflowActions.resetGetSubtask().type:
      return state.merge({
        getSubtaskLoading: false,
        getSubtaskError: null,
        getSubtaskCalled: false,
      });

    case WorkflowActions.resetGetSubtaskError().type:
      return state.merge({
        getSubtaskLoading: false,
        getSubtaskError: null,
        getSubtaskCalled: true,
      });

    //  CREATE

    case WorkflowActions.saveTaskFormData().type: {
      return state.merge({
        saveTaskFormData: action.payload.formData,
      });
    }

    case WorkflowActions.resetTaskFormData().type: {
      return state.merge({
        saveTaskFormData: null,
      });
    }

    case WorkflowActions.saveTaskRequest().type:
      return state.merge({
        saveTaskLoading: true,
        saveTaskError: null,
        saveTaskCalled: true,
        saveTaskSuccess: false,
        updateTaskSuccess: false,
      });

    case WorkflowActions.saveTaskSuccess().type:
      return state.merge({
        saveTaskFormData: null,
        saveTaskLoading: false,
        saveTaskError: null,
        saveTaskCalled: true,
        saveTaskSuccess: true,
        updateTaskSuccess: false,
      });

    case WorkflowActions.saveTaskFailed().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: action.payload.error,
        saveTaskCalled: true,
        saveTaskSuccess: false,
        updateTaskSuccess: false,
      });

    case WorkflowActions.resetSaveTask().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: null,
        saveTaskCalled: false,
        saveTaskSuccess: false,
        updateTaskSuccess: false,

        usersInviteTask: null,
      });

    case WorkflowActions.resetSaveTaskError().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: null,
        saveTaskCalled: true,
        saveTaskSuccess: false,
        updateTaskSuccess: false,

        usersInviteTask: null,
      });

    //  UPDATE
    case WorkflowActions.updateTaskRequest().type:
      return state.merge({
        saveTaskLoading: true,
        saveTaskError: null,
        saveTaskCalled: true,
        saveTaskSuccess: false,
        updateTaskSuccess: false,
      });

    case WorkflowActions.updateTaskSuccess().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: null,
        saveTaskCalled: true,
        saveTaskSuccess: true,
        updateTaskSuccess: true,
      });

    case WorkflowActions.updateTaskFailed().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: action.payload.error,
        saveTaskCalled: true,
        saveTaskSuccess: false,
        updateTaskSuccess: false,
      });

    case WorkflowActions.resetUpdateTask().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: null,
        saveTaskCalled: false,
        updateTaskSuccess: false,

        usersInviteTask: null,
      });

    case WorkflowActions.resetUpdateTaskError().type:
      return state.merge({
        saveTaskLoading: false,
        saveTaskError: null,
        saveTaskCalled: true,
        updateTaskSuccess: false,

        usersInviteTask: null,
      });

    //  DELETE
    case WorkflowActions.deleteTaskRequest().type:
      return state.merge({
        deleteTaskLoading: true,
        deleteTaskError: null,
        deleteTaskCalled: true,
        deleteTaskSuccess: false,
      });

    case WorkflowActions.deleteTaskSuccess().type:
      return state.merge({
        deleteTaskLoading: false,
        deleteTaskError: null,
        deleteTaskCalled: true,
        deleteTaskSuccess: true,
      });

    case WorkflowActions.deleteTaskFailed().type:
      return state.merge({
        deleteTaskLoading: false,
        deleteTaskError: action.payload.error,
        deleteTaskCalled: true,
        deleteTaskSuccess: false,
      });

    case WorkflowActions.resetDeleteTask().type:
      return state.merge({
        deleteTaskLoading: false,
        deleteTaskError: null,
        deleteTaskCalled: false,
        deleteTaskSuccess: false,

        usersInviteTask: null,
      });

    case WorkflowActions.resetDeleteTaskError().type:
      return state.merge({
        deleteTaskLoading: false,
        deleteTaskError: null,
        deleteTaskCalled: true,
        deleteTaskSuccess: false,

        usersInviteTask: null,
      });

    //  UPDATE REQUIREMENTS
    case WorkflowActions.updateRequirementTaskRequest().type:
      return state.merge({
        updateRequirementTaskLoading: true,
        updateRequirementTaskError: null,
        updateRequirementTaskCalled: true,
        updateRequirementTaskSuccess: false,
      });

    case WorkflowActions.updateRequirementTaskSuccess().type:
      return state.merge({
        updateRequirementTaskLoading: false,
        updateRequirementTaskError: null,
        updateRequirementTaskCalled: true,
        updateRequirementTaskSuccess: true,
      });

    case WorkflowActions.updateRequirementTaskFailed().type:
      return state.merge({
        updateRequirementTaskLoading: false,
        updateRequirementTaskError: action.payload.error,
        updateRequirementTaskCalled: true,
        updateRequirementTaskSuccess: false,
      });

    case WorkflowActions.resetUpdateRequirementTask().type:
      return state.merge({
        updateRequirementTaskLoading: false,
        updateRequirementTaskError: null,
        updateRequirementTaskCalled: false,
        updateRequirementTaskSuccess: false,

        usersInviteTask: null,
      });

    case WorkflowActions.resetUpdateRequirementTaskError().type:
      return state.merge({
        updateRequirementTaskLoading: false,
        updateRequirementTaskError: null,
        updateRequirementTaskCalled: true,
        updateRequirementTaskSuccess: false,

        usersInviteTask: null,
      });

    case WorkflowActions.updateOffTakeScheduleSelection().type:
      return state.merge({
        offTakeScheduleSelection: action.payload.offTakeSchedule,
      });

    case WorkflowActions.updateTaskSelection().type:
      return state.merge({
        taskSelection: action.payload.task,
      });

    case WorkflowActions.updateSubtaskSelection().type:
      return state.merge({
        subtaskSelection: action.payload.task,
      });

    case WorkflowActions.updateParentTaskSelection().type:
      return state.merge({
        parentTaskSelection: action.payload.task,
      });

    case WorkflowActions.updateUsersInviteTask().type:
      return state.merge({
        usersInviteTask: action.payload.users,
      });

    //  STATE
    case WorkflowActions.updateTaskStateRequest().type:
      return state.merge({
        updateTaskStateLoading: true,
        updateTaskStateError: null,
        updateTaskStateCalled: true,
        updateTaskStateSuccess: false,
      });

    case WorkflowActions.updateTaskStateSuccess().type:
      return state.merge({
        updateTaskStateLoading: false,
        updateTaskStateError: null,
        updateTaskStateCalled: true,
        updateTaskStateSuccess: true,
      });

    case WorkflowActions.updateTaskStateFailed().type:
      return state.merge({
        updateTaskStateLoading: false,
        updateTaskStateError: action.payload.error,
        updateTaskStateCalled: true,
        updateTaskStateSuccess: false,
      });

    case WorkflowActions.resetUpdateTaskState().type:
      return state.merge({
        updateTaskStateLoading: false,
        updateTaskStateError: null,
        updateTaskStateCalled: false,
        updateTaskStateSuccess: false,
      });

    case WorkflowActions.resetUpdateTaskStateError().type:
      return state.merge({
        updateTaskStateLoading: false,
        updateTaskStateError: null,
        updateTaskStateCalled: true,
        updateTaskStateSuccess: false,
      });

    //  DOCUMENT STATE
    case WorkflowActions.updateDocumentStateRequest().type:
      return state.merge({
        updateDocumentStateLoading: true,
        updateDocumentStateError: null,
        updateDocumentStateCalled: true,
        updateDocumentStateSuccess: false,
      });

    case WorkflowActions.updateDocumentStateSuccess().type:
      return state.merge({
        updateDocumentStateLoading: false,
        updateDocumentStateError: null,
        updateDocumentStateCalled: true,
        updateDocumentStateSuccess: true,
      });

    case WorkflowActions.updateDocumentStateFailed().type:
      return state.merge({
        updateDocumentStateLoading: false,
        updateDocumentStateError: action.payload.error,
        updateDocumentStateCalled: true,
        updateDocumentStateSuccess: false,
      });

    case WorkflowActions.resetUpdateDocumentState().type:
      return state.merge({
        updateDocumentStateLoading: false,
        updateDocumentStateError: null,
        updateDocumentStateCalled: false,
        updateDocumentStateSuccess: false,
      });

    case WorkflowActions.resetUpdateDocumentStateError().type:
      return state.merge({
        updateDocumentStateLoading: false,
        updateDocumentStateError: null,
        updateDocumentStateCalled: true,
        updateDocumentStateSuccess: false,
      });

    /**
     * Delete Task Document Actions
     */
    case WorkflowActions.deleteDocumentRequest().type:
      return state.merge({
        deleteDocumentLoading: true,
        deleteDocumentError: null,
        deleteDocumentCalled: true,
        deleteDocumentSuccess: false,
      });

    case WorkflowActions.deleteDocumentSuccess().type:
      return state.merge({
        deleteDocumentLoading: false,
        deleteDocumentError: null,
        deleteDocumentCalled: true,
        deleteDocumentSuccess: true,
      });

    case WorkflowActions.deleteDocumentFailed().type:
      return state.merge({
        deleteDocumentLoading: false,
        deleteDocumentError: action.payload.error,
        deleteDocumentCalled: true,
        deleteDocumentSuccess: false,
      });

    case WorkflowActions.resetDeleteDocument().type:
      return state.merge({
        deleteDocumentLoading: false,
        deleteDocumentError: null,
        deleteDocumentCalled: false,
        deleteDocumentSuccess: false,
      });

    case WorkflowActions.resetDeleteDocumentError().type:
      return state.merge({
        deleteDocumentLoading: false,
        deleteDocumentError: null,
        deleteDocumentCalled: true,
        deleteDocumentSuccess: false,
      });

    //  UPLOAD DOCUMENT
    case WorkflowActions.uploadDocumentRequest().type:
      return state.merge({
        uploadDocumentLoading: true,
        uploadDocumentError: null,
        uploadDocumentCalled: true,
        uploadDocumentSuccess: false,
      });

    case WorkflowActions.uploadDocumentSuccess().type:
      return state.merge({
        uploadDocumentLoading: false,
        uploadDocumentError: null,
        uploadDocumentCalled: true,
        uploadDocumentSuccess: true,
      });

    case WorkflowActions.uploadDocumentFailed().type:
      return state.merge({
        uploadDocumentLoading: false,
        uploadDocumentError: action.payload.error,
        uploadDocumentCalled: true,
        uploadDocumentSuccess: false,
      });

    case WorkflowActions.resetUploadDocument().type:
      return state.merge({
        uploadDocumentLoading: false,
        uploadDocumentError: null,
        uploadDocumentCalled: false,
        uploadDocumentSuccess: false,
      });

    case WorkflowActions.resetUploadDocumentError().type:
      return state.merge({
        uploadDocumentLoading: false,
        uploadDocumentError: null,
        uploadDocumentCalled: true,
        uploadDocumentSuccess: false,
      });
    default:
      return state;
  }
};
