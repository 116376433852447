import OffTakeScheduleActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    //  GET
    case OffTakeScheduleActions.getOffTakeSchedulesRequest().type:
      return state.merge({
        getOffTakeSchedulesLoading: true,
        getOffTakeSchedulesError: null,
        getOffTakeSchedulesCalled: true,
      });

    case OffTakeScheduleActions.getOffTakeSchedulesSuccess().type:
      return state.merge({
        getOffTakeSchedulesLoading: false,
        getOffTakeSchedulesError: null,
        getOffTakeSchedulesCalled: true,

        offTakeSchedules: action.payload.offTakeSchedules,
      });

    case OffTakeScheduleActions.getOffTakeSchedulesFailed().type:
      return state.merge({
        getOffTakeSchedulesLoading: false,
        getOffTakeSchedulesError: action.payload.error,
        getOffTakeSchedulesCalled: true,
      });

    case OffTakeScheduleActions.resetGetOffTakeSchedules().type:
      return state.merge({
        getOffTakeSchedulesLoading: false,
        getOffTakeSchedulesError: null,
        getOffTakeSchedulesCalled: false,
        offTakeSchedules: null,
      });

    case OffTakeScheduleActions.resetGetOffTakeSchedulesError().type:
      return state.merge({
        getOffTakeSchedulesLoading: false,
        getOffTakeSchedulesError: null,
        getOffTakeSchedulesCalled: true,
      });

    //  CREATE
    case OffTakeScheduleActions.createOffTakeScheduleRequest().type:
      return state.merge({
        createOffTakeScheduleLoading: true,
        createOffTakeScheduleError: null,
        createOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.createOffTakeScheduleSuccess().type:
      return state.merge({
        createOffTakeScheduleLoading: false,
        createOffTakeScheduleError: null,
        createOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.createOffTakeScheduleFailed().type:
      return state.merge({
        createOffTakeScheduleLoading: false,
        createOffTakeScheduleError: action.payload.error,
        createOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.resetCreateOffTakeSchedule().type:
      return state.merge({
        createOffTakeScheduleLoading: false,
        createOffTakeScheduleError: null,
        createOffTakeScheduleCalled: false,
      });

    case OffTakeScheduleActions.resetCreateOffTakeScheduleError().type:
      return state.merge({
        createOffTakeScheduleLoading: false,
        createOffTakeScheduleError: null,
        createOffTakeScheduleCalled: true,
      });

    //  UPDATE
    case OffTakeScheduleActions.updateOffTakeScheduleRequest().type:
      return state.merge({
        updateOffTakeScheduleLoading: true,
        updateOffTakeScheduleError: null,
        updateOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.updateOffTakeScheduleSuccess().type:
      return state.merge({
        updateOffTakeScheduleLoading: false,
        updateOffTakeScheduleError: null,
        updateOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.updateOffTakeScheduleFailed().type:
      return state.merge({
        updateOffTakeScheduleLoading: false,
        updateOffTakeScheduleError: action.payload.error,
        updateOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.resetUpdateOffTakeSchedule().type:
      return state.merge({
        updateOffTakeScheduleLoading: false,
        updateOffTakeScheduleError: null,
        updateOffTakeScheduleCalled: false,
      });

    case OffTakeScheduleActions.resetUpdateOffTakeScheduleError().type:
      return state.merge({
        updateOffTakeScheduleLoading: false,
        updateOffTakeScheduleError: null,
        updateOffTakeScheduleCalled: true,
      });

    //  DELETE
    case OffTakeScheduleActions.deleteOffTakeScheduleRequest().type:
      return state.merge({
        deleteOffTakeScheduleLoading: true,
        deleteOffTakeScheduleError: null,
        deleteOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.deleteOffTakeScheduleSuccess().type:
      return state.merge({
        deleteOffTakeScheduleLoading: false,
        deleteOffTakeScheduleError: null,
        deleteOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.deleteOffTakeScheduleFailed().type:
      return state.merge({
        deleteOffTakeScheduleLoading: false,
        deleteOffTakeScheduleError: action.payload.error,
        deleteOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.resetDeleteOffTakeSchedule().type:
      return state.merge({
        deleteOffTakeScheduleLoading: false,
        deleteOffTakeScheduleError: null,
        deleteOffTakeScheduleCalled: false,
      });

    case OffTakeScheduleActions.resetDeleteOffTakeScheduleError().type:
      return state.merge({
        deleteOffTakeScheduleLoading: false,
        deleteOffTakeScheduleError: null,
        deleteOffTakeScheduleCalled: true,
      });

    //  UPDATE USER
    case OffTakeScheduleActions.updateUserOffTakeScheduleRequest().type:
      return state.merge({
        updateUserOffTakeScheduleLoading: true,
        updateUserOffTakeScheduleError: null,
        updateUserOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.updateUserOffTakeScheduleSuccess().type:
      return state.merge({
        updateUserOffTakeScheduleLoading: false,
        updateUserOffTakeScheduleError: null,
        updateUserOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.updateUserOffTakeScheduleFailed().type:
      return state.merge({
        updateUserOffTakeScheduleLoading: false,
        updateUserOffTakeScheduleError: action.payload.error,
        updateUserOffTakeScheduleCalled: true,
      });

    case OffTakeScheduleActions.resetUpdateUserOffTakeSchedule().type:
      return state.merge({
        updateUserOffTakeScheduleLoading: false,
        updateUserOffTakeScheduleError: null,
        updateUserOffTakeScheduleCalled: false,
      });

    case OffTakeScheduleActions.resetUpdateUserOffTakeScheduleError().type:
      return state.merge({
        updateUserOffTakeScheduleLoading: false,
        updateUserOffTakeScheduleError: null,
        updateUserOffTakeScheduleCalled: true,
      });
    default:
      return state;
  }
};
