import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';
import PrintIcon from '@material-ui/icons/Print';

import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid } from '@material-ui/core';

import { usePDF } from '@react-pdf/renderer';

import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import MessageModal from '../../../modals/message';
import ModalView from '../../../modals/modalView';
import Select from '../../../components/Select';
import Loading from '../../../components/loading';
import StatusBox from '../../../components/StatusBox';
import Menu from '../../../components/MenuButton';

import CheckIcon from '../../../assets/images/icons/check.svg';
import DenyIcon from '../../../assets/images/icons/denied.svg';
import PedingIcon from '../../../assets/images/icons/pending.svg';

import TermsActions from '../../../redux/terms/actions';
import AuthActions from '../../../redux/auth/actions';
import ContractsActions from '../../../redux/contracts/actions';

import ContractPDF from '../contractPDF';

import styles from './styles.module.sass';

import DeniedComment from '../deniedComment';
import SeeComment from '../seeComment';
import Autocomplete from '../../../components/Autocomplete';

const OtherTerms = ({ t, history }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const {
    getTermsLoading,
    getTermsError,
    getTermsCalled,
    terms,

    createTermsLoading,
    createTermsError,
    createTermsCalled,

    updateTermsLoading,
    updateTermsError,
    updateTermsCalled,

    deleteTermsLoading,
    deleteTermsError,
    deleteTermsCalled,

    updateUserTermsLoading,
    updateUserTermsError,
    updateUserTermsCalled,
  } = useSelector((state) => state.terms);

  const {
    saveUserContractsLoading,
    saveUserContractsError,
    saveUserContractsCalled,
    contractSelection,

    getUserContractLoading,
    getUserContractError,
    getUserContractCalled,

    contractMe,
    getContractMeCalled,
    getContractMeLoading,

    acceptInviteLoading,
    acceptInviteError,
    acceptInviteCalled,
    autocomplete,
  } = useSelector((state) => state.contracts);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [editing, setEditing] = useState(null);
  const [creating, setCreating] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tempTerms, setTempTerms] = useState([]);
  const [tempDeleteTerms, setTempDeleteTerms] = useState([]);
  const [tempUpdateTerms, setTempUpdateTerms] = useState([]);
  const [updateTerms, setUpdateTerms] = useState(null);
  const [addDisagreeComments, setAddDisagreeComments] = useState(null);
  const [seeDisagreeComments, setSeeDisagreeComments] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);

  const menuOptions = [
    { label: t('common.edit'), action: 'edit' },
    { label: t('common.delete'), action: 'delete' },
  ];

  const [instancePdf, updateInstancePdf] = usePDF({
    document: <ContractPDF contract={contractSelection} />,
  });

  useEffect(() => {
    dispatch(ContractsActions.getAutocomplete());
  }, []);

  useEffect(() => {
    if (!getUserContractCalled && contractSelection?.id) {
      dispatch(ContractsActions.getUserContract(contractSelection?.id));
    }
  }, [getUserContractCalled, contractSelection]);

  useEffect(() => {
    if (!getTermsCalled && contractSelection?.id) {
      dispatch(TermsActions.getTerms(contractSelection?.id));
    }
  }, [getTermsCalled, contractSelection]);

  useEffect(() => {
    if (contractSelection?.status === 'created' && contractSelection?.owner?.id !== user.id) {
      if (!acceptInviteCalled && !acceptInviteLoading && !acceptInviteError) {
        dispatch(ContractsActions.acceptInvite(contractSelection?.id));
      }
    }
    if (contractSelection?.id) {
      if (terms?.length > 0) {
        if (contractSelection.status === 'created') {
          setEditing(true);
        } else {
          setEditing(false);
          setCreating(false);
        }
      } else {
        setEditing(false);
        setCreating(true);
      }
    }
  }, [contractSelection, updateUserTermsCalled, deleteTermsCalled]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  useEffect(() => {
    send();
    return () => {};
  }, [tempUpdateTerms, tempTerms, tempDeleteTerms]);

  useEffect(() => {
    return () => {
      dispatch(ContractsActions.resetGetUserContract());
      dispatch(ContractsActions.resetAcceptInvite());
      dispatch(TermsActions.resetGetTerms());
      dispatch(TermsActions.resetCreateTerms());
      dispatch(TermsActions.resetUpdateTerms());
      dispatch(TermsActions.resetDeleteTerms());
    };
  }, []);

  useEffect(() => {
    console.log(editing);
    console.log(creating);
    return () => {};
  }, [editing, creating]);

  useEffect(() => {
    if (!getContractMeCalled && contractSelection) {
      dispatch(ContractsActions.getContractMe(contractSelection?.id));
    }
  }, [contractSelection, getContractMeCalled]);

  useEffect(() => {
    if (contractSelection) {
      updateInstancePdf({
        document: <ContractPDF contract={contractSelection} />,
      });
    }
  }, [contractSelection]);

  function handleSubmit(values) {
    if (updateTerms) {
      if (updateTerms.id) {
        const term = {
          id: updateTerms.id,
          label: values.label,
          type: values.type,
          description: values.description,
        };
        setTempUpdateTerms((tempUpdateTerms) => [...tempUpdateTerms, term]);
        setUpdateTerms(null);
      } else {
        const term = {
          contract: contractSelection?.id,
          label: values.label,
          type: values.type,
          description: values.description,
        };
        setTempTerms((tempTerms) => [...tempTerms.filter((el) => el !== updateTerms)]);
        setTempTerms((tempTerms) => [...tempTerms, term]);
        setUpdateTerms(null);
      }
    } else {
      const term = {
        contract: contractSelection?.id,
        label: values.label,
        type: values.type,
        description: values.description,
      };
      setTempTerms((tempTerms) => [...tempTerms, term]);
    }
  }

  const handlePrintContract = () => {
    if (!instancePdf.isLoading && !instancePdf.error) {
      window.open(instancePdf.url, '_blank');
    }
  };

  function send() {
    if (tempTerms.length > 0) {
      dispatch(TermsActions.createTerms({ terms: tempTerms }));
    }
    if (tempDeleteTerms.length > 0) {
      dispatch(
        TermsActions.deleteTerms({ terms: tempDeleteTerms.map((element) => ({ id: element.id })) })
      );
    }
    if (
      tempUpdateTerms.filter(
        (item) => item.id !== 'volume' && item.id !== 'sugarSpec' && item.id !== 'packagingType'
      ).length > 0
    ) {
      dispatch(
        TermsActions.updateTerms({
          terms: tempUpdateTerms.filter(
            (item) => item.id !== 'volume' && item.id !== 'sugarSpec' && item.id !== 'packagingType'
          ),
        })
      );
    }

    if (
      tempUpdateTerms.filter(
        (item) => item.id === 'volume' || item.id === 'sugarSpec' || item.id === 'packagingType'
      ).length > 0
    ) {
      let newContract = {};

      if (tempUpdateTerms.find((item) => item.id === 'volume')?.description) {
        newContract = {
          ...newContract,
          totalVolume: tempUpdateTerms.find((item) => item.id === 'volume')?.description,
        };
      }
      if (tempUpdateTerms.find((item) => item.id === 'sugarSpec')?.description) {
        newContract = {
          ...newContract,
          sugarSpec: tempUpdateTerms.find((item) => item.id === 'sugarSpec')?.description,
        };
      }
      if (tempUpdateTerms.find((item) => item.id === 'packagingType')?.description) {
        newContract = {
          ...newContract,
          packageType: tempUpdateTerms.find((item) => item.id === 'packagingType')?.description,
        };
      }

      dispatch(ContractsActions.saveUserContracts(contractSelection?.id, newContract));
    }
  }

  async function handleAgree(termId) {
    const data = { status: 'approved' };
    await dispatch(TermsActions.updateUserTerms(contractSelection?.id, termId, data));
    dispatch(TermsActions.resetGetTerms());
  }

  function showError() {
    if (
      (createTermsError || updateTermsError || deleteTermsError || saveUserContractsError) &&
      !createTermsLoading &&
      !updateTermsLoading &&
      !deleteTermsLoading &&
      !saveUserContractsLoading
    ) {
      return (
        <MessageModal
          icon={null}
          title={t('common.error')}
          messages={[
            createTermsError?.userMessage,
            updateTermsError?.userMessage,
            deleteTermsError?.userMessage,
            saveUserContractsError?.userMessage,
          ]}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetSaveUserContractsError());
            dispatch(TermsActions.resetCreateTermsError());
            dispatch(TermsActions.resetDeleteTermsError());
            dispatch(TermsActions.resetUpdateTermsError());
          }}
        />
      );
    }
    return null;
  }

  function showSuccess() {
    if (
      (!createTermsCalled || (createTermsCalled && !createTermsLoading && !createTermsError)) &&
      (!updateTermsCalled || (updateTermsCalled && !updateTermsLoading && !updateTermsError)) &&
      (!deleteTermsCalled || (deleteTermsCalled && !deleteTermsLoading && !deleteTermsError)) &&
      (!saveUserContractsCalled ||
        (saveUserContractsCalled && !saveUserContractsLoading && !saveUserContractsError))
    ) {
      return (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.addOtherTerms.sentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            // history.push('/contracts/other-terms');
            dispatch(TermsActions.resetGetTerms());
            dispatch(TermsActions.resetCreateTerms());
            dispatch(TermsActions.resetUpdateTerms());
            dispatch(TermsActions.resetDeleteTerms());
            dispatch(TermsActions.resetUpdateUserTerms());
            dispatch(ContractsActions.resetGetUserContract());
            dispatch(ContractsActions.resetSaveUserContracts());
            // TODO: check this, clear state vars after submit to avoid duplicates
            setTempTerms([]);
            setUpdateTerms(null);
            setTempDeleteTerms([]);
            setTempUpdateTerms([]);
          }}
        />
      );
    }
    return null;
  }

  function showStatusBox() {
    const foundPendingReview = terms?.find(
      (term) => term?.requestedByTeam !== contractMe?.team && term.status === 'pending'
    );
    const foundWaitingReview = terms?.find(
      (term) => term?.requestedByTeam === contractMe?.team && term.status === 'pending'
    );
    const foundWatingReviewDenied = terms?.find(
      (term) => term?.requestedByTeam !== contractMe?.team && term.status === 'rejected'
    );
    const foundDenied = terms?.find(
      (term) => term?.requestedByTeam === contractMe?.team && term.status === 'rejected'
    );
    const foundApproval = terms?.find((term) => term.status === 'approved');
    if (contractSelection?.status !== 'created') {
      const isSeller = contractSelection?.seller?.id === user?.id;
      if (foundPendingReview) {
        return (
          <StatusBox
            type="review"
            counterpartName={
              isSeller
                ? `${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`
                : `${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`
            }
          />
        );
      }

      if (foundDenied) {
        return (
          <StatusBox
            type="default"
            user={isSeller ? contractSelection?.buyer : contractSelection?.seller}
            userStatus={false}
          />
        );
      }
      if (foundWaitingReview) {
        return (
          <StatusBox
            type="default"
            user={isSeller ? contractSelection?.buyer : contractSelection?.seller}
            userStatus={null}
          />
        );
      }
      if (foundWatingReviewDenied) {
        return (
          <StatusBox
            type="denied"
            counterpartName={
              isSeller
                ? `${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`
                : `${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`
            }
          />
        );
      }
      if (foundApproval) {
        return (
          <StatusBox
            type="default"
            user={isSeller ? contractSelection?.buyer : contractSelection?.seller}
            userStatus
          />
        );
      }
    }
  }

  const validationSchema = Yup.object({
    label: Yup.string().required(t('common.requiredField')),
    type: Yup.string().required(t('common.requiredField')),
    description: Yup.string().required(t('common.requiredField')),
  });

  const TermsCard = () => (
    <Box className={styles.terms_card}>
      <TermItem
        title={t('contracts.box.totalVolume')}
        description={` ${
          tempUpdateTerms.find((item) => item.id === 'volume')
            ? tempUpdateTerms.find((item) => item.id === 'volume').description
            : contractSelection?.totalVolume || ''
        } MT`}
        isTemp={tempUpdateTerms.find((item) => item.id === 'volume')}
        value={{
          id: 'volume',
          label: t('contracts.box.totalVolume'),
          description: contractSelection?.totalVolume,
          type: 'Obligation',
        }}
        index={0}
      />
      <TermItem
        title={t('contracts.box.packagingType')}
        description={
          tempUpdateTerms.find((item) => item.id === 'packagingType')
            ? tempUpdateTerms.find((item) => item.id === 'packagingType').description
            : contractSelection?.packageType || ''
        }
        isTemp={tempUpdateTerms.find((item) => item.id === 'packagingType')}
        value={{
          id: 'packagingType',
          label: t('contracts.box.packagingType'),
          description: contractSelection?.packageType,
          type: 'Obligation',
        }}
        index={1}
      />
      <TermItem
        title={t('contracts.box.sugarSpec')}
        description={
          tempUpdateTerms.find((item) => item.id === 'sugarSpec')
            ? tempUpdateTerms.find((item) => item.id === 'sugarSpec').description
            : contractSelection?.sugarSpec || ''
        }
        isTemp={tempUpdateTerms.find((item) => item.id === 'sugarSpec')}
        value={{
          id: 'sugarSpec',
          label: t('contracts.box.sugarSpec'),
          description: contractSelection?.sugarSpec,
          type: 'Obligation',
        }}
        index={2}
      />

      {contractMe?.team &&
        terms?.map((item, index) => {
          return (
            <TermItem
              key={`terms-card-${index}`}
              title={item?.label || ''}
              type={item.type || ''}
              description={item?.description}
              isTemp={false}
              value={item}
              index={index + 1}
              owner={item?.requestedByTeam === contractMe?.team}
              status={item.status}
              comment={item.rejectedComments}
            />
          );
        })}
      {tempUpdateTerms?.map((item, index) => {
        if (item.id !== 'volume' && item.id !== 'sugarSpec' && item.id !== 'packagingType') {
          return (
            <TermItem
              key={`terms-card-${index}`}
              title={item?.label || ''}
              type={item.type || ''}
              description={item?.description}
              isTemp
              value={item}
              index={index + 1}
            />
          );
        }
        return null;
      })}
      {tempTerms?.map((item, index) => (
        <TermItem
          key={`terms-card-${index}`}
          title={item?.label || ''}
          type={item.type || ''}
          description={item?.description}
          isTemp
          value={item}
          index={index + 1}
        />
      ))}
    </Box>
  );

  const MenuButton = ({ term, isTemp }) => {
    return (
      <Menu
        menuOptions={menuOptions}
        onAction={(action) => {
          if (action?.action === 'edit') {
            setEditing(true);
            setUpdateTerms(term);
          } else if (action?.action === 'delete') {
            setEditing(true);
            setUpdateTerms(null);
            if (isTemp) {
              setTempTerms((tempTerms) => [...tempTerms.filter((el) => el !== term)]);
              setTempUpdateTerms((tempUpdateTerms) => [
                ...tempUpdateTerms.filter((el) => el !== term),
              ]);
            } else {
              setTempDeleteTerms((tempDeleteTerms) => [...tempDeleteTerms, term]);
            }
          }
        }}
      />
    );
  };

  const TermItem = ({ title, type, description, isTemp, value, index, owner, status, comment }) => {
    if (!isTemp) {
      if (tempDeleteTerms.includes(value)) {
        return null;
      }
      if (
        tempUpdateTerms.find(
          (item) =>
            item.id === value.id &&
            item.id !== 'volume' &&
            item.id !== 'sugarSpec' &&
            item.id !== 'packagingType'
        )
      ) {
        return null;
      }
    }
    let background = '#fff';
    let borderColor = 'none';
    if (updateTerms === value) {
      background = 'rgba(76, 72, 143, 0.15)';
      borderColor = '1px solid rgba(76, 72, 143, 0.5)';
    }

    return (
      <Box
        style={{
          backgroundColor: isTemp ? 'rgba(76, 72, 143, 0.15)' : background,
          border: borderColor,
        }}
      >
        <Box className={styles.container_terms_item}>
          <Box className={styles.title}>
            {title}
            {type && <Box className={styles.type}>{`(${type})`}</Box>}
          </Box>
          <Box className={styles.container_data} style={isMobile ? { paddingLeft: 20 } : {}}>
            <Box className={styles.description}>{description}</Box>
          </Box>
          {isMobile && !isTablet ? (
            <>
              {status === 'pending' && !owner ? null : (
                <Box className={styles.container_actions}>
                  <MenuButton term={value} isTemp={isTemp} />
                </Box>
              )}
            </>
          ) : status === 'pending' && !owner ? (
            <Box className={styles.container_actions}>
              <>
                <Button
                  maxHeight={40}
                  minHeight={40}
                  fontSize={12}
                  className={styles.button}
                  onPress={() => {
                    setShowConfirmation(value);
                  }}
                >
                  {t('common.agree')}
                </Button>
                <Box style={{ width: 20 }} />
                <Button
                  maxHeight={40}
                  minHeight={40}
                  fontSize={12}
                  inverted
                  bordered
                  className={styles.button}
                  onPress={() => {
                    setAddDisagreeComments(value);
                  }}
                >
                  {t('contracts.details.workflow.addComment')}
                </Button>
              </>
            </Box>
          ) : status === 'pending' && owner ? (
            <Box className={styles.container_actions}>
              <SvgIcon
                style={{ height: 20, width: 20, marginRight: 5 }}
                component={PedingIcon}
                viewBox="0 0 32 32"
              />
              {t('paymentInstructions.pending')}
              <MenuButton term={value} isTemp={isTemp} />
            </Box>
          ) : status === 'rejected' ? (
            <Box className={styles.container_actions}>
              <Box
                style={{
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => {
                  setSeeDisagreeComments(value.rejectedComments);
                }}
              >
                <SvgIcon
                  style={{ height: 20, width: 20, marginRight: 5 }}
                  component={DenyIcon}
                  viewBox="0 0 32 32"
                />
                {t('contracts.deniedTitle')}
              </Box>
              <MenuButton term={value} isTemp={isTemp} />
            </Box>
          ) : status === 'approved' ? (
            <Box className={styles.container_actions}>
              <SvgIcon
                style={{ height: 20, width: 20, marginRight: 5 }}
                component={CheckIcon}
                viewBox="0 0 32 32"
              />
              <MenuButton term={value} isTemp={isTemp} />
            </Box>
          ) : (
            <Box className={styles.container_actions}>
              <MenuButton term={value} isTemp={isTemp} />
            </Box>
          )}
        </Box>
        {isMobile && !isTablet && (
          <Box className={styles.container_terms_item} style={{ paddingTop: 0, paddingRight: 20 }}>
            {status === 'pending' && !owner ? (
              <Box className={styles.container_actions}>
                <>
                  <Button
                    maxHeight={40}
                    minHeight={40}
                    fontSize={12}
                    className={styles.button}
                    onPress={() => {
                      setShowConfirmation(value);
                    }}
                  >
                    {t('common.agree')}
                  </Button>
                  <Box style={{ width: 20 }} />
                  <Button
                    maxHeight={40}
                    minHeight={40}
                    fontSize={12}
                    inverted
                    bordered
                    className={styles.button}
                    onPress={() => {
                      setAddDisagreeComments(value);
                    }}
                  >
                    {t('contracts.details.workflow.addComment')}
                  </Button>
                </>
              </Box>
            ) : status === 'pending' && owner ? (
              <Box className={styles.container_actions}>
                <SvgIcon
                  style={{ height: 20, width: 20, marginRight: 5 }}
                  component={PedingIcon}
                  viewBox="0 0 32 32"
                />
                {t('paymentInstructions.pending')}
              </Box>
            ) : status === 'rejected' ? (
              <Box className={styles.container_actions}>
                <Box
                  style={{
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setSeeDisagreeComments(value.rejectedComments);
                  }}
                >
                  <SvgIcon
                    style={{ height: 20, width: 20, marginRight: 5 }}
                    component={DenyIcon}
                    viewBox="0 0 32 32"
                  />
                  {t('contracts.deniedTitle')}
                </Box>
              </Box>
            ) : status === 'approved' ? (
              <Box className={styles.container_actions}>
                <SvgIcon
                  style={{ height: 20, width: 20, marginRight: 5 }}
                  component={CheckIcon}
                  viewBox="0 0 32 32"
                />
              </Box>
            ) : null}
          </Box>
        )}
        <Divider />
      </Box>
    );
  };

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={
          getContractMeLoading ||
          getTermsLoading ||
          createTermsLoading ||
          updateTermsLoading ||
          deleteTermsLoading ||
          saveUserContractsLoading ||
          getUserContractLoading ||
          acceptInviteLoading
        }
      />
      <NavigationBar
        titleDescription={{
          title: t('contracts.details.contractTerms').toUpperCase(),
          description: t('contracts.addOtherTerms.description'),
        }}
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        history={history}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid
            container
            className={styles.grid_container}
            justify="flex-start"
            style={{ paddingBottom: isTablet ? 130 : isMobile ? 100 : 0 }}
          >
            <Grid item xs={12} sm={6} lg={9}>
              {(!isMobile || isTablet) && (
                <Box className={styles.container_actions}>
                  <Box className={styles.options} onClick={handlePrintContract}>
                    <PrintIcon style={{ marginRight: 10 }} />
                    <Box>{t('contracts.print')}</Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} container justify="flex-start">
              {!isMobile && (
                <Box className={styles.title_container}>
                  {creating === false && editing === false && (
                    <Box className={styles.container_propose_changes}>
                      <Button
                        inverted
                        bordered
                        className={styles.button_alone}
                        minWidth={130}
                        maxWidth={270}
                        onPress={() => {
                          setEditing(true);
                        }}
                      >
                        {t('contracts.proposeChanges')}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              <Container className={styles.content}>
                {contractMe && showStatusBox()}
                {isMobile && creating === false && editing === false && (
                  <Box className={styles.container_propose_changes}>
                    <Button
                      inverted
                      bordered
                      className={styles.button_alone}
                      minWidth={130}
                      maxWidth={270}
                      onPress={() => {
                        setEditing(true);
                      }}
                    >
                      {t('contracts.proposeChanges')}
                    </Button>
                  </Box>
                )}
                {(creating === true || editing === true) && (
                  <Box style={{ paddingTop: isMobile && !isTablet ? 0 : 20 }}>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        label: updateTerms?.label || '',
                        type: updateTerms?.type || '',
                        description: updateTerms?.description || '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm({ values: { label: '', type: '', description: '' } });
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box className={styles.container_inputs}>
                            {updateTerms?.id === 'volume' && (
                              <Box className={styles.container_names}>
                                <Box
                                  className={clsx(
                                    styles.container_first_field,
                                    styles.container_input
                                  )}
                                >
                                  <TextField
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    helperText={
                                      errors.description &&
                                      touched.description &&
                                      errors.description
                                    }
                                    placeholder={t('contracts.totalVolume')}
                                    label={t('contracts.totalVolume').toUpperCase()}
                                    disabled={!editing && !creating}
                                    required
                                    icon="MT"
                                    type="number"
                                    onKeyDown={(evt) =>
                                      (evt.key === 'e' || evt.key === '+' || evt.key === '-') &&
                                      evt.preventDefault()
                                    }
                                  />
                                </Box>
                              </Box>
                            )}

                            {updateTerms?.id === 'packagingType' && (
                              <Box className={styles.container_input}>
                                <Select
                                  name="description"
                                  values={['50 kg Bag', '1 MT Bag', 'Bulk', 'Other']}
                                  value={values.description}
                                  onChange={(value) => {
                                    values.description = value;
                                    const event = {
                                      persist: () => {},
                                      target: {
                                        type: 'change',
                                        id: 'description',
                                        name: 'description',
                                        value: values.description,
                                      },
                                    };
                                    handleChange(event);
                                  }}
                                  helperText={
                                    errors.description && touched.description && errors.description
                                  }
                                  placeholder={t('contracts.packagingType')}
                                  label={t('contracts.packagingType').toUpperCase()}
                                  disabled={!editing && !creating}
                                  required
                                />
                              </Box>
                            )}

                            {updateTerms?.id === 'sugarSpec' && (
                              <Box className={styles.container_names}>
                                <Box
                                  className={clsx(
                                    styles.container_first_field,
                                    styles.container_input
                                  )}
                                >
                                  <TextField
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    helperText={
                                      errors.description &&
                                      touched.description &&
                                      errors.description
                                    }
                                    placeholder={t('contracts.sugarSpec')}
                                    label={t('contracts.sugarSpec').toUpperCase()}
                                    disabled={!editing && !creating}
                                    required
                                  />
                                </Box>
                              </Box>
                            )}

                            {updateTerms?.id !== 'volume' &&
                              updateTerms?.id !== 'sugarSpec' &&
                              updateTerms?.id !== 'packagingType' && (
                                <>
                                  <Box className={styles.container_names}>
                                    <Box
                                      className={clsx(
                                        styles.container_first_field,
                                        styles.container_input
                                      )}
                                    >
                                      <Autocomplete
                                        name="label"
                                        value={values.label}
                                        onChange={(e) => {
                                          const found = autocomplete?.terms?.find(
                                            (item) => item.label === e.target.value
                                          );
                                          if (found) {
                                            setFieldValue('description', found.description);
                                            setFieldValue('type', found.type);
                                          }
                                          handleChange(e);
                                        }}
                                        helperText={errors.label && touched.label && errors.label}
                                        placeholder={t('contracts.addOtherTerms.label')}
                                        label={t('contracts.addOtherTerms.label').toUpperCase()}
                                        disabled={!editing && !creating}
                                        required
                                        freeSolo
                                        options={autocomplete?.terms}
                                        displayKey="label"
                                        valueKey="label"
                                      />
                                    </Box>
                                    <Box
                                      className={clsx(
                                        styles.container_last_field,
                                        styles.container_input
                                      )}
                                    >
                                      <Select
                                        name="type"
                                        values={['Option', 'Obligation']}
                                        value={values.type}
                                        onChange={(value) => {
                                          values.type = value;
                                          const event = {
                                            persist: () => {},
                                            target: {
                                              type: 'change',
                                              id: 'type',
                                              name: 'type',
                                              value: values.type,
                                            },
                                          };
                                          handleChange(event);
                                        }}
                                        helperText={errors.type && touched.type && errors.type}
                                        placeholder={t('contracts.addOtherTerms.type')}
                                        label={t('contracts.addOtherTerms.type').toUpperCase()}
                                        disabled={!editing && !creating}
                                        required
                                      />
                                    </Box>
                                  </Box>
                                  <Box className={styles.container_input}>
                                    <TextField
                                      name="description"
                                      value={values.description}
                                      onChange={handleChange}
                                      helperText={
                                        errors.description &&
                                        touched.description &&
                                        errors.description
                                      }
                                      placeholder={t('contracts.addOtherTerms.descriptionLabel')}
                                      label={t(
                                        'contracts.addOtherTerms.descriptionLabel'
                                      ).toUpperCase()}
                                      disabled={!editing && !creating}
                                      multiline
                                      rows={5}
                                      required
                                    />
                                  </Box>
                                </>
                              )}
                          </Box>
                          <Box>
                            <Box className={styles.container_submit}>
                              <Box className={styles.box_two_options}>
                                {(creating || editing) && (
                                  <Button
                                    type="submit"
                                    disabled={(!editing && !creating) || createTermsLoading}
                                    loading={createTermsLoading}
                                    className={styles.button}
                                    inverted
                                    bordered
                                    data-info-submit="Submit"
                                    minWidth={130}
                                    maxWidth={270}
                                  >
                                    {updateTerms
                                      ? t('contracts.addOtherTerms.edit')
                                      : t('contracts.addOtherTerms.add')}
                                  </Button>
                                )}
                                {(creating || editing) && (
                                  <Button
                                    disabled={(!editing && !creating) || createTermsLoading}
                                    loading={createTermsLoading}
                                    className={styles.button}
                                    inverted
                                    bordered
                                    minWidth={130}
                                    maxWidth={270}
                                    onPress={() => {
                                      setUpdateTerms(null);
                                      setEditing(false);
                                    }}
                                  >
                                    {t('common.cancel')}
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                )}

                <Divider style={{ margin: 30 }} />

                {!isMobile && (
                  <Box
                    className="subtitle_container"
                    style={{ textAlign: 'center', marginBottom: 40 }}
                  >
                    {t('contracts.addOtherTerms.addTerms').toUpperCase()}
                  </Box>
                )}

                <TermsCard />
              </Container>
            </Grid>
          </Grid>
        }
      />

      {(createTermsCalled || updateTermsCalled || deleteTermsCalled || saveUserContractsCalled) &&
        showSuccess()}
      {(createTermsCalled || updateTermsCalled || deleteTermsCalled || saveUserContractsCalled) &&
        showError()}
      {getTermsCalled && !getTermsLoading && getTermsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getTermsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(TermsActions.resetGetTermsError());
          }}
        />
      )}
      {showConfirmation && (
        <MessageModal
          title={t('common.agreeConfirmationTitle')}
          secundaryAction={t('common.no')}
          secundaryEvent={() => {
            setShowConfirmation(false);
          }}
          primaryAction={t('common.yes')}
          primaryEvent={() => {
            setShowConfirmation(false);
            handleAgree(showConfirmation.id);
          }}
        />
      )}
      {updateUserTermsCalled && !updateUserTermsLoading && updateUserTermsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={updateUserTermsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(TermsActions.resetUpdateUserTermsError());
          }}
        />
      )}
      {addDisagreeComments && (
        <ModalView
          view={
            <DeniedComment
              isModal
              formType="terms"
              onClose={() => {
                setAddDisagreeComments(null);
              }}
              onSave={async (comment) => {
                const data = { rejectedComments: comment, status: 'rejected' };
                await dispatch(
                  TermsActions.updateUserTerms(contractSelection?.id, addDisagreeComments.id, data)
                );
                dispatch(TermsActions.resetGetTerms());
              }}
            />
          }
          onClose={() => setAddDisagreeComments(null)}
        />
      )}

      {seeDisagreeComments && (
        <ModalView
          view={
            <SeeComment
              isModal
              formType="terms"
              comments={seeDisagreeComments}
              onClose={() => setSeeDisagreeComments(null)}
            />
          }
          onClose={() => setSeeDisagreeComments(null)}
        />
      )}
    </Box>
  );
};

export default withTranslation()(OtherTerms);
