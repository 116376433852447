import { Box, Container } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useWindowDimensions from '../../../utils/screenSize';
import Button from '../../../components/Button';


import styles from './styles.module.sass';
import Logo from '../../../components/Logo';
import BackIcon from '../../../assets/images/icons/back.svg';

const ExternalContent = (props) => {
  const { history, t } = props;
  const { height } = useWindowDimensions();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const backButtonText = t('common.back')
  console.log('height:', height);
  const [, path] = history?.location?.pathname.split('/');
  const urls = {
    privacy: 'https://app.termly.io/document/privacy-policy/4e72bc8d-632d-4a2c-8e60-633b2f40108d',
    terms:
      'https://app.termly.io/document/terms-of-use-for-saas/ee46438e-c4a1-4c88-ab82-06cfc4d74200',
  };

  const titles = {
    privacy: 'common.privacy',
    terms: 'common.terms',
  };

  let title = '';
  let url = '';
  if (path && urls[path]) {
    title = t(titles[path]);
    url = urls[path];
  }


  console.log('history:', history, history?.location);

  return (
    <Box className={styles.container}>
      <Box className={styles.icon_container}>
        <Button
          align="center"
          padding='20px'
          margin={0}
          clean
          inverted
          style={{ width: !isMobile ? 'max-content' : '100%' }}
          onPress={() => {
            history.goBack();
          }}
        >
          <SvgIcon component={BackIcon} />
          {!isMobile && backButtonText}
        </Button>
      </Box>
      <Box className={styles.containerLogo}>
        <Logo />
      </Box>
      <Container maxWidth="md" className={styles.content}>
        <iframe className={styles.iframe} src={url} title={title} style={{height: (height - 150) }}  />
      </Container>
    </Box>
  );
};

export default withTranslation()(ExternalContent);
