import InvitesActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    //  GET
    case InvitesActions.getInvitesRequest().type:
      return state.merge({
        getInvitesLoading: true,
        getInvitesError: null,
        getInvitesCalled: true,
      });

    case InvitesActions.getInvitesSuccess().type:
      return state.merge({
        getInvitesLoading: false,
        getInvitesError: null,
        getInvitesCalled: true,

        invites: action.payload.invites,
      });

    case InvitesActions.getInvitesFailed().type:
      return state.merge({
        getInvitesLoading: false,
        getInvitesError: action.payload.error,
        getInvitesCalled: true,
      });

    case InvitesActions.resetGetInvites().type:
      return state.merge({
        getInvitesLoading: false,
        getInvitesError: null,
        getInvitesCalled: false,
      });

    case InvitesActions.resetGetInvitesError().type:
      return state.merge({
        getInvitesLoading: false,
        getInvitesError: null,
        getInvitesCalled: true,
      });

    //  CREATE
    case InvitesActions.createInvitesRequest().type:
      return state.merge({
        createInvitesLoading: true,
        createInvitesError: null,
        createInvitesCalled: true,
      });

    case InvitesActions.createInvitesSuccess().type:
      return state.merge({
        createInvitesLoading: false,
        createInvitesError: null,
        createInvitesCalled: true,
      });

    case InvitesActions.createInvitesFailed().type:
      return state.merge({
        createInvitesLoading: false,
        createInvitesError: action.payload.error,
        createInvitesCalled: true,
      });

    case InvitesActions.resetCreateInvites().type:
      return state.merge({
        createInvitesLoading: false,
        createInvitesError: null,
        createInvitesCalled: false,
      });

    case InvitesActions.resetCreateInvitesError().type:
      return state.merge({
        createInvitesLoading: false,
        createInvitesError: null,
        createInvitesCalled: true,
      });

    //  UPDATE
    case InvitesActions.updateInvitesRequest().type:
      return state.merge({
        updateInvitesLoading: true,
        updateInvitesError: null,
        updateInvitesCalled: true,
      });

    case InvitesActions.updateInvitesSuccess().type:
      return state.merge({
        updateInvitesLoading: false,
        updateInvitesError: null,
        updateInvitesCalled: true,
      });

    case InvitesActions.updateInvitesFailed().type:
      return state.merge({
        updateInvitesLoading: false,
        updateInvitesError: action.payload.error,
        updateInvitesCalled: true,
      });

    case InvitesActions.resetUpdateInvites().type:
      return state.merge({
        updateInvitesLoading: false,
        updateInvitesError: null,
        updateInvitesCalled: false,
      });

    case InvitesActions.resetUpdateInvitesError().type:
      return state.merge({
        updateInvitesLoading: false,
        updateInvitesError: null,
        updateInvitesCalled: true,
      });

    //  DELETE
    case InvitesActions.deleteInvitesRequest().type:
      return state.merge({
        deleteInvitesLoading: true,
        deleteInvitesError: null,
        deleteInvitesCalled: true,
      });

    case InvitesActions.deleteInvitesSuccess().type:
      return state.merge({
        deleteInvitesLoading: false,
        deleteInvitesError: null,
        deleteInvitesCalled: true,
      });

    case InvitesActions.deleteInvitesFailed().type:
      return state.merge({
        deleteInvitesLoading: false,
        deleteInvitesError: action.payload.error,
        deleteInvitesCalled: true,
      });

    case InvitesActions.resetDeleteInvites().type:
      return state.merge({
        deleteInvitesLoading: false,
        deleteInvitesError: null,
        deleteInvitesCalled: false,
      });

    case InvitesActions.resetDeleteInvitesError().type:
      return state.merge({
        deleteInvitesLoading: false,
        deleteInvitesError: null,
        deleteInvitesCalled: true,
      });

    //  GET ROLES
    case InvitesActions.getRolesRequest().type:
      return state.merge({
        getRolesLoading: true,
        getRolesError: null,
        getRolesCalled: true,
      });

    case InvitesActions.getRolesSuccess().type:
      return state.merge({
        getRolesLoading: false,
        getRolesError: null,
        getRolesCalled: true,

        roles: action.payload.roles,
      });

    case InvitesActions.getRolesFailed().type:
      return state.merge({
        getRolesLoading: false,
        getRolesError: action.payload.error,
        getRolesCalled: true,
      });

    case InvitesActions.resetGetRoles().type:
      return state.merge({
        getRolesLoading: false,
        getRolesError: null,
        getRolesCalled: false,
        roles: null,
      });

    case InvitesActions.resetGetRolesError().type:
      return state.merge({
        getRolesLoading: false,
        getRolesError: null,
        getRolesCalled: true,
      });
    default:
      return state;
  }
};
