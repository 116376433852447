import api from './api';

export const getOffTakeSchedules = (id) => {
  return api.get(`contracts/schedule?contract=${id}`);
};

export const postOffTakeSchedule = (data) => {
  return api.post('contracts/schedule', data);
};

export const putOffTakeSchedule = (data) => {
  return api.put('contracts/schedule', data);
};

export const deleteOffTakeSchedule = (payload) => {
  return api.delete('contracts/schedule', {}, { data: payload });
};

export const putUserOffTakeSchedule = (id, data) => {
  return api.put(`contracts/${id}/schedule/status`, data);
};
