import { hot } from 'react-hot-loader/root';
import React, { Fragment, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { withTranslation } from 'react-i18next';
import firebase from 'firebase/app';
import NotificationActions from './redux/notifications/actions';
import Store, { persistor } from './redux/store';

import Root from './pages/Root';

import CacheBuster from './CacheBuster';

import mock from './services/mocks';

const Container = () => {
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_SHOULD_MOCK_API_CALLS) {
      console.log('mocking configurated api calls');
      mock();
    }
  }, []);

  useEffect(() => {
    console.log('add onMessage event');
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload) => {
        const data = JSON.parse(JSON.stringify(payload));
        const message = { ...data?.data };
        message.fromUser = JSON.parse(message.fromUser);
        message.title = data?.notification?.title;
        message.description = data?.notification?.body;
        console.log('onMessage: ', message);
        Store.dispatch(NotificationActions.addPushNotification(message));
      });
    }
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        setShowUpdateMessage(!loading && !isLatestVersion);
        return (
          <>
            <Provider store={Store}>
              <PersistGate loading={null} persistor={persistor}>
                <Root showUpdateMessage={showUpdateMessage} />
              </PersistGate>
            </Provider>
          </>
        );
      }}
    </CacheBuster>
  );
};

export default hot(withTranslation()(Container));
