import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MatButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';

import ContractChart from '../ContractChart';
import UserPhoto from '../UserPhoto';
import MoreIcon from '../../assets/images/icons/more.svg';
import DeliveryIcon from '../../assets/images/icons/delivery-card.svg';
import NominationIcon from '../../assets/images/icons/nomination-card.svg';
import GeneralIcon from '../../assets/images/icons/general-card.svg';

import styles from './styles.module.sass';

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    marginLeft: -30,
    '& ul': { padding: 0 },
  },
})((props) => <Menu elevation={2} getContentAnchorEl={null} {...props} />);

const StyledMenuItem = withStyles({
  root: {
    borderBottom: '1px solid #E1E1E1',
    padding: 12,
    textTransform: 'capitalize',
  },
})((props) => <MenuItem {...props} />);

const ContractListItem = ({ t, className, contract, onAction, onMoreOption, type = 'default' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuItems = [
    { label: t('common.menu.contractSummary'), route: '/contracts/details' },
    { label: t('common.menu.contractTerms'), route: '/contracts/other-terms' },
    { label: t('common.menu.deliverySchedule'), route: '/contracts/off-take-schedule' },
    { label: t('common.menu.paymentInstructions'), route: '/contracts/payment-instructions' },
    { label: t('common.menu.documents'), route: '/contracts/documents/list' },
    { label: t('common.menu.workflow'), route: '/contracts/workflows/off-take-schedule-detail' },
    // { label: t('contracts.archive'), route: 'archive' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, item) => {
    setAnchorEl(null);
    if (onMoreOption) {
      onMoreOption(item);
    }
  };

  const defaultCard = () => {
    return (
      <Box className={styles.list_menu_content}>
        <Box className={styles.list_menu_item}>
          <Box  
            style={{ position: 'relative' }}
            onClick={() => {
              if (onAction) {
                onAction();
              }
            }}
          >
            <Box className={styles.contract_reference}>
              {contract?.contractReference?.toUpperCase() || ''}
            </Box>
            <Box className={styles.contract_volume}>
              {`${t('contracts.totalVol')}:
          ${contract?.totalVolume} MT`}
            </Box>
            <Box className={styles.sugar_spec}>{contract?.sugarSpec?.toUpperCase() || ''}</Box>
            <Box className={styles.line} />
          </Box>
          <ContractChart
            contract={contract}
            small
            onAddOffTakeSchedule={() => {
              onMoreOption({
                label: t('common.menu.deliverySchedule'),
                route: '/contracts/off-take-schedule',
              });
            }}
            onClick={() => {
              if (onAction) {
                onAction();
              }
            }}
          />
        </Box>
        <Box className={styles.action} style={{ top: 30 }}>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {menuItems.map((item, index) => (
              <StyledMenuItem key={`menui-${index}`} onClick={(event) => handleClose(event, item)}>
                {item.label}
              </StyledMenuItem>
            ))}
          </StyledMenu>
          <MatButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            data-help="Clicked More"
            onClick={handleClick}
          >
            <SvgIcon viewBox="-5 0 5 23" component={MoreIcon} style={{ color: '#000000' }} />
          </MatButton>
        </Box>
      </Box>
    );
  };

  const financierCard = () => {
    return (
      <Box className={styles.list_menu_content}>
        <Box
          className={styles.list_menu_item}
          onClick={() => {
            if (onAction) {
              onAction();
            }
          }}
        >
          <Box style={{ position: 'relative' }}>
            <Box className={styles.contract_reference}>
              {contract?.contractReference?.toUpperCase() || ''}
            </Box>
            <Box className={styles.contract_volume}>{contract?.name?.toUpperCase()}</Box>
            <Box className={styles.line} />
          </Box>
          <Box className={styles.financier_content}>
            <Box className={styles.user_content}>
              <>
                {contract?.seller && (
                  <>
                    <UserPhoto
                      className={styles.photo}
                      initials={
                        (contract?.seller?.firstName ?? '').slice(0, 1) +
                        (contract?.seller?.lastName ?? '').slice(0, 1)
                      }
                    />

                    <Box className={styles.company}>
                      {contract?.seller?.companyName?.toUpperCase() ||
                        t('common.companyName').toUpperCase()}
                    </Box>
                  </>
                )}
                <Box className={styles.role}>
                  {contract?.seller
                    ? t('contracts.generalInformation.seller').toUpperCase()
                    : t('contracts.generalInformation.noSeller')}
                </Box>
                {contract?.seller && (
                  <Box
                    className={styles.status}
                    style={{
                      opacity: contract?.seller?.status === 'waiting' ? 0.5 : 1,
                    }}
                  >
                    {`${contract?.seller?.status?.toUpperCase() || ''}`}
                  </Box>
                )}
              </>
            </Box>
            <Box className={styles.center_content}>
              <Box className={styles.you}>YOU</Box>
              <Box
                className={styles.status}
                style={{
                  opacity: contract?.legalRepresentative?.status === 'waiting' ? 0.5 : 1,
                }}
              >
                {contract?.legalRepresentative?.status?.toUpperCase() || ''}
              </Box>
              <Divider orientation="vertical" />
            </Box>
            <Box className={styles.user_content}>
              <>
                {contract?.buyer && (
                  <>
                    <UserPhoto
                      className={styles.photo}
                      initials={
                        (contract?.buyer?.firstName ?? '').slice(0, 1) +
                        (contract?.buyer?.lastName ?? '').slice(0, 1)
                      }
                    />
                    <Box className={styles.company}>
                      {contract?.buyer?.companyName?.toUpperCase() ||
                        t('common.companyName').toUpperCase()}
                    </Box>
                  </>
                )}
                <Box className={styles.role}>
                  {contract?.buyer
                    ? t('contracts.generalInformation.buyer').toUpperCase()
                    : t('contracts.generalInformation.noBuyer')}
                </Box>
                {contract?.buyer && (
                  <Box
                    className={styles.status}
                    style={{
                      opacity: contract?.buyer?.status === 'waiting' ? 0.5 : 1,
                    }}
                  >
                    {`${contract?.buyer?.status?.toUpperCase() || ''}`}
                  </Box>
                )}
              </>
            </Box>
          </Box>
        </Box>
        <Box className={styles.action} style={{ top: 20 }}>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {[t('common.viewPayment')].map((item, index) => (
              <MenuItem key={index} onClick={(event) => handleClose(event, item)}>
                {item}
              </MenuItem>
            ))}
          </StyledMenu>
          <MatButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            data-help="Clicked More"
            onClick={handleClick}
          >
            <SvgIcon viewBox="-5 0 5 23" component={MoreIcon} style={{ color: '#000000' }} />
          </MatButton>
        </Box>
      </Box>
    );
  };

  const taskCollaboratorCard = () => {
    return (
      <Box className={styles.list_menu_content}>
        <Box
          className={styles.list_menu_item}
          onClick={() => {
            if (onAction) {
              onAction();
            }
          }}
        >
          <Box style={{ position: 'relative' }}>
            <Box className={styles.contract_reference}>
              {contract?.contractReference?.toUpperCase() || ''}
            </Box>
            <Box className={styles.contract_volume}>{contract?.name?.toUpperCase()}</Box>
            <Box className={styles.line} />
          </Box>
          <Box className={styles.task_collaborator_content}>
            <Box className={styles.task_type_content}>
              <SvgIcon className={styles.icon} component={NominationIcon} viewBox="0 0 23 23" />
              <Box className={styles.type}>{t('contracts.details.workflow.nomination')}</Box>
              {/* <Box className={styles.new}>{t('common.new').toUpperCase()}</Box> */}
              <Box className={styles.number}>{contract?.nominations?.length || 0}</Box>
            </Box>
            <Divider />
            <Box className={styles.task_type_content}>
              <SvgIcon className={styles.icon} component={DeliveryIcon} viewBox="0 0 23 23" />
              <Box className={styles.type}>{t('contracts.details.workflow.delivery')}</Box>
              {/* <Box className={styles.new}>{t('common.new').toUpperCase()}</Box> */}
              <Box className={styles.number}>{contract?.deliveries?.length || 0}</Box>
            </Box>
            <Divider />
            <Box className={styles.task_type_content}>
              <SvgIcon className={styles.icon} component={GeneralIcon} viewBox="0 0 23 23" />
              <Box className={styles.type}>{t('contracts.details.workflow.general')}</Box>
              {/* <Box className={styles.new}>{t('common.new').toUpperCase()}</Box> */}
              <Box className={styles.number}>{contract?.generals?.length || 0}</Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.action} style={{ top: 20 }}>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {[t('contracts.manageWorkflow')].map((item, index) => (
              <MenuItem key={index} onClick={(event) => handleClose(event, item)}>
                {item}
              </MenuItem>
            ))}
          </StyledMenu>
          <MatButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            data-help="Clicked More"
            onClick={handleClick}
          >
            <SvgIcon viewBox="-5 0 5 23" component={MoreIcon} style={{ color: '#000000' }} />
          </MatButton>
        </Box>
      </Box>
    );
  };
  switch (type) {
    case 'default':
      return defaultCard();
    case 'financier':
      return financierCard();
    case 'task':
      return taskCollaboratorCard();
    default:
      return defaultCard();
  }
};

export default withTranslation()(ContractListItem);
