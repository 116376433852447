import React, { useState, useEffect } from 'react';
import { Box, SvgIcon, FormControlLabel, Checkbox, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import BasicDateRangePicker from '../../../components/BasicDateRangePicker';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

import CalendarIcon from '../../../assets/images/icons/calendar.svg';
import GridIcon from '../../../assets/images/icons/grid.svg';
import ListIcon from '../../../assets/images/icons/list.svg';
import AlphabetIcon from '../../../assets/images/icons/alphabet.svg';
import AlphabetIIcon from '../../../assets/images/icons/alphabet-i.svg';
import TextField from '../../../components/TextField';

const RowFilters = ({
  t,
  showAddFilterButton = false,
  onChangeView = () => {},
  typeView,
  nominations = [],
  types = [],
  getData = () => {},
  reloadData,
  documents = [],
  onAddFilters = () => {},
  onCancel = () => {},
  setDocuments = () => {},
  allDocuments = [],
  setFilters = () => {},
}) => {
  const allDocs = documents;
  const [mobileFilters, setMobileFilters] = useState(null);
  const [searchTxt, setSearchTxt] = useState('');
  const [showSuggest, setShowSuggest] = useState(false);

  const [orderTitleData, setOrderTitleData] = useState(null);
  const [orderTitle, setOrderTitle] = useState('asc');
  const [nomination, setNomination] = useState(null);
  const [docType, setDocType] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isRfp, setIsRfp] = useState(false);
  const [isRfpOld, setIsRfpOld] = useState(false);
  const [reloadDataOld, setReloadDataOld] = useState(reloadData);

  const returnTypes = () => {
    if (nomination || (dateRange[0] && dateRange[1]) || isRfp) {
      const auxTypes = {};
      documents.forEach((doc) => {
        auxTypes[doc.extension] = true;
      });
      return ['All', ...Object.keys(auxTypes).map((key) => key)];
    }
    return ['All', ...types];
  };

  const changeDate = (value) => {
    let auxDate = [...dateRange];
    if (auxDate[0] && auxDate[1]) {
      auxDate = [value[0], null];
    } else {
      auxDate = value;
    }
    setDateRange(auxDate);
  };

  const changeNom = (value) => {
    if (value === 'All') {
      setNomination({ name: 'All', value: 'All' });
    } else {
      const selected = nominations.find((nomination) => nomination.value === value);
      setNomination(selected);
    }
  };

  const changeType = (value) => {
    if (value === 'All') {
      setDocType('All');
      setTimeout(() => setDocType(''), 100);
    } else {
      setDocType(value);
    }
  };

  const orderTitleFunc = () => {
    setOrderTitle(orderTitle === 'asc' ? 'desc' : 'asc');
    setOrderTitleData(orderTitleData === 'asc' ? 'desc' : 'asc');
    setTimeout(() => setOrderTitleData(null), 50);
  };

  const formatDate = (date) => {
    return date.toISOString().replace('.000', '');
  };

  const nextConditions = (vfilters = '') => {
    let filters = vfilters;
    if (nomination && nomination.value !== 'All') {
      filters = `${filters}${filters.length > 1 ? '&' : ''}nomination=${nomination.value}`;
    }
    if (docType && docType !== 'All') {
      filters = `${filters}${filters.length > 1 ? '&' : ''}ext=${docType}`;
    }
    if (orderTitle) {
      filters = `${filters}${filters.length > 1 ? '&' : ''}title=${orderTitle}`;
    }
    if (isRfp) {
      filters = `${filters}${filters.length > 1 ? '&' : ''}rfp=${isRfp ? 'true' : 'false'}`;
    }
    setFilters(filters.length > 1 ? filters : '');
  };

  const suggestDocs = () => {
    if (searchTxt && allDocuments && allDocuments.length > 0) {
      return allDocuments.filter((doc) => {
        const titleDoc = (doc?.title || '').toLowerCase();
        return titleDoc.indexOf(searchTxt.toLowerCase()) > -1;
      });
    }
    return [];
  };

  useEffect(() => {
    if (!showSuggest && allDocuments && allDocuments.length > 0) {
      setDocuments((ov) => ({
        ...ov,
        data: allDocuments.filter((doc) => {
          const titleDoc = (doc?.title || '').toLowerCase();
          return titleDoc.indexOf(searchTxt.toLowerCase()) > -1;
        }),
      }));
    }
  }, [showSuggest]);

  useEffect(() => {
    let filters = '?';
    if (
      firstLoad &&
      ((dateRange && dateRange[0] && dateRange[1]) ||
        nomination ||
        docType ||
        orderTitleData ||
        isRfp !== isRfpOld)
    ) {
      setIsRfpOld(isRfp);
      if (dateRange && dateRange[0]) {
        if (dateRange && dateRange[0] && dateRange[1]) {
          const date1 = formatDate(dateRange[0]);
          const date2 = formatDate(dateRange[1]);
          filters = `${filters}start=${date1}&end=${date2}`;
          if (!isMobile || isTablet) {
            nextConditions(filters);
          } else {
            setMobileFilters(filters);
          }
        }
      } else if (!isMobile || isTablet) {
        nextConditions(filters);
      } else {
        setMobileFilters(filters);
      }
    } else if (firstLoad && reloadData !== reloadDataOld) {
      setReloadDataOld(reloadData);
      getData();
    } else {
      setFirstLoad(true);
    }
  }, [dateRange, nomination, docType, orderTitleData, reloadData, isRfp]);

  return (
    <Box className={styles.rowFilters} style={{ paddingRight: isMobile ? 18 : 0 }}>
      {showAddFilterButton ? (
        <Button
          clean
          inverted
          onPress={() => {
            onAddFilters();
          }}
        >
          {t('documents.addFilters')}
        </Button>
      ) : (
        <>
          {(!isMobile || isTablet) && (
            <p className={styles.lblFilters}>{t('documents.filterBy')}</p>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: isMobile && !isTablet ? 'column' : 'row',
              width: isMobile && !isTablet ? '100%' : null,
              marginLeft: isTablet ? -20 : 0,
            }}
          >
            <Box
              className={styles.inputDate}
              style={{
                maxWidth: isMobile && !isTablet ? '100%' : null,
                marginLeft: isMobile && !isTablet ? 0 : null,
              }}
            >
              <BasicDateRangePicker
                name="dateRange"
                value={dateRange}
                onChange={changeDate}
                placeholder={t('documents.submitedDate')}
                label={t('documents.submitedDate')}
                icon={<SvgIcon viewBox="0 0 22 22" component={CalendarIcon} />}
              />
            </Box>
            <Box
              className={styles.inputNomination}
              style={{
                maxWidth: isMobile && !isTablet ? '100%' : null,
                marginLeft: isMobile && !isTablet ? 0 : null,
              }}
            >
              <Select
                name="nomination"
                values={[{ label: 'All', value: 'All' }, ...nominations]}
                value={nomination?.value}
                valueKey="value"
                displayKey="label"
                onChange={changeNom}
                placeholder={t('documents.nomination')}
                label={t('documents.nomination')}
              />
            </Box>
            <Box
              className={styles.inputType}
              style={{
                maxWidth: isMobile && !isTablet ? '100%' : null,
                marginLeft: isMobile && !isTablet ? 0 : null,
              }}
            >
              <Select
                name="type"
                values={returnTypes()}
                value={docType}
                onChange={changeType}
                placeholder={t('documents.docType')}
                label={t('documents.docType')}
              />
            </Box>

            <FormControlLabel
              style={{
                maxWidth: isMobile && !isTablet ? '100%' : null,
                marginLeft: isMobile && !isTablet ? 0 : null,
              }}
              control={<Checkbox name="checkedC" color="default" />}
              label={t('documents.requiredPayment')}
              className={styles.checkbox}
              value={isRfp}
              onChange={(value) => setIsRfp(value.target.checked)}
            />
          </Box>
        </>
      )}
      {!isMobile || isTablet || showAddFilterButton ? (
        <>
          {!showAddFilterButton ? null : (
            <>
              <Box style={{ flex: 1 }} />
              <Box className={styles.btFilter} onClick={onChangeView}>
                {typeView === 'list' ? (
                  <SvgIcon viewBox="0 0 22 22" component={GridIcon} />
                ) : (
                  <SvgIcon viewBox="0 0 22 22" component={ListIcon} />
                )}
              </Box>
              <Box className={styles.btFilter} onClick={orderTitleFunc}>
                <SvgIcon
                  viewBox="0 0 24 24"
                  component={orderTitle === 'asc' ? AlphabetIcon : AlphabetIIcon}
                />
              </Box>
            </>
          )}
          <Box
            className={styles.contSearch}
            style={{
              marginLeft: isTablet ? -8 : 0,
              width: showAddFilterButton ? '100%' : 'auto',
              flex: showAddFilterButton ? 'auto' : 1,
            }}
          >
            <TextField
              label={t('documents.search')}
              value={searchTxt}
              onChange={(e) => {
                setSearchTxt(e.target.value);
              }}
              onBlur={() =>
                setTimeout(() => {
                  setShowSuggest(false);
                }, 100)
              }
              onFocus={() => setShowSuggest(true)}
            />
            {searchTxt && showSuggest && (
              <Paper className={styles.contSuggest} elevation={3}>
                {suggestDocs().length > 0 ? (
                  suggestDocs().map((item, index) => (
                    <Box
                      key={`${index}-itemsuggest`}
                      className={styles.itemSuggest}
                      onClick={() => {
                        setSearchTxt(item?.title || '');
                      }}
                    >
                      {item?.title || '-'}
                    </Box>
                  ))
                ) : (
                  <Box className={styles.itemSuggest}>{t('documents.noDocuments')}</Box>
                )}
              </Paper>
            )}
          </Box>
          {showAddFilterButton ? null : (
            <>
              <Box className={styles.btFilter} onClick={onChangeView}>
                {typeView === 'list' ? (
                  <SvgIcon viewBox="0 0 22 22" component={GridIcon} />
                ) : (
                  <SvgIcon viewBox="0 0 22 22" component={ListIcon} />
                )}
              </Box>
              <Box className={styles.btFilter} onClick={orderTitleFunc}>
                <SvgIcon
                  viewBox="0 0 24 24"
                  component={orderTitle === 'asc' ? AlphabetIcon : AlphabetIIcon}
                />
              </Box>
            </>
          )}
        </>
      ) : (
        <Box
          style={{ marginTop: 40, display: 'flex', width: '100%', justifyContent: 'space-around' }}
        >
          <Button
            onPress={() => {
              if (mobileFilters) {
                nextConditions(mobileFilters);
              } else {
                getData();
              }
              onCancel();
            }}
          >
            {t('documents.applyFilter')}
          </Button>
          <Box />
          <Button
            inverted
            bordered
            onPress={() => {
              onCancel();
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(RowFilters);
