import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MoreIcon from '../../assets/images/icons/more.svg';

import styles from './styles.module.sass';

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    marginLeft: -30,
    '& ul': { padding: 0 },
  },
})((props) => <Menu elevation={2} getContentAnchorEl={null} {...props} />);

const StyledMenuItem = withStyles({
  root: {
    borderBottom: '1px solid #E1E1E1',
    padding: 12,
    textTransform: 'capitalize',
  },
})((props) => <MenuItem {...props} />);

const MenuButton = ({ menuOptions, onAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuOptions.map((item, index) => (
          <StyledMenuItem
            key={`menu-${index}`}
            onClick={() => {
              if (onAction) {
                onAction(item);
              }
              setAnchorEl(null);
            }}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </StyledMenu>
      <MatButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        data-help="Clicked More"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <SvgIcon style={{ height: 15, width: 3 }} component={MoreIcon} viewBox="0 0 5 23" />
      </MatButton>
    </>
  );
};

export default withTranslation()(MenuButton);
