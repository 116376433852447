import ContractsActions from './actions';
import InitialState from '.';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case ContractsActions.getUserContractsRequest().type:
      return state.merge({
        getUserContractsLoading: true,
        getUserContractsError: null,
        getUserContractsCalled: true,
      });

    case ContractsActions.getUserContractsSuccess().type:
      return state.merge({
        getUserContractsLoading: false,
        getUserContractsError: null,
        getUserContractsCalled: true,

        userContracts:
          action.payload.page === 1
            ? action.payload.userContracts
            : {
                contractInfo: state.userContracts?.contractInfo?.concat(
                  action.payload.userContracts?.contractInfo
                ),
                paging: action.payload.userContracts.paging,
              },
      });

    case ContractsActions.getUserContractsFailed().type:
      return state.merge({
        getUserContractsLoading: false,
        getUserContractsError: action.payload.error,
        getUserContractsCalled: true,
      });

    case ContractsActions.resetGetUserContracts().type:
      return state.merge({
        getUserContractsLoading: false,
        getUserContractsError: null,
        getUserContractsCalled: false,
      });

    case ContractsActions.resetGetUserContractsError().type:
      return state.merge({
        getUserContractsLoading: false,
        getUserContractsError: null,
        getUserContractsCalled: true,
      });

    case ContractsActions.getContractMeRequest().type:
      return state.merge({
        getContractMeLoading: true,
        getContractMeError: null,
        getContractMeCalled: true,
      });

    case ContractsActions.getContractMeSuccess().type:
      return state.merge({
        getContractMeLoading: false,
        getContractMeError: null,
        getContractMeCalled: true,

        contractMe: action.payload.contractMe,
      });

    case ContractsActions.getContractMeFailed().type:
      return state.merge({
        getContractMeLoading: false,
        getContractMeError: action.payload.error,
        getContractMeCalled: true,
      });

    case ContractsActions.resetGetContractMe().type:
      return state.merge({
        getContractMeLoading: false,
        getContractMeError: null,
        getContractMeCalled: false,
      });

    case ContractsActions.resetGetContractMeError().type:
      return state.merge({
        getContractMeLoading: false,
        getContractMeError: null,
        getContractMeCalled: true,
      });

    case ContractsActions.updateContractSelection().type:
      return state.merge({
        contractSelection: action.payload.contractSelection,
      });

    case ContractsActions.updateContractSelectionStadistics().type:
      return state.merge({
        contractSelectionStadistics: action.payload.stadistics,
      });

    case ContractsActions.saveUserContractsRequest().type:
      return state.merge({
        saveUserContractsLoading: true,
        saveUserContractsError: null,
        saveUserContractsCalled: true,
        saveUserContractsSuccess: false,
      });

    case ContractsActions.saveUserContractsSuccess().type:
      return state.merge({
        saveUserContractsLoading: false,
        saveUserContractsError: null,
        saveUserContractsCalled: true,
        saveUserContractsSuccess: true,

        contractSelection: action.payload.contractSelection,
      });

    case ContractsActions.saveUserContractsFailed().type:
      return state.merge({
        saveUserContractsLoading: false,
        saveUserContractsError: action.payload.error,
        saveUserContractsCalled: true,
        saveUserContractsSuccess: false,
      });

    case ContractsActions.resetSaveUserContracts().type:
      return state.merge({
        saveUserContractsLoading: false,
        saveUserContractsError: null,
        saveUserContractsCalled: false,
        saveUserContractsSuccess: false,
      });

    case ContractsActions.resetSaveUserContractsError().type:
      return state.merge({
        saveUserContractsLoading: false,
        saveUserContractsError: null,
        saveUserContractsCalled: true,
        saveUserContractsSuccess: false,
      });

    case ContractsActions.getUserContractRequest().type:
      return state.merge({
        getUserContractLoading: true,
        getUserContractError: null,
        getUserContractCalled: true,
      });

    case ContractsActions.getUserContractSuccess().type:
      return state.merge({
        getUserContractLoading: false,
        getUserContractError: null,
        getUserContractCalled: true,

        contractSelection: action.payload.contractSelection,
      });

    case ContractsActions.getUserContractFailed().type:
      return state.merge({
        getUserContractLoading: false,
        getUserContractError: action.payload.error,
        getUserContractCalled: true,
      });

    case ContractsActions.resetGetUserContract().type:
      return state.merge({
        getUserContractLoading: false,
        getUserContractError: null,
        getUserContractCalled: false,
      });

    case ContractsActions.resetGetUserContractError().type:
      return state.merge({
        getUserContractLoading: false,
        getUserContractError: null,
        getUserContractCalled: true,
      });

    case ContractsActions.deleteUserContractsRequest().type:
      return state.merge({
        deleteUserContractsLoading: true,
        deleteUserContractsError: null,
        deleteUserContractsCalled: true,
      });

    case ContractsActions.deleteUserContractsSuccess().type:
      return state.merge({
        deleteUserContractsLoading: false,
        deleteUserContractsError: null,
        deleteUserContractsCalled: true,

        contractSelection: null,
      });

    case ContractsActions.deleteUserContractsFailed().type:
      return state.merge({
        deleteUserContractsLoading: false,
        deleteUserContractsError: action.payload.error,
        deleteUserContractsCalled: true,
      });

    case ContractsActions.resetDeleteUserContracts().type:
      return state.merge({
        deleteUserContractsLoading: false,
        deleteUserContractsError: null,
        deleteUserContractsCalled: false,
      });

    case ContractsActions.resetDeleteUserContractsError().type:
      return state.merge({
        deleteUserContractsLoading: false,
        deleteUserContractsError: null,
        deleteUserContractsCalled: true,
      });

    case ContractsActions.updateSelectedUserDenied().type:
      return state.merge({
        selectedUserDenied: action.payload.user,
      });

    //  UPDATE USER
    case ContractsActions.updateUserContractRequest().type:
      return state.merge({
        updateUserContractLoading: true,
        updateUserContractError: null,
        updateUserContractCalled: true,
      });

    case ContractsActions.updateUserContractSuccess().type:
      return state.merge({
        updateUserContractLoading: false,
        updateUserContractError: null,
        updateUserContractCalled: true,
      });

    case ContractsActions.updateUserContractFailed().type:
      return state.merge({
        updateUserContractLoading: false,
        updateUserContractError: action.payload.error,
        updateUserContractCalled: true,
      });

    case ContractsActions.resetUpdateUserContract().type:
      return state.merge({
        updateUserContractLoading: false,
        updateUserContractError: null,
        updateUserContractCalled: false,
      });

    case ContractsActions.resetUpdateUserContractError().type:
      return state.merge({
        updateUserContractLoading: false,
        updateUserContractError: null,
        updateUserContractCalled: true,
      });

    //  ACCEPT INVITE
    case ContractsActions.acceptInviteRequest().type:
      return state.merge({
        acceptInviteLoading: true,
        acceptInviteError: null,
        acceptInviteCalled: true,
      });

    case ContractsActions.acceptInviteSuccess().type:
      return state.merge({
        acceptInviteLoading: false,
        acceptInviteError: null,
        acceptInviteCalled: true,
      });

    case ContractsActions.acceptInviteFailed().type:
      return state.merge({
        acceptInviteLoading: false,
        acceptInviteError: action.payload.error,
        acceptInviteCalled: true,
      });

    case ContractsActions.resetAcceptInvite().type:
      return state.merge({
        acceptInviteLoading: false,
        acceptInviteError: null,
        acceptInviteCalled: false,
      });

    case ContractsActions.resetAcceptInviteError().type:
      return state.merge({
        acceptInviteLoading: false,
        acceptInviteError: null,
        acceptInviteCalled: true,
      });

    //  GET TOTAL STADISTICS
    case ContractsActions.getTotalStadisticsRequest().type:
      return state.merge({
        getTotalStadisticsLoading: true,
        getTotalStadisticsError: null,
        getTotalStadisticsCalled: true,
      });

    case ContractsActions.getTotalStadisticsSuccess().type:
      return state.merge({
        getTotalStadisticsLoading: false,
        getTotalStadisticsError: null,
        getTotalStadisticsCalled: true,

        totalStadistics: action.payload.totalStadistics,
      });

    case ContractsActions.getTotalStadisticsFailed().type:
      return state.merge({
        getTotalStadisticsLoading: false,
        getTotalStadisticsError: action.payload.error,
        getTotalStadisticsCalled: true,
      });

    case ContractsActions.resetGetTotalStadistics().type:
      return state.merge({
        getTotalStadisticsLoading: false,
        getTotalStadisticsError: null,
        getTotalStadisticsCalled: false,
      });

    case ContractsActions.resetGetTotalStadisticsError().type:
      return state.merge({
        getTotalStadisticsLoading: false,
        getTotalStadisticsError: null,
        getTotalStadisticsCalled: true,
      });

    case ContractsActions.getContractsUsersRequest().type:
      return state.merge({
        getContractsUsersLoading: true,
        getContractsUsersError: null,
        getContractsUsersCalled: true,
      });

    case ContractsActions.getContractsUsersSuccess().type:
      return state.merge({
        getContractsUsersLoading: false,
        getContractsUsersError: null,
        getContractsUsersCalled: true,

        contractsUsers: action.payload.users,
      });

    case ContractsActions.getContractsUsersFailed().type:
      return state.merge({
        getContractsUsersLoading: false,
        getContractsUsersError: action.payload.error,
        getContractsUsersCalled: true,
      });

    case ContractsActions.resetGetContractsUsers().type:
      return state.merge({
        getContractsUsersLoading: false,
        getContractsUsersError: null,
        getContractsUsersCalled: false,
      });

    case ContractsActions.resetGetContractsUsersError().type:
      return state.merge({
        getContractsUsersLoading: false,
        getContractsUsersError: null,
        getContractsUsersCalled: true,
      });

    case ContractsActions.getContractsProductsRequest().type:
      return state.merge({
        getContractsProductsLoading: true,
        getContractsProductsError: null,
        getContractsProductsCalled: true,
      });

    case ContractsActions.getContractsProductsSuccess().type:
      return state.merge({
        getContractsProductsLoading: false,
        getContractsProductsError: null,
        getContractsProductsCalled: true,

        contractsProducts: action.payload.products,
      });

    case ContractsActions.getContractsProductsFailed().type:
      return state.merge({
        getContractsProductsLoading: false,
        getContractsProductsError: action.payload.error,
        getContractsProductsCalled: true,
      });

    case ContractsActions.resetGetContractsProducts().type:
      return state.merge({
        getContractsProductsLoading: false,
        getContractsProductsError: null,
        getContractsProductsCalled: false,
      });

    case ContractsActions.resetGetContractsProductsError().type:
      return state.merge({
        getContractsProductsLoading: false,
        getContractsProductsError: null,
        getContractsProductsCalled: true,
      });

    case ContractsActions.filterUpdate().type:
      return state.merge({
        filterDateRange: action.payload.dateRange,
        filterBuyer: action.payload.buyer,
        filterSeller: action.payload.seller,
        filterProduct: action.payload.product,
        filterSort: action.payload.sort,
        filterStatistic: action.payload.statistic,
      });

    case ContractsActions.getAutocompleteSuccess().type:
      return state.merge({
        getAutocompleteLoading: false,
        getAutocompleteError: null,
        getAutocompleteCalled: true,
        autocomplete: action.payload.autocomplete,
      });

    case ContractsActions.getAutocompleteFailed().type:
      return state.merge({
        getAutocompleteLoading: false,
        getAutocompleteError: action.payload.error,
        getAutocompleteCalled: true,
      });

    case ContractsActions.resetGetAutocomplete().type:
      return state.merge({
        getAutocompleteLoading: false,
        getAutocompleteError: null,
        getAutocompleteCalled: false,
      });

          case ContractsActions.resetGetAutocompleteError().type:
      return state.merge({
        getAutocompleteLoading: false,
        getAutocompleteError: null,
        getAutocompleteCalled: true,
      });

    default:
      return state;
  }
};
