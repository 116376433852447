import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import NavigationBar from '../../../../components/NavigationBar';
import ContractBox from '../../../../components/ContractBox';
import OffTakeSchedules from '../../../../components/OffTakeSchedules';
import MessageModal from '../../../../modals/message';
import Loading from '../../../../components/loading';

import OffTakeScheduleActions from '../../../../redux/offTakeSchedule/actions';
import WorkflowActions from '../../../../redux/workflow/actions';
import AuthActions from '../../../../redux/auth/actions';

import styles from './styles.module.sass';

const ListOffTakeSchedules = ({ t, history }) => {
  const dispatch = useDispatch();

  const [drawerOpened, setDrawerOpened] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const { contractSelection } = useSelector((state) => state.contracts);

  const {
    getOffTakeSchedulesLoading,
    getOffTakeSchedulesError,
    getOffTakeSchedulesCalled,
    offTakeSchedules,
  } = useSelector((state) => state.offTakeSchedule);

  useEffect(() => {
    if (!getOffTakeSchedulesCalled && contractSelection) {
      dispatch(OffTakeScheduleActions.getOffTakeSchedules(contractSelection?.id));
    }
    return () => {};
  }, [dispatch, contractSelection, OffTakeScheduleActions, getOffTakeSchedulesCalled]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  return (
    <Box className={styles.container}>
      <Loading isLoading={getOffTakeSchedulesLoading} />
      <NavigationBar
        title={t('contracts.details.workflow.offTakeSchedule.title')}
        hasMenuButton={!isMobile}
        barColor={isMobile ? '#fff' : '#111'}
        white={!isMobile}
        hasBackButton
        hasNotificationButton
        hasFilterButton={!isMobile}
        history={history}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Box className={clsx(styles.main_content)}>
            <Container maxWidth="md" className={styles.content}>
              <ContractBox contract={contractSelection} />
              <OffTakeSchedules history={history} contract={contractSelection} />
            </Container>
          </Box>
        }
      />
      {getOffTakeSchedulesCalled && !getOffTakeSchedulesLoading && getOffTakeSchedulesError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getOffTakeSchedulesError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(OffTakeScheduleActions.resetGetOffTakeScheduleError());
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(ListOffTakeSchedules);
