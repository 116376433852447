import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Container, Box, Divider, SvgIcon, Tooltip } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isMobile, isTablet } from 'react-device-detect';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import TextField from '../TextField';
import Button from '../Button';
import Select from '../Select';
import MessageModal from '../../modals/message';
import ModalView from '../../modals/modalView';

import DeleteIcon from '../../assets/images/icons/delete.svg';
import EditIcon from '../../assets/images/icons/edit_item.svg';

import InvitesActions from '../../redux/invites/actions';

import styles from './styles.module.sass';

const ContractInvites = ({ t, history, match }) => {
  const dispatch = useDispatch();

  const [tempInvites, setTempInvites] = useState([]);
  const [showRolePermission, setShowRolePermission] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const { contractSelection } = useSelector((state) => state.contracts);

  const {
    getInvitesLoading,
    getInvitesError,
    getInvitesCalled,
    invites,

    createInvitesLoading,
    createInvitesError,
    createInvitesCalled,

    updateInvitesLoading,
    updateInvitesError,
    updateInvitesCalled,

    deleteInvitesLoading,
    deleteInvitesError,
    deleteInvitesCalled,

    updateUserInvitesLoading,
    updateUserInvitesError,
    updateUserInvitesCalled,

    getRolesLoading,
    getRolesError,
    getRolesCalled,
    roles,
  } = useSelector((state) => state.invites);

  useEffect(() => {
    if (!getRolesCalled && !roles) {
      dispatch(InvitesActions.getRoles('?visible=true'));
    }
  }, [getRolesCalled, roles]);

  useEffect(() => {
    if (!getInvitesCalled && contractSelection) {
      dispatch(InvitesActions.getInvites(contractSelection?.id));
    }
  }, [getInvitesCalled, contractSelection]);

  useEffect(() => {
    if (contractSelection?.id) {
      if (invites?.length > 0) {
        setTempInvites((tempInvites) => [
          ...invites.map((invite) => {
            return {
              id: invite.id,
              role: invite.roleSlug,
              contract: invite.contract,
              email: invite.email,
              firstName: invite.firstName,
              lastName: invite.lastName,
              editing: false,
            };
          }),
        ]);
      }
    }
  }, [contractSelection, invites]);

  useEffect(() => {
    return () => {
      dispatch(InvitesActions.resetCreateInvites());
      dispatch(InvitesActions.resetUpdateInvites());
      dispatch(InvitesActions.resetDeleteInvites());
      dispatch(InvitesActions.resetGetRoles());
    };
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
  });

  function handleSubmit(values) {
    const invite = {
      contract: contractSelection?.id,
      email: values.email,
      role: null,
      editing: true,
      added: true,
    };
    setTempInvites((tempInvites) => [...tempInvites, invite]);
  }

  function send() {
    if (tempInvites.length > 0) {
      // CREATE
      const createInvites = tempInvites
        .filter((invite) => !invite.id && !invite.editing)
        .map((invite) => {
          delete invite.editing;
          delete invite.edited;
          delete invite.added;

          return invite;
        });
      if (createInvites.length > 0) {
        dispatch(
          InvitesActions.createInvites({
            collaborators: createInvites,
          })
        );
      }

      // UPDATE
      const updateInvites = tempInvites
        .filter((invite) => invite.id && invite.edited && !invite.deleted && !invite.editing)
        .map((invite) => {
          return { id: invite.id, role: invite.role };
        });
      if (updateInvites.length > 0) {
        dispatch(
          InvitesActions.updateInvites({
            collaborators: updateInvites,
          })
        );
      }
      // DELETE
      const deleteInvites = tempInvites
        .filter((invite) => invite.id && invite.deleted && !invite.editing)
        .map((invite) => {
          return { id: invite.id };
        });
      if (deleteInvites.length > 0) {
        dispatch(
          InvitesActions.deleteInvites({
            collaborators: deleteInvites,
          })
        );
      }
    }
  }

  function toHumanText(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  function drawItem(value, index) {
    if (value.deleted) {
      return null;
    }
    return (
      <Box key={index}>
        <Box className={styles.item_box}>
          <Box className={styles.data}>
            <Tooltip title={value.email}>
              <Box className={styles.email}>{value.email}</Box>
            </Tooltip>
            <Box className={styles.role}>
              {value.role !== 'commercialOwner' && value.role !== 'commercialExecutive' ? (
                <Select
                  disabled={!value.editing}
                  isFilled={false}
                  values={roles || []}
                  valueKey="slug"
                  displayKey="name"
                  value={value.roleSlug || value.role || ''}
                  onChange={
                    value.editing
                      ? (selectValue) => {
                          if (selectValue !== value.role) {
                            const editedValue = { ...value };
                            editedValue.role = selectValue;
                            editedValue.editing = false;
                            setTempInvites((tempInvites) => [
                              ...tempInvites.filter((el) => el !== value),
                            ]);
                            setTempInvites((tempInvites) => [...tempInvites, editedValue]);
                          }
                        }
                      : null
                  }
                />
              ) : (
                <Box style={{ paddingLeft: 10 }}>
                  {t(`contracts.addCollaborators.roles.${value.role}`)}
                </Box>
              )}
            </Box>
          </Box>
          {value.role !== 'commercialOwner' && value.role !== 'commercialExecutive' && (
            <Box className={styles.actions}>
              {value.editing ? (
                <Button
                  align="left"
                  padding="0 0 0 5px"
                  margin={0}
                  clean
                  className={styles.icon_container}
                  onPress={() => {
                    if (value.id) {
                      const editedValue = { ...value };
                      editedValue.editing = false;
                      editedValue.deleted = true;
                      setTempInvites((tempInvites) => [
                        ...tempInvites.filter((el) => el !== value),
                      ]);
                      setTempInvites((tempInvites) => [...tempInvites, editedValue]);
                    } else {
                      setTempInvites((tempInvites) => [
                        ...tempInvites.filter((el) => el !== value),
                      ]);
                    }
                  }}
                >
                  <SvgIcon
                    style={{ height: 18, width: 18 }}
                    component={DeleteIcon}
                    viewBox="0 0 17 17"
                  />
                </Button>
              ) : (
                <Button
                  align="left"
                  padding="0 0 0 5px"
                  margin={0}
                  clean
                  className={styles.icon_container}
                  onPress={() => {
                    const editedValue = { ...value };
                    editedValue.editing = true;
                    editedValue.edited = true;
                    setTempInvites((tempInvites) => [...tempInvites.filter((el) => el !== value)]);
                    setTempInvites((tempInvites) => [...tempInvites, editedValue]);
                  }}
                >
                  <SvgIcon
                    style={{ height: 18, width: 18 }}
                    component={EditIcon}
                    viewBox="0 0 17 17"
                  />
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
      </Box>
    );
  }

  function showError() {
    if (
      (createInvitesError || updateInvitesError || deleteInvitesError) &&
      !createInvitesLoading &&
      !updateInvitesLoading &&
      !deleteInvitesLoading
    ) {
      return (
        <MessageModal
          icon={null}
          title={t('common.error')}
          messages={[
            createInvitesError?.userMessage,
            updateInvitesError?.userMessage,
            deleteInvitesError?.userMessage,
          ]}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetCreateInvitesError());
            dispatch(InvitesActions.resetDeleteInvitesError());
            dispatch(InvitesActions.resetUpdateInvitesError());
          }}
        />
      );
    }
    return null;
  }

  function showSuccess() {
    if (
      (!createInvitesCalled ||
        (createInvitesCalled && !createInvitesLoading && !createInvitesError)) &&
      (!updateInvitesCalled ||
        (updateInvitesCalled && !updateInvitesLoading && !updateInvitesError)) &&
      (!deleteInvitesCalled ||
        (deleteInvitesCalled && !deleteInvitesLoading && !deleteInvitesError))
    ) {
      return (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.addCollaborators.sentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetGetInvites());
            dispatch(InvitesActions.resetCreateInvites());
            dispatch(InvitesActions.resetUpdateInvites());
            dispatch(InvitesActions.resetDeleteInvites());
          }}
        />
      );
    }
    return null;
  }

  const TeamTitle = () => (
    <Box
      className={styles.team_card_title_container}
      style={
        isMobile && !isTablet
          ? { height: 46, backgroundColor: 'black', borderRadius: '5px 5px 0 0', padding: '0 20px' }
          : { marginBottom: 15 }
      }
    >
      <Box
        className={styles.team_card_title}
        style={isMobile && !isTablet ? { color: 'white', fontSize: 15 } : {}}
      >
        {t('contracts.details.invite.contractTeam')}
      </Box>
      <Box
        style={isMobile && !isTablet ? { color: 'white', fontSize: 11 } : {}}
        className={styles.team_card_action}
        onClick={() => {
          setShowRolePermission(true);
        }}
      >
        {t('contracts.addCollaborators.seeRolePermissions')}
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile && !isTablet && <TeamTitle />}

      <Box
        className={styles.team_card}
        style={{
          borderTop: isMobile && !isTablet ? '' : '10px solid black',
          boxShadow: '0px 5px 8px 0 #ededed, 0px 5px 8px 0 #f6f6f6',
        }}
      >
        {(!isMobile || isTablet) && <TeamTitle />}

        <Box className={styles.container_step_description}>
          {t('contracts.addCollaborators.description')}
        </Box>
        <Box>
          <Formik
            enableReinitialize
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm({ values: { email: '' } });
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box className={styles.container_inputs}>
                  <Box className={styles.container_names}>
                    <Box className={clsx(styles.container_first_field, styles.container_input)}>
                      <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        helperText={errors.email && touched.email && errors.email}
                        placeholder={t('common.email')}
                        label={t('common.email').toUpperCase()}
                        required
                      />
                    </Box>
                    <Box className={clsx(styles.container_last_field, styles.container_input)}>
                      <Box className={styles.container_submit}>
                        <Button
                          type="submit"
                          disabled={createInvitesLoading}
                          loading={createInvitesLoading}
                          data-info-submit="Submit"
                        >
                          {t('contracts.addCollaborators.add')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        {(tempInvites?.length > 0 || invites?.length > 0) && (
          <Box className={styles.list_content}>
            <Box className={styles.added_content}>
              <Box className={styles.added_list}>
                <Divider style={{ marginTop: 30 }} />

                {tempInvites?.map((value, index) => {
                  if (value.editing || value.edited || value.added) {
                    return drawItem(value, index);
                  }
                  return null;
                })}

                {tempInvites?.filter((value) => {
                  if (value.editing || value.edited || value.added) {
                    return true;
                  }
                  return false;
                }).length > 0 && (
                  <Box className={styles.container_send_action}>
                    <Button
                      disabled={createInvitesLoading}
                      loading={createInvitesLoading || updateInvitesLoading || deleteInvitesLoading}
                      className={styles.button_alone}
                      minWidth={130}
                      maxWidth={270}
                      onPress={() => {
                        send();
                      }}
                    >
                      {t('contracts.addCollaborators.send')}
                    </Button>
                  </Box>
                )}

                <Box className={styles.container_step_title}>
                  <Box>{t('contracts.addCollaborators.listTitle')}</Box>
                </Box>

                {tempInvites?.map((value, index) => {
                  if (!value.edited && !value.added && !value.editing) {
                    return drawItem(value, index);
                  }
                  return null;
                })}
              </Box>
            </Box>
          </Box>
        )}

        {(createInvitesCalled || updateInvitesCalled || deleteInvitesCalled) && showSuccess()}
        {(createInvitesCalled || updateInvitesCalled || deleteInvitesCalled) && showError()}
        {getInvitesCalled && !getInvitesLoading && getInvitesError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={getInvitesError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(InvitesActions.resetGetInvitesError());
            }}
          />
        )}
        {updateUserInvitesCalled && !updateUserInvitesLoading && !updateUserInvitesError && (
          <MessageModal
            title={t('common.success')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(InvitesActions.resetUpdateUserInvites());
              history.goBack();
            }}
          />
        )}
        {updateUserInvitesCalled && !updateUserInvitesLoading && updateUserInvitesError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={updateUserInvitesError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(InvitesActions.resetUpdateUserInvitesError());
            }}
          />
        )}
        {getRolesCalled && !getRolesLoading && getRolesError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={getRolesError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(InvitesActions.resetGetRolesError());
            }}
          />
        )}

        {showRolePermission && (
          <ModalView
            view={
              <Container
                maxWidth="md"
                className={styles.modal_content}
                style={!isMobile && !isTablet ? { minWidth: 600 } : {}}
              >
                <Box className={styles.container_title}>
                  <Box component="span" style={{ color: '#2f2f2f' }} className={styles.title}>
                    {t('contracts.addCollaborators.rolePermissions.title').toUpperCase()}
                  </Box>
                </Box>
                <Box className={styles.container_step_description}>
                  <Box className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.description')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.roles.commercialExecutive').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.commercialExecutiveDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.roles.contractAgent').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.contractAgentDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.roles.exportAgent').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.exportAgentDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.roles.financier').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.financierDescription')}
                  </Box>
                  <Box style={{ marginTop: 30 }} className="form_title">
                    {t('contracts.addCollaborators.roles.financier2').toUpperCase()}
                  </Box>
                  <Box style={{ marginTop: 12 }} className="subtitle">
                    {t('contracts.addCollaborators.rolePermissions.financier2Description')}
                  </Box>
                </Box>
              </Container>
            }
            onClose={() => {
              setShowRolePermission(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default withRouter(withTranslation()(ContractInvites));
