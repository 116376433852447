import MockAdapter from 'axios-mock-adapter';
import { axios } from './api';

export default () => {
  const mock = new MockAdapter(axios, { delayResponse: 2000 });

  // mock.onGet("payments/instructions").reply(200, {
  //   data: [
  //     {
  //       id: "1",
  //       ownerId: 17,
  //       contract: "123456789",
  //       buyerName: "Lorem ipsum",
  //       accountHolderName: "Lorem ipsum",
  //       bankName: "Bank AZR",
  //       routingNumber: "2345678",
  //       accountNumber: "72448927",
  //       financier: "Lorem ipsum",
  //       legalName: "Lorem ipsum"
  //     },
  //     {
  //       id: "6",
  //       ownerId: 6,
  //       contract: "123456789",
  //       buyerName: "Lorem ipsum",
  //       accountHolderName: "Lorem ipsum",
  //       bankName: "Bank AZR",
  //       routingNumber: "2345678",
  //       accountNumber: "72448927",
  //       financier: null,
  //       legalName: null
  //     },
  //   ],
  // });

  // mock.onPost("/payment").reply(200);
  // mock.onPut("/payment").reply(200);
  // mock.onPost("/deny-payment").reply(200);
  mock.onAny().passThrough();
};
