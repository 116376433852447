import { create } from 'apisauce';

import { GenericError } from '../utils/errors';
// define the api
const api = create({
  baseURL: process.env.REACT_APP_FILES_BASE_URL,
});

export const axios = api.axiosInstance;

export default api;

export const uploadFile = async (file) => {
  try {
    const form = new FormData();
    form.append('file', file);
    form.append('provider', 'GoogleCloud');
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await api.post('files', form, headers);

    /** awaiting fixes * */
    /* if (!responseSignUrl.ok) {
      throw new GenericError('sign url not responding ok')
    }

    if (!responseSignUrl.data?.data) {
      throw new GenericError('sign url data not found')
    }

    const responseSignUrlData = responseSignUrl.data.data
    const signedUrl = responseSignUrlData.urlSigned

    const signedURLAPI = create({
      baseURL: signedUrl.url,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })

    const response = await signedURLAPI.put('/', file) */

    if (!response.ok) {
      throw new GenericError('google sign url not responding ok');
    }

    /* return `${responseSignUrlData.url}/${responseSignUrlData.file_name}` */
    return response.data.data.url;
  } catch (err) {
    throw new GenericError(err);
  }
};
