import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import RowFilters from './rowFilters';

function MobileFilters({
  onChangeView = () => {},
  typeView,
  nominations = [],
  types = [],
  getData = () => {},
  reloadData,
  documents = [],
  onCancel = () => {},
}) {
  return (
    <Box style={{ paddingBottom: 100 }}>
      <RowFilters
        onChangeView={onChangeView}
        typeView={typeView}
        nominations={nominations}
        types={types}
        getData={getData}
        reloadData={reloadData}
        documents={documents}
        onCancel={onCancel}
      />
    </Box>
  );
}

export default withTranslation()(MobileFilters);
