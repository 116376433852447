import Immutable from 'seamless-immutable';

const initialState = Immutable({
  getTermsLoading: false,
  getTermsError: null,
  getTermsCalled: false,
  terms: null,

  createTermsLoading: false,
  createTermsError: null,
  createTermsCalled: false,

  updateTermsLoading: false,
  updateTermsError: null,
  updateTermsCalled: false,

  deleteTermsLoading: false,
  deleteTermsError: null,
  deleteTermsCalled: false,

  updateUserTermsLoading: false,
  updateUserTermsError: null,
  updateUserTermsCalled: false,
});

export default initialState;
