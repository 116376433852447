import { createActions } from 'redux-actions';
import amplitude from 'amplitude-js';

import {
  getTask as getTaskService,
  getSubtask as getSubtaskService,
  getTasks as getTasksService,
  postTask as postTaskService,
  putTask as putTaskService,
  putRequirementsGeneralTask as putRequirementsGeneralTaskService,
  putRequirementsDeliveryTask as putRequirementsDeliveryTaskService,
  putRequirementsNominationTask as putRequirementsNominationTaskService,
  deleteTask as deleteTaskService,
  putTaskState as putTaskStateService,
  putDocumentState as putDocumentStateService,
  uploadDocument as uploadDocumentService,
  deleteDocument,
} from '../../services/workflow';

import { GenericError } from '../../utils/errors';

const WorkflowActions = createActions({
  GET_TASK_REQUEST: () => {},
  GET_TASK_SUCCESS: (task) => ({ task }),
  GET_TASK_FAILED: (error) => ({ error }),
  RESET_GET_TASK: () => {},
  RESET_GET_TASK_ERROR: () => {},

  GET_SUBTASK_REQUEST: () => {},
  GET_SUBTASK_SUCCESS: (task) => ({ task }),
  GET_SUBTASK_FAILED: (error) => ({ error }),
  RESET_GET_SUBTASK: () => {},
  RESET_GET_SUBTASK_ERROR: () => {},

  GET_TASKS_REQUEST: () => {},
  GET_TASKS_SUCCESS: (tasks) => ({ tasks }),
  GET_TASKS_FAILED: (error) => ({ error }),
  RESET_GET_TASKS: () => {},
  RESET_GET_TASKS_ERROR: () => {},
  RESET_GET_TASKS_ALL: () => {},

  SAVE_TASK_FORM_DATA: (formData) => ({ formData }),
  RESET_TASK_FORM_DATA: () => {},
  RESET_TASK_FORM_DATA_ERROR: () => {},
  SAVE_TASK_REQUEST: () => {},
  SAVE_TASK_SUCCESS: () => {},
  SAVE_TASK_FAILED: (error) => ({ error }),
  RESET_SAVE_TASK: () => {},
  RESET_SAVE_TASK_ERROR: () => {},

  UPDATE_TASK_REQUEST: () => {},
  UPDATE_TASK_SUCCESS: () => {},
  UPDATE_TASK_FAILED: (error) => ({ error }),
  RESET_UPDATE_TASK: () => {},
  RESET_UPDATE_TASK_ERROR: () => {},

  DELETE_TASK_REQUEST: () => {},
  DELETE_TASK_SUCCESS: () => {},
  DELETE_TASK_FAILED: (error) => ({ error }),
  RESET_DELETE_TASK: () => {},
  RESET_DELETE_TASK_ERROR: () => {},

  UPDATE_REQUIREMENT_TASK_REQUEST: () => {},
  UPDATE_REQUIREMENT_TASK_SUCCESS: () => {},
  UPDATE_REQUIREMENT_TASK_FAILED: (error) => ({ error }),
  RESET_UPDATE_REQUIREMENT_TASK: () => {},
  RESET_UPDATE_REQUIREMENT_TASK_ERROR: () => {},

  UPDATE_OFF_TAKE_SCHEDULE_SELECTION: (offTakeSchedule) => ({ offTakeSchedule }),
  UPDATE_TASK_SELECTION: (task) => ({ task }),
  UPDATE_SUBTASK_SELECTION: (task) => ({ task }),
  UPDATE_PARENT_TASK_SELECTION: (task) => ({ task }),

  UPDATE_USERS_INVITE_TASK: (users) => ({ users }),

  UPDATE_TASK_STATE_REQUEST: () => {},
  UPDATE_TASK_STATE_SUCCESS: () => {},
  UPDATE_TASK_STATE_FAILED: (error) => ({ error }),
  RESET_UPDATE_TASK_STATE: () => {},
  RESET_UPDATE_TASK_STATE_ERROR: () => {},

  UPDATE_DOCUMENT_STATE_REQUEST: () => {},
  UPDATE_DOCUMENT_STATE_SUCCESS: () => {},
  UPDATE_DOCUMENT_STATE_FAILED: (error) => ({ error }),
  RESET_UPDATE_DOCUMENT_STATE: () => {},
  RESET_UPDATE_DOCUMENT_STATE_ERROR: () => {},

  UPLOAD_DOCUMENT_REQUEST: () => {},
  UPLOAD_DOCUMENT_SUCCESS: () => {},
  UPLOAD_DOCUMENT_FAILED: (error) => ({ error }),
  RESET_UPLOAD_DOCUMENT: () => {},
  RESET_UPLOAD_DOCUMENT_ERROR: () => {},

  DELETE_DOCUMENT_REQUEST: () => {},
  DELETE_DOCUMENT_SUCCESS: () => {},
  DELETE_DOCUMENT_FAILED: (error) => ({ error }),
  RESET_DELETE_DOCUMENT: () => {},
  RESET_DELETE_DOCUMENT_ERROR: () => {},
});

WorkflowActions.getTasks = (id) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.getTasksRequest());
      const result = await getTasksService(id);
      if (result.ok) {
        const newTasks = result.data.data.map((value) => {
          const valueCopy = { ...value };
          const subTasksCopy = valueCopy.subTasks ? [...valueCopy.subTasks] : [];
          delete valueCopy.subTasks;
          subTasksCopy.push({ ...valueCopy });
          valueCopy.subTasks = subTasksCopy.sort(function (a, b) {
            return new Date(a.completionDate || a.date) - new Date(b.completionDate || b.date);
          });
          return valueCopy;
        });
        dispatch(WorkflowActions.getTasksSuccess(newTasks));
      } else if (result.data) {
        dispatch(
          WorkflowActions.getTasksFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.getTasksFailed(null));
      } else {
        dispatch(
          WorkflowActions.getTasksFailed(new GenericError('something went wrong on get tasks'))
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.getTasksFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.getTask = (id, type) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.getTaskRequest());
      const result = await getTaskService(id);
      if (result.ok) {
        dispatch(WorkflowActions.getTaskSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          WorkflowActions.getTaskFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.getTaskFailed(null));
      } else {
        dispatch(
          WorkflowActions.getTaskFailed(new GenericError('something went wrong on get task'))
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.getTaskFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.getSubtask = (id, type) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.getSubtaskRequest());
      const result = await getSubtaskService(id);
      if (result.ok) {
        dispatch(WorkflowActions.getSubtaskSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          WorkflowActions.getSubtaskFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.getSubtaskFailed(null));
      } else {
        dispatch(
          WorkflowActions.getSubtaskFailed(new GenericError('something went wrong on get task'))
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.getSubtaskFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.saveTask = (data, type, payload) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.saveTaskRequest());

      const result = await postTaskService(data);
      if (result.ok) {
        if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
          amplitude.getInstance().logEvent('Create Task', {
            userId: payload.userId,
            contractId: payload.contractId,
            taskId: result.data.data.id,
            task: type,
            userRole: payload.role,
            date: new Date(),
          });
        }
        if (
          (!data?.id && data?.collaborators?.length > 0) ||
          data?.collaborators?.added?.length > 0
        ) {
          amplitude.getInstance().logEvent('Invite Task', {
            userId: payload.userId,
            contractId: payload.contractId,
            taskId: result.data.data.id,
            task: type,
            userRole: payload.role,
            date: new Date(),
          });
        }
        dispatch(WorkflowActions.saveTaskSuccess());
      } else if (result.data) {
        dispatch(
          WorkflowActions.saveTaskFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.saveTaskFailed(null));
      } else {
        dispatch(
          WorkflowActions.saveTaskFailed(new GenericError('something went wrong on save task'))
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.saveTaskFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.updateTask = (id, data, type, idTaskSchedule) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.updateTaskRequest());
      const result = await putTaskService(id, data);
      if (result.ok) {
        dispatch(WorkflowActions.updateTaskSuccess());
        if (idTaskSchedule) {
          dispatch(WorkflowActions.getTasks(idTaskSchedule));
        }
      } else if (result.data) {
        dispatch(
          WorkflowActions.updateTaskFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.updateTaskFailed(null));
      } else {
        dispatch(
          WorkflowActions.updateTaskFailed(new GenericError('something went wrong on update task'))
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.updateTaskFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.deleteTask = (id, type) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.deleteTaskRequest());
      const result = await deleteTaskService(id);
      if (result.ok) {
        dispatch(WorkflowActions.deleteTaskSuccess());
      } else if (result.data) {
        dispatch(
          WorkflowActions.deleteTaskFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.deleteTaskFailed(null));
      } else {
        dispatch(
          WorkflowActions.deleteTaskFailed(new GenericError('something went wrong on delete task'))
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.deleteTaskFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.updateTaskState = (id, data, idTaskSchedule) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.updateTaskStateRequest());
      const result = await putTaskStateService(id, data);
      if (result.ok) {
        dispatch(WorkflowActions.updateTaskStateSuccess());
        if (idTaskSchedule) {
          dispatch(WorkflowActions.getTasks(idTaskSchedule));
        }
      } else if (result.data) {
        dispatch(
          WorkflowActions.updateTaskStateFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.updateTaskStateFailed(null));
      } else {
        dispatch(
          WorkflowActions.updateTaskStateFailed(
            new GenericError('something went wrong on update task state')
          )
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.updateTaskStateFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.updateDocumentState = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.updateDocumentStateRequest());
      const result = await putDocumentStateService(id, data);
      if (result.ok) {
        dispatch(WorkflowActions.updateDocumentStateSuccess());
      } else if (result.data) {
        dispatch(
          WorkflowActions.updateDocumentStateFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.updateDocumentStateFailed(null));
      } else {
        dispatch(
          WorkflowActions.updateDocumentStateFailed(
            new GenericError('something went wrong on update document state')
          )
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.updateDocumentStateFailed(new GenericError(err, err)));
    }
  };
};

WorkflowActions.deleteDocument = (id) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.deleteDocumentRequest());
      const result = await deleteDocument(id);
      if (result.ok) {
        dispatch(WorkflowActions.deleteDocumentSuccess());
      } else {
        throw new Error();
      }
    } catch (error) {
      dispatch(
        WorkflowActions.deleteDocumentFailed(
          new GenericError('something went wrong on delete document state')
        )
      );
    }
  };
};

WorkflowActions.uploadDocument = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(WorkflowActions.uploadDocumentRequest());
      const result = await uploadDocumentService(id, data);
      if (result.ok) {
        dispatch(WorkflowActions.uploadDocumentSuccess());
      } else if (result.data) {
        dispatch(
          WorkflowActions.uploadDocumentFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(WorkflowActions.uploadDocumentFailed(null));
      } else {
        dispatch(
          WorkflowActions.uploadDocumentFailed(
            new GenericError('something went wrong on upload document')
          )
        );
      }
    } catch (err) {
      dispatch(WorkflowActions.uploadDocumentFailed(new GenericError(err, err)));
    }
  };
};

export default WorkflowActions;
