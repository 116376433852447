import React from 'react';

import { Box, Tooltip } from '@material-ui/core';

import clsx from 'clsx';
import Progress from '../Progress';

import styles from './styles.module.sass';

const UserPhoto = ({
  className,
  photoClassName = styles.photo_container,
  color,
  initials,
  name,
  photo,
  loadingPhoto,
}) => {
  return (
    <Box className={clsx(styles.container, className)}>
      <Tooltip title={name || initials}>
        <Box className={photoClassName}>
          <Box className={styles.photo_border}>
            <Box className={styles.container_photo}>
              {photo ? (
                <Box className={styles.container_image}>
                  <img src={photo} alt="" />
                </Box>
              ) : (
                <Box style={{ color }} className={styles.initials}>
                  {initials}
                </Box>
              )}

              {loadingPhoto && (
                <Box className={styles.container_loading}>
                  <Progress />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default UserPhoto;
