import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilitySensor from 'react-visibility-sensor';

import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import NavigationBar from '../../../components/NavigationBar';
import Button from '../../../components/Button';
import Loading from '../../../components/loading';
import MessageModal from '../../../modals/message';
import FilterButton from '../../../components/FilterButton';
import Select from '../../../components/Select';
import ContractsEmpty from '../../../components/ContractsEmpty';
import ContractListItem from '../../../components/ContractListItem';
import BasicDateRangePicker from '../../../components/BasicDateRangePicker';
import CalendarIcon from '../../../assets/images/icons/calendar.svg';
import homeBG from '../../../assets/images/background/homeBG.svg';

import AuthActions from '../../../redux/auth/actions';
import WorkflowActions from '../../../redux/workflow/actions';
import PaymentsActions from '../../../redux/payments/actions';
import ContractsActions from '../../../redux/contracts/actions';
import OffTakeScheduleActions from '../../../redux/offTakeSchedule/actions';

import styles from './styles.module.sass';

const Home = ({ t, history }) => {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('1920'));
  const lg = useMediaQuery(theme.breakpoints.up('1200'));
  const lgToMd = useMediaQuery(theme.breakpoints.up('960'));
  const md = useMediaQuery(theme.breakpoints.up('768'));

  const dispatch = useDispatch();
  const [dateRangeFilter, setDateRangeFilter] = useState(null);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [activeFilter, setActiveFilter] = useState('active');
  const [openDeleteContract, setOpenDeleteContract] = useState(false);
  const [visibilityScrolling, setVisibilityScrolling] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const {
    deleteUserContractsLoading,
    deleteUserContractsError,
    deleteUserContractsCalled,

    getUserContractsLoading,
    getUserContractsError,
    getUserContractsCalled,

    getContractsUsersLoading,
    getContractsUsersError,
    getContractsUsersCalled,

    getContractsProductsLoading,
    getContractsProductsError,
    getContractsProductsCalled,

    getTotalStadisticsLoading,
    getTotalStadisticsError,
    getTotalStadisticsCalled,

    userContracts,
    totalStadistics,
    contractsUsers,
    contractsProducts,

    filterDateRange,
    filterBuyer,
    filterSeller,
    filterProduct,
    filterSort,
    filterStatistic,

    contractSelection,
  } = useSelector((state) => state.contracts);

  useEffect(() => {
    dispatch(WorkflowActions.resetGetTasksAll());
  }, []);

  useEffect(() => {
    dispatch(PaymentsActions.savePaymentSelection(null));
    dispatch(ContractsActions.saveContractSelection(null));
    if (!getContractsProductsCalled) {
      dispatch(ContractsActions.getContractsProducts());
    }
    if (!getContractsUsersCalled) {
      dispatch(ContractsActions.getContractsUsers());
    }

    dispatch(
      ContractsActions.getUserContracts(user?.id, 9, 1, {
        dateRange: filterDateRange,
        buyer: filterBuyer,
        seller: filterSeller,
        product: filterProduct,
        sort: filterSort,
        statistic: filterStatistic,
      })
    );
    if (!getTotalStadisticsCalled) {
      dispatch(ContractsActions.getTotalStadistics(user?.id));
    }

    return () => {};
  }, [
    dispatch,
    getUserContractsCalled,
    getTotalStadisticsCalled,
    user,
    getContractsProductsCalled,
    getContractsUsersCalled,
  ]);

  useEffect(() => {
    if (user?.roles.length > 1) {
      let count = 0;
      const rolesObj = user?.roles?.reduce((obj, item) => {
        count += 1;
        return {
          ...obj,
          [item.role]: item.role,
        };
      }, {});

      if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
        amplitude.getInstance().logEvent('Different Views_v2', {
          userId: user.id,
          ...rolesObj,
        });
      }
    }
    return () => {};
  }, [user]);

  useEffect(() => {
    dispatch(
      ContractsActions.filterUpdate(
        filterDateRange,
        filterBuyer,
        filterSeller,
        filterProduct,
        filterSort,
        activeFilter
      )
    );
    dispatch(ContractsActions.getUserContractsSuccess(null, 1));
    dispatch(ContractsActions.resetGetUserContracts());
  }, [activeFilter]);

  useEffect(() => {
    if (!deleteUserContractsLoading && !deleteUserContractsError && deleteUserContractsCalled) {
      dispatch(ContractsActions.resetDeleteUserContracts());
      dispatch(ContractsActions.resetGetTotalStadistics());
      dispatch(ContractsActions.resetGetUserContracts());
      dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
      dispatch(WorkflowActions.updateOffTakeScheduleSelection(null));
      dispatch(WorkflowActions.resetGetTasks(null));
    }
  }, [deleteUserContractsLoading, deleteUserContractsError, deleteUserContractsCalled]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
    return () => {
      dispatch(ContractsActions.resetGetTotalStadistics());
      dispatch(ContractsActions.resetGetUserContracts());
      dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
      dispatch(WorkflowActions.updateOffTakeScheduleSelection(null));
      dispatch(WorkflowActions.resetGetTasks(null));
    };
  }, []);

  useEffect(() => {
    if (userContracts?.paging?.page < userContracts?.paging?.pages) {
      handleLoadMore();
    }
  }, [visibilityScrolling]);

  function handleLoadMore() {
    if (!getUserContractsLoading && !getUserContractsError) {
      dispatch(
        ContractsActions.getUserContracts(
          user?.id,
          userContracts?.paging?.limit,
          userContracts?.paging?.page + 1,
          {
            dateRange: filterDateRange,
            buyer: filterBuyer,
            seller: filterSeller,
            product: filterProduct,
            sort: filterSort,
            statistic: filterStatistic,
          }
        )
      );
    }
  }

  const getCardForItemRole = (roleSlug) => {
    switch (roleSlug) {
      case 'executionOwner':
        return 'default';
      case 'contractAgent':
        return 'default';
      case 'taskAgent':
        return 'task';
      case 'exportAgent':
        return 'task';
      case 'financier':
        return 'default';
      case 'financier1':
        return 'financier';
      case 'financier2':
        return 'financier';
      case 'commercialOwnerClient':
        return 'default';
      case 'commercialOwnerCollaborator':
        return 'default';
      case 'operationsManager':
        return 'default';
      case 'collaborationAgent':
        return 'task';
      case 'taskCollaborator':
        return 'task';
      default:
        return 'default';
    }
  };

  const FiltersMobile = () => (
    <>
      {(contractsUsers?.buyers?.length > 0 ||
        contractsUsers?.sellers?.length > 0 ||
        contractsProducts?.length > 0) && (
        <Box className={styles.filter_box}>
          <Box className={styles.sort_box}>
            <Box className={styles.sort_label}>{t('home.sortBy')}</Box>
            <Box className={styles.sort_select}>
              <Select
                isFilled={false}
                values={[
                  t('home.deliveryStatusBehind'),
                  t('home.deliveryStatusAhead'),
                  t('home.totalContract'),
                  t('home.totalNominated'),
                  t('home.totalVolume'),
                ]}
              />
            </Box>
          </Box>
          <Button
            inverted
            clean
            fontSize={18}
            labelStyle={{ justifyContent: 'center' }}
            onPress={() => history.push('/filters')}
          >
            {t('home.filters')}
          </Button>
        </Box>
      )}
    </>
  );

  const FiltersDesktop = () => (
    <>
      {(contractsUsers?.buyers?.length > 0 ||
        contractsUsers?.sellers?.length > 0 ||
        contractsProducts?.length > 0) && (
        <Box className={styles.filter_box}>
          <Box
            className={styles.sort_box}
            style={isTablet ? { alignItems: 'flex-end' } : { alignItems: 'center' }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: isTablet ? 'column' : 'row',
                alignItems: isTablet ? 'flex-start' : 'center',
              }}
            >
              <Box className={styles.sort_label}>{t('documents.filterBy')}</Box>
              <Box
                className={styles.filter_date}
                style={isTablet ? { paddingLeft: 0, marginLeft: -5, maxWidth: 185 } : {}}
              >
                <BasicDateRangePicker
                  clearable={
                    filterDateRange?.length === 2 && filterDateRange[0] && filterDateRange[1]
                  }
                  filled={false}
                  value={filterDateRange}
                  placeholder={t('common.deliveryDateRange')}
                  onChange={(value) => {
                    if (
                      value.length === 2 &&
                      ((value[0] && value[1]) || (!value[0] && !value[1]))
                    ) {
                      dispatch(
                        ContractsActions.filterUpdate(
                          value.length === 2 && value[0] && value[1] ? value : null,
                          filterBuyer,
                          filterSeller,
                          filterProduct,
                          filterSort,
                          filterStatistic
                        )
                      );
                      dispatch(ContractsActions.getUserContractsSuccess(null, 1));
                      dispatch(ContractsActions.resetGetUserContracts());
                    }
                  }}
                  icon={<SvgIcon fontSize="small" viewBox="0 0 22 22" component={CalendarIcon} />}
                />
              </Box>
            </Box>

            <Box
              className={styles.sort_select}
              style={isTablet ? { marginBottom: 5, maxWidth: 100 } : {}}
            >
              <Select
                defaultValue={t('common.allBuyers')}
                disableDefault={false}
                value={filterBuyer}
                isFilled={false}
                valueKey="id"
                displayKey="name"
                values={contractsUsers?.buyers?.map((item) => {
                  return { id: item.id, name: `${item.firstName || ''} ${item.lastName || ''}` };
                })}
                onChange={(value) => {
                  dispatch(
                    ContractsActions.filterUpdate(
                      filterDateRange,
                      value === t('common.allBuyers') ? null : value,
                      filterSeller,
                      filterProduct,
                      filterSort,
                      filterStatistic
                    )
                  );
                  dispatch(ContractsActions.getUserContractsSuccess(null, 1));
                  dispatch(ContractsActions.resetGetUserContracts());
                }}
              />
            </Box>
            <Box
              className={styles.sort_select}
              style={isTablet ? { marginBottom: 5, maxWidth: 100 } : {}}
            >
              <Select
                defaultValue={t('common.allSellers')}
                disableDefault={false}
                value={filterSeller}
                isFilled={false}
                valueKey="id"
                displayKey="name"
                values={contractsUsers?.sellers?.map((item) => {
                  return { id: item.id, name: `${item.firstName || ''} ${item.lastName || ''}` };
                })}
                onChange={(value) => {
                  dispatch(
                    ContractsActions.filterUpdate(
                      filterDateRange,
                      filterBuyer,
                      value === t('common.allSellers') ? null : value,
                      filterProduct,
                      filterSort,
                      filterStatistic
                    )
                  );
                  dispatch(ContractsActions.getUserContractsSuccess(null, 1));
                  dispatch(ContractsActions.resetGetUserContracts());
                }}
              />
            </Box>
            <Box
              className={styles.sort_select}
              style={isTablet ? { marginBottom: 5, maxWidth: 100 } : {}}
            >
              <Select
                value={filterProduct}
                defaultValue={t('common.allProducts')}
                disableDefault={false}
                isFilled={false}
                values={contractsProducts}
                onChange={(value) => {
                  dispatch(
                    ContractsActions.filterUpdate(
                      filterDateRange,
                      filterBuyer,
                      filterSeller,
                      value === t('common.allProducts') ? null : value,
                      filterSort,
                      filterStatistic
                    )
                  );
                  dispatch(ContractsActions.getUserContractsSuccess(null, 1));
                  dispatch(ContractsActions.resetGetUserContracts());
                }}
              />
            </Box>
          </Box>

          <Box className={styles.sort_box}>
            <Box
              style={{
                display: 'flex',
                flexDirection: isTablet ? 'column' : 'row',
                alignItems: isTablet ? 'flex-start' : 'center',
              }}
            >
              <Box className={styles.sort_label}>{t('home.sortBy')}</Box>
              <Box
                className={styles.sort_select}
                style={
                  isTablet
                    ? { paddingLeft: 0, margin: '8px 0px;', paddingTop: 8, maxWidth: 100 }
                    : {}
                }
              >
                <Select
                  defaultValue={t('common.default')}
                  disableDefault={false}
                  value={filterSort}
                  isFilled={false}
                  values={[
                    t('home.deliveryStatusBehind'),
                    t('home.deliveryStatusAhead'),
                    t('home.totalContract'),
                    t('home.totalNominated'),
                    t('home.totalVolume'),
                  ]}
                  onChange={(value) => {
                    dispatch(
                      ContractsActions.filterUpdate(
                        filterDateRange,
                        filterBuyer,
                        filterSeller,
                        filterProduct,
                        value === t('common.default') ? null : value,
                        filterStatistic
                      )
                    );
                    dispatch(ContractsActions.getUserContractsSuccess(null, 1));
                    dispatch(ContractsActions.resetGetUserContracts());
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );

  const handleDeleteContract = () => {
    setOpenDeleteContract((prevState) => !prevState);
  };

  const onMoreOption = (item, menuSelected) => {
    if (menuSelected?.route === 'archive') {
      // Nothing
    } else {
      dispatch(PaymentsActions.resetGetPayment());
      dispatch(PaymentsActions.savePaymentSelection(item?.paymentInstructions));
      dispatch(ContractsActions.resetGetUserContract());
      dispatch(ContractsActions.updateContractSelection(item));
      dispatch(ContractsActions.updateContractSelectionStadistics(item));
      history.push(menuSelected?.route);
    }
  };

  useEffect(() => {
    console.log(lgToMd, md, lg, xl);
  }, [lgToMd, md, lg, xl]);

  return (
    <Box className={styles.container}>
      <NavigationBar
        xl={xl}
        lg={lg}
        md={md}
        hideTitle={false}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet && (md || xl || lg)}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        history={history}
        pageContent={
          <Box className={clsx(styles.main_content, drawerOpened ? 'container_with_drawer' : null)}>
            <Box className={styles.container_bg}>
              <SvgIcon component={homeBG} viewBox="275 50 275 205" />
            </Box>
            <Container className={styles.content}>
              <Loading
                isLoading={
                  (!userContracts && getUserContractsLoading) ||
                  getTotalStadisticsLoading ||
                  deleteUserContractsLoading
                }
              />
              {totalStadistics && (
                <Box className={styles.filter_buttons}>
                  {(!isMobile || isTablet) && (
                    <FilterButton
                      label={t('contracts.active').toUpperCase()}
                      number={totalStadistics?.active || '0'}
                      active={activeFilter === 'active'}
                      onPress={() => {
                        amplitude.getInstance().logEvent('Status Button', {
                          StatusButton: 'active',
                          date: new Date(),
                        });
                        setActiveFilter('active');
                      }}
                    />
                  )}
                  <FilterButton
                    label={t('contracts.nominated').toUpperCase()}
                    number={totalStadistics?.nominated || '0'}
                    active={activeFilter === 'nominated'}
                    onPress={() => {
                      amplitude.getInstance().logEvent('Status Button', {
                        StatusButton: 'nominated',
                        date: new Date(),
                      });
                      setActiveFilter('nominated');
                    }}
                  />
                  <FilterButton
                    label={t('contracts.behind').toUpperCase()}
                    number={totalStadistics?.behind || '0'}
                    active={activeFilter === 'behind'}
                    onPress={() => {
                      amplitude.getInstance().logEvent('Status Button', {
                        StatusButton: 'behind',
                        date: new Date(),
                      });
                      setActiveFilter('behind');
                    }}
                  />
                  <FilterButton
                    label={t('contracts.undefinedDeliver').toUpperCase()}
                    number={totalStadistics?.noSchedule || '0'}
                    active={activeFilter === 'undefined'}
                    onPress={() => {
                      amplitude.getInstance().logEvent('Status Button', {
                        StatusButton: 'undefined',
                        date: new Date(),
                      });
                      setActiveFilter('undefined');
                    }}
                  />
                  {(!isMobile || isTablet) && (
                    <FilterButton
                      label={t('contracts.onTime').toUpperCase()}
                      number={totalStadistics?.onTime || '0'}
                      active={activeFilter === 'onTime'}
                      onPress={() => {
                        amplitude.getInstance().logEvent('Status Button', {
                          StatusButton: 'onTime',
                          date: new Date(),
                        });
                        setActiveFilter('onTime');
                      }}
                    />
                  )}
                  {(!isMobile || isTablet) && (
                    <FilterButton
                      label={t('contracts.ahead').toUpperCase()}
                      number={totalStadistics?.ahead || '0'}
                      active={activeFilter === 'ahead'}
                      onPress={() => {
                        amplitude.getInstance().logEvent('Status Button', {
                          StatusButton: 'ahead',
                          date: new Date(),
                        });
                        setActiveFilter('ahead');
                      }}
                    />
                  )}
                </Box>
              )}

              {isMobile && !isTablet ? <FiltersMobile /> : <FiltersDesktop />}

              {userContracts?.contractInfo?.length > 0 && (
                <Box className={styles.labels_box}>
                  <Box className={styles.received_circle} />
                  <Box className={styles.label}>{t('home.received')}</Box>
                  <Box className={styles.nominated_circle} />
                  <Box className={styles.label}>{t('home.nominated')}</Box>
                </Box>
              )}
              {!(userContracts?.contractInfo?.length > 0) && <ContractsEmpty />}
              {userContracts?.contractInfo?.length > 0 && (
                <GridList cellHeight={255} cols={xl ? 3 : lg ? 3 : md ? 2 : 1} spacing={20}>
                  {userContracts?.contractInfo?.map((item, index) => (
                    <GridListTile key={index}>
                      <Box
                        style={{
                          padding: 10,
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <ContractListItem
                          key={index}
                          contract={item}
                          onMoreOption={(route) => onMoreOption(item, route)}
                          onAction={() => {
                            dispatch(ContractsActions.resetGetUserContract());
                            dispatch(ContractsActions.updateContractSelection(item));
                            dispatch(ContractsActions.updateContractSelectionStadistics(item));
                            if (getCardForItemRole(item.role) === 'task') {
                              dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
                              isMobile
                                ? history.push('/contracts/workflows/list-off-take-schedules')
                                : history.push('/contracts/workflows/off-take-schedule-detail');
                            } else if (getCardForItemRole(item.role) === 'financier') {
                              dispatch(PaymentsActions.resetGetPayment());
                              dispatch(
                                PaymentsActions.savePaymentSelection(item?.paymentInstructions)
                              );
                              isMobile
                                ? history.replace('/contracts/specs')
                                : history.replace('/contracts/payment-instructions');
                            } else {
                              history.push('/contracts/details');
                            }
                          }}
                          type={getCardForItemRole(item.role)}
                        />
                      </Box>
                    </GridListTile>
                  ))}
                  {userContracts?.paging?.page < userContracts?.paging?.pages && (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        setVisibilityScrolling(isVisible);
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: 50,
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    </VisibilitySensor>
                  )}
                </GridList>
              )}
            </Container>
          </Box>
        }
      />
      {getUserContractsCalled && !getUserContractsLoading && getUserContractsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getUserContractsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetGetUserContractsError());
          }}
        />
      )}

      {getTotalStadisticsCalled && !getTotalStadisticsLoading && getTotalStadisticsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getTotalStadisticsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetGetTotalStadisticsError());
          }}
        />
      )}

      {deleteUserContractsCalled && !deleteUserContractsLoading && deleteUserContractsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={deleteUserContractsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetDeleteUserContractsError());
          }}
        />
      )}

      {openDeleteContract && (
        <MessageModal
          center
          title={t('common.deleteContractTitle')}
          icon={null}
          messages={[t('common.deleteContractDes1'), t('common.deleteContractDes2')]}
          secundaryAction={isMobile ? t('common.no') : t('common.noChangeMyMind')}
          secundaryEvent={() => {
            dispatch(ContractsActions.updateContractSelection(null));
            setOpenDeleteContract(false);
          }}
          primaryAction={isMobile ? t('common.yes') : t('common.yesDelete')}
          primaryEvent={async () => {
            setOpenDeleteContract(false);
            if (contractSelection) {
              await dispatch(ContractsActions.deleteUserContracts(contractSelection?.id));
            }
          }}
        />
      )}

      {!drawerOpened && (
        <Box
          className={styles.container_add_button}
          style={isTablet ? { bottom: 40, right: 32 } : {}}
        >
          <Button
            className={styles.add_button}
            onPress={() => {
              dispatch(ContractsActions.updateContractSelection(null));
              dispatch(ContractsActions.resetSaveUserContracts());
              history.push('/contracts/general-information');
            }}
          >
            <AddIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(Home);
