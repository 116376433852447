import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import * as Sentry from '@sentry/browser';

import { Typography, Container, Box } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useSelector } from 'react-redux';

import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '../../assets/images/icons/check.svg';
import TextField from '../TextField';
import Button from '../Button';

import { putResetOtp } from '../../services/auth';

import styles from './styles.module.sass';

const validationSchema = Yup.object({
  first: Yup.string().required('*').min(1, '*').max(1, '*'),
  second: Yup.string().required('*').min(1, '*').max(1, '*'),
  third: Yup.string().required('*').min(1, '*').max(1, '*'),
  fourth: Yup.string().required('*').min(1, '*').max(1, '*'),
  fifth: Yup.string().required('*').min(1, '*').max(1, '*'),
  sixth: Yup.string().required('*').min(1, '*').max(1, '*'),
});

const ValidateKey = ({
  t,
  loading,
  disabled,
  onValidateKey,
  showResetSecret = true,
  error,
  tokenIsValid = null,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [pasting, setPasting] = useState(false);

  const [firstValue, setFirstValue] = useState(null);
  const [secondValue, setSecondValue] = useState(null);
  const [thirdValue, setThirdValue] = useState(null);
  const [fourthValue, setFourthValue] = useState(null);
  const [fifthValue, setFifthValue] = useState(null);
  const [sixthValue, setSixthValue] = useState(null);

  const [sendingReset, setSendingReset] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  if (error) {
    Sentry.captureMessage(`Invalid token ${error}`);
  }

  function handleKeyUp(event) {
    setPasting(false);
    if (event.keyCode === 8) {
      const { form } = event.target;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (index > 0) {
        form.elements[index - 1].focus();
      }
    } else if (event.char !== '') {
      const { form } = event.target;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (index < 5) {
        form.elements[index + 1].focus();
      }
    }
  }

  function onPaste(event) {
    setPasting(true);
    const pasteString = event.clipboardData.getData('Text').replace(' ', '');
    const values = pasteString.split('');
    if (values.length === 6) {
      setFirstValue(values[0]);
      setSecondValue(values[1]);
      setThirdValue(values[2]);
      setFourthValue(values[3]);
      setFifthValue(values[4]);
      setSixthValue(values[5]);
      const { form } = event.target;
      form.elements[6].focus();
    }
  }

  const handleResetOtp = async () => {
    if (user) {
      setSendingReset(true);
      const result = await putResetOtp({ email: user?.email });
      if (result.ok) {
        setResetSent(true);
      } else {
        setResetSent(false);
      }
      setSendingReset(false);
    }
  };

  return (
    <Container maxWidth="md" className={styles.content}>
      <Box className={styles.container_step_description}>
        <Box className="subtitle" style={{ marginTop: 10 }}>
          {t('validateToken.description')}
        </Box>
      </Box>
      <Box className={styles.content_form}>
        <Formik
          enableReinitialize
          initialValues={{
            first: firstValue || '',
            second: secondValue || '',
            third: thirdValue || '',
            fourth: fourthValue || '',
            fifth: fifthValue || '',
            sixth: sixthValue || '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let token =
              values.first.trim() +
              values.second.trim() +
              values.third.trim() +
              values.fourth.trim() +
              values.fifth.trim() +
              values.sixth.trim();
            token = token.trim();
            if (token.length === 6) {
              if (onValidateKey) {
                onValidateKey(token);
              }
            } else {
              Sentry.captureMessage(`Invalid token length ${token}`);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit} className={styles.form}>
              <Box className={styles.container_inputs}>
                <Box className={styles.container_numbers}>
                  <Box className={clsx(styles.container_first, styles.container_input)}>
                    <TextField
                      autoFocus
                      disabled={tokenIsValid}
                      name="first"
                      value={values.first}
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' },
                        maxLength: 1,
                        inputMode: 'numeric',
                      }}
                      onChange={handleChange}
                      helperText={errors.first && touched.first && errors.first}
                      onKeyUp={handleKeyUp}
                      onPaste={onPaste}
                      onFocus={(event) => {
                        if (!pasting) {
                          values.first = '';
                          values.second = '';
                          values.third = '';
                          values.fourth = '';
                          values.fifth = '';
                          values.sixth = '';
                          const { form } = event.target;
                          const index = Array.prototype.indexOf.call(form, event.target);
                          form.elements[index].value = '';
                          handleChange(event);
                        }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box className={clsx(styles.container_following, styles.container_input)}>
                    <TextField
                      disabled={tokenIsValid}
                      name="second"
                      value={values.second}
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' },
                        maxLength: 1,
                        inputMode: 'numeric',
                      }}
                      onChange={handleChange}
                      helperText={errors.second && touched.second && errors.second}
                      onKeyUp={handleKeyUp}
                      onPaste={onPaste}
                      onFocus={(event) => {
                        if (!pasting) {
                          values.second = '';
                          values.third = '';
                          values.fourth = '';
                          values.fifth = '';
                          values.sixth = '';
                          const { form } = event.target;
                          const index = Array.prototype.indexOf.call(form, event.target);
                          form.elements[index].value = '';
                          handleChange(event);
                        }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box className={clsx(styles.container_following, styles.container_input)}>
                    <TextField
                      disabled={tokenIsValid}
                      name="third"
                      value={values.third}
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' },
                        maxLength: 1,
                        inputMode: 'numeric',
                      }}
                      onChange={handleChange}
                      helperText={errors.third && touched.third && errors.third}
                      onKeyUp={handleKeyUp}
                      onPaste={onPaste}
                      onFocus={(event) => {
                        if (!pasting) {
                          values.third = '';
                          values.fourth = '';
                          values.fifth = '';
                          values.sixth = '';
                          const { form } = event.target;
                          const index = Array.prototype.indexOf.call(form, event.target);
                          form.elements[index].value = '';
                          handleChange(event);
                        }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box className={clsx(styles.container_following, styles.container_input)}>
                    <TextField
                      disabled={tokenIsValid}
                      name="fourth"
                      value={values.fourth}
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' },
                        maxLength: 1,
                        inputMode: 'numeric',
                      }}
                      onChange={handleChange}
                      helperText={errors.fourth && touched.fourth && errors.fourth}
                      onKeyUp={handleKeyUp}
                      onPaste={onPaste}
                      onFocus={(event) => {
                        if (!pasting) {
                          values.fourth = '';
                          values.fifth = '';
                          values.sixth = '';
                          const { form } = event.target;
                          const index = Array.prototype.indexOf.call(form, event.target);
                          form.elements[index].value = '';
                          handleChange(event);
                        }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box className={clsx(styles.container_following, styles.container_input)}>
                    <TextField
                      disabled={tokenIsValid}
                      name="fifth"
                      value={values.fifth}
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' },
                        maxLength: 1,
                        inputMode: 'numeric',
                      }}
                      onChange={handleChange}
                      helperText={errors.fifth && touched.fifth && errors.fifth}
                      onKeyUp={handleKeyUp}
                      onPaste={onPaste}
                      onFocus={(event) => {
                        if (!pasting) {
                          values.fifth = '';
                          values.sixth = '';
                          const { form } = event.target;
                          const index = Array.prototype.indexOf.call(form, event.target);
                          form.elements[index].value = '';
                          handleChange(event);
                        }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box className={clsx(styles.container_following, styles.container_input)}>
                    <TextField
                      disabled={tokenIsValid}
                      name="sixth"
                      value={values.sixth}
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' },
                        maxLength: 1,
                        inputMode: 'numeric',
                      }}
                      onChange={handleChange}
                      helperText={errors.sixth && touched.sixth && errors.sixth}
                      onKeyUp={handleKeyUp}
                      onPaste={onPaste}
                      onFocus={(event) => {
                        if (!pasting) {
                          values.sixth = '';
                          const { form } = event.target;
                          const index = Array.prototype.indexOf.call(form, event.target);
                          form.elements[index].value = '';
                          handleChange(event);
                        }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </Box>
              {tokenIsValid === false && (
                <Box className={styles.container_error}>
                  <Box className="error">{t('validateToken.invalid')}</Box>
                </Box>
              )}

              <Box className={styles.container_submit}>
                {tokenIsValid === true ? (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <SvgIcon
                      style={{
                        width: 40,
                        height: 40,
                      }}
                      component={CheckIcon}
                      viewBox="0 0 32 32"
                    />
                  </Box>
                ) : (
                  <>
                    <Button
                      type="submit"
                      loading={loading}
                      disabled={disabled}
                      className={styles.button}
                      data-info-submit="Submit"
                      minWidth={130}
                      maxWidth={270}
                    >
                      {t('validateToken.verifyCode')}
                    </Button>
                    {showResetSecret && !resetSent && (
                      <>
                        <div style={{ height: 20 }} />
                        <Button
                          inverted
                          clean
                          loading={sendingReset}
                          disabled={sendingReset}
                          minWidth={130}
                          onClick={handleResetOtp}
                        >
                          {t('validateToken.resetDescription')}
                        </Button>
                      </>
                    )}

                    {resetSent && (
                      <>
                        <div style={{ height: 20 }} />
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <SvgIcon
                            style={{
                              width: 40,
                              height: 40,
                              marginRight: 20,
                            }}
                            component={CheckIcon}
                            viewBox="0 0 32 32"
                          />
                          <Typography variant="caption">
                            {t('validateToken.resetSent', { email: user?.email ?? '' })}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default withTranslation()(ValidateKey);
