import InitialState from '.';
import NotificationActions from './actions';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case NotificationActions.registerPushNotificationSuccess().type:
      return state.merge({
        token: action.payload.token,
      });

    case NotificationActions.getNotificationsRequest().type:
      return state.merge({
        getNotificationsLoading: true,
        getNotificationsCalled: true,
        getNotificationsError: null,
        // notifications: null,
      });
    case NotificationActions.getNotificationsSuccess().type:
      return state.merge({
        getNotificationsLoading: false,
        getNotificationsCalled: true,
        getNotificationsError: null,
        notifications:
          action.payload.page === 1
            ? action.payload.notifications.data
            : state.notifications.concat(action.payload.notifications.data),
        pagination: action.payload.notifications.paging,
      });
    case NotificationActions.getNotificationsFailed().type:
      return state.merge({
        getNotificationsLoading: false,
        getNotificationsCalled: true,
        getNotificationsError: action.payload.error,
        notifications: null,
      });

    case NotificationActions.addPushNotificationSuccess().type:
      return state.merge({
        notification: action.payload.notification,
        pushNotification: true,
      });
    case NotificationActions.clearPushNotificationSuccess().type:
      return state.merge({
        notification:null,
        pushNotification: false,
      });
    default:
      return state;
  }
};
