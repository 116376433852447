import { createActions } from 'redux-actions';

import {
  getTerms as getTermsService,
  postTerms as postTermsService,
  putTerms as putTermsService,
  deleteTerms as deleteTermsService,
  putUserTerms as putUserTermsService,
} from '../../services/terms';

import { GenericError } from '../../utils/errors';

const TermsActions = createActions({
  GET_TERMS_REQUEST: () => {},
  GET_TERMS_SUCCESS: (terms) => ({ terms }),
  GET_TERMS_FAILED: (error) => ({ error }),
  RESET_GET_TERMS: () => {},
  RESET_GET_TERMS_ERROR: () => {},

  CREATE_TERMS_REQUEST: () => {},
  CREATE_TERMS_SUCCESS: () => {},
  CREATE_TERMS_FAILED: (error) => ({ error }),
  RESET_CREATE_TERMS: () => {},
  RESET_CREATE_TERMS_ERROR: () => {},

  UPDATE_TERMS_REQUEST: () => {},
  UPDATE_TERMS_SUCCESS: () => {},
  UPDATE_TERMS_FAILED: (error) => ({ error }),
  RESET_UPDATE_TERMS: () => {},
  RESET_UPDATE_TERMS_ERROR: () => {},

  DELETE_TERMS_REQUEST: () => {},
  DELETE_TERMS_SUCCESS: () => {},
  DELETE_TERMS_FAILED: (error) => ({ error }),
  RESET_DELETE_TERMS: () => {},
  RESET_DELETE_TERMS_ERROR: () => {},

  UPDATE_USER_TERMS_REQUEST: () => {},
  UPDATE_USER_TERMS_SUCCESS: () => {},
  UPDATE_USER_TERMS_FAILED: (error) => ({ error }),
  RESET_UPDATE_USER_TERMS: () => {},
  RESET_UPDATE_USER_TERMS_ERROR: () => {},
});

TermsActions.getTerms = (id) => {
  return async (dispatch) => {
    try {
      dispatch(TermsActions.getTermsRequest());
      const result = await getTermsService(id);
      if (result.ok) {
        dispatch(TermsActions.getTermsSuccess(result.data.data));
      } else if (result.data) {
        dispatch(
          TermsActions.getTermsFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(TermsActions.getTermsFailed(null));
      } else {
        dispatch(
          TermsActions.getTermsFailed(new GenericError('something went wrong on get terms'))
        );
      }
    } catch (err) {
      dispatch(TermsActions.getTermsFailed(new GenericError(err, err)));
    }
  };
};

TermsActions.createTerms = (data) => {
  return async (dispatch) => {
    try {
      dispatch(TermsActions.createTermsRequest());
      const result = await postTermsService(data);
      if (result.ok) {
        dispatch(TermsActions.createTermsSuccess());
      } else if (result.data) {
        dispatch(
          TermsActions.createTermsFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(TermsActions.createTermsFailed(null));
      } else {
        dispatch(
          TermsActions.createTermsFailed(new GenericError('something went wrong on create terms'))
        );
      }
    } catch (err) {
      dispatch(TermsActions.createTermsFailed(new GenericError(err, err)));
    }
  };
};

TermsActions.updateTerms = (data) => {
  return async (dispatch) => {
    try {
      dispatch(TermsActions.updateTermsRequest());
      const result = await putTermsService(data);
      if (result.ok) {
        dispatch(TermsActions.updateTermsSuccess());
      } else if (result.data) {
        dispatch(
          TermsActions.updateTermsFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(TermsActions.updateTermsFailed(null));
      } else {
        dispatch(
          TermsActions.updateTermsFailed(new GenericError('something went wrong on update terms'))
        );
      }
    } catch (err) {
      dispatch(TermsActions.updateTermsFailed(new GenericError(err, err)));
    }
  };
};

TermsActions.deleteTerms = (data) => {
  return async (dispatch) => {
    try {
      dispatch(TermsActions.deleteTermsRequest());
      const result = await deleteTermsService(data);
      if (result.ok) {
        dispatch(TermsActions.deleteTermsSuccess());
      } else if (result.data) {
        dispatch(
          TermsActions.deleteTermsFailed(new GenericError(result.data.error, result.data.message))
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(TermsActions.deleteTermsFailed(null));
      } else {
        dispatch(
          TermsActions.deleteTermsFailed(new GenericError('something went wrong on delete terms'))
        );
      }
    } catch (err) {
      dispatch(TermsActions.deleteTermsFailed(new GenericError(err, err)));
    }
  };
};

TermsActions.updateUserTerms = (id, termId, data) => {
  return async (dispatch) => {
    try {
      dispatch(TermsActions.updateUserTermsRequest());
      const result = await putUserTermsService(id, termId, data);
      if (result.ok) {
        dispatch(TermsActions.updateUserTermsSuccess());
      } else if (result.data) {
        dispatch(
          TermsActions.updateUserTermsFailed(
            new GenericError(result.data.error, result.data.message)
          )
        );
      } else if (result.originalError.message === 'IgnoreResponse') {
        dispatch(TermsActions.updateUserTermsFailed(null));
      } else {
        dispatch(
          TermsActions.updateUserTermsFailed(
            new GenericError('something went wrong on update user terms')
          )
        );
      }
    } catch (err) {
      dispatch(TermsActions.updateUserTermsFailed(new GenericError(err, err)));
    }
  };
};
export default TermsActions;
