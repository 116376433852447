/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

import Box from '@material-ui/core/Box';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import _ from 'lodash';

import styles from './styles.module.sass';

const ContractChart = ({
  t,
  className,
  contract,
  showLabels = false,
  title,
  small,
  subTasks,
  onAddOffTakeSchedule,
  isDetail,
  schedule,
  onClick = () => {},
}) => {
  const [totalDelivered, setTotalDelivered] = useState(contract?.statistics?.totalDelivered || 0);
  const [totalNominated, setTotalNominated] = useState(contract?.statistics?.totalNominated || 0);
  const [totalScheduled, setTotalScheduled] = useState(contract?.statistics?.totalScheduled || 0);
  const [completionStatusSlug, setCompletionStatusSlug] = useState(
    contract?.completionStatusSlug || ''
  );
  const [totalVolumeWidth, setTotalVolumeWidth] = useState(0);

  const schedules = contract?.schedules || schedule || [];

  useEffect(() => {
    if (subTasks?.length > 0) {
      const nomination = subTasks?.find((item) => item.type === 'nomination');
      const totalNominated = nomination?.volume;
      const deliveries = subTasks?.filter((item) => item.type === 'delivery');
      const totalDelivered = deliveries?.reduce((total, item) => {
        return total + item.volume;
      }, 0);
      setTotalDelivered(totalDelivered);
      setTotalNominated(totalNominated);
      setTotalScheduled(totalNominated);
      if (totalNominated === totalDelivered) {
        setCompletionStatusSlug('completed');
      } else if (moment(nomination.date).isAfter()) {
        setCompletionStatusSlug('onTime');
      } else if (moment(nomination.date).isBefore() && totalDelivered < totalNominated) {
        setCompletionStatusSlug('behind');
      } else if (totalDelivered > totalNominated) {
        setCompletionStatusSlug('ahead');
      }
    }
  }, [subTasks]);

  useEffect(() => {
    if (totalScheduled > totalDelivered && totalScheduled > totalNominated) {
      setTotalVolumeWidth(totalScheduled);
    } else if (totalDelivered > totalNominated) {
      setTotalVolumeWidth(totalDelivered);
    } else {
      setTotalVolumeWidth(totalNominated);
    }
  }, [totalScheduled, totalDelivered, totalNominated]);

  function getRealNominated() {
    const delivered = contract?.statistics?.totalDelivered || totalDelivered || 0;
    const nominated = contract?.statistics?.totalNominated || totalNominated || 0;
    if (delivered > nominated) {
      return 0;
    }
    return nominated - delivered;
  }

  const renderNum = (refLbl) => {
    return _.get(contract, `statistics.${refLbl}`, 0);
  };

  function getStatus() {
    if (contract?.completionStatusSlug || completionStatusSlug === 'behind') {
      return t('contracts.behind');
    }
    if (contract?.completionStatusSlug || completionStatusSlug === 'onTime') {
      return t('contracts.onTime');
    }
    if (contract?.completionStatusSlug || completionStatusSlug === 'ahead') {
      return t('contracts.ahead');
    }
    if (contract?.completionStatusSlug || completionStatusSlug === 'completed') {
      return t('contracts.completed');
    }
    return t('contracts.addOffTakeSchedule');
  }

  const renderStatus = () => {
    if (isDetail) {
      return (
        <Box className={styles.contStatus}>
          <Box
            className={styles.lblStatus}
            style={
              isMobile && !isTablet
                ? {
                    textAlign: 'center',
                    fontFamily: 'FuturaPT-Medium',
                    fontSize: 15,
                    fontWeight: 500,
                    letterSpacing: 0.75,
                  }
                : {}
            }
          >
            {t('contracts.contractStatus')}: <b>{title || getStatus()}</b>
          </Box>
          {/* <Box className={styles.subLblStatus}>
            <InfoOutlined />
            <span>
              The Task “General task title” has been delay
            </span>
          </Box> */}
        </Box>
      );
    }
    return <Box className={styles.status}>{title || getStatus()}</Box>;
  };

  function getBar(arrDates = []) {
    let limitValNom = 0;
    let limitValDel = 0;
    let indexLastNom = -1;
    let indexLastDel = -1;
    let enditem = '';
    let startDate = '';
    let auxStartDate = '';
    let endDate = '';
    let distLeft = 0;
    let widthNominated = 0;
    let widthDelivered = 0;
    let widthBehind = 0;
    let diffRange = 0;
    let alldays = 0;
    let valNom = 0;
    let totalBehind = 0;
    if (arrDates.length > 0 && !subTasks) {
      startDate = arrDates[0].startDate.split('T')[0];
      endDate = arrDates[arrDates.length - 1].endDate.split('T')[0];

      const diffProgress = moment(endDate, 'YYYY-MM-DD').diff(
        moment(startDate, 'YYYY-MM-DD'),
        'days'
      );

      if (moment(endDate).isBefore()) {
        widthBehind = 100;
      }

      arrDates.forEach((task, index) => {
        if (moment(task.endDate, 'YYYY-MM-DD').isBefore(moment())) {
          const sum = task.tasks.reduce((previousValue, current) => {
            if (current.type === 'delivery' && current.status === 'completed') {
              return previousValue + (current?.volume || 0);
            }
            return previousValue;
          }, 0);
          totalBehind += task.volume - sum;
        }

        if (indexLastNom === -1) {
          limitValNom += task?.volume || 0;
        }
        if (limitValNom >= totalNominated && indexLastNom === -1) {
          indexLastNom = index;
          distLeft = 0;
          if (index > 0) {
            auxStartDate = arrDates[index - 1].endDate.split('T')[0];
          } else {
            auxStartDate = startDate;
          }
          enditem = task.endDate.split('T')[0];
          diffRange = moment(enditem, 'YYYY-MM-DD').diff(
            moment(auxStartDate, 'YYYY-MM-DD'),
            'days'
          );
          diffRange = diffRange * (1 - totalNominated / limitValNom);
          alldays = moment(enditem, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days');
          valNom = diffRange > 0 ? alldays - diffRange : alldays;
          if (valNom > 0) {
            distLeft = (valNom * 100) / diffProgress;
          }
          widthNominated = distLeft < 0 ? 0 : distLeft > 100 ? 100 : distLeft;
        }

        if (indexLastDel === -1) {
          limitValDel += task?.volume || 0;
        }
        if (limitValDel >= totalDelivered && indexLastDel === -1) {
          indexLastDel = index;
          distLeft = 0;
          if (index > 0) {
            auxStartDate = arrDates[index - 1].endDate.split('T')[0];
          } else {
            auxStartDate = startDate;
          }
          enditem = task.endDate.split('T')[0];
          diffRange = moment(enditem, 'YYYY-MM-DD').diff(
            moment(auxStartDate, 'YYYY-MM-DD'),
            'days'
          );
          diffRange = diffRange * (1 - totalDelivered / limitValDel);
          alldays = moment(enditem, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days');
          valNom = diffRange > 0 ? alldays - diffRange : alldays;
          if (valNom > 0) {
            distLeft = (valNom * 100) / diffProgress;
          }
          widthDelivered = distLeft < 0 ? 0 : distLeft > 100 ? 100 : distLeft;
        }
      });
    } else {
      widthDelivered = (totalDelivered * 100) / totalScheduled;
      widthNominated = (totalNominated * 100) / totalScheduled;
    }

    const deliveredPercentage = `${
      widthDelivered > 100 ? 100 : widthDelivered < 0 ? 0 : widthDelivered
    }%`;
    const nominatedPercentage = `${
      widthNominated > 100 ? 100 : widthNominated < 0 ? 0 : widthNominated
    }%`;

    widthBehind = (totalBehind * 100) / totalScheduled;

    const behindPercentage = `${widthBehind > 100 ? 100 : widthBehind < 0 ? 0 : widthBehind}%`;

    return (
      <Box className={styles.flexRow}>
        <Box
          style={{
            backgroundColor: '#4c488f',
            width: nominatedPercentage,
          }}
        />
        <Box
          style={{
            backgroundColor: '#f44336',
            left: deliveredPercentage,
            width: behindPercentage,
          }}
        />
        <Box
          style={{
            backgroundColor: '#757e95',
            width: deliveredPercentage,
          }}
        />
      </Box>
    );
  }

  const renderDates = () => {
    let startDate = '';
    let endDate = '';
    let arrDates = [];
    if (schedules.length > 0) {
      arrDates = [...schedules].sort((a, b) => {
        const adate = moment(a.endDate.split('T')[0], 'YYYY-MM-DD');
        const bdate = moment(b.endDate.split('T')[0], 'YYYY-MM-DD');
        return adate.diff(bdate, 'days');
      });
      schedules.forEach((item, index) => {
        const startDateAux = item?.startDate.split('T')[0];
        const endDateAux = item?.endDate.split('T')[0];
        if (startDate) {
          const diffDaysStart = moment(startDate, 'YYYY-MM-DD').diff(
            moment(startDateAux, 'YYYY-MM-DD')
          );
          const diffDaysEnd = moment(endDateAux, 'YYYY-MM-DD').diff(moment(endDate, 'YYYY-MM-DD'));
          if (diffDaysStart > 0) {
            startDate = startDateAux;
          }
          if (diffDaysEnd > 0) {
            endDate = endDateAux;
          }
        } else {
          startDate = startDateAux;
          endDate = endDateAux;
        }
      });
      const renderTasks = () => {
        let sumTotal = 0;
        return arrDates.map((task, index) => {
          const enditem = task.endDate.split('T')[0];
          if (enditem === endDate || enditem === startDate) {
            return null;
          }
          let distLeft = 0;
          const diffTodayStart = moment(enditem, 'YYYY-MM-DD').diff(
            moment(startDate, 'YYYY-MM-DD'),
            'days'
          );
          const diffProgress = moment(endDate, 'YYYY-MM-DD').diff(
            moment(startDate, 'YYYY-MM-DD'),
            'days'
          );
          if (diffTodayStart > 0) {
            distLeft = (diffTodayStart * 100) / diffProgress;
          }
          distLeft = distLeft < 0 ? 0 : distLeft > 100 ? 100 : distLeft;
          sumTotal += task?.volume;
          return (
            <Box
              className={styles.dateInRowFloat}
              style={{ left: `${distLeft}%` }}
              key={`${index}-item-date-task`}
            >
              <span>{sumTotal} MT</span>
              {moment(enditem, 'YYYY-MM-DD').format('MMM DD')}
              <Box className={styles.lineTask} />
            </Box>
          );
        });
      };
      return (
        <>
          <Box className={styles.rowDates}>
            <Box className={styles.dateInRow}>
              {isDetail ? <span>0 MT</span> : null}
              {moment(startDate, 'YYYY-MM-DD').format('MMM DD')}
            </Box>
            <Box className={styles.dateInRow}>
              {isDetail ? <span className={styles.lastRow}>{totalScheduled} MT</span> : null}
              {moment(endDate, 'YYYY-MM-DD').format('MMM DD')}
            </Box>
            {isDetail && arrDates.length > 0 ? renderTasks() : null}
          </Box>
          <Box className={styles.bar}>{getBar(arrDates)}</Box>
        </>
      );
    }
    return (
      <>
        <Box className={styles.rowDates} />
        <Box className={styles.bar}>{getBar(arrDates)}</Box>
      </>
    );
  };

  const renderToday = () => {
    const todayDate = moment();
    let distLeft = 0;
    let startDate = '';
    let endDate = '';
    if (schedules.length > 0) {
      schedules.forEach((item) => {
        const startDateAux = item?.startDate.split('T')[0];
        const endDateAux = item?.endDate.split('T')[0];
        if (startDate) {
          const diffDaysStart = moment(startDate, 'YYYY-MM-DD').diff(
            moment(startDateAux, 'YYYY-MM-DD')
          );
          const diffDaysEnd = moment(endDateAux, 'YYYY-MM-DD').diff(moment(endDate, 'YYYY-MM-DD'));
          if (diffDaysStart > 0) {
            startDate = startDateAux;
          }
          if (diffDaysEnd > 0) {
            endDate = endDateAux;
          }
        } else {
          startDate = startDateAux;
          endDate = endDateAux;
        }
      });
      if (startDate && endDate) {
        const diffTodayStart = todayDate.diff(moment(startDate, 'YYYY-MM-DD'), 'days');
        const diffTodayEnd = todayDate.diff(moment(endDate, 'YYYY-MM-DD'), 'days');
        const diffProgress = moment(endDate, 'YYYY-MM-DD').diff(
          moment(startDate, 'YYYY-MM-DD'),
          'days'
        );
        if (diffTodayStart > 0) {
          if (diffTodayEnd >= 0) {
            distLeft = 100;
          } else {
            distLeft = (diffTodayStart * 100) / diffProgress;
          }
        }
        distLeft = distLeft < 0 ? 0 : distLeft > 99.6 ? 99.6 : distLeft;
      }
    }
    return distLeft >= 99.6 ? null : (
      <Box
        className={[
          styles.contLine,
          distLeft < 5 ? styles.justLeft : '',
          distLeft > 95 ? styles.justRight : '',
        ].join(' ')}
        style={{ left: `${distLeft}%` }}
      >
        <Box className={styles.lblToday}>
          <b>{t('contracts.today')}</b>
        </Box>
        <Box className={styles.dateToday}>{moment().format('DD MMM, YYYY')}</Box>
        <Box className={styles.circleLine} />
        <Box className={styles.lineDate} />
      </Box>
    );
  };

  const onClickChart = (event) => {
    if (getStatus() === t('contracts.addOffTakeSchedule')) {
      if (onAddOffTakeSchedule) {
        event.stopPropagation();
        onAddOffTakeSchedule();
      }
    } else if (onClick) {
      event.stopPropagation();
      onClick();
    }
  };

  return isDetail && schedules.length === 0 ? (
    <Box className={styles.chart} onClick={onClickChart}>
      <img
        alt="Empty schedule"
        src={require('../../assets/images/icons/truck.png')}
        className={styles.emptyIcon}
      />
      <p className={styles.haventDates}>{t('contracts.haventAdded')}</p>
      <Box
        className={styles.btnAddDate}
        onClick={(event) => {
          if (onAddOffTakeSchedule) {
            event.stopPropagation();
            onAddOffTakeSchedule();
          }
        }}
      >
        {t('contracts.addDeliverySchedule')}
      </Box>
    </Box>
  ) : (
    <Box className={styles.chart} onClick={onClickChart}>
      {renderStatus()}
      <Box className={styles.time_line} style={{ width: isDetail ? '100%' : '90%' }}>
        {renderDates()}
        {isDetail ? renderToday() : null}
      </Box>
      <Box className={[styles.labels_box, isDetail ? styles.labels_box2 : ''].join(' ')}>
        <Box className={styles.label_item}>
          <Box className={styles.label_box}>
            <Box className={styles.received_circle} />
            {showLabels && <Box className={styles.label}>{t('home.received')}:</Box>}
          </Box>
          <Box className={styles.label}>{`${
            contract?.statistics?.totalDelivered || totalDelivered || 0
          } MT`}</Box>
          {/* {isDetail ?  <Box className={styles.priceLabel}>*${renderNum('totalDelivered')}</Box> : null} */}
        </Box>
        <Box className={styles.label_item}>
          <Box className={styles.label_box}>
            <Box className={styles.nominated_circle} />
            {showLabels && <Box className={styles.label}>{t('home.nominated')}:</Box>}
          </Box>
          <Box className={styles.label}>{`${getRealNominated()} MT`}</Box>
          {/* {isDetail ?  <Box className={styles.priceLabel}>*${renderNum('totalNominated')}</Box> : null} */}
        </Box>
        {/* {isDetail ? (
          <Box className={styles.label_item}>
            <Box className={styles.white_circle} />
            <Box className={styles.label}>{t('home.pending')}:</Box>
            <Box className={styles.label}>0 MT</Box>
            <Box className={styles.priceLabel}>*$0</Box>
          </Box>
        ) : null} */}
        {isDetail ? (
          <Box className={styles.label_item}>
            <Box className={styles.label_box}>
              <Box className={styles.white_circle} />
              <Box className={styles.label}>Total:</Box>
            </Box>
            <Box className={styles.label}>{totalVolumeWidth} MT</Box>
            {/* <Box className={styles.priceLabel}>*$0</Box> */}
          </Box>
        ) : null}
      </Box>
      {/* contract?.tags?.indexOf('undefined') > -1 ? (
        <Box className={styles.status_lbl_container}>
          <Box className={styles.status}>
            <Button
              onClick={(event) => {
                if (onAddOffTakeSchedule) {
                  event.stopPropagation();
                  onAddOffTakeSchedule();
                }
              }}
            >
              {t('contracts.addOffTakeSchedule')}
            </Button>
          </Box>
        </Box> */}
    </Box>
  );
};

export default withTranslation()(ContractChart);
