import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns'; // import
import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { MobileDatePicker, MobileTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Formik } from 'formik';
import * as Yup from 'yup';
import NavigationBar from '../../../../components/NavigationBar';
import TextField from '../../../../components/TextField';
import Select from '../../../../components/Select';
import MessageModal from '../../../../modals/message';
import Loading from '../../../../components/loading';
import Button from '../../../../components/Button';
import UserPhoto from '../../../../components/UserPhoto';
import Recipients from '../../../../components/Recipients';
import CheckBox from '../../../../components/CheckBox';
import TimeIcon from '../../../../assets/images/icons/time.svg';
import CalendarIcon from '../../../../assets/images/icons/calendar.svg';
import DeleteIcon from '../../../../assets/images/icons/delete.svg';
import EditIcon from '../../../../assets/images/icons/edit_item.svg';
import CheckIcon from '../../../../assets/images/icons/check.svg';

import AuthActions from '../../../../redux/auth/actions';
import WorkflowActions from '../../../../redux/workflow/actions';
import InvitesActions from '../../../../redux/invites/actions';

import styles from './styles.module.sass';
import InviteCollaboratorsTaskModal from '../../../../modals/inviteCollaboratorsTask';

const CreateNominationTask = ({ t, history, desktop = false, onClose, taskId }) => {
  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        root: {
          backgroundColor: '#111',
        },
      },
      MuiPickersDateRangeDay: {
        rangeIntervalDayHighlight: {
          color: '#111',
          backgroundColor: '#f4f4f5',
        },
      },
      MuiPickersClockPointer: {
        noPoint: {
          backgroundColor: '#111',
        },
        thumb: {
          border: '14px solid #111',
        },
        pointer: {
          backgroundColor: '#111',
        },
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: '#111',
        },
        meridiemButtonSelected: {
          backgroundColor: '#111',
          '&:hover': {
            backgroundColor: '#111',
          },
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          color: '#111',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#111',
        },
      },
    },
  });

  function requiredDate(ref, msg) {
    return Yup.mixed().test({
      name: 'requiredDate',
      exclusive: false,
      message: msg || 'Required',
      params: {
        reference: ref.path,
      },
      test(value) {
        if (this.resolve(ref) === true) {
          return true;
        }
        if (value === undefined) {
          return false;
        }
        return true;
      },
    });
  }
  Yup.addMethod(Yup.string, 'requiredDate', requiredDate);

  const validationSchema = Yup.object({
    volume: Yup.string().required(t('common.requiredField')),
    packagingType: Yup.string().required(t('common.requiredField')),
    pickupLocation: Yup.string().required(t('common.requiredField')),
    date: Yup.string().requiredDate(Yup.ref('tbdDate'), t('common.requiredField')),
    eta: Yup.string().requiredDate(t('common.requiredField')),
    etd: Yup.string().requiredDate(t('common.requiredField')),
    from: Yup.string().requiredDate(Yup.ref('tbdTime'), t('common.requiredField')),
    till: Yup.string().requiredDate(Yup.ref('tbdTime'), t('common.requiredField')),
  });

  const dispatch = useDispatch();
  const [openCollaboratorsModal, setOpenCollaboratorsModal] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  // const [disableDate, setDisableDate] = useState(false);
  // const [disableTime, setDisableTime] = useState(false);
  const [editing, setEditing] = useState(true);
  const [nominating, setNominating] = useState(false);
  const [creating, setCreating] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { contractSelection } = useSelector((state) => state.contracts);
  const {
    offTakeScheduleSelection,
    saveTaskLoading,
    saveTaskError,
    saveTaskCalled,
    saveTaskFormData,

    deleteTaskLoading,
    deleteTaskError,
    deleteTaskCalled,

    usersInviteTask,
    subtaskSelection,
    getSubtaskCalled,
    getSubtaskLoading,
    getSubtaskError,
  } = useSelector((state) => state.workflow);

  useEffect(() => {
    if (!getSubtaskCalled && (taskId || subtaskSelection?.id)) {
      dispatch(
        WorkflowActions.getSubtask(
          taskId || subtaskSelection?.id || subtaskSelection?.id,
          'nomination'
        )
      );
    }

    if (deleteTaskCalled && !deleteTaskLoading && !deleteTaskError) {
      dispatch(WorkflowActions.resetDeleteTask());
      !desktop && history.goBack();
    }
    if (saveTaskCalled && !saveTaskLoading && !saveTaskError) {
      if (!isMobile && !isTablet) {
        dispatch(WorkflowActions.resetGetTasks());
        dispatch(WorkflowActions.resetSaveTask());
        onClose();
      }
    }

    return () => {};
  }, [
    dispatch,
    history,
    subtaskSelection,
    deleteTaskCalled,
    deleteTaskLoading,
    deleteTaskError,
    saveTaskCalled,
    saveTaskLoading,
    saveTaskError,
  ]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function handleSubmit(values) {
    dispatch(WorkflowActions.saveTaskFormData(values));
    const data = {};
    if (!values.tbdDate) {
      data.date = values.date;
    }

    data.eta = values.eta;
    data.etd = values.etd;

    if (!values.tbdTime) {
      data.endTime = moment(values.till).format('HH:mm');
      data.startTime = moment(values.from).format('HH:mm');
    }
    if (values.referenceNumber && values.referenceNumber !== '') {
      data.referenceNumber = values.referenceNumber;
    }
    if (!(taskId || subtaskSelection?.id)) {
      data.type = 'nomination';
    }
    data.location = values.pickupLocation;
    data.countryOrigin = values.countryOrigin;
    data.packageType = values.packagingType;
    data.volume = values.volume;
    // data.comments = values.comments;
    if (usersInviteTask) {
      if (subtaskSelection) {
        data.collaborators = { added: [...usersInviteTask] };
      } else {
        data.collaborators = [...usersInviteTask];
      }
    }
    if (subtaskSelection) {
      if (nominating) {
        data.status = 'nominated';
      }
      dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'nomination'));
    } else {
      data.schedule = offTakeScheduleSelection?.id;
      const userFound = contractSelection.collaborators.find(
        (collaborator) => collaborator.user === user.id
      );
      dispatch(
        WorkflowActions.saveTask(data, 'nomination', {
          contractId: contractSelection.id,
          role: userFound?.role || '',
          userId: user.id,
        })
      );
    }
  }

  function updateRequirement(requirement) {
    const data = {};
    if (subtaskSelection) {
      const newTaskSelection = JSON.parse(JSON.stringify(subtaskSelection));
      newTaskSelection.requirements[
        newTaskSelection.requirements.findIndex((el) => el.id === requirement.id)
      ].completed = requirement.completed;
      dispatch(WorkflowActions.updateTaskSelection(newTaskSelection));
      dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'nomination'));
    }
  }

  function deleteTask() {
    dispatch(WorkflowActions.deleteTask(taskId || subtaskSelection?.id, 'nomination'));
  }

  const PageContent = () => {
    const [disableDate, setDisableDate] = useState(false);
    const [disableTime, setDisableTime] = useState(false);

    return (
      <Box
        className={clsx(styles.main_content)}
        style={isMobile || isTablet ? { paddingBottom: 120 } : {}}
      >
        <Container maxWidth="md" className={desktop ? styles.content_desktop : styles.content}>
          {!nominating && (
            <Box>
              {desktop && (
                <Box className={styles.container_step_description}>
                  <Box width="100%" style={{ marginBottom: 10 }} className="subtitle_container">
                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                      <Box>
                        {t('contracts.details.workflow.nominationPage.title', {
                          action: !(taskId || subtaskSelection?.id)
                            ? `${t('contracts.details.workflow.add')} `
                            : `${t('contracts.details.workflow.edit')} `,
                        })}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="description_container">
                    {t('contracts.details.workflow.nominationPage.description')}
                  </Box>
                </Box>
              )}
              {/* <Box className={styles.collaborators}>
                {(!subtaskSelection || subtaskSelection.status !== 'nominated') && (
                  <Button
                    onPress={() => {
                      dispatch(InvitesActions.resetGetRoles());
                      desktop
                        ? setOpenCollaboratorsModal(true)
                        : history.push('/contracts/workflows/invite-collaborators-task/nomination');
                    }}
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      maxWidth: 30,
                      maxHeight: 30,
                      margin: 5,
                    }}
                  >
                    <AddIcon style={{ fontSize: 10 }} />
                  </Button>
                )}
                {subtaskSelection?.collaborators &&
                  subtaskSelection?.collaborators?.map((item, index) => (
                    <UserPhoto
                      key={index}
                      className={styles.box_image}
                      initials={
                        (item?.firstName ?? '').slice(0, 1) + (item?.lastName ?? '').slice(0, 1)
                      }
                    />
                  ))}
                {usersInviteTask &&
                  usersInviteTask?.map((item, index) => (
                    <UserPhoto
                      key={index}
                      className={styles.box_image}
                      initials={(item?.email ?? '').slice(0, 1) + (item?.email ?? '').slice(1, 2)}
                    />
                  ))}
              </Box> */}
            </Box>
          )}

          {(creating || editing || nominating) && (
            <Box>
              <Formik
                initialValues={{
                  referenceNumber:
                    saveTaskFormData?.referenceNumber || subtaskSelection?.referenceNumber || '',
                  volume: saveTaskFormData?.volume || subtaskSelection?.volume || '',
                  packagingType:
                    saveTaskFormData?.packagingType ||
                    subtaskSelection?.packageType ||
                    contractSelection?.packageType ||
                    '',
                  pickupLocation:
                    saveTaskFormData?.pickupLocation || subtaskSelection?.location || '',
                  countryOrigin:
                    saveTaskFormData?.countryOrigin || subtaskSelection?.countryOrigin || '',
                  date: saveTaskFormData?.date || subtaskSelection?.date || '',

                  eta: saveTaskFormData?.eta || subtaskSelection?.eta || '',
                  etd: saveTaskFormData?.etd || subtaskSelection?.etd || '',

                  from: saveTaskFormData?.startTime
                    ? moment.utc(saveTaskFormData?.startTime, 'HH:mm:ss')
                    : subtaskSelection?.startTime
                    ? moment.utc(subtaskSelection?.startTime, 'HH:mm:ss')
                    : '',
                  till: saveTaskFormData?.endTime
                    ? moment.utc(saveTaskFormData?.endTime, 'HH:mm:ss')
                    : subtaskSelection?.endTime
                    ? moment.utc(subtaskSelection?.endTime, 'HH:mm:ss')
                    : '',
                  tbdDate: !saveTaskFormData?.date || !subtaskSelection?.date,
                  tbdTime: !saveTaskFormData?.startTime || !subtaskSelection?.startTime,
                  comments: saveTaskFormData?.comments || subtaskSelection?.comments || '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box style={{ display: 'flex' }}>
                      <Box className={styles.container_inputs} style={{ flex: '1 1 0px' }}>
                        <Box className={styles.container_input}>
                          <TextField
                            name="referenceNumber"
                            value={values.referenceNumber}
                            onChange={handleChange}
                            helperText={
                              errors.referenceNumber &&
                              touched.referenceNumber &&
                              errors.referenceNumber
                            }
                            placeholder={t(
                              'contracts.details.workflow.deliveryPage.referenceNumber'
                            )}
                            label={t(
                              'contracts.details.workflow.deliveryPage.referenceNumber'
                            ).toUpperCase()}
                          />
                        </Box>
                        {!nominating && (
                          <Box className={styles.container_names}>
                            <Box
                              className={clsx(styles.container_first_field, styles.container_input)}
                            >
                              <TextField
                                name="volume"
                                value={values.volume}
                                onChange={handleChange}
                                helperText={errors.volume && touched.volume && errors.volume}
                                placeholder={t('contracts.details.workflow.nominationPage.volume')}
                                label={t(
                                  'contracts.details.workflow.nominationPage.volume'
                                ).toUpperCase()}
                                required
                                icon="MT"
                                type="number"
                              />
                            </Box>
                            <Box
                              className={clsx(styles.container_last_field, styles.container_input)}
                            >
                              <Select
                                name="packagingType"
                                values={['50 kg Bag', '1 MT Bag', 'Bulk', 'Other']}
                                value={values.packagingType}
                                onChange={(value) => {
                                  values.packagingType = value;
                                  const event = {
                                    persist: () => {},
                                    target: {
                                      type: 'change',
                                      id: 'packagingType',
                                      name: 'packagingType',
                                      value: values.packagingType,
                                    },
                                  };
                                  handleChange(event);
                                }}
                                helperText={
                                  errors.packagingType &&
                                  touched.packagingType &&
                                  errors.packagingType
                                }
                                placeholder={t('contracts.packagingType')}
                                label={t('contracts.packagingType').toUpperCase()}
                                required
                              />
                            </Box>
                          </Box>
                        )}
                        {nominating && (
                          <Box className={styles.container_input}>
                            <TextField
                              name="volume"
                              value={values.volume}
                              onChange={handleChange}
                              helperText={errors.volume && touched.volume && errors.volume}
                              placeholder={t('contracts.details.workflow.nominationPage.volume')}
                              label={t(
                                'contracts.details.workflow.nominationPage.volume'
                              ).toUpperCase()}
                              required
                              icon="MT"
                              type="number"
                            />
                          </Box>
                        )}
                        <Box className={styles.container_input}>
                          <TextField
                            name="pickupLocation"
                            value={values.pickupLocation}
                            onChange={handleChange}
                            helperText={
                              errors.pickupLocation &&
                              touched.pickupLocation &&
                              errors.pickupLocation
                            }
                            placeholder={t(
                              'contracts.details.workflow.nominationPage.pickupLocation'
                            )}
                            label={t(
                              'contracts.details.workflow.nominationPage.pickupLocation'
                            ).toUpperCase()}
                            required
                          />
                        </Box>

                        <Box className={styles.container_input}>
                          <TextField
                            name="countryOrigin"
                            value={values.countryOrigin}
                            onChange={handleChange}
                            helperText={
                              errors.countryOrigin && touched.countryOrigin && errors.countryOrigin
                            }
                            placeholder={t(
                              'contracts.details.workflow.nominationPage.countryOrigin'
                            )}
                            label={t(
                              'contracts.details.workflow.nominationPage.countryOrigin'
                            ).toUpperCase()}
                          />
                        </Box>

                        <Box className={styles.container_input_checkbox}>
                          <Box style={{ flexGrow: 1, marginRight: 5 }}>
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <MobileDatePicker
                                  style={{ width: 'auto' }}
                                  name="date"
                                  renderInput={(props) => {
                                    const rangeProps = {
                                      // ...props,
                                      helperText: '',
                                      inputProps: {
                                        ...props.inputProps,
                                        value:
                                          values.date !== '' ? moment(values.date).format('L') : '',
                                        onBlur: props.inputProps.onBlur,
                                        onFocus: props.inputProps.onFocus,
                                        onChange: props.inputProps.onChange,
                                      },
                                    };
                                    return (
                                      <Box style={{ width: '100%' }}>
                                        <TextField
                                          {...rangeProps}
                                          variant="filled"
                                          value=""
                                          helperText={errors.date && touched.date && errors.date}
                                          placeholder={t(
                                            'contracts.details.workflow.nominationPage.date'
                                          )}
                                          label={t(
                                            'contracts.details.workflow.nominationPage.date'
                                          ).toUpperCase()}
                                          icon={
                                            <SvgIcon
                                              style={{ width: 18, height: 18 }}
                                              viewBox="0 0 22 22"
                                              component={CalendarIcon}
                                            />
                                          }
                                          onFocus={() => {
                                            values.tbdDate = false;
                                            const event = {
                                              persist: () => {},
                                              target: {
                                                type: 'change',
                                                id: 'tbdDate',
                                                name: 'tbdDate',
                                                value: values.tbdDate,
                                              },
                                            };
                                            handleChange(event);
                                          }}
                                        />
                                      </Box>
                                    );
                                  }}
                                  value={values.date}
                                  onChange={(date) => {
                                    values.date = date;
                                  }}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Box>
                          {!nominating && (
                            <Box className={styles.check_container}>
                              <CheckBox
                                name="tbdDate"
                                checked={values.tbdDate}
                                onChange={(event) => {
                                  handleChange(event);
                                  values.date = '';
                                  const event1 = {
                                    persist: () => {},
                                    target: {
                                      type: 'change',
                                      id: 'date',
                                      name: 'date',
                                      value: values.date,
                                    },
                                  };
                                  handleChange(event1);
                                }}
                              />
                              <Box className={styles.label}>
                                {t('contracts.details.workflow.nominationPage.tbd')}
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box className={styles.container_section_title} style={{ marginTop: 5 }}>
                          {t('contracts.details.workflow.nominationPage.timeframe').toUpperCase()}
                        </Box>

                        <Box className={styles.container_input_checkbox}>
                          <Box style={{ display: 'flex', flexGrow: 1 }}>
                            <Box
                              style={{
                                marginRight: 5,
                                flexGrow: 1,
                                width: '1% !important',
                              }}
                            >
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                  <MobileTimePicker
                                    style={{ width: 'auto' }}
                                    ampm
                                    ampmInClock
                                    name="from"
                                    minutesStep={5}
                                    renderInput={(props) => {
                                      const rangeProps = {
                                        // ...props,
                                        helperText: '',
                                        inputProps: {
                                          ...props.inputProps,
                                          value:
                                            values.from !== ''
                                              ? moment(values.from).format('LT')
                                              : '',
                                          onBlur: props.inputProps.onBlur,
                                          onFocus: props.inputProps.onFocus,
                                          onChange: props.inputProps.onChange,
                                        },
                                      };
                                      return (
                                        <Box style={{ width: '100%' }}>
                                          <TextField
                                            {...rangeProps}
                                            variant="filled"
                                            value=""
                                            helperText={errors.from && touched.from && errors.from}
                                            placeholder={t(
                                              'contracts.details.workflow.nominationPage.from'
                                            )}
                                            label={t(
                                              'contracts.details.workflow.nominationPage.from'
                                            ).toUpperCase()}
                                            icon={
                                              <SvgIcon
                                                style={{ width: 18, height: 18 }}
                                                viewBox="0 0 20 20"
                                                component={TimeIcon}
                                              />
                                            }
                                            onFocus={() => {
                                              values.tbdTime = false;
                                              const event = {
                                                persist: () => {},
                                                target: {
                                                  type: 'change',
                                                  id: 'tbdTime',
                                                  name: 'tbdTime',
                                                  value: values.tbdTime,
                                                },
                                              };
                                              handleChange(event);
                                            }}
                                          />
                                        </Box>
                                      );
                                    }}
                                    value={values.from}
                                    onChange={(date) => {
                                      values.from = date;
                                    }}
                                  />
                                </ThemeProvider>
                              </LocalizationProvider>
                            </Box>
                            <Box
                              style={{
                                marginRight: 5,
                                flexGrow: 1,
                                width: '1% !important',
                              }}
                            >
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                  <MobileTimePicker
                                    style={{ width: 'auto' }}
                                    ampm
                                    ampmInClock
                                    name="till"
                                    minutesStep={5}
                                    renderInput={(props) => {
                                      const rangeProps = {
                                        // ...props,
                                        helperText: '',
                                        inputProps: {
                                          ...props.inputProps,
                                          value:
                                            values.till !== ''
                                              ? moment(values.till).format('LT')
                                              : '',
                                          onBlur: props.inputProps.onBlur,
                                          onFocus: props.inputProps.onFocus,
                                          onChange: props.inputProps.onChange,
                                        },
                                      };
                                      return (
                                        <Box style={{ width: '100%' }}>
                                          <TextField
                                            {...rangeProps}
                                            variant="filled"
                                            value=""
                                            helperText={errors.till && touched.till && errors.till}
                                            placeholder={t(
                                              'contracts.details.workflow.nominationPage.till'
                                            )}
                                            label={t(
                                              'contracts.details.workflow.nominationPage.till'
                                            ).toUpperCase()}
                                            icon={
                                              <SvgIcon
                                                style={{ width: 18, height: 18 }}
                                                viewBox="0 0 20 20"
                                                component={TimeIcon}
                                              />
                                            }
                                            onFocus={() => {
                                              values.tbdTime = false;
                                              const event = {
                                                persist: () => {},
                                                target: {
                                                  type: 'change',
                                                  id: 'tbdTime',
                                                  name: 'tbdTime',
                                                  value: values.tbdTime,
                                                },
                                              };
                                              handleChange(event);
                                            }}
                                          />
                                        </Box>
                                      );
                                    }}
                                    value={values.till}
                                    onChange={(date) => {
                                      values.till = date;
                                    }}
                                  />
                                </ThemeProvider>
                              </LocalizationProvider>
                            </Box>
                          </Box>
                          {!nominating && (
                            <Box className={styles.check_container}>
                              <CheckBox
                                name="tbdTime"
                                checked={values.tbdTime}
                                onChange={(event) => {
                                  handleChange(event);
                                  values.from = '';
                                  const event1 = {
                                    persist: () => {},
                                    target: {
                                      type: 'change',
                                      id: 'from',
                                      name: 'from',
                                      value: values.from,
                                    },
                                  };
                                  handleChange(event1);

                                  values.till = '';
                                  const event2 = {
                                    persist: () => {},
                                    target: {
                                      type: 'change',
                                      id: 'till',
                                      name: 'till',
                                      value: values.till,
                                    },
                                  };
                                  handleChange(event2);
                                }}
                              />
                              <Box className={styles.label}>
                                {t('contracts.details.workflow.nominationPage.tbd')}
                              </Box>
                            </Box>
                          )}
                        </Box>

                        <Box className={styles.container_input_checkbox}>
                          <Box style={{ flexGrow: 1, marginRight: 5 }}>
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <MobileDatePicker
                                  style={{ width: 'auto' }}
                                  name="etd"
                                  renderInput={(props) => {
                                    const rangeProps = {
                                      // ...props,
                                      helperText: '',
                                      inputProps: {
                                        ...props.inputProps,
                                        value:
                                          values.etd !== '' ? moment(values.etd).format('L') : '',
                                        onBlur: props.inputProps.onBlur,
                                        onFocus: props.inputProps.onFocus,
                                        onChange: props.inputProps.onChange,
                                      },
                                    };
                                    return (
                                      <Box style={{ width: '100%' }}>
                                        <TextField
                                          {...rangeProps}
                                          variant="filled"
                                          value=""
                                          helperText={errors.etd && touched.etd && errors.etd}
                                          placeholder={t(
                                            'contracts.details.workflow.nominationPage.etd'
                                          )}
                                          label={t(
                                            'contracts.details.workflow.nominationPage.etd'
                                          ).toUpperCase()}
                                          icon={
                                            <SvgIcon
                                              style={{ width: 18, height: 18 }}
                                              viewBox="0 0 22 22"
                                              component={CalendarIcon}
                                            />
                                          }
                                        />
                                      </Box>
                                    );
                                  }}
                                  value={values.etd}
                                  onChange={(date) => {
                                    values.etd = date;
                                  }}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Box>
                        </Box>

                        <Box className={styles.container_input_checkbox}>
                          <Box style={{ flexGrow: 1, marginRight: 5 }}>
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <MobileDatePicker
                                  style={{ width: 'auto' }}
                                  name="eta"
                                  renderInput={(props) => {
                                    const rangeProps = {
                                      // ...props,
                                      helperText: '',
                                      inputProps: {
                                        ...props.inputProps,
                                        value:
                                          values.eta !== '' ? moment(values.eta).format('L') : '',
                                        onBlur: props.inputProps.onBlur,
                                        onFocus: props.inputProps.onFocus,
                                        onChange: props.inputProps.onChange,
                                      },
                                    };
                                    return (
                                      <Box style={{ width: '100%' }}>
                                        <TextField
                                          {...rangeProps}
                                          variant="filled"
                                          value=""
                                          helperText={errors.eta && touched.eta && errors.eta}
                                          placeholder={t(
                                            'contracts.details.workflow.nominationPage.eta'
                                          )}
                                          label={t(
                                            'contracts.details.workflow.nominationPage.eta'
                                          ).toUpperCase()}
                                          icon={
                                            <SvgIcon
                                              style={{ width: 18, height: 18 }}
                                              viewBox="0 0 22 22"
                                              component={CalendarIcon}
                                            />
                                          }
                                        />
                                      </Box>
                                    );
                                  }}
                                  value={values.eta}
                                  onChange={(date) => {
                                    values.eta = date;
                                  }}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      </Box>
                      {/* <Divider
                        style={{ marginRight: 40, marginLeft: 40 }}
                        orientation="vertical"
                        flexItem
                      />
                      <Box className={styles.container_inputs} style={{ flex: '1 1 0px' }}>
                        <Box className={styles.container_input}>
                          <TextField
                            name="comments"
                            value={values.comments}
                            onChange={handleChange}
                            helperText={errors.comments && touched.comments && errors.comments}
                            placeholder={t('contracts.details.workflow.nominationPage.comments')}
                            label={t(
                              'contracts.details.workflow.nominationPage.comments'
                            ).toUpperCase()}
                            multiline
                            rows={17}
                            required
                          />
                        </Box>
                      </Box> */}
                    </Box>
                    {nominating && (
                      <Box style={{ marginTop: 15, marginBottom: 15 }}>
                        <Box
                          style={{
                            fontFamily: 'FuturaPT-Medium',
                            fontSize: '16.5px',
                            fontWeight: 500,
                            letterSpacing: '0.92px',
                            textAlign: 'center',
                          }}
                        >
                          {t('contracts.details.workflow.recipients')}
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            marginTop: 10,
                          }}
                        >
                          {subtaskSelection?.collaborators?.map((item, index) => (
                            <Recipients key={index} user={item} />
                          ))}
                        </Box>
                      </Box>
                    )}
                    <Box>
                      <Box>
                        {!nominating && (
                          <Box className={styles.container_submit}>
                            <Box className={styles.box_two_options}>
                              <Button
                                inverted
                                bordered
                                style={{ marginRight: 10 }}
                                className={styles.button}
                                onPress={() => {
                                  if (isMobile || isTablet) {
                                    history.goBack();
                                  } else {
                                    onClose();
                                  }
                                }}
                              >
                                {t('common.cancel')}
                              </Button>
                              <Button
                                type="submit"
                                disabled={saveTaskLoading}
                                loading={saveTaskLoading}
                                style={{ marginLeft: 10 }}
                                className={styles.button}
                                data-info-submit="Submit"
                                minWidth={130}
                                maxWidth={270}
                              >
                                {t('contracts.details.workflow.nominationPage.add')}
                              </Button>
                            </Box>
                          </Box>
                        )}
                        {nominating && (
                          <Box className={styles.container_submit}>
                            <Box className={styles.box_two_options}>
                              <Button
                                inverted
                                bordered
                                className={styles.button}
                                onPress={() => {
                                  setNominating(false);
                                }}
                              >
                                {t('common.cancel')}
                              </Button>
                              <Box style={{ width: 20 }} />
                              <Button
                                className={styles.button}
                                onPress={() => {
                                  handleSubmit();
                                }}
                              >
                                {t('common.confirm')}
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Container>
        {getSubtaskCalled && !getSubtaskLoading && getSubtaskError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={getSubtaskError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(WorkflowActions.resetGetSubtaskError());
            }}
          />
        )}
        {saveTaskCalled && !saveTaskLoading && !saveTaskError && (
          <MessageModal
            title={t('common.success')}
            message={t('contracts.details.workflow.sentSuccess')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(WorkflowActions.resetGetTasks());
              !desktop && history.goBack();
              dispatch(WorkflowActions.resetSaveTask());
            }}
          />
        )}
        {saveTaskCalled && !saveTaskLoading && saveTaskError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={saveTaskError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(WorkflowActions.resetSaveTaskError());
            }}
          />
        )}
        {deleteTaskCalled && !deleteTaskLoading && deleteTaskError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={deleteTaskError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(WorkflowActions.resetDeleteTaskError());
            }}
          />
        )}
        {deleteConfirmation && (
          <MessageModal
            title=""
            icon={null}
            message={t('common.areYouSure')}
            secundaryAction={t('common.no')}
            secundaryEvent={() => {
              setDeleteConfirmation(false);
            }}
            primaryAction={t('common.yes')}
            primaryEvent={() => {
              setDeleteConfirmation(false);
              deleteTask();
            }}
          />
        )}
        {openCollaboratorsModal && (
          <InviteCollaboratorsTaskModal
            history={history}
            type="nomination"
            onClose={() => setOpenCollaboratorsModal(false)}
          />
        )}
      </Box>
    );
  };

  return (
    <Box className={styles.container}>
      <Loading isLoading={saveTaskLoading || deleteTaskLoading || getSubtaskLoading} />
      {desktop ? (
        <PageContent />
      ) : (
        <NavigationBar
          title={t('contracts.details.workflow.nominationPage.title', {
            action: !(taskId || subtaskSelection?.id)
              ? `${t('contracts.details.workflow.add')} `
              : `${t('contracts.details.workflow.edit')} `,
          })}
          subtitle={t('contracts.details.workflow.taskSubtitle', {
            date: `${
              offTakeScheduleSelection?.startDate
                ? moment(offTakeScheduleSelection?.startDate).format('DD MMM')
                : ''
            } - ${
              offTakeScheduleSelection?.endDate
                ? moment(offTakeScheduleSelection?.endDate).format('DD MMM')
                : ''
            }`,
            volume: offTakeScheduleSelection?.volume || '',
          })}
          hideTitle={!isMobile}
          hasMenuButton
          barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
          white={!isMobile || isTablet}
          hasNotificationButton={!isMobile && !isTablet}
          history={history}
          user={user}
          menuList={[
            {
              label: t('common.goHome'),
              action: () => {
                history.replace('/home');
              },
            },
          ]}
          pageContent={<PageContent />}
        />
      )}
    </Box>
  );
};

export default withTranslation()(CreateNominationTask);
