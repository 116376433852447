import React, { Fragment, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import UrlsComponent from '../urls';
import I18n from '../utils/I18n';
import Snakbar from '../components/snakbar';
import Button from '../components/Button';
import FreemiumMessage from '../modals/freemiumMessage';
import MessageModal from '../modals/message';

import AuthActions from '../redux/auth/actions';
import PaymentsActions from '../redux/payments/actions';
import ContractsActions from '../redux/contracts/actions';

const Root = ({ t, showUpdateMessage = false }) => {
  const dispatch = useDispatch();

  const serviceWorker = useSelector((state) => state.serviceWorker.registration);
  const lang = useSelector((state) => state.serviceWorker.lang);

  const { loggedIn, paymentRequired, showForbiddenMessage } = useSelector((state) => state.auth);

  useEffect(() => {
    I18n.changeLanguage(lang);
    if (!loggedIn) {
      dispatch(AuthActions.restoreUserAccess());
    } else {
      // dispatch(PaymentsActions.restoreSelectedPayment());
      // dispatch(ContractsActions.restoreSelectedContract());
    }
  }, [dispatch, loggedIn]);

  return (
    <>
      <Snakbar
        open={showUpdateMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        message="New version availabe"
        action={
          <>
            <Button
              clean
              onPress={() => {
                if (serviceWorker) {
                  const { waiting } = serviceWorker;
                  if (waiting) {
                    waiting.postMessage({ type: 'SKIP_WAITING' });
                    window.location.reload(true);
                  }
                }
              }}
            >
              <Box style={{ fontWeight: 'bold' }} component="span">
                Update
              </Box>
            </Button>
          </>
        }
      />
      {paymentRequired && (
        <FreemiumMessage
          onClose={() => {
            dispatch(AuthActions.paymentRequired(false));
          }}
          onJoin={() => {
            window.open(process.env.REACT_APP_JOIN_PREMIUM, '_blank');
          }}
        />
      )}
      {showForbiddenMessage && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={t('common.forbidden')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(AuthActions.forbiddenMessage(false));
          }}
        />
      )}
      <UrlsComponent />
    </>
  );
};

export default withTranslation()(Root);
