import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import { SwipeableDrawer, Box, useMediaQuery } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import LogoMenu from '../../assets/images/icons/menu-icon.png';
import MenuBG from '../../assets/images/background/menuBG.svg';

import ProfileData from '../ProfileData';
import Button from '../Button';

import styles from './styles.module.sass';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: 99999999,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderColor: '#ffffff',
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    minHeight: 600,
    maxHeight: 1500,
  },
  imageContainer: {
    width: 44,
    height: 42,
    position: 'absolute',
    left: 27,
    top: 38,
  },
  profileContainer: {},
  photoContainer: {
    width: 134,
    height: 134,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listMenuItem: {
    height: 50,
    fontSize: 18,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoutContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  logoutButtonText: {
    fontFamily: 'FuturaPT-Demi',
    fontSize: 18,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#000000',
    textTransform: 'uppercase',
  },
}));

const MenuDrawer = ({
  t,
  user,
  color,
  open,
  list = [],
  onLogout,
  onPressProfile,
  onClose,
  onOpen,
  currentWidthChanged,
  onPressEditProfile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [drawerWidth, setDrawerWidth] = useState(299);

  useEffect(() => {
    const width = isMobile ? 299 : 420;
    setDrawerWidth(width);
    if (currentWidthChanged) {
      currentWidthChanged(width);
    }
  }, [isMobile, currentWidthChanged]);

  return (
    <SwipeableDrawer
      style={{ width: drawerWidth }}
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      onClose={onClose}
      onOpen={onOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{
        style: { width: drawerWidth },
      }}
      open={open}
    >
      <Box className={classes.imageContainer}>
        <img src={LogoMenu} alt="" />
      </Box>
      <Box className={styles.top_bg}>
        <SvgIcon component={MenuBG} viewBox="400 0 142 733" />
      </Box>
      <Box className={classes.boxContent}>
        <ProfileData
          onPress={onPressProfile}
          onPressEdit={onPressEditProfile}
          photoClassName={classes.photoContainer}
          className={classes.profileContainer}
          initials={user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
          username={`${user.firstName} ${user.lastName}`}
          photo={user.profile_url}
          email={user.email}
          color={color}
        />

        <Box className={classes.listContainer}>
          {list.map((item) => (
            <Box
              key={item.label}
              className={classes.listMenuItem}
              onClick={() => {
                item.action();
              }}
            >
              {item.label}
            </Box>
          ))}
        </Box>

        <Box className={classes.logoutContainer}>
          <Button onPress={onLogout} clean fontSize={20}>
            <Box className={classes.logoutButtonText} component="span">
              {t('common.signOut')}
            </Box>
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default withTranslation()(MenuDrawer);
