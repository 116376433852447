import React, { Fragment, useState } from 'react';

import CheckBoxMaterial from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

const CheckBox = ({ ...props }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CheckBoxMaterial
          {...props}
          color="primary"
          disableRipple
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default CheckBox;
