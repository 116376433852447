import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import enLang from './langs/en';
import esLang from './langs/es';
import ptLang from './langs/pt';

// Init I18n
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enLang,
    },
    es: {
      translation: esLang,
    },
    pt: {
      translation: ptLang,
    },
  },
  lng: 'en',
  fallbackLng: 'en',

  interpolation: {
    format(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
});

export default i18n;
