import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { isMobile } from 'react-device-detect';
import FirstAddArrow from '../../assets/images/icons/task_arrow.svg';
import FirstAddArrowDesktop from '../../assets/images/icons/task_arrow_desktop.svg';
import EmptyTasks from '../../assets/images/empty_tasks.png';
import EmptyTasksDesktop from '../../assets/images/empty_tasks_desktop.png';

import { useOnScreen } from '../../utils/onScreen';

import styles from './styles.module.sass';

const WorkflowEmpty = ({ t, className, hideDescription }) => {
  const firstAddRef = useRef();
  const visible = useOnScreen({ ref: firstAddRef, rootMargin: '70px' });
  const centerImg = isMobile ? EmptyTasks : EmptyTasksDesktop;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {!isMobile && (
        <>
          <Box className={styles.title}>{t('contracts.details.workflow.title')}</Box>
          <Box className={styles.subtitle}>{t('contracts.details.workflow.subtitle')}</Box>
        </>
      )}
      <Box className={styles.container_image}>
        <img src={centerImg} alt="empty-workflow" />
      </Box>
      {!isMobile && (
        <>
          <Box className={styles.first_step_title}>
            {t('contracts.details.workflow.first_step_title')}
          </Box>
          <Box className={styles.first_step_description}>
            {t('contracts.details.workflow.first_step_description')}
          </Box>
        </>
      )}
      {!hideDescription && (
        <Box className={styles.first_add_description}>
          <Box className={styles.container_first_add_description}>
            <Box className={styles.first_add_wrapper}>
              <Box className={styles.first_add_title}>
                {t('contracts.details.workflow.empty.endStepTitle')}
              </Box>
              <Box className={styles.add_your_first_task}>
                {t('contracts.details.workflow.empty.endStepDescription')}
              </Box>
            </Box>
            <Box className={styles.icon_container}>
              <Box style={{ width: 'calc(50% + 80px)' }} />
              <Box className={styles.first_add_arrow}>
                <SvgIcon
                  style={{ height: 105, width: 109, position: 'fixed', top: '10%' }}
                  component={isMobile ? FirstAddArrow : FirstAddArrowDesktop}
                  viewBox="0 0 109 150"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withTranslation()(WorkflowEmpty);
