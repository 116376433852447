import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';

import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { Grid } from '@material-ui/core';

import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import MessageModal from '../../../modals/message';
import Select from '../../../components/Select';
import Loading from '../../../components/loading';
import StatusBox from '../../../components/StatusBox';
import DeleteIcon from '../../../assets/images/icons/delete.svg';
import EditIcon from '../../../assets/images/icons/edit_item.svg';
import InvitesActions from '../../../redux/invites/actions';
import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import ContractInfoCard from '../../../components/ContractInfoCard';

const Invites = ({ t, history }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const { contractSelection } = useSelector((state) => state.contracts);

  const {
    getInvitesLoading,
    getInvitesError,
    getInvitesCalled,
    invites,

    createInvitesLoading,
    createInvitesError,
    createInvitesCalled,

    updateInvitesLoading,
    updateInvitesError,
    updateInvitesCalled,

    deleteInvitesLoading,
    deleteInvitesError,
    deleteInvitesCalled,

    updateUserInvitesLoading,
    updateUserInvitesError,
    updateUserInvitesCalled,

    getRolesLoading,
    getRolesError,
    getRolesCalled,
    roles,
  } = useSelector((state) => state.invites);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [tempInvites, setTempInvites] = useState([]);

  useEffect(() => {
    if (!getRolesCalled && !roles) {
      dispatch(InvitesActions.getRoles('?visible=true'));
    }
    if (!getInvitesCalled && contractSelection) {
      dispatch(InvitesActions.getInvites(contractSelection?.id));
    }
    if (contractSelection?.id) {
      if (invites?.length > 0) {
        setTempInvites((tempInvites) => [
          ...invites.map((invite) => {
            return {
              id: invite.id,
              role: invite.roleSlug,
              contract: invite.contract,
              email: invite.email,
              firstName: invite.firstName,
              lastName: invite.lastName,
              editing: false,
            };
          }),
        ]);
      }
    }
    return () => {
      dispatch(InvitesActions.resetCreateInvites());
      dispatch(InvitesActions.resetUpdateInvites());
      dispatch(InvitesActions.resetDeleteInvites());
    };
  }, [dispatch, getInvitesCalled, contractSelection, invites, getRolesCalled, roles]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function handleSubmit(values) {
    const invite = {
      contract: contractSelection?.id,
      email: values.email,
      role: null,
      editing: true,
    };
    setTempInvites((tempInvites) => [...tempInvites, invite]);
  }

  function send() {
    if (tempInvites.length > 0) {
      // CREATE
      const createInvites = tempInvites
        .filter((invite) => !invite.id && !invite.editing)
        .map((invite) => {
          delete invite.editing;
          delete invite.edited;
          return invite;
        });
      if (createInvites.length > 0) {
        dispatch(
          InvitesActions.createInvites({
            collaborators: createInvites,
          })
        );
      }

      // UPDATE
      const updateInvites = tempInvites
        .filter((invite) => invite.id && invite.edited && !invite.deleted && !invite.editing)
        .map((invite) => {
          return { id: invite.id, role: invite.role };
        });
      if (updateInvites.length > 0) {
        dispatch(
          InvitesActions.updateInvites({
            collaborators: updateInvites,
          })
        );
      }
      // DELETE
      const deleteInvites = tempInvites
        .filter((invite) => invite.id && invite.deleted && !invite.editing)
        .map((invite) => {
          return { id: invite.id };
        });
      if (deleteInvites.length > 0) {
        dispatch(
          InvitesActions.deleteInvites({
            collaborators: deleteInvites,
          })
        );
      }
    }
  }

  function toHumanText(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  function drawItem(value, index) {
    if (value.deleted) {
      return null;
    }
    return (
      <Box key={index}>
        <Box className={styles.item_box}>
          <Box className={styles.data}>
            <Box className={styles.email}>{value.email}</Box>
            <Box className={styles.role}>
              {value.role !== 'commercialOwner' && value.role !== 'commercialExecutive' ? (
                <Select
                  disabled={!value.editing}
                  isFilled={false}
                  values={roles || []}
                  valueKey="slug"
                  displayKey="name"
                  value={value.roleSlug || value.role || ''}
                  onChange={
                    value.editing
                      ? (selectValue) => {
                          if (selectValue !== value.role) {
                            const editedValue = { ...value };
                            editedValue.role = selectValue;
                            editedValue.editing = false;
                            setTempInvites((tempInvites) => [
                              ...tempInvites.filter((el) => el !== value),
                            ]);
                            setTempInvites((tempInvites) => [...tempInvites, editedValue]);
                          }
                        }
                      : null
                  }
                />
              ) : (
                <Box style={{ paddingLeft: 10 }}>{toHumanText(value.role)}</Box>
              )}
            </Box>
          </Box>
          {value.role !== 'commercialOwner' && value.role !== 'commercialExecutive' && (
            <Box className={styles.actions}>
              {value.editing ? (
                <Button
                  align="left"
                  padding="0 0 0 5px"
                  margin={0}
                  clean
                  className={styles.icon_container}
                  onPress={() => {
                    if (value.id) {
                      const editedValue = { ...value };
                      editedValue.editing = false;
                      editedValue.deleted = true;
                      setTempInvites((tempInvites) => [
                        ...tempInvites.filter((el) => el !== value),
                      ]);
                      setTempInvites((tempInvites) => [...tempInvites, editedValue]);
                    } else {
                      setTempInvites((tempInvites) => [
                        ...tempInvites.filter((el) => el !== value),
                      ]);
                    }
                  }}
                >
                  <SvgIcon
                    style={{ height: 18, width: 18 }}
                    component={DeleteIcon}
                    viewBox="0 0 17 17"
                  />
                </Button>
              ) : (
                <Button
                  align="left"
                  padding="0 0 0 5px"
                  margin={0}
                  clean
                  className={styles.icon_container}
                  onPress={() => {
                    const editedValue = { ...value };
                    editedValue.editing = true;
                    editedValue.edited = true;
                    setTempInvites((tempInvites) => [...tempInvites.filter((el) => el !== value)]);
                    setTempInvites((tempInvites) => [...tempInvites, editedValue]);
                  }}
                >
                  <SvgIcon
                    style={{ height: 18, width: 18 }}
                    component={EditIcon}
                    viewBox="0 0 17 17"
                  />
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
      </Box>
    );
  }

  function showError() {
    if (
      (createInvitesError || updateInvitesError || deleteInvitesError) &&
      !createInvitesLoading &&
      !updateInvitesLoading &&
      !deleteInvitesLoading
    ) {
      return (
        <MessageModal
          icon={null}
          title={t('common.error')}
          messages={[
            createInvitesError?.userMessage,
            updateInvitesError?.userMessage,
            deleteInvitesError?.userMessage,
          ]}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetCreateInvitesError());
            dispatch(InvitesActions.resetDeleteInvitesError());
            dispatch(InvitesActions.resetUpdateInvitesError());
          }}
        />
      );
    }
    return null;
  }

  function showSuccess() {
    if (
      (!createInvitesCalled ||
        (createInvitesCalled && !createInvitesLoading && !createInvitesError)) &&
      (!updateInvitesCalled ||
        (updateInvitesCalled && !updateInvitesLoading && !updateInvitesError)) &&
      (!deleteInvitesCalled ||
        (deleteInvitesCalled && !deleteInvitesLoading && !deleteInvitesError))
    ) {
      return (
        <MessageModal
          title={t('common.success')}
          message={t('contracts.addCollaborators.sentSuccess')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            history.push('/contracts/invites');
            dispatch(InvitesActions.resetGetInvites());
          }}
        />
      );
    }
    return null;
  }

  const validationSchema = Yup.object({
    email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
  });

  const TitleComponent = () => (
    <>
      <Grid item xs={12} />
      <Grid item xs={12}>
        {t('contracts.addCollaborators.title').toUpperCase()}
      </Grid>
      <Grid container justify="flex-end">
        <Button
          style={{
            fontFamily: 'FuturaPT-Demi',
            lineHeight: 0.93,
            letterSpacing: 0.75,
            textAlign: 'right',
          }}
          fontSize={15}
          clean
          inverted
          onPress={() => history.push('/contracts/invites/role-permissions')}
        >
          {t('contracts.addCollaborators.seeRolePermissions')}
        </Button>
      </Grid>
    </>
  );

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={
          getInvitesLoading ||
          createInvitesLoading ||
          updateInvitesLoading ||
          deleteInvitesLoading ||
          getRolesLoading
        }
      />
      <NavigationBar
        title={t('contracts.addCollaborators.title').toUpperCase()}
        hideTitle={!isMobile}
        hasMenuButton={!isMobile}
        barColor={isMobile ? '#fff' : '#111'}
        white={!isMobile}
        hasBackButton
        hasNotificationButton
        hasFilterButton={!isMobile}
        backButtonText={t('common.backToPortfolio').toUpperCase()}
        customBack={!isMobile ? () => history.replace('/home') : null}
        customTextButton={isMobile ? t('contracts.addCollaborators.seeRolePermissions') : null}
        history={history}
        onCustomTextButton={() => history.push('/contracts/invites/role-permissions')}
        user={user}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid container className={styles.grid_container} justify="center">
            {!isMobile && (
              <Grid item xs={12} sm={3}>
                <ContractInfoCard />
                <ContractSpecsCard />
              </Grid>
            )}
            <Grid item xs={12} sm={6} container justify="center">
              <Box className={clsx(styles.main_content)}>
                <Container maxWidth="md" className={styles.content}>
                  {!isMobile && (
                    <Box className={styles.container_title}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        width="100%"
                        component="span"
                        style={{ color: '#2f2f2f' }}
                        className={styles.title}
                      >
                        <TitleComponent />
                      </Box>
                    </Box>
                  )}

                  <Box className={styles.container_step_description}>
                    <Box className="subtitle">{t('contracts.addCollaborators.description')}</Box>
                  </Box>
                  <Box>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        email: '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm({ values: { email: '' } });
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box className={styles.container_inputs}>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(
                                  styles.container_first_field,
                                  styles.container_input
                                )}
                              >
                                <TextField
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  helperText={errors.email && touched.email && errors.email}
                                  placeholder={t('common.email')}
                                  label={t('common.email').toUpperCase()}
                                  required
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <Box className={styles.container_submit}>
                                  <Button
                                    type="submit"
                                    disabled={createInvitesLoading}
                                    loading={createInvitesLoading}
                                    data-info-submit="Submit"
                                  >
                                    {t('contracts.addCollaborators.add')}
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                  {(tempInvites?.length > 0 || invites?.length > 0) && (
                    <Box className={styles.list_content}>
                      <Box className={styles.added_content}>
                        <Box className={styles.added_list}>
                          <Divider style={{ marginTop: 30 }} />

                          {tempInvites?.map((value, index) => {
                            if (value.editing) {
                              return drawItem(value, index);
                            }
                            return null;
                          })}

                          <Box className={styles.container_step_description}>
                            <Box className="form_title">
                              {t('contracts.addCollaborators.listTitle')}
                            </Box>
                          </Box>

                          {tempInvites?.map((value, index) => {
                            if (!value.editing) {
                              return drawItem(value, index);
                            }
                            return null;
                          })}
                        </Box>
                      </Box>

                      <Box>
                        <Box className={styles.container_submit}>
                          <Button
                            disabled={createInvitesLoading}
                            loading={
                              createInvitesLoading || updateInvitesLoading || deleteInvitesLoading
                            }
                            className={styles.button_alone}
                            minWidth={130}
                            maxWidth={270}
                            onPress={() => {
                              send();
                            }}
                          >
                            {t('contracts.addCollaborators.send')}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Container>
              </Box>
            </Grid>
          </Grid>
        }
      />
      {(createInvitesCalled || updateInvitesCalled || deleteInvitesCalled) && showSuccess()}
      {(createInvitesCalled || updateInvitesCalled || deleteInvitesCalled) && showError()}
      {getInvitesCalled && !getInvitesLoading && getInvitesError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getInvitesError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetGetInvitesError());
          }}
        />
      )}
      {updateUserInvitesCalled && !updateUserInvitesLoading && !updateUserInvitesError && (
        <MessageModal
          title={t('common.success')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetUpdateUserInvites());
            history.goBack();
          }}
        />
      )}
      {updateUserInvitesCalled && !updateUserInvitesLoading && updateUserInvitesError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={updateUserInvitesError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetUpdateUserInvitesError());
          }}
        />
      )}
      {getRolesCalled && !getRolesLoading && getRolesError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getRolesError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(InvitesActions.resetGetRolesError());
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(Invites);
