import React, { useState, useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';
import MatButton from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { usePDF } from '@react-pdf/renderer';

import ContractPDF from '../contractPDF';
import NavigationBar from '../../../components/NavigationBar';
import MessageModal from '../../../modals/message';
import Loading from '../../../components/loading';
import Button from '../../../components/Button';
import { AntTabs, AntTab } from '../../../components/AntTabs';
import ContractChart from '../../../components/ContractChart';
import UserPhoto from '../../../components/UserPhoto';
import ContractInvites from '../../../components/ContractInvites';
import Popup from '../../../components/Popup';

import ModalView from '../../../modals/modalView';

import MoreIcon from '../../../assets/images/icons/more.svg';
import EditIcon from '../../../assets/images/icons/edit_item.svg';
import DeleteIcon from '../../../assets/images/icons/delete.svg';
import ArchiveIcon from '../../../assets/images/icons/archived.svg';
import OffTakeScheduleActions from '../../../redux/offTakeSchedule/actions';
import ContractsActions from '../../../redux/contracts/actions';
import AuthActions from '../../../redux/auth/actions';
import PaymentsActions from '../../../redux/payments/actions';

import styles from './styles.module.sass';
import GeneralInformation from '../generalInformation';

import '../../../utils/stringhelper';

const ContractDetails = ({ t, history }) => {
  const dispatch = useDispatch();

  const [openDeleteContract, setOpenDeleteContract] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [userType, setUserType] = useState(null);
  const [showEditGeneralInformation, setShowEditGeneralInformation] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const {
    contractSelection,
    contractSelectionStadistics,
    deleteUserContractsLoading,
    getUserContractLoading,
    getUserContractError,
    getUserContractCalled,
    acceptInviteLoading,
    acceptInviteError,
    acceptInviteCalled,
  } = useSelector((state) => state.contracts);

  const [instancePdf, updateInstancePdf] = usePDF({
    document: <ContractPDF contract={contractSelection} />,
  });

  useEffect(() => {
    if (!getUserContractCalled && contractSelection) {
      dispatch(ContractsActions.getUserContract(contractSelection?.id));
    }
    if (contractSelection?.status === 'created' && contractSelection?.owner?.id !== user.id) {
      if (!acceptInviteCalled && !acceptInviteLoading && !acceptInviteError) {
        dispatch(ContractsActions.acceptInvite(contractSelection?.id));
      }
    }

    if (contractSelection?.id) {
      if (contractSelection?.buyer?.email === user?.email) {
        setUserType('BUYER');
      } else if (contractSelection?.seller?.email === user?.email) {
        setUserType('SELLER');
      }
      dispatch(PaymentsActions.resetGetPayment());
      dispatch(PaymentsActions.savePaymentSelection(contractSelection?.paymentInstructions));
    } else {
      setUserType(null);
    }
  }, [dispatch, contractSelection, user, getUserContractCalled]);

  useEffect(() => {
    if (contractSelection) {
      updateInstancePdf({
        document: <ContractPDF contract={contractSelection} />,
      });
    }
  }, [contractSelection]);

  useEffect(() => {
    return () => {
      dispatch(ContractsActions.resetGetUserContract());
      dispatch(ContractsActions.resetAcceptInvite());
    };
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function titleComponent() {
    return (
      <Box className={styles.container_title}>
        <Box className={styles.contract_reference}>
          {contractSelection?.contractReference || ''}
        </Box>
        <Box className={styles.contract_name}>{contractSelection?.name || ''}</Box>
      </Box>
    );
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteContract = () => {
    setOpenDeleteContract((prevState) => !prevState);
  };

  const handlePrintContract = () => {
    if (!instancePdf.isLoading && !instancePdf.error) {
      window.open(instancePdf.url, '_blank');
    }
  };

  const TermsComponent = () => (
    <Box className={styles.tab_bar} style={{ backgroundColor: isMobile ? '#fff' : 'transparent' }}>
      <Box>
        <AntTabs value={tabValue} onChange={handleChange} centered>
          <AntTab label={t('contracts.details.obligations')} />
          <AntTab label={t('contracts.details.options')} />
        </AntTabs>
        {tabValue === 0
          ? contractSelection?.terms
              ?.filter((item) => item?.type === 'Obligation')
              .map((item, index) => (
                <Box key={index} className={styles.container_terms_item}>
                  <Box className={styles.container_data}>
                    <Box className={styles.title}>{item?.label?.toUpperCase() || ''}</Box>
                    <Box className={styles.description}>{item?.description}</Box>
                  </Box>
                  <Divider />
                </Box>
              ))
          : contractSelection?.terms
              ?.filter((item) => item?.type === 'Option')
              .map((item, index) => (
                <Box key={index} className={styles.container_terms_item}>
                  <Box className={styles.container_data}>
                    <Box className={styles.title}>{item?.label?.toUpperCase() || ''}</Box>
                    <Box className={styles.description}>{item?.description}</Box>
                  </Box>
                  <Divider />
                </Box>
              ))}
      </Box>
    </Box>
  );

  const TermTitle = () => (
    <Box
      className={styles.terms_card_title_container}
      style={
        isMobile && !isTablet
          ? { height: 46, backgroundColor: 'black', borderRadius: '5px 5px 0 0', padding: '0 20px' }
          : { marginBottom: 15 }
      }
    >
      <Box
        className={styles.terms_card_title}
        style={isMobile && !isTablet ? { color: 'white', fontSize: 15 } : {}}
      >
        {t('contracts.details.contractTerms')}
      </Box>
      <Box
        style={isMobile && !isTablet ? { color: 'white', fontSize: 11 } : {}}
        className={styles.terms_card_action}
        onClick={() => {
          history.push('/contracts/other-terms');
        }}
      >
        {t('contracts.details.contractTermsAction')}
      </Box>
    </Box>
  );

  const TermsCard = () => (
    <>
      {isMobile && !isTablet && <TermTitle />}
      <Box
        className={styles.terms_card}
        style={{
          borderTop: !isMobile || isTablet ? '10px solid black' : '',
          boxShadow: '0px 5px 8px 0 #ededed, 0px 5px 8px 0 #f6f6f6',
        }}
      >
        {(!isMobile || isTablet) && <TermTitle />}
        <TermItem
          title={t('contracts.box.totalVolume')}
          description={`${contractSelection?.totalVolume || ''} MT`}
        />
        <TermItem
          title={t('contracts.box.packagingType')}
          description={contractSelection?.packageType || ''}
        />
        <TermItem
          title={t('contracts.box.sugarSpec')}
          description={contractSelection?.sugarSpec || ''}
        />
        {contractSelection?.terms?.map((item, index) => (
          <TermItem
            key={`terms-card-${index}`}
            title={item?.label || ''}
            type={item.type || ''}
            description={item?.description}
          />
        ))}
      </Box>
    </>
  );
  const TermItem = ({ title, type, description }) => (
    <Box>
      <Box className={styles.container_terms_item}>
        <Box className={styles.title}>
          {title}
          {type && <Box className={styles.type}>{`(${type})`}</Box>}
        </Box>
        <Box
          className={styles.container_data}
          style={isMobile && !isTablet ? { padding: '0 0 0 0', textAlign: 'right' } : {}}
        >
          <Box className={styles.description}>{description}</Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  const TeamCard = () => <ContractInvites />;

  const DeleteMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const moreOptions = [t('contracts.delete')];
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <Menu
          id="delete-contract-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {moreOptions.map((item, index) => (
            <MenuItem key={`menu-item-${index}`} onClick={handleDeleteContract}>
              {item}
            </MenuItem>
          ))}
        </Menu>
        <MatButton
          size="small"
          className={styles.more_button}
          aria-controls="delete-contract-menu"
          aria-haspopup="true"
          data-help="Clicked More"
          onClick={handleClick}
        >
          <SvgIcon viewBox="-5 0 5 23" component={MoreIcon} style={{ color: '#000000' }} />
        </MatButton>
      </>
    );
  };

  const DesktopLayout = () => (
    <Grid
      container
      className={styles.grid_container}
      style={{
        marginTop: isMobile && !isTablet ? 10 : 30,
        paddingLeft: isTablet || !isMobile ? 20 : 0,
        paddingRight: isTablet ? 20 : 0,
        paddingBottom: isTablet ? 130 : isMobile ? 100 : 0,
        backgroundImage: `url("${require('../../../assets/images/background/pathChart.png')}")`,
        backgroundSize: 'contain',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
      }}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={6} lg={9}>
        <Box className="title_container">
          <Box className={styles.form_title}>{contractSelection?.contractReference || ''}</Box>
        </Box>
        {(!isMobile || isTablet) && (
          <Box className={styles.container_submit}>
            <Box
              className={styles.options}
              onClick={() => {
                setShowEditGeneralInformation(true);
              }}
            >
              <SvgIcon component={EditIcon} viewBox="0 0 18 18" style={{ marginRight: 10 }} />
              <Box>{t('contracts.modifyTerms')}</Box>
            </Box>
            {/* <Box className={styles.options}>
          <SvgIcon component={ArchiveIcon} viewBox="0 0 23 23" style={{ marginRight: 10 }} />
          <Box>{t('contracts.archive')}</Box>
        </Box> */}
            <Box className={styles.options} onClick={handleDeleteContract}>
              <SvgIcon component={DeleteIcon} viewBox="0 0 17 17" style={{ marginRight: 10 }} />
              <Box>{t('contracts.delete')}</Box>
            </Box>

            <Box className={styles.options} onClick={handlePrintContract}>
              <PrintIcon style={{ marginRight: 10 }} />
              <Box>{t('contracts.print')}</Box>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        {contractSelection?.buyer && contractSelection?.seller && (
          <Grid item xs={12}>
            <Box
              className={
                isTablet || !isMobile
                  ? styles.contract_details_card
                  : styles.contract_details_card_mobile
              }
              p={isTablet || !isMobile ? 3 : ''}
              mb={isTablet || !isMobile ? 3 : ''}
            >
              <Box
                style={{
                  width: '100%',
                  height: isMobile && !isTablet ? 50 : '',
                  display: isMobile && !isTablet ? 'flex' : '',
                  alignItems: isMobile && !isTablet ? 'center' : '',
                  backgroundImage:
                    isMobile && !isTablet
                      ? 'linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff)'
                      : 'none',
                }}
              >
                {isMobile && !isTablet ? (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0 20px',
                    }}
                  >
                    <Box className={styles.counterpart_mobile}>
                      {userType === 'SELLER'
                        ? t('contracts.generalInformation.buyer')
                        : t('contracts.generalInformation.seller')}
                    </Box>
                    <UserPhoto
                      className={styles.box_image}
                      initials={
                        userType === 'SELLER'
                          ? (contractSelection?.buyer?.firstName || '').slice(0, 1) +
                            (contractSelection?.buyer?.lastName || '').slice(0, 1)
                          : (contractSelection?.seller?.firstName || '').slice(0, 1) +
                            (contractSelection?.seller?.lastName || '').slice(0, 1)
                      }
                    />
                    <Box className={styles.name_mobile}>
                      {userType === 'SELLER'
                        ? `${contractSelection?.buyer?.firstName || ''} ${
                            contractSelection?.buyer?.lastName || ''
                          }`
                        : `${contractSelection?.seller?.firstName || ''} ${
                            contractSelection?.seller?.lastName || ''
                          }`}
                    </Box>
                    <Box className={styles.name_mobile}>
                      {userType === 'SELLER'
                        ? ` - ${contractSelection?.buyer?.companyName || ''}`
                        : ` - ${contractSelection?.seller?.companyName || ''}`}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box className={styles.reference}>
                      {userType === 'SELLER'
                        ? t('contracts.paymentInstructions.buyerInformation')
                        : t('contracts.paymentInstructions.sellerInformation')}
                    </Box>
                    <Box className={styles.box_row}>
                      <Box className={styles.box_personal_data}>
                        <Box className={styles.box_photo_name}>
                          <Box className={styles.box_name}>
                            {userType === 'SELLER'
                              ? `${contractSelection?.buyer?.companyName || ''}`
                              : `${contractSelection?.seller?.companyName || ''}`}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.box_row}>
                      <Box className={styles.box_personal_data}>
                        <Box className={styles.box_photo_name}>
                          <UserPhoto
                            className={styles.box_image}
                            initials={
                              userType === 'SELLER'
                                ? (contractSelection?.buyer?.firstName || '').slice(0, 1) +
                                  (contractSelection?.buyer?.lastName || '').slice(0, 1)
                                : (contractSelection?.seller?.firstName || '').slice(0, 1) +
                                  (contractSelection?.seller?.lastName || '').slice(0, 1)
                            }
                          />
                          <Box className={styles.box_name}>
                            {userType === 'SELLER'
                              ? `${contractSelection?.buyer?.firstName || ''} ${
                                  contractSelection?.buyer?.lastName || ''
                                }`
                              : `${contractSelection?.seller?.firstName || ''} ${
                                  contractSelection?.seller?.lastName || ''
                                }`}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} sm={12} container>
        <Box
          display="flex"
          alignItems="center"
          className={styles.container_chart}
          style={isMobile && !isTablet ? { padding: '40px 30px 0px 30px' } : {}}
        >
          <ContractChart
            contract={contractSelection}
            showLabels
            small
            onAddOffTakeSchedule={() => {
              history.push('/contracts/off-take-schedule');
            }}
            isDetail
          />
        </Box>
        {/* {(contractSelection?.schedules || []).length > 0 ? (
          <Box className={styles.label_subchart}>
            You have delivered <b>50%</b> of volume due by <b>April 30th</b> and <b>10%</b> of your total contract volume due by <b>July 15th</b>
          </Box>
        ) : null} */}
      </Grid>
      <Grid item xs={12} sm={12} container style={{ marginTop: 20 }}>
        <Grid
          container
          spacing={!isMobile || isTablet ? 6 : 0}
          direction="row"
          justify="space-between"
        >
          {(!isMobile || isTablet) && (
            <Grid
              item
              xs={12}
              sm={6}
              style={
                isMobile && !isTablet ? { paddingRight: 20, paddingLeft: 20, marginBottom: 20 } : {}
              }
            >
              <TermsCard />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={6}
            style={
              isMobile && !isTablet ? { paddingRight: 20, paddingLeft: 20, marginBottom: 20 } : {}
            }
          >
            <TeamCard />
          </Grid>

          {isMobile && !isTablet && (
            <Grid
              item
              xs={12}
              sm={6}
              style={
                isMobile && !isTablet ? { paddingRight: 20, paddingLeft: 20, marginBottom: 20 } : {}
              }
            >
              <TermsCard />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const MobileLayout = () => (
    <Box className={clsx(styles.main_content)}>
      <Container maxWidth="md" className={styles.content}>
        <Box className={styles.container_chart}>
          <Box className={styles.title}>{contractSelection?.sugarSpec || ''}</Box>
          <ContractChart contract={contractSelectionStadistics} showLabels small />
        </Box>

        <Box className={styles.container_step_description}>
          <Box className="form_title">{t('contracts.details.terms')}</Box>
        </Box>
        <TermsComponent />

        <Box className={styles.container_submit}>
          <Button
            className={styles.button_alone}
            disabled={!(contractSelection?.schedules?.length > 0)}
            minWidth={130}
            maxWidth={270}
            onPress={() => {
              dispatch(OffTakeScheduleActions.resetGetOffTakeSchedules());
              history.push('/contracts/workflows/list-off-take-schedules');
            }}
          >
            {t('contracts.details.workflow.title')}
          </Button>
        </Box>
      </Container>
    </Box>
  );

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={getUserContractLoading || acceptInviteLoading || deleteUserContractsLoading}
      />
      <NavigationBar
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        history={history}
        customBack={() => history.replace('/')}
        user={user}
        onPressEditProfile={() => {
          history.push('/profile');
        }}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        // pageContent={isMobile && !isTablet ? <MobileLayout /> : <DesktopLayout />}
        pageContent={<DesktopLayout />}
      />
      {getUserContractCalled && !getUserContractLoading && getUserContractError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={getUserContractError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetGetUserContractError());
          }}
        />
      )}
      {acceptInviteCalled && !acceptInviteLoading && acceptInviteError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={acceptInviteError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetAcceptInviteError());
          }}
        />
      )}
      {openDeleteContract && (
        <Popup
          title={t('common.deleteContractTitle')}
          description={t('common.deleteContractDes1')}
          subDescription={t('common.deleteContractDes2')}
          txtConfirm={t('common.yesDelete')}
          onConfirm={async () => {
            setOpenDeleteContract(false);
            if (contractSelection) {
              await dispatch(ContractsActions.deleteUserContracts(contractSelection?.id));
              history.push('/home');
            }
          }}
          txtCancel={t('common.noChangeMyMind')}
          onCancel={() => {
            setOpenDeleteContract(false);
          }}
          onClose={() => {
            setOpenDeleteContract(false);
          }}
        />
        // <MessageModal
        //   center
        //   title={t('common.deleteContractTitle')}
        //   icon={null}
        //   messages={[t('common.deleteContractDes1'), t('common.deleteContractDes2')]}
        //   secundaryAction={isMobile ? t('common.no') : t('common.noChangeMyMind')}
        //   secundaryEvent={() => {
        //     setOpenDeleteContract(false);
        //   }}
        //   primaryAction={isMobile ? t('common.yes') : t('common.yesDelete')}
        //   primaryEvent={async () => {
        //     setOpenDeleteContract(false);
        //     if (contractSelection) {
        //       await dispatch(ContractsActions.deleteUserContracts(contractSelection?.id));
        //       history.push('/home');
        //     }
        //   }}
        // />
      )}

      {showEditGeneralInformation && (
        <ModalView
          view={
            <GeneralInformation
              history={history}
              modalView
              onClose={(success) => {
                setShowEditGeneralInformation(false);
              }}
            />
          }
          onClose={() => {
            setShowEditGeneralInformation(false);
          }}
        />
      )}
    </Box>
  );
};

export default withTranslation()(ContractDetails);
