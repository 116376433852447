import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import { MobileDatePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns'; // import

import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { isMobile, isTablet } from 'react-device-detect';
import { Formik } from 'formik';
import * as Yup from 'yup';
import NavigationBar from '../../../../components/NavigationBar';
import TextField from '../../../../components/TextField';
import Select from '../../../../components/Select';
import MessageModal from '../../../../modals/message';
import InviteCollaboratorsTaskModal from '../../../../modals/inviteCollaboratorsTask';
import Loading from '../../../../components/loading';
import Button from '../../../../components/Button';
import UserPhoto from '../../../../components/UserPhoto';
import Recipients from '../../../../components/Recipients';
import DeleteIcon from '../../../../assets/images/icons/delete.svg';
import CheckBox from '../../../../components/CheckBox';
import CalendarIcon from '../../../../assets/images/icons/calendar.svg';
import EditIcon from '../../../../assets/images/icons/edit_item.svg';
import CheckIcon from '../../../../assets/images/icons/check.svg';

import AuthActions from '../../../../redux/auth/actions';
import WorkflowActions from '../../../../redux/workflow/actions';
import InvitesActions from '../../../../redux/invites/actions';

import styles from './styles.module.sass';

const CreateDeliveryTask = ({ t, parentTask, task, history, desktop = false, onClose, taskId }) => {
  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        root: {
          backgroundColor: '#111',
        },
      },
      MuiPickersDateRangeDay: {
        rangeIntervalDayHighlight: {
          color: '#111',
          backgroundColor: '#f4f4f5',
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          color: '#111',
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#111',
        },
      },
    },
  });

  function requiredCompletionDate(ref, msg) {
    return Yup.mixed().test({
      name: 'requiredCompletionDate',
      exclusive: false,
      message: msg || 'Required',
      params: {
        reference: ref.path,
      },
      test(value) {
        if (this.resolve(ref) === true) {
          return true;
        }
        if (value === undefined) {
          return false;
        }
        return true;
      },
    });
  }
  Yup.addMethod(Yup.string, 'requiredCompletionDate', requiredCompletionDate);

  const dispatch = useDispatch();

  const [openCollaboratorsModal, setOpenCollaboratorsModal] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  // const [dataPoints, setDataPoints] = useState([]);
  // const [documents, setDocuments] = useState([]);
  const [editing, setEditing] = useState(true);
  const [editingReferenceNumber, setEditingReferenceNumber] = useState(false);
  const [updatingRequirement, setUpdatingRequirement] = useState(false);
  const [updatingReferenceNumber, setUpdatingReferenceNumber] = useState(false);
  const [delivering, setDelivering] = useState(false);
  const [creating, setCreating] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {
    offTakeScheduleSelection,
    saveTaskLoading,
    saveTaskError,
    saveTaskCalled,
    usersInviteTask,
    deleteTaskLoading,
    deleteTaskError,
    deleteTaskCalled,
    subtaskSelection,
    parentTaskSelection,
    updateRequirementTaskLoading,
    updateRequirementTaskError,
    updateRequirementTaskCalled,
    getSubtaskCalled,
    getSubtaskLoading,
    getSubtaskError,
  } = useSelector((state) => state.workflow);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function deleteTask() {
    setUpdatingRequirement(false);
    dispatch(WorkflowActions.deleteTask(taskId || subtaskSelection?.id, 'delivery'));
  }

  const TabComponent = ({ values, handleChange }) => (
    <>
      {!isMobile && !isTablet && (
        <Box className={styles.row_tabs}>
          <Box
            className={[styles.tabBtn, values.tabIndex === 0 ? styles.tabSelected : ''].join(' ')}
            onClick={() => {
              const event = {
                persist: () => {},
                target: {
                  type: 'change',
                  id: 'tabIndex',
                  name: 'tabIndex',
                  value: 0,
                },
              };
              handleChange(event);
            }}
          >
            {t('contracts.details.workflow.dataPointRequired')}
          </Box>
          <Box
            className={[styles.tabBtn, values.tabIndex === 1 ? styles.tabSelected : ''].join(' ')}
            onClick={() => {
              const event = {
                persist: () => {},
                target: {
                  type: 'change',
                  id: 'tabIndex',
                  name: 'tabIndex',
                  value: 1,
                },
              };
              handleChange(event);
            }}
          >
            {t('contracts.details.workflow.documentRequired')}
          </Box>
        </Box>
      )}
    </>
  );

  const PageContent = () => {
    const [disableCompletionDate, setDisableCompletionDate] = useState(false);
    const { contractSelection } = useSelector((state) => state.contracts);
    const [newDataPoint, setNewDataPoint] = useState({
      requirement: '',
      requiredForPayment: false,
    });
    const [newDocument, setNewDocument] = useState({
      requirement: '',
      requiredForPayment: false,
      requiredForReview: false,
    });
    const [dataPoints, setDataPoints] = useState([]);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
      if (!getSubtaskCalled && (taskId || subtaskSelection?.id)) {
        dispatch(WorkflowActions.getSubtask(taskId || subtaskSelection?.id, 'delivery'));
      }
      if (
        updateRequirementTaskCalled &&
        !updateRequirementTaskLoading &&
        !updateRequirementTaskError
      ) {
        dispatch(WorkflowActions.resetUpdateRequirementTask());
      }
      if (saveTaskCalled && !saveTaskLoading && !saveTaskError && updatingRequirement) {
        setUpdatingReferenceNumber(false);
        dispatch(WorkflowActions.resetGetTasks());
        dispatch(WorkflowActions.resetSaveTask());
      }
      if (saveTaskCalled && !saveTaskLoading && !saveTaskError && updatingReferenceNumber) {
        setUpdatingReferenceNumber(false);
        dispatch(WorkflowActions.resetGetSubtask());
      }
      if (saveTaskCalled && !saveTaskLoading && !saveTaskError && !updatingRequirement) {
        if (!isMobile && !isTablet) {
          dispatch(WorkflowActions.resetGetTasks());
          dispatch(WorkflowActions.resetSaveTask());
          onClose();
        }
      }

      if (subtaskSelection) {
        setCreating(false);
        // setEditing(false);
        if (subtaskSelection.requirements) {
          const dataPoints = subtaskSelection.requirements
            ?.filter((requirement) => requirement.type === 'dataPoint')
            .map((requirement) => {
              return {
                id: requirement.id,
                requirement: requirement.requirement,
                requiredForPayment: requirement.requiredForPayment,
              };
            });
          setDataPoints(dataPoints);

          const documents = subtaskSelection.requirements
            ?.filter((requirement) => requirement.type === 'documentation')
            .map((requirement) => {
              return {
                id: requirement.id,
                requirement: requirement.requirement,
                requiredForPayment: requirement.requiredForPayment,
                requiredForReview: requirement.requiredForReview,
              };
            });
          setDocuments(documents);

          const referenceNumber = subtaskSelection.requirements?.filter(
            (requirement) => requirement.type === 'referenceNumber'
          );
          if (referenceNumber?.length > 0) {
            setEditingReferenceNumber(false);
          } else {
            setEditingReferenceNumber(true);
          }
        } else {
          setEditingReferenceNumber(true);
        }
      } else {
        setCreating(true);
        // setEditing(false);
      }
      if (usersInviteTask) {
        setEditing(true);
      }
      if (deleteTaskCalled && !deleteTaskLoading && !deleteTaskError) {
        dispatch(WorkflowActions.resetDeleteTask());
        !desktop && history.goBack();
      }
      return () => {};
    }, [
      dispatch,
      history,
      subtaskSelection,
      deleteTaskCalled,
      deleteTaskLoading,
      deleteTaskError,
      saveTaskCalled,
      saveTaskLoading,
      saveTaskError,
      updatingRequirement,
    ]);

    function handleSubmit(values) {
      setUpdatingRequirement(false);
      const data = {};
      data.location = values.location;
      data.countryOrigin = values.countryOrigin;
      data.title = values.title;
      if (!taskId && !subtaskSelection?.id) {
        data.type = 'delivery';
        data.taskParentId = parentTask || subtaskSelection?.taskParentId;
      }
      if (values.referenceNumber && values.referenceNumber !== '') {
        data.referenceNumber = values.referenceNumber;
      }
      if (!values.tbd) {
        data.completionDate = values.completionDate;
      }

      if (values.volume && values.volume !== '') {
        data.volume = values.volume;
      }
      if (values.sugarSpec && values.sugarSpec !== '') {
        data.sugarSpec = values.sugarSpec;
      }
      if (values.packagingType && values.packagingType !== '') {
        data.packageType = values.packagingType;
      }

      if (subtaskSelection?.id) {
        // if (delivering) {
        //   data.status = 'delivered';
        //   data.volume = values.volume;
        //   data.sugarSpec = values.sugarSpec;
        //   data.completionDate = values.completionDate;
        //   data.packageType = values.packagingType;
        //   data.location = values.location;

        //   if (values.comments) {
        //     data.comments = values.comments;
        //   }
        // }

        data.requirements = {
          added: [
            ...documents
              ?.filter((requirement) => !requirement.deleted && !requirement.id)
              ?.map((value) => {
                return {
                  type: 'documentation',
                  requirement: value.requirement,
                  requiredForPayment: value.requiredForPayment,
                  requiredForReview: value.requiredForReview,
                };
              }),
            ...dataPoints
              ?.filter((requirement) => !requirement.deleted && !requirement.id)
              ?.map((value) => {
                return {
                  type: 'dataPoint',
                  requirement: value.requirement,
                  requiredForPayment: value.requiredForPayment,
                };
              }),
          ],
          deleted: [
            ...documents
              ?.filter((requirement) => requirement.deleted)
              ?.map((value) => {
                return {
                  id: value.id,
                };
              }),
            ...dataPoints
              ?.filter((requirement) => requirement.deleted)
              ?.map((value) => {
                return {
                  id: value.id,
                };
              }),
          ],
          updated: [
            ...documents
              ?.filter((requirement) => requirement.updated)
              ?.map((value) => {
                return {
                  id: value.id,
                  requiredForPayment: value.requiredForPayment,
                  requiredForReview: value.requiredForReview,
                };
              }),
            ...dataPoints
              ?.filter((requirement) => requirement.updated)
              ?.map((value) => {
                return {
                  id: value.id,
                  requiredForPayment: value.requiredForPayment,
                };
              }),
          ],
        };

        if (data.requirements?.added?.length <= 0) {
          delete data.requirements?.added;
        }
        if (data.requirements?.deleted?.length <= 0) {
          delete data.requirements?.deleted;
        }
        if (data.requirements?.updated?.length <= 0) {
          delete data.requirements?.delupdatedeted;
        }
        if (
          !data.requirements?.deleted &&
          !data.requirements?.added &&
          !data.requirements?.updated
        ) {
          delete data.requirements;
        }
        dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'delivery'));
      } else {
        data.requirements = [
          ...documents
            ?.filter((requirement) => !requirement.deleted)
            ?.map((value) => {
              return {
                type: 'documentation',
                requirement: value.requirement,
                requiredForPayment: value.requiredForPayment,
                requiredForReview: value.requiredForReview,
              };
            }),
          ...dataPoints
            ?.filter((requirement) => !requirement.deleted)
            ?.map((value) => {
              return {
                type: 'dataPoint',
                requirement: value.requirement,
                requiredForPayment: value.requiredForPayment,
              };
            }),
        ];
        if (data.requirements.length === 0) {
          delete data.requirements;
        }

        data.schedule = offTakeScheduleSelection?.id;
        const userFound = contractSelection.collaborators.find(
          (collaborator) => collaborator.user === user.id
        );
        dispatch(
          WorkflowActions.saveTask(data, 'delivery', {
            contractId: contractSelection.id,
            role: userFound?.role || '',
            userId: user.id,
          })
        );
      }
    }

    function updateRequirement(requirement) {
      setUpdatingRequirement(true);
      const data = {};
      data.requirements = { updated: [requirement] };
      if (subtaskSelection) {
        const newTaskSelection = JSON.parse(JSON.stringify(subtaskSelection));
        newTaskSelection.requirements[
          newTaskSelection.requirements.findIndex((el) => el.id === requirement.id)
        ].completed = requirement.completed;
        dispatch(WorkflowActions.updateTaskSelection(newTaskSelection));
        dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'delivery'));
      }
    }

    function addReferenceNumber(values) {
      setUpdatingRequirement(true);
      const data = {};
      const requirement = {};
      requirement.completed = true;
      requirement.requirement = values.referenceNumber;
      const currentReferenceNumber = subtaskSelection?.requirements?.filter(
        (requirement) => requirement.type === 'referenceNumber'
      );
      if (currentReferenceNumber?.length > 0) {
        requirement.id = currentReferenceNumber[0].id;
        data.requirements = { updated: [requirement] };
      } else {
        requirement.type = 'referenceNumber';
        data.requirements = { added: [requirement] };
      }
      if (subtaskSelection) {
        setUpdatingReferenceNumber(true);
        dispatch(WorkflowActions.updateTask(taskId || subtaskSelection?.id, data, 'delivery'));
      }
    }

    const validationSchema = Yup.object({
      volume: delivering ? Yup.string().required(t('common.requiredField')) : null,
      sugarSpec: delivering ? Yup.string().required(t('common.requiredField')) : null,
      packagingType: delivering ? Yup.string().required(t('common.requiredField')) : null,
      title: !delivering ? Yup.string().required(t('common.requiredField')) : null,
      location: !delivering ? Yup.string().required(t('common.requiredField')) : null,

      completionDate: !delivering
        ? Yup.string().requiredCompletionDate(Yup.ref('tbd'), t('common.requiredField'))
        : null,
    });

    return (
      <Box
        className={clsx(styles.main_content)}
        style={isMobile || isTablet ? { paddingBottom: 120 } : {}}
      >
        <Container maxWidth="md" className={desktop ? styles.content_desktop : styles.content}>
          {!delivering && (
            <Box>
              {desktop && (
                <Box className={styles.container_step_description}>
                  <Box width="100%" style={{ marginBottom: 10 }} className="subtitle_container">
                    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                      <Box>
                        {t('contracts.details.workflow.deliveryPage.title', {
                          action: !(taskId || subtaskSelection?.id)
                            ? `${t('contracts.details.workflow.add')} `
                            : `${t('contracts.details.workflow.edit')} `,
                        })}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="description_container">
                    {t('contracts.details.workflow.deliveryPage.description')}
                  </Box>
                </Box>
              )}
              {/* <Box className={styles.collaborators}>
                {(!subtaskSelection || subtaskSelection.status !== 'delivered') && (
                  <Button
                    onPress={() => {
                      dispatch(InvitesActions.resetGetRoles());
                      desktop
                        ? setOpenCollaboratorsModal(true)
                        : history.push('/contracts/workflows/invite-collaborators-task/delivery');
                    }}
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      maxWidth: 30,
                      maxHeight: 30,
                      margin: 5,
                    }}
                  >
                    <AddIcon style={{ fontSize: 10 }} />
                  </Button>
                )}
                {subtaskSelection?.collaborators &&
                  subtaskSelection?.collaborators?.map((item, index) => (
                    <UserPhoto
                      key={index}
                      className={styles.box_image}
                      initials={
                        (item?.firstName ?? '').slice(0, 1) + (item?.lastName ?? '').slice(0, 1)
                      }
                    />
                  ))}
                {usersInviteTask &&
                  usersInviteTask?.map((item, index) => (
                    <UserPhoto
                      key={index}
                      className={styles.box_image}
                      initials={(item?.email ?? '').slice(0, 1) + (item?.email ?? '').slice(1, 2)}
                    />
                  ))}
              </Box> */}
            </Box>
          )}
          {(creating || editing || delivering) && (
            <Box>
              <Formik
                initialValues={{
                  referenceNumber: subtaskSelection?.referenceNumber || task?.referenceNumber || '',
                  volume: subtaskSelection?.volume || task?.volume || '',
                  sugarSpec: subtaskSelection?.sugarSpec || contractSelection?.sugarSpec || '',
                  completionDate: subtaskSelection?.completionDate || '',
                  packagingType:
                    subtaskSelection?.packageType || contractSelection?.packageType || '',

                  title: subtaskSelection?.title || '',
                  location: subtaskSelection?.location || task?.location || '',
                  countryOrigin:
                    subtaskSelection?.countryOrigin || parentTaskSelection?.countryOrigin || '',

                  tbd: !subtaskSelection?.completionDate,
                  dataPoint: '',
                  documentRequired: '',
                  tabIndex: 0,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container style={{ paddingBottom: isMobile && !isTablet ? 100 : 0 }}>
                      {!delivering && (
                        <Grid container item xs={12} md={12} spacing={2}>
                          <Grid item xs={12} sm={12} md={6} className={styles.container_inputs}>
                            <Box className={styles.container_input}>
                              <TextField
                                name="referenceNumber"
                                value={values.referenceNumber}
                                onChange={handleChange}
                                helperText={
                                  errors.referenceNumber &&
                                  touched.referenceNumber &&
                                  errors.referenceNumber
                                }
                                placeholder={t(
                                  'contracts.details.workflow.deliveryPage.referenceNumber'
                                )}
                                label={t(
                                  'contracts.details.workflow.deliveryPage.referenceNumber'
                                ).toUpperCase()}
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                helperText={errors.title && touched.title && errors.title}
                                placeholder={t(
                                  'contracts.details.workflow.deliveryPage.titleInput'
                                )}
                                label={t(
                                  'contracts.details.workflow.deliveryPage.titleInput'
                                ).toUpperCase()}
                                required
                              />
                            </Box>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(styles.container_volume, styles.container_input)}
                              >
                                <TextField
                                  name="volume"
                                  value={values.volume}
                                  onChange={handleChange}
                                  helperText={errors.volume && touched.volume && errors.volume}
                                  placeholder={t('contracts.details.workflow.deliveryPage.volume')}
                                  label={t(
                                    'contracts.details.workflow.deliveryPage.volume'
                                  ).toUpperCase()}
                                  required
                                  icon="MT"
                                  type="number"
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <TextField
                                  name="sugarSpec"
                                  value={values.sugarSpec}
                                  onChange={handleChange}
                                  helperText={
                                    errors.sugarSpec && touched.sugarSpec && errors.sugarSpec
                                  }
                                  placeholder={t(
                                    'contracts.details.workflow.deliveryPage.sugarSpec'
                                  )}
                                  label={t(
                                    'contracts.details.workflow.deliveryPage.sugarSpec'
                                  ).toUpperCase()}
                                  required
                                />
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <Select
                                name="packagingType"
                                values={['50 kg Bag', '1 MT Bag', 'Bulk', 'Other']}
                                value={values.packagingType}
                                onChange={(value) => {
                                  values.packagingType = value;
                                  const event = {
                                    persist: () => {},
                                    target: {
                                      type: 'change',
                                      id: 'packagingType',
                                      name: 'packagingType',
                                      value: values.packagingType,
                                    },
                                  };
                                  handleChange(event);
                                }}
                                helperText={
                                  errors.packagingType &&
                                  touched.packagingType &&
                                  errors.packagingType
                                }
                                placeholder={t('contracts.packagingType')}
                                label={t('contracts.packagingType').toUpperCase()}
                                required
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="countryOrigin"
                                value={values.countryOrigin}
                                onChange={handleChange}
                                helperText={
                                  errors.countryOrigin &&
                                  touched.countryOrigin &&
                                  errors.countryOrigin
                                }
                                placeholder={t(
                                  'contracts.details.workflow.nominationPage.countryOrigin'
                                )}
                                label={t(
                                  'contracts.details.workflow.nominationPage.countryOrigin'
                                ).toUpperCase()}
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="location"
                                value={values.location}
                                onChange={handleChange}
                                helperText={errors.location && touched.location && errors.location}
                                placeholder={t('contracts.details.workflow.deliveryPage.location')}
                                label={t(
                                  'contracts.details.workflow.deliveryPage.location'
                                ).toUpperCase()}
                                required
                              />
                            </Box>
                            <Box className={styles.container_input_checkbox}>
                              <Box style={{ flexGrow: 1, marginRight: 5 }}>
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <MobileDatePicker
                                      style={{ width: 'auto' }}
                                      name="completionDate"
                                      renderInput={(props) => {
                                        const rangeProps = {
                                          // ...props,
                                          helperText: '',
                                          inputProps: {
                                            ...props.inputProps,
                                            value:
                                              values.completionDate !== ''
                                                ? moment(values.completionDate).format('L')
                                                : '',
                                            onBlur: props.inputProps.onBlur,
                                            onFocus: props.inputProps.onFocus,
                                            onChange: props.inputProps.onChange,
                                          },
                                        };
                                        return (
                                          <Box style={{ width: '100%' }}>
                                            <TextField
                                              {...rangeProps}
                                              variant="filled"
                                              value=""
                                              helperText={
                                                errors.completionDate &&
                                                touched.completionDate &&
                                                errors.completionDate
                                              }
                                              placeholder={t(
                                                'contracts.details.workflow.deliveryPage.completionDate'
                                              )}
                                              label={t(
                                                'contracts.details.workflow.deliveryPage.completionDate'
                                              ).toUpperCase()}
                                              icon={
                                                <SvgIcon
                                                  style={{ width: 18, height: 18 }}
                                                  viewBox="0 0 22 22"
                                                  component={CalendarIcon}
                                                />
                                              }
                                              onFocus={() => {
                                                values.tbd = false;
                                                const event = {
                                                  persist: () => {},
                                                  target: {
                                                    type: 'change',
                                                    id: 'tbd',
                                                    name: 'tbd',
                                                    value: values.tbd,
                                                  },
                                                };
                                                handleChange(event);
                                              }}
                                            />
                                          </Box>
                                        );
                                      }}
                                      value={values.completionDate}
                                      onChange={(completionDate) => {
                                        values.completionDate = completionDate;
                                      }}
                                    />
                                  </ThemeProvider>
                                </LocalizationProvider>
                              </Box>
                              {!delivering && (
                                <Box className={styles.check_container}>
                                  <CheckBox
                                    name="tbd"
                                    checked={values.tbd}
                                    onChange={(event) => {
                                      handleChange(event);
                                      values.completionDate = '';
                                      const event1 = {
                                        persist: () => {},
                                        target: {
                                          type: 'change',
                                          id: 'completionDate',
                                          name: 'completionDate',
                                          value: values.completionDate,
                                        },
                                      };
                                      handleChange(event1);
                                    }}
                                  />
                                  <Box className={styles.label}>
                                    {t('contracts.details.workflow.deliveryPage.tbd')}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            <Divider
                              style={{ marginRight: 40, marginLeft: 40 }}
                              orientation="vertical"
                              flexItem
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            spacing={2}
                            direction="row"
                            style={
                              !isMobile || isTablet ? { overflow: 'scroll', maxHeight: 455 } : {}
                            }
                          >
                            {!delivering && (
                              <>
                                {(isMobile || isTablet || values.tabIndex === 0) && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={isTablet ? 6 : 12}
                                    className={styles.container_inputs}
                                  >
                                    <TabComponent values={values} handleChange={handleChange} />
                                    {(isMobile || isTablet) && (
                                      <Box
                                        className={styles.container_requirement_description}
                                        style={{ marginTop: 20 }}
                                      >
                                        <Box
                                          style={{ marginBottom: 10 }}
                                          className={styles.title_requirement}
                                        >
                                          {t('contracts.details.workflow.dataPointRequired')}
                                        </Box>
                                        <Box className="subtitle">
                                          {t('contracts.details.workflow.dataPointDescription')}
                                        </Box>
                                      </Box>
                                    )}
                                    <Box className={styles.container_input}>
                                      <Box className={styles.check_container}>
                                        <CheckBox
                                          checked={newDataPoint?.requiredForPayment}
                                          onChange={(event) => {
                                            setNewDataPoint({
                                              requirement: newDataPoint?.requirement || '',
                                              requiredForPayment: event.target.checked,
                                            });
                                          }}
                                        />
                                        <Box className={styles.label}>
                                          {t('contracts.details.workflow.requiredForPayment')}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      className={clsx(
                                        styles.container_input,
                                        styles.container_inputs2
                                      )}
                                    >
                                      <TextField
                                        value={newDataPoint.requirement}
                                        onChange={(e) => {
                                          setNewDataPoint({
                                            requirement: e.target.value,
                                            requiredForPayment:
                                              newDataPoint?.requiredForPayment || false,
                                          });
                                        }}
                                      />
                                      <Box
                                        className={styles.buttonAdd}
                                        onClick={() => {
                                          if (
                                            !(saveTaskLoading || newDataPoint?.requirement === '')
                                          ) {
                                            setDataPoints([...dataPoints, newDataPoint]);
                                            setNewDataPoint({
                                              requirement: '',
                                              requiredForPayment: false,
                                            });
                                          }
                                        }}
                                      >
                                        {t('contracts.addCollaborators.add')}
                                      </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={styles.list}>
                                      {dataPoints?.map((value, index) => {
                                        if (value.deleted) {
                                          return null;
                                        }
                                        return (
                                          <Box key={index}>
                                            <Box className={styles.item_container}>
                                              <Box className={styles.label}>
                                                {value.requirement.toUpperCase()}
                                                {value.requiredForPayment && (
                                                  <Box className={styles.rfp}>
                                                    (
                                                    {t(
                                                      'contracts.details.workflow.requiredForPayment'
                                                    )}
                                                    )
                                                  </Box>
                                                )}
                                              </Box>
                                              <Box className={styles.item}>
                                                <Button
                                                  align="left"
                                                  padding="0 10px 0 10px"
                                                  margin={0}
                                                  clean
                                                  className={styles.action}
                                                  onPress={() => {
                                                    const newData = { ...value };
                                                    newData.deleted = true;
                                                    setDataPoints((dataPoint) => [
                                                      ...dataPoint?.filter(
                                                        (el) => el.requirement !== value.requirement
                                                      ),
                                                    ]);
                                                    if (value.id) {
                                                      setDataPoints((dataPoint) => [
                                                        ...dataPoint,
                                                        newData,
                                                      ]);
                                                    }
                                                  }}
                                                >
                                                  <SvgIcon
                                                    style={{ height: 18, width: 18 }}
                                                    component={DeleteIcon}
                                                    viewBox="0 0 17 17"
                                                  />
                                                </Button>
                                              </Box>
                                            </Box>
                                            <Divider />
                                          </Box>
                                        );
                                      })}
                                    </Box>
                                  </Grid>
                                )}
                                {(isMobile || isTablet || values.tabIndex === 1) && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={isTablet ? 6 : 12}
                                    className={styles.container_inputs}
                                  >
                                    <TabComponent values={values} handleChange={handleChange} />
                                    {(isMobile || isTablet) && (
                                      <Box
                                        className={styles.container_requirement_description}
                                        style={{ marginTop: 20 }}
                                      >
                                        <Box
                                          style={{ marginBottom: 10 }}
                                          className={styles.title_requirement}
                                        >
                                          {t('contracts.details.workflow.documentRequired')}
                                        </Box>
                                        <Box className="subtitle">
                                          {t('contracts.details.workflow.documentDescription')}
                                        </Box>
                                      </Box>
                                    )}
                                    <Box className={styles.container_inputs2}>
                                      <Box className={styles.container_input}>
                                        <Box className={styles.check_container}>
                                          <CheckBox
                                            checked={newDocument.requiredForPayment}
                                            onChange={(event) => {
                                              setNewDocument({
                                                requirement: newDocument.requirement,
                                                requiredForPayment: event.target.checked,
                                                requiredForReview: newDocument.requiredForReview,
                                              });
                                            }}
                                          />
                                          <Box className={styles.label}>
                                            {t('contracts.details.workflow.requiredForPayment')}
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box className={styles.container_input}>
                                        <Box className={styles.check_container}>
                                          <CheckBox
                                            checked={newDocument.requiredForReview}
                                            onChange={(event) => {
                                              setNewDocument({
                                                requirement: newDocument.requirement,
                                                requiredForPayment: newDocument.requiredForPayment,
                                                requiredForReview: event.target.checked,
                                              });
                                            }}
                                          />
                                          <Box className={styles.label}>
                                            {t('contracts.details.workflow.requiredForReview')}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      className={clsx(
                                        styles.container_input,
                                        styles.container_inputs2
                                      )}
                                    >
                                      <TextField
                                        value={newDocument.requirement}
                                        onChange={(e) => {
                                          setNewDocument({
                                            requirement: e.target.value,
                                            requiredForPayment: newDocument.requiredForPayment,
                                            requiredForReview: newDocument.requiredForReview,
                                          });
                                        }}
                                      />
                                      <Box
                                        className={styles.buttonAdd}
                                        onClick={() => {
                                          if (
                                            !(saveTaskLoading || newDocument?.requirement === '')
                                          ) {
                                            setDocuments([...documents, newDocument]);
                                            setNewDocument({
                                              requirement: '',
                                              requiredForPayment: false,
                                              requiredForReview: false,
                                            });
                                          }
                                        }}
                                      >
                                        {t('contracts.addCollaborators.add')}
                                      </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={styles.list}>
                                      {documents?.map((value, index) => {
                                        if (value.deleted) {
                                          return null;
                                        }
                                        return (
                                          <Box key={index}>
                                            <Box className={styles.item_container}>
                                              <Box className={styles.label}>
                                                {value.requirement.toUpperCase()}
                                                {value.requiredForPayment && (
                                                  <Box className={styles.rfp}>
                                                    (
                                                    {t(
                                                      'contracts.details.workflow.requiredForPayment'
                                                    )}
                                                    )
                                                  </Box>
                                                )}
                                              </Box>
                                              <Box className={styles.item}>
                                                <Button
                                                  align="left"
                                                  padding="0 10px 0 10px"
                                                  margin={0}
                                                  clean
                                                  className={styles.action}
                                                  onPress={() => {
                                                    const newData = { ...value };
                                                    newData.deleted = true;
                                                    setDocuments((document) => [
                                                      ...document?.filter(
                                                        (el) => el.requirement !== value.requirement
                                                      ),
                                                    ]);
                                                    if (value.id) {
                                                      setDocuments((document) => [
                                                        ...document,
                                                        newData,
                                                      ]);
                                                    }
                                                  }}
                                                >
                                                  <SvgIcon
                                                    style={{ height: 18, width: 18 }}
                                                    component={DeleteIcon}
                                                    viewBox="0 0 17 17"
                                                  />
                                                </Button>
                                              </Box>
                                            </Box>
                                            <Divider />
                                          </Box>
                                        );
                                      })}
                                    </Box>
                                  </Grid>
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {delivering && (
                        <Box style={{ marginTop: 15, marginBottom: 15 }}>
                          <Box
                            style={{
                              fontFamily: 'FuturaPT-Medium',
                              fontSize: '16.5px',
                              fontWeight: 500,
                              letterSpacing: '0.92px',
                              textAlign: 'center',
                            }}
                          >
                            {t('contracts.details.workflow.recipients')}
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              marginTop: 10,
                            }}
                          >
                            {subtaskSelection?.collaborators?.map((item, index) => (
                              <Recipients key={index} user={item} />
                            ))}
                          </Box>
                        </Box>
                      )}
                      {delivering && (
                        <Box className={styles.container_inputs}>
                          <Box className={styles.container_input}>
                            <TextField
                              name="comments"
                              value={values.comments}
                              onChange={handleChange}
                              helperText={errors.comments && touched.comments && errors.comments}
                              placeholder={t('contracts.details.workflow.deliveryPage.comments')}
                              label={t(
                                'contracts.details.workflow.deliveryPage.comments'
                              ).toUpperCase()}
                              multiline
                              rows={15}
                              required
                            />
                          </Box>
                        </Box>
                      )}
                      <Grid item xs={12} md={12} className={styles.container_inputs}>
                        <Box>
                          {!delivering && (
                            <Box className={styles.container_submit}>
                              <Box className={styles.box_two_options}>
                                <Button
                                  inverted
                                  bordered
                                  style={{ marginRight: 10 }}
                                  className={styles.button}
                                  onPress={() => {
                                    if (isMobile || isTablet) {
                                      history.goBack();
                                    } else {
                                      onClose();
                                    }
                                  }}
                                >
                                  {t('common.cancel')}
                                </Button>

                                <Button
                                  type="submit"
                                  disabled={saveTaskLoading}
                                  loading={saveTaskLoading}
                                  style={{ marginLeft: 10 }}
                                  className={styles.button}
                                  data-info-submit="Submit"
                                  minWidth={130}
                                  maxWidth={270}
                                >
                                  {t('contracts.details.workflow.generalPage.add')}
                                </Button>
                              </Box>
                            </Box>
                          )}
                          {delivering && (
                            <Box className={styles.container_submit}>
                              <Box className={styles.box_two_options}>
                                <Button
                                  inverted
                                  bordered
                                  className={styles.button}
                                  onPress={() => {
                                    setDelivering(false);
                                  }}
                                >
                                  {t('common.cancel')}
                                </Button>
                                <Box style={{ width: 20 }} />
                                <Button
                                  className={styles.button}
                                  onPress={() => {
                                    handleSubmit();
                                  }}
                                >
                                  {t('common.confirm')}
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          )}
        </Container>
        {saveTaskCalled && !saveTaskLoading && !saveTaskError && !updatingRequirement && (
          <MessageModal
            title={t('common.success')}
            message={t('contracts.details.workflow.sentSuccess')}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              setUpdatingReferenceNumber(false);
              dispatch(WorkflowActions.resetGetTasks());
              !desktop && history.goBack();
              dispatch(WorkflowActions.resetSaveTask());
            }}
          />
        )}
        {getSubtaskCalled && !getSubtaskLoading && getSubtaskError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={getSubtaskError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(WorkflowActions.resetGetSubtaskError());
            }}
          />
        )}

        {saveTaskCalled && !saveTaskLoading && saveTaskError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={saveTaskError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              setUpdatingReferenceNumber(false);
              dispatch(WorkflowActions.resetSaveTaskError());
            }}
          />
        )}

        {deleteTaskCalled && !deleteTaskLoading && deleteTaskError && (
          <MessageModal
            icon={null}
            title={t('common.error')}
            message={deleteTaskError.userMessage}
            primaryAction={t('common.close')}
            primaryEvent={() => {
              dispatch(WorkflowActions.resetDeleteTaskError());
            }}
          />
        )}

        {updateRequirementTaskCalled &&
          !updateRequirementTaskLoading &&
          updateRequirementTaskError && (
            <MessageModal
              icon={null}
              title={t('common.error')}
              message={updateRequirementTaskError.userMessage}
              primaryAction={t('common.close')}
              primaryEvent={() => {
                dispatch(WorkflowActions.resetUpdateRequirementTaskError());
              }}
            />
          )}

        {deleteConfirmation && (
          <MessageModal
            title=""
            icon={null}
            message={t('common.areYouSure')}
            secundaryAction={t('common.no')}
            secundaryEvent={() => {
              setDeleteConfirmation(false);
            }}
            primaryAction={t('common.yes')}
            primaryEvent={() => {
              setDeleteConfirmation(false);
              deleteTask();
            }}
          />
        )}

        {openCollaboratorsModal && (
          <InviteCollaboratorsTaskModal
            history={history}
            type="delivery"
            onClose={() => setOpenCollaboratorsModal(false)}
          />
        )}
      </Box>
    );
  };

  return (
    <Box className={styles.container}>
      <Loading
        isLoading={
          saveTaskLoading || deleteTaskLoading || updateRequirementTaskLoading || getSubtaskLoading
        }
      />
      {desktop ? (
        <PageContent />
      ) : (
        <NavigationBar
          title={t('contracts.details.workflow.deliveryPage.title', {
            action: !(taskId || subtaskSelection?.id)
              ? `${t('contracts.details.workflow.add')} `
              : `${t('contracts.details.workflow.edit')} `,
          })}
          subtitle={t('contracts.details.workflow.subtaskSubtitle', {
            date: parentTaskSelection?.date
              ? moment(parentTaskSelection?.date).format('DD MMM')
              : '',
            volume: parentTaskSelection?.volume || '',
          })}
          hideTitle={!isMobile}
          hasMenuButton
          barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
          white={!isMobile || isTablet}
          hasNotificationButton={!isMobile && !isTablet}
          history={history}
          user={user}
          menuList={[
            {
              label: t('common.goHome'),
              action: () => {
                history.replace('/home');
              },
            },
          ]}
          onToggleDrawer={(opened) => {
            setDrawerOpened(opened);
          }}
          onLogout={() => {
            dispatch(AuthActions.logout());
          }}
          pageContent={<PageContent />}
        />
      )}
    </Box>
  );
};

export default withTranslation()(CreateDeliveryTask);
