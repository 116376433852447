import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import { isMobile } from 'react-device-detect';
import NavigationBar from '../../components/NavigationBar';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import ProfileData from '../../components/ProfileData';

import MessageModal from '../../modals/message';

import AuthActions from '../../redux/auth/actions';

import styles from './styles/editProfile.module.sass';

const EditProfile = ({ t, history }) => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required(t('common.requiredField')),
    lastName: Yup.string().required(t('common.requiredField')),
    email: Yup.string().email(t('common.enterValidEmail')).required(t('common.requiredField')),
  });

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const profilePictureLoading = useSelector((state) => state.auth.uploadProfilePictureLoading);

  const [editing, setEditing] = useState(false);
  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + 356);
  }, [formBox]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  const { updateAccountLoading, updateAccountError, updateAccountCalled } = useSelector(
    (state) => state.auth
  );

  function handleSubmit(values) {
    dispatch(AuthActions.updateAccount(values));
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <NavigationBar
        title={t('profile.title')}
        hideTitle={!isMobile}
        hasBackButton
        hasEditButton={isMobile}
        onPressEditButton={() => setEditing(!editing)}
        hideFirstBar={!isMobile}
        hasNotificationButton={false}
        hasMenuButton={!isMobile}
        hasFilterButton={false}
        history={history}
        editing={editing}
      />
      {updateAccountCalled && updateAccountLoading === false && updateAccountError === null && (
        <MessageModal
          title={t('common.success')}
          message={t('profile.successDescription')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            setEditing(false);
            dispatch(AuthActions.clearUpdateAccount());
          }}
        />
      )}
      <Container maxWidth="md" className={styles.content} ref={formBox}>
        <Box>
          {!isMobile && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box style={{ width: 23 }} />
              <Box className={styles.title}>PROFILE</Box>
              <Box className={styles.edit} onClick={() => setEditing(!editing)}>
                {!editing ? t('common.edit') : <ClearIcon style={{ color: '#000000' }} />}
              </Box>
            </Box>
          )}
          <Box
            style={{ backgroundColor: '#fff' }}
            className={styles.container_personal_information}
          >
            <ProfileData
              edit
              uploadPhoto
              photoClassName={styles.container_profile_data}
              initials={user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
              username={`${user.firstName} ${user.lastName}`}
              email={user.email}
              photo={user.profile_url}
              loadingPhoto={profilePictureLoading}
              color="#fff"
              onPressEdit={() => history.push('/profile')}
              onSelectImage={(file) => dispatch(AuthActions.uploadProfilePicture(file))}
              showData={false}
            />
          </Box>

          <Box>
            <Formik
              initialValues={{
                email: user?.email || '',
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                companyName: user?.companyName || '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box className={styles.container_inputs}>
                    <Box className={styles.container_names}>
                      <Box className={clsx(styles.container_firstname, styles.container_input)}>
                        <TextField
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          helperText={errors.firstName && touched.firstName && errors.firstName}
                          required
                          placeholder={t('common.firstName')}
                          label={t('common.firstName').toUpperCase()}
                          disabled={!editing}
                        />
                      </Box>
                      <Box className={clsx(styles.container_lastname, styles.container_input)}>
                        <TextField
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          helperText={errors.lastName && touched.lastName && errors.lastName}
                          required
                          placeholder={t('common.lastName')}
                          label={t('common.lastName').toUpperCase()}
                          disabled={!editing}
                        />
                      </Box>
                    </Box>
                    <Box className={styles.container_input}>
                      <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        helperText={errors.email && touched.email && errors.email}
                        required
                        placeholder={t('common.email')}
                        label={t('common.email').toUpperCase()}
                        disabled
                      />
                    </Box>
                    <Box className={styles.container_input}>
                      <TextField
                        name="companyName"
                        value={values.companyName}
                        onChange={handleChange}
                        helperText={errors.companyName && touched.companyName && errors.companyName}
                        required
                        placeholder={t('common.companyName')}
                        label={t('common.companyName').toUpperCase()}
                        disabled
                      />
                    </Box>
                  </Box>
                  {updateAccountError && (
                    <Box className="error" style={{ marginTop: 15 }}>
                      {updateAccountError?.userMessage}
                    </Box>
                  )}
                  {editing && (
                    <Box className={styles.container_submit}>
                      <Button
                        type="submit"
                        loading={updateAccountLoading}
                        className={styles.button}
                        data-info-submit="Submit"
                        minWidth={130}
                        maxWidth={270}
                      >
                        {t('common.saveChanges')}
                      </Button>
                    </Box>
                  )}
                </form>
              )}
            </Formik>
          </Box>

          {!editing && (
            <Box className={styles.container_submit}>
              <Button
                className={styles.button}
                minWidth={130}
                maxWidth={270}
                onPress={() => history.push('/change-password')}
              >
                {t('common.changePassword')}
              </Button>

              <Button
                className={styles.button}
                minWidth={130}
                maxWidth={270}
                onPress={() => history.push('/setup-totp')}
              >
                {t('common.resetOTP')}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default withTranslation()(EditProfile);
