import firebase from 'firebase/app';
import 'firebase/analytics';

import api from './api';
import filesApi from './files';
import i18n from '../utils/I18n';

export const login = async (email, password) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function (result) {
          resolve(result.user);
        })
        .catch(function () {
          reject(i18n.t('auth.invalidCredentials'));
        });
    } catch {
      reject(i18n.t('auth.invalidCredentials'));
    }
  });
};

export const getUserToken = (reload = false) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(reload)
      .then((idToken) => {
        if (idToken) {
          resolve(idToken);
        } else {
          window.location.reload(false);
          reject(i18n.t('auth.getTokenError'));
        }
      })
      .catch(() => {
        window.location.reload(false);
        reject(i18n.t('auth.getTokenError'));
      });
  });
};

export const createAccount = async (params) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(params.email, params.password)
        .then(function (result) {
          resolve(result.user);
        })
        .catch(function () {
          reject(i18n.t('auth.createAccountError'));
        });
    } catch {
      reject(i18n.t('auth.createAccountError'));
    }
  });
};

export const deleteAccount = async () => {
  return new Promise((resolve, reject) => {
    try {
      const user = firebase.auth().currentUser;
      user
        .delete()
        .then(function () {
          resolve();
        })
        .catch(function () {
          reject(i18n.t('auth.deleteAccountError'));
        });
    } catch {
      reject(i18n.t('auth.deleteAccountError'));
    }
  });
};

export const updateProfile = async (params) => {
  return new Promise((resolve, reject) => {
    try {
      const user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: `${params.firstName}  ${params.lastName}`,
        })
        .then(function () {
          resolve(user);
        })
        .catch(function () {
          reject(i18n.t('auth.updateProfileError'));
        });
    } catch {
      reject(i18n.t('auth.updateProfileError'));
    }
  });
};

export const sendEmailVerification = async (params) => {
  return new Promise((resolve, reject) => {
    try {
      const user = firebase.auth().currentUser;
      user
        .sendEmailVerification()
        .then(function () {
          resolve();
        })
        .catch(function () {
          reject(i18n.t('auth.sendEmailVerificationEmailError'));
        });
    } catch {
      reject(i18n.t('auth.sendEmailVerificationEmailError'));
    }
  });
};

export const verifyEmail = async (actionCode) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .applyActionCode(actionCode)
        .then(function () {
          resolve();
        })
        .catch(function () {
          reject(i18n.t('auth.verifyEmailError'));
        });
    } catch {
      reject(i18n.t('auth.verifyEmailError'));
    }
  });
};

export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
          resolve();
        })
        .catch(function () {
          reject(i18n.t('auth.forgotPasswordError'));
        });
    } catch {
      reject(i18n.t('auth.forgotPasswordError'));
    }
  });
};

export const changePassword = (password) => {
  return new Promise((resolve, reject) => {
    try {
      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .currentUser.updatePassword(password)
          .then(function () {
            resolve();
          })
          .catch(function () {
            reject(i18n.t('auth.changePasswordError'));
          });
      }
    } catch (err) {
      reject(i18n.t('auth.changePasswordError'));
    }
  });
};

export const resetPassword = (code, password) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .confirmPasswordReset(code, password)
        .then(function () {
          resolve();
        })
        .catch(function () {
          reject(i18n.t('auth.resetPassword'));
        });
    } catch {
      reject(i18n.t('auth.resetPassword'));
    }
  });
};

export const storeCredentials = (credentials) => {
  localStorage.setItem('access_token', credentials);
  api.setHeader('Authorization', `Bearer ${credentials}`);
  filesApi.setHeader('Authorization', `Bearer ${credentials}`);
};

export const getCredentials = () => {
  return localStorage.getItem('access_token');
};

export const removeCredentials = async () => {
  localStorage.removeItem('access_token');
  delete api.headers.Authorization;
  delete filesApi.headers.Authorization;
  await firebase.auth().signOut();
};

export const restoreCredentials = () => {
  const credentials = localStorage.getItem('access_token');
  if (credentials) {
    api.setHeader('Authorization', `Bearer ${credentials}`);
    filesApi.setHeader('Authorization', `Bearer ${credentials}`);
    return true;
  }

  return false;
};

export const postMe = (data) => {
  delete data.password;
  return api.post('users/sign-up', data);
};

export const getMe = (uid) => {
  return api.get(`users/${uid}`);
};

export const putMe = (data) => {
  return api.put(`users/${data.uid}`, data);
};

export const getUsers = () => {
  return api.get('users');
};

export const sendNotificationsTokenToServer = (token) => {
  return api.post('users/token', { token });
};

export const getNotificationsTokenToServer = () => {
  return api.get('users/token');
};

export const putValidate2FAToken = (uid, data) => {
  return api.put(`users/validate-user-token/${uid}`, data);
};

export const putResetOtp = (data) => {
  return api.put(`users/reset-otp`, data);
};

export const checkFirebaseUserSession = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject('User is signed out.');
      }
    });
  });
};

export const setAnalyticsUserId = (userId) => {
  firebase.analytics().setUserId(userId);
};

export const storeTokenValid = (uid, lastTokenValid) => {
  localStorage.setItem(`${uid}lastTokenValid`, lastTokenValid);
};

export const restoreTokenValid = (uid) => {
  if (process.env.REACT_APP_DISABLE_2FA === 'disable') {
    return true;
  }
  return localStorage.getItem(`${uid}lastTokenValid`);
};

export const removeTokenValid = (uid) => {
  localStorage.removeItem(`${uid}lastTokenValid`);
};
