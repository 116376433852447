import React from 'react';
import Box from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FAB from '@material-ui/core/Fab';
import MaterialButton from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import Progress from './Progress';

import theme from './theme';

function alignResolver(type) {
  switch (type) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'center';
  }
}

const Button = ({
  className,
  inverted,
  clean = false,
  undo,
  children,
  isPrimary = true,
  onPress,
  disabled,
  padding,
  textTransform = 'none',
  align = 'center',
  shading = true,
  minWidth = '100%',
  maxWidth,
  minHeight = 60,
  maxHeight = 60,
  fontSize = '17px',
  color,
  backgroundColor,
  backgroundImage,
  bordered = false,
  labelStyle = {},
  loading = false,
  type = 'button',
  ...props
}) => {
  const CustomFABButton = withStyles((theme) => ({
    root: {
      border: bordered
        ? `10px solid ${
            color || (inverted ? theme.palette.primary.main : theme.palette.secondary.main)
          }`
        : 'none',
      borderWidth: 1,
      boxShadow: shading
        ? inverted
          ? '4px 3px 19px 0 rgba(234, 234, 234, 0.55), -7px -7px 16px 0 rgba(250, 251, 252, 0.25)'
          : '4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc'
        : 'none',
      minWidth: maxWidth ? null : minWidth,
      padding: '0 20px',
      width: '100%',
      maxWidth: maxWidth ? `${maxWidth}px !important` : null,
      height: '100%',
      minHeight: !clean ? minHeight : null,
      maxHeight: !clean ? maxHeight : null,
      userSelect: 'auto',
      pointerEvents: 'all',
      cursor: 'pointer',
      boxSizing: 'border-box',
      borderRadius: '40px',
      // boxShadow: 'none',
      backgroundImage: backgroundImage === undefined ? null : backgroundImage,
      backgroundColor:
        backgroundColor === undefined
          ? inverted
            ? theme.palette.secondary.main
            : theme.palette.primary.main
          : backgroundColor,
      '&:hover': {
        backgroundColor: `${
          inverted ? theme.palette.secondary.main : theme.palette.primary.main
        } !important`,
      },
    },
    label: {
      alignItems: alignResolver(align),
      padding: padding || '6px 12px',
      color: color || (inverted ? theme.palette.primary.main : theme.palette.secondary.main),
      textTransform,
      fontSize,

      fontFamily: 'FuturaPT-Medium',
      ...labelStyle,
      '&:hover': {
        color: `${inverted ? theme.palette.primary.main : theme.palette.secondary.main} !important`,
      },
      minHeight: !clean ? minHeight : null,
      boxSizing: 'border-box',
    },
    disabled: {
      backgroundColor: `${theme.palette.disabled.main} !important`,
    },
  }))(FAB);

  const CustomButton = withStyles((theme) => ({
    root: {
      boxShadow: 'none',
      minWidth,
      maxWidth,
      width: '100%',
      height: '100%',
      minHeight: !clean ? minHeight : null,
      userSelect: 'auto',
      pointerEvents: 'all',
      display: 'flex',
      padding,
      alignItems: alignResolver(align),
    },
    label: {
      color: color || (inverted ? theme.palette.primary.main : theme.palette.secondary.main),
      textTransform,
      fontSize,
      fontFamily: 'FuturaPT-Demi',
      display: 'flex',
      justifyContent: alignResolver(align),
      alignItems: alignResolver(align),
      ...labelStyle,
    },
  }))(MaterialButton);

  const UndoButton = withStyles((theme) => ({
    root: {
      backgroundColor: '#fceff2',
      height: '100%',
      minHeight: !clean ? minHeight : null,
      userSelect: 'auto',
      pointerEvents: 'all',
    },
    label: {
      color: color || inverted ? theme.palette.primary.main : theme.palette.secondary.main,
      textTransform,
      fontSize,
      fontFamily: 'FuturaPT',
      ...labelStyle,
    },
  }))(CustomFABButton);

  function BuildButton() {
    if (clean) {
      return (
        <CustomButton
          type={type}
          className={className}
          disabled={disabled}
          variant="text"
          color={isPrimary ? 'primary' : 'secondary'}
          onClick={() => onPress()}
          {...props}
        >
          {children}
          {loading && (
            <Box style={{ paddingLeft: 10 }}>
              <Progress color="secondary" size={20} />
            </Box>
          )}
        </CustomButton>
      );
    }

    if (undo) {
      return (
        <UndoButton
          type={type}
          disabled={disabled}
          variant={inverted ? 'outlined' : clean ? 'text' : 'extended'}
          color={isPrimary ? 'primary' : 'secondary'}
          onClick={() => onPress()}
          {...props}
        >
          {children}
          {loading && (
            <Box style={{ paddingLeft: 10 }}>
              <Progress color="secondary" size={20} />
            </Box>
          )}
        </UndoButton>
      );
    }
    return (
      <CustomFABButton
        type={type}
        disabled={disabled}
        className={className || ''}
        color={isPrimary ? 'primary' : 'secondary'}
        variant="extended"
        onClick={() => onPress()}
        {...props}
      >
        {children}
        {loading && (
          <Box style={{ paddingLeft: 10 }}>
            <Progress color="secondary" size={20} />
          </Box>
        )}
      </CustomFABButton>
    );
  }

  return (
    <Box className={className}>
      <ThemeProvider theme={theme}>{BuildButton()}</ThemeProvider>
    </Box>
  );
};

Button.propTypes = {
  inverted: PropTypes.bool,
  clean: PropTypes.bool,
  isPrimary: PropTypes.bool,
  disabled: PropTypes.bool,
  customBackgroundColor: PropTypes.string,
  onPress: PropTypes.func,
};

Button.defaultProps = {
  onPress: () => {},
};

export default Button;
