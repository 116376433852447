import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { FilePreviewerThumbnail } from 'react-file-previewer';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import WorkflowActions from '../../redux/workflow/actions';
import MoreIcon from '../../assets/images/icons/more.svg';
import UploadIcon from '../../assets/images/icons/upload.svg';
import CheckIcon from '../../assets/images/icons/check.svg';
import PendingIcon from '../../assets/images/icons/pending.svg';
import DenyIcon from '../../assets/images/icons/denied.svg';
import XlsIcon from '../../assets/images/icons/xls-icon.png';
import DocIcon from '../../assets/images/icons/doc-icon.png';
import PdfIcon from '../../assets/images/icons/pdf-icon.png';
import PptIcon from '../../assets/images/icons/ppt-icon.png';

import styles from './styles.module.sass';

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    marginLeft: -30,
    '& ul': { padding: 0 },
  },
})((props) => <Menu elevation={2} getContentAnchorEl={null} {...props} />);

const StyledMenuItem = withStyles({
  root: {
    borderBottom: '1px solid #E1E1E1',
    padding: 12,
    textTransform: 'capitalize',
  },
})((props) => <MenuItem {...props} />);

const DocumentationCard = ({
  t,
  type,
  meCollaborator,
  name,
  documentUrl,
  date,
  isRfp,
  requiredForReview,
  status,
  onUpload,
  menuOptions,
  onAction,
  onSeeComments,
}) => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const contFile = useRef(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [iconDoc, setIconDoc] = useState(null);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    if (fileToUpload) {
      if (onUpload) {
        onUpload(fileToUpload);
      }
    }
    return () => {};
  }, [fileToUpload]);

  useEffect(() => {
    if (documentUrl && documentUrl.match(/\.(jpeg|jpg|gif|png)/i) != null) {
      setIsImage(true)
    } else if (documentUrl) {
      download(documentUrl);
    }
    return () => {};
  }, [documentUrl]);

  const getChildren = (compotHtml) => {
    if (compotHtml) {
      const className = compotHtml?.children[0]?.className;
      if (className && className.indexOf('react-pdf__message--error') > -1) {
        return 'error';
      }
      if (compotHtml?.children[0]) {
        return getChildren(compotHtml.children[0]);
      }
    }
    return '';
  };

  useEffect(() => {
    console.log(documentUrl)
    if (documentUrl) {
      setTimeout(() => {
        if (getChildren(contFile.current) === 'error') {
          const types = ['pdf', 'doc', 'xls', 'ppt']
          const docUrl = documentUrl.split('?')[0]
          let typeExt = ''
          types.forEach((typeTxt) => {
            if (docUrl.indexOf(typeTxt) > -1 && !typeExt) {
              typeExt = typeTxt;
            }
          })
          setIconDoc(typeExt === 'pdf' ? PdfIcon : (
            typeExt === 'xls' ? XlsIcon : (
              typeExt === 'ppt' ? PptIcon : DocIcon
            )
          ))
        }
      }, 1000);
    }
  }, [currentFile, contFile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, item) => {
    if (item) {
      if (item?.action === 'upload') {
        inputFile.current.click();
      } else if (onAction) {
        onAction(item.action);
      }
    }
    setAnchorEl(null);
  };

  const getStatusCopy = () => {
    switch (status) {
      case 'pending':
        if (
          (type === 'nomination' && meCollaborator?.side === 'sell-side') ||
          (type === 'delivery' && meCollaborator?.side === 'buy-side') ||
          (type === 'general' && meCollaborator.team === 'counter-part-team')
        ) {
          return t('contracts.reviewChanges', { counterpart: 'counterpart' });
        }
        return t('contracts.waitingReview');
      case 'approved':
        return t('contracts.details.workflow.generalPage.done');
      case 'rejected':
        if (meCollaborator?.team === 'counter-part-team') {
          return t('contracts.deniedTitle');
        }
        if (meCollaborator?.team === 'owner-team') {
          return t('contracts.deniedMessage', { counterpart: 'counterpart' });
        }
        break;
      default:
        return '';
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case 'pending':
        return PendingIcon;
      case 'approved':
        return CheckIcon;
      case 'rejected':
        return DenyIcon;
      default:
        return PendingIcon;
    }
  };

  const download = (url) => {
    axios(`${url}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          setCurrentFile({ url: reader.result });
        };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const acceptMimeTypesList = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];
  const acceptMimeTypes = acceptMimeTypesList.join(', ');

  return (
    <Box className={styles.document_container}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept={acceptMimeTypes}
        onChange={(event) => {
          const fileReader = new window.FileReader();
          const file = event.target.files[0];

          if (file.size > 10 * 1024 * 1024) {
            dispatch(
              WorkflowActions.uploadDocumentFailed({
                title: 'common.error',
                message: 'documents.errorFileSize',
              })
            );
            return false;
          }

          setFileToUpload(file);
          fileReader.onload = (fileLoad) => {
            const { result } = fileLoad.target;
            setCurrentFile({ url: result });
          };
          fileReader.readAsDataURL(file);
        }}
      />

      {requiredForReview && documentUrl && (
        <Tooltip title={getStatusCopy()}>
          <Box
            className={styles.status}
            style={{ cursor: status === 'rejected' ? 'pointer' : 'default' }}
            onClick={() => {
              if (status === 'rejected') {
                if (onSeeComments) {
                  onSeeComments();
                }
              }
            }}
          >
            <SvgIcon
              style={{ height: 18, width: 18, marginRight: 5 }}
              component={getStatusIcon()}
              viewBox="0 0 32 32"
            />
          </Box>
        </Tooltip>
      )}

      <Box className={styles.document_content}>
        {isRfp && <Box className={styles.rfp}>{t('common.rfp')}</Box>}

        {currentFile || isImage ? (
          <Box
            className={styles.document}
            onClick={() => {
              if (documentUrl) {
                window.open(documentUrl);
              }
            }}
            ref={contFile}
          >
            {iconDoc || isImage ? (
              <img alt="icon-doc" src={isImage ? documentUrl : iconDoc} className={styles.error_type_doc} />
            ) : (
              <FilePreviewerThumbnail file={currentFile} />
            )}
          </Box>
        ) : (
          <>
            <Box
              className={styles.upload}
              onClick={() => {
                inputFile.current.click();
              }}
            >
              <SvgIcon
                style={{ height: 20, width: 20, marginBottom: 9 }}
                component={UploadIcon}
                viewBox="0 0 22 22"
              />
              {t('common.upload')}
            </Box>
          </>
        )}
      </Box>
      <Box className={styles.document_actions}>
        {menuOptions?.length > 0 && (
          <>
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {menuOptions.map((item, index) => (
                <StyledMenuItem
                  key={`menui-${index}`}
                  onClick={(event) => {
                    handleClose(event, item);
                  }}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledMenu>
            <IconButton
              style={{
                position: 'absolute',
                top: -10,
                right: 3,
                transform: 'rotate(90deg)',
              }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              data-help="Clicked More"
              onClick={(event) => handleClick(event)}
            >
              <SvgIcon style={{ height: 15, width: 3 }} component={MoreIcon} viewBox="0 0 5 23" />
            </IconButton>
          </>
        )}
        <Box className={styles.document_name}>{name}</Box>
        <Box className={styles.document_date}>{date}</Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(DocumentationCard);
