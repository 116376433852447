import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Controller, Scene } from 'react-scrollmagic';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import DoneIcon from '@material-ui/icons/Done';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';
import clsx from 'clsx';

import { isMobile, isTablet } from 'react-device-detect';
import UserPhoto from '../UserPhoto';
import TextField from '../TextField';
import Button from '../Button';
import ContractChart from '../ContractChart';
import DocumentationCard from '../DocumentationCard';
import NextIcon from '../../assets/images/icons/next.svg';
import LocationIcon from '../../assets/images/icons/location.svg';
import TimeframeIcon from '../../assets/images/icons/timeframe.svg';
import DeleteWhiteIcon from '../../assets/images/icons/delete-white.svg';
import CloseWhiteIcon from '../../assets/images/icons/x-white.svg';
import GeneralIcon from '../../assets/images/icons/general.svg';
import DeliveryIcon from '../../assets/images/icons/delivery.svg';
import MoreIcon from '../../assets/images/icons/more.svg';
import AlertIcon from '../../assets/images/icons/alert.svg';

import CheckIcon from '../../assets/images/icons/check.svg';
import PendingIcon from '../../assets/images/icons/pending.svg';
import DenyIcon from '../../assets/images/icons/denied.svg';
import EditIcon from '../../assets/images/icons/edit_item.svg';

import styles from './styles.module.sass';
import MiniChart from '../MiniChart';

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    marginLeft: -30,
    '& ul': { padding: 0 },
  },
})((props) => <Menu elevation={2} getContentAnchorEl={null} {...props} />);

const StyledMenuItem = withStyles({
  root: {
    borderBottom: '1px solid #E1E1E1',
    padding: 12,
    textTransform: 'capitalize',
  },
})((props) => <MenuItem {...props} />);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: 12,
  },
  tooltipPlacementRight: {
    margin: '6px 0px 10px 0px',
  },
}))(Tooltip);

const TaskItem = ({
  t,
  date,
  type,
  title,
  status,
  referenceNumber,
  location,
  startTime,
  endTime,
  eta,
  etd,
  showNextIcon = true,
  requirements,
  subTasks,
  withCounterpart = false,
  meCollaborator,
  onClick,
  onDelete,
  onEdit,
  onCreateTask,
  onAddCollaborator,
  onEditDataPoint,
  onChangeState,
  onSeeRejectedComments,
  onUpload,
  onChangeDocumentState,
  showLoading,
  schedule,
}) => {
  const [optionTaskOpen, setOptionTaskOpen] = useState(false);
  React.useEffect(() => {
    if (showNextIcon) {
      setOptionTaskOpen(false);
    }
  }, [showNextIcon]);

  const menuItems = [
    { label: t('common.edit'), action: 'edit' },
    { label: t('common.delete'), action: 'delete' },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [dataPointEditing, setDataPointEditing] = useState(null);
  const [dataPointValue, setDataPointValue] = useState('');
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [durationPin, setDurationPin] = useState(0);
  const valueRef = useRef('');
  const itemRef = useRef(null);

  React.useEffect(() => {
    if (itemRef.current) {
      let value = itemRef.current.clientHeight || 300;
      value = value > 300 ? value - 136 : value;
      setDurationPin(value);
    }
  }, [itemRef.current]);

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setSelectedSubtask(value);
  };

  const handleClose = (event, item, id, type) => {
    setAnchorEl(null);
    if (item.action === 'edit') {
      onEdit(id, type);
    } else if (item.action === 'delete') {
      onDelete(id, type);
    }
    setSelectedSubtask(null);
  };

  function getStatusCopy(subtaskStatus, type) {
    if (subtaskStatus === 'nominated') {
      return t('contracts.details.workflow.nominationPage.nominated');
    }
    if (subtaskStatus === 'delivered') {
      return t('contracts.details.workflow.deliveryPage.delivered');
    }
    if (subtaskStatus === 'completed') {
      return t('contracts.details.workflow.generalPage.done');
    }
    if (subtaskStatus === 'approved') {
      return t('contracts.changesConfirmed');
    }
    if (subtaskStatus === 'pending_approval_counterpart') {
      if (
        (type === 'nomination' && meCollaborator?.side === 'sell-side') ||
        (type === 'delivery' && meCollaborator?.side === 'buy-side')
      ) {
        return t('contracts.reviewChanges', { counterpart: 'counterpart' });
      }
      return t('contracts.waitingReview');
    }
    if (subtaskStatus === 'rejected_counterpart' || subtaskStatus === 'rejected_owner') {
      if (meCollaborator?.team === 'counter-part-team') {
        return t('contracts.deniedTitle');
      }
      if (meCollaborator?.team === 'owner-team') {
        return t('contracts.deniedMessage', { counterpart: 'counterpart' });
      }
      return '';
    }
  }

  const GetStatusIcon = ({ subtaskStatus, rejectedComments, type }) => (
    <>
      {subtaskStatus === 'completed' || subtaskStatus === 'approved' ? (
        <Box className={styles.task_status}>
          {' '}
          <SvgIcon
            style={{ height: 18, width: 18, marginRight: 5 }}
            component={CheckIcon}
            viewBox="0 0 32 32"
          />
          {getStatusCopy(subtaskStatus, type)}
        </Box>
      ) : subtaskStatus === 'rejected_counterpart' || subtaskStatus === 'rejected_owner' ? (
        <Box
          className={styles.task_status}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onSeeRejectedComments(rejectedComments, 'task');
          }}
        >
          {' '}
          <SvgIcon
            style={{ height: 20, width: 20, marginRight: 5 }}
            component={DenyIcon}
            viewBox="0 0 32 32"
          />
          {getStatusCopy(subtaskStatus, type)}
        </Box>
      ) : (
        <Box className={styles.task_status}>
          {' '}
          <SvgIcon
            style={{ height: 18, width: 18, marginRight: 5 }}
            component={PendingIcon}
            viewBox="0 0 32 32"
          />
          {getStatusCopy(subtaskStatus, type)}
        </Box>
      )}
    </>
  );

  const renderOptions = () => {
    if (!optionTaskOpen) {
      return (
        <Box className={styles.floating_button}>
          <IconButton onClick={() => setOptionTaskOpen(true)}>
            <AddCircleOutlineOutlinedIcon style={{ color: 'white' }} />
          </IconButton>
          <IconButton onClick={() => onDelete()}>
            <SvgIcon component={DeleteWhiteIcon} viewBox="0 0 22 22" />
          </IconButton>
        </Box>
      );
    }
    return (
      <Box className={styles.floating_actions}>
        {(!withCounterpart || meCollaborator?.side === 'sell-side') && (
          <LightTooltip title={t('contracts.details.workflow.delivery')} open placement="right">
            <IconButton onClick={() => onCreateTask('delivery')}>
              <SvgIcon component={DeliveryIcon} viewBox="0 0 22 22" />
            </IconButton>
          </LightTooltip>
        )}
        <LightTooltip title={t('contracts.details.workflow.general')} open placement="right">
          <IconButton onClick={() => onCreateTask('general')}>
            <SvgIcon component={GeneralIcon} viewBox="0 0 22 22" />
          </IconButton>
        </LightTooltip>
        <IconButton onClick={() => setOptionTaskOpen(false)}>
          <SvgIcon component={CloseWhiteIcon} viewBox="0 0 22 22" />
        </IconButton>
      </Box>
    );
  };

  const Datapoint = ({ label, value, rfp, onChanged }) => {
    return (
      <Box className={styles.datapoint_container}>
        <Box
          className={styles.container_input}
          style={!isMobile && !isTablet ? { width: 300 } : {}}
        >
          <form noValidate autoComplete="off">
            <TextField
              defaultValue={value}
              inputRef={dataPointEditing?.requirement === label ? valueRef : null}
              placeholder={label}
              label={label.toUpperCase()}
              disabled={!(dataPointEditing?.requirement === label)}
            />
          </form>
          {rfp && (
            <Box className={styles.rfp} style={{ left: isTablet ? 170 : isMobile ? 140 : 280 }}>
              {t('common.rfp')}
            </Box>
          )}
        </Box>

        {!(dataPointEditing?.requirement === label) ? (
          <IconButton
            className={styles.edit_button}
            onClick={() => {
              setDataPointEditing({ requirement: label });
            }}
          >
            <SvgIcon style={{ height: 18, width: 18 }} component={EditIcon} viewBox="0 0 18 18" />
          </IconButton>
        ) : (
          <>
            <IconButton
              className={styles.edit_button}
              onClick={() => {
                setDataPointEditing(null);
              }}
            >
              <CloseIcon style={{ color: 'black' }} />
            </IconButton>
            <IconButton
              className={styles.edit_button}
              onClick={() => {
                setDataPointEditing(null);
                onChanged(valueRef.current.value);
              }}
            >
              <SaveIcon style={{ color: 'black' }} />
            </IconButton>
          </>
        )}
      </Box>
    );
  };

  const Actions = ({ subtaskStatus, type, taskId }) => {
    return (
      <Box className={styles.actions_container}>
        <Box className={styles.box_two_options}>
          {subtaskStatus === 'pending_approval_counterpart' &&
            ((type === 'nomination' && meCollaborator?.side === 'sell-side') ||
              (type === 'delivery' && meCollaborator?.side === 'buy-side') ||
              !withCounterpart) && (
              <>
                <Button
                  inverted
                  bordered
                  className={styles.button}
                  onPress={() => {
                    onChangeState(taskId, 'disagree', type);
                  }}
                >
                  {t('contracts.details.workflow.addComment')}
                </Button>
                <Box style={{ width: 20 }} />
                <Button
                  className={styles.button}
                  onPress={() => {
                    onChangeState(taskId, 'agree', type);
                  }}
                >
                  {t('common.agree')}
                </Button>
              </>
            )}
          {subtaskStatus === 'approved' &&
            ((type === 'nomination' && meCollaborator?.side === 'sell-side') ||
              (type === 'delivery' && meCollaborator?.side === 'buy-side') ||
              !withCounterpart ||
              type === 'general') && (
              <>
                {type === 'nomination' && (
                  <Button
                    className={styles.button}
                    onPress={() => {
                      onChangeState(taskId, 'completed', type);
                    }}
                  >
                    {t('contracts.details.workflow.nominationPage.markNominated')}
                  </Button>
                )}
                {type === 'general' && (
                  <Button
                    className={styles.button}
                    onPress={() => {
                      onChangeState(taskId, 'completed', type);
                    }}
                  >
                    {t('contracts.details.workflow.generalPage.markDone')}
                  </Button>
                )}
                {type === 'delivery' && (
                  <Button
                    className={styles.button}
                    onPress={() => {
                      onChangeState(taskId, 'completed', type);
                    }}
                  >
                    {t('contracts.details.workflow.deliveryPage.markDelivered')}
                  </Button>
                )}
              </>
            )}
        </Box>
      </Box>
    );
  };

  const renderSubtasks = () => {
    if (subTasks?.length > 0) {
      return subTasks?.map((value, index) => {
        return (
          <Box
            key={index}
            className={styles.subtasks_container}
            style={
              isMobile && !isTablet
                ? { padding: '21px 0 20px 0', marginLeft: -20, width: 'calc(100% + 40px)' }
                : {}
            }
          >
            <Box className={styles.timeline}>
              <Box
                className={clsx(
                  value.status !== 'nominated' &&
                    value.status !== 'delivered' &&
                    value.status !== 'completed'
                    ? styles.circle_empty
                    : styles.circle_filled,
                  subTasks?.length === index + 1
                    ? null
                    : subTasks[index + 1].status !== 'nominated' &&
                      subTasks[index + 1].status !== 'delivered' &&
                      subTasks[index + 1].status !== 'completed'
                    ? styles.line_dashed
                    : styles.line_solid
                )}
              />
            </Box>
            <Box
              className={styles.task_container}
              style={
                isMobile && !isTablet
                  ? { padding: '0 0 10px 10px', width: 'calc(100% - 25px)' }
                  : {}
              }
            >
              <Box className={styles.subtask_first_row}>
                <Box className={styles.subtask_type}>{value?.type?.toUpperCase()}</Box>
                <GetStatusIcon
                  subtaskStatus={value.status}
                  rejectedComments={value.rejectedComments}
                  type={value.type}
                />
                <Box className={styles.right_container}>
                  <Box className={styles.collaborators_container}>
                    {value?.users?.map((collaborator, index2) => (
                      <UserPhoto
                        key={`${index}-${index2}-userphoto`}
                        className={styles.user_photo}
                        name={`${collaborator?.firstName ?? ''} ${collaborator?.lastName ?? ''}`}
                        initials={
                          (collaborator?.firstName ?? '').slice(0, 1) +
                          (collaborator?.lastName ?? '').slice(0, 1)
                        }
                      />
                    ))}
                  </Box>
                  <Box
                    className={styles.assign_task}
                    onClick={() => {
                      onAddCollaborator(value.id);
                    }}
                  >
                    {t('contracts.details.workflow.assignTask')}
                  </Box>
                  <StyledMenu
                    id={`simple-menu${index}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    {menuItems.map((item, index) => (
                      <StyledMenuItem
                        key={`menui-${index}`}
                        onClick={(event) => {
                          handleClose(event, item, selectedSubtask?.id, selectedSubtask?.type);
                        }}
                      >
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledMenu>
                  {((value.type === 'nomination' && meCollaborator?.side === 'buy-side') ||
                    (value.type === 'delivery' && meCollaborator?.side === 'sell-side') ||
                    !withCounterpart ||
                    value.type === 'general') && (
                    <IconButton
                      aria-controls={`simple-menu${index}`}
                      aria-haspopup="true"
                      data-help="Clicked More"
                      onClick={(event) => handleClick(event, value)}
                    >
                      <SvgIcon
                        style={{ height: 23, width: 5 }}
                        component={MoreIcon}
                        viewBox="0 0 5 23"
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Box className={styles.subtask_title}>
                {value?.date || value?.completionDate
                  ? moment
                      .utc(value?.date || value?.completionDate)
                      .format('DD MMM YYYY')
                      .toUpperCase()
                  : t('contracts.details.workflow.deliveryPage.tbd')}{' '}
                · {value?.title}
              </Box>
              {value?.description !== null && (
                <Typography className={styles.subtask_description}>
                  <Box className={styles.subtask_description_bold} display="inline">
                    {t('contracts.addOtherTerms.descriptionLabel')}
                  </Box>
                  {': '}
                  {value?.description}
                </Typography>
              )}
              {value?.location !== null && (
                <Typography className={styles.subtask_description}>
                  <SvgIcon
                    style={{ width: 16, height: 16, marginRight: 5 }}
                    viewBox="0 0 13 18"
                    component={LocationIcon}
                    display="inline"
                  />
                  <Box className={styles.subtask_description_bold} display="inline">
                    {t('contracts.details.workflow.nominationPage.pickupLocation')} {': '}
                  </Box>
                  {value?.location || ''}
                </Typography>
              )}
              {value?.startTime !== null && (
                <Typography className={styles.subtask_description}>
                  <SvgIcon
                    style={{ width: 16, height: 16, marginRight: 5 }}
                    viewBox="0 0 18 18"
                    component={TimeframeIcon}
                    display="inline"
                  />
                  <Box className={styles.subtask_description_bold} display="inline">
                    {t('contracts.details.workflow.nominationPage.timeframe')} {': '}
                  </Box>
                  {value?.startTime
                    ? `${moment(value?.startTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm')} - ${
                        moment(value?.endTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm') || ''
                      }`
                    : t('contracts.details.workflow.deliveryPage.tbd')}
                </Typography>
              )}

              {value?.comments !== null && (
                <Typography className={styles.subtask_description}>
                  <Box className={styles.subtask_description_bold} display="inline">
                    {t('contracts.details.workflow.generalPage.comments')}
                  </Box>
                  {': '}
                  {value?.comments}
                </Typography>
              )}
              {value?.requirements?.filter((requirement) => requirement.type === 'dataPoint')
                ?.length > 0 && (
                <>
                  <Box className={styles.subtask_subtitle}>
                    {t('contracts.details.workflow.dataPointRequired')}
                  </Box>
                  <Box className={styles.datapoints_container}>
                    {value?.requirements?.map((requirement) => {
                      if (requirement.type === 'dataPoint') {
                        return (
                          <Datapoint
                            label={requirement.requirement}
                            value={requirement.value}
                            rfp={requirement.requiredForPayment}
                            onChanged={(dataPointValue) => {
                              onEditDataPoint({
                                id: value.id,
                                requirements: {
                                  updated: [
                                    { id: requirement.id, value: dataPointValue, completed: true },
                                  ],
                                },
                              });
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </Box>
                </>
              )}
              {value?.requirements?.filter((requirement) => requirement.type === 'documentation')
                ?.length > 0 && (
                <>
                  <Box className={styles.subtask_subtitle}>
                    {t('contracts.details.workflow.documentRequired')}
                  </Box>
                  <Box className={styles.documents_container}>
                    {value?.requirements?.map((requirement) => {
                      if (requirement.type === 'documentation') {
                        return (
                          <DocumentationCard
                            type={type}
                            meCollaborator={meCollaborator}
                            name={requirement.requirement}
                            date={
                              requirement?.document
                                ? moment(
                                    requirement?.document?.updatedAt ||
                                      requirement?.document?.createdAt
                                  ).format('DD MMM YYYY')
                                : ''
                            }
                            documentUrl={requirement?.document?.documentPath}
                            isRfp={requirement.requiredForPayment}
                            requiredForReview={requirement.requiredForReview}
                            status={requirement?.document?.status}
                            onUpload={(file) => {
                              onUpload(requirement.document?.id, file);
                            }}
                            menuOptions={
                              requirement?.document?.status === 'pending' &&
                              requirement?.document?.documentPath &&
                              meCollaborator.team === 'counter-part-team'
                                ? [
                                    { label: t('common.agree'), action: 'agree' },
                                    {
                                      label: t('contracts.details.workflow.addComment'),
                                      action: 'disagree',
                                    },
                                  ]
                                : requirement?.document?.documentPath &&
                                  requirement?.document?.updatedBy === meCollaborator.user
                                ? [
                                    { label: t('common.upload'), action: 'upload' },
                                    { label: t('common.delete'), action: 'delete' },
                                  ]
                                : [{ label: t('common.upload'), action: 'upload' }]
                            }
                            onAction={(action) => {
                              onChangeDocumentState(requirement?.document?.id, action);
                            }}
                            onSeeComments={() => {
                              onSeeRejectedComments(
                                requirement?.document?.rejectedComments,
                                'document'
                              );
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </Box>
                </>
              )}
              <Actions subtaskStatus={value.status} type={value.type} taskId={value.id} />
              <Divider className={styles.divider} />
            </Box>
          </Box>
        );
      });
    }
    return null;
  };

  const renderNominationStatus = () => {
    return (
      <Box className={styles.nomination_status}>
        <SvgIcon
          style={{ height: 22, width: 22, marginRight: 20 }}
          component={AlertIcon}
          viewBox="0 0 22 22"
        />
        {t('contracts.details.workflow.nominationStatusPending')}
      </Box>
    );
  };

  return (
    <Box
      className={styles.full_item}
      ref={itemRef}
      style={
        isMobile && !isTablet
          ? { borderBottom: '1px solid #d2d7da' }
          : { boxShadow: '4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6' }
      }
    >
      <Box
        className={styles.item}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <Box className={styles.date_container}>
          <Box className={styles.date}>
            {date && (
              <>
                <Box className={styles.month}>{date ? moment.utc(date).format('MMM') : ''}</Box>
                <Box className={styles.day}>{date ? moment.utc(date).format('DD') : ''}</Box>
                <Box className={styles.year}>{date ? moment.utc(date).format('YYYY') : ''}</Box>
              </>
            )}
            {!date && (
              <>
                <Box className={styles.tbd}>{t('contracts.details.workflow.deliveryPage.tbd')}</Box>
              </>
            )}
          </Box>
          <Divider style={{ marginRight: 20, marginLeft: 20 }} orientation="vertical" flexItem />
        </Box>
        <Box className={styles.data}>
          <Box className={styles.first_row}>
            <Box className={styles.task_info}>
              <Box className={styles.task_type}>{type?.toUpperCase()}</Box>
              <Box className={styles.task_description}>{title?.toUpperCase()}</Box>
              {referenceNumber && <Box className={styles.task_reference}>{referenceNumber}</Box>}
            </Box>
            {(!isMobile || isTablet) && (
              <Box className={styles.nomination_info}>
                <Box className={styles.info_row}>
                  <SvgIcon
                    style={{ width: 16, height: 16, marginRight: 5 }}
                    viewBox="0 0 13 18"
                    component={LocationIcon}
                  />
                  <Box className={styles.label}>
                    {t('contracts.details.workflow.nominationPage.pickupLocation')} {':'}
                  </Box>
                  <Box className={styles.text}>{location || ''}</Box>
                </Box>
                <Box className={styles.info_row}>
                  <SvgIcon
                    style={{ width: 16, height: 16, marginRight: 5 }}
                    viewBox="0 0 18 18"
                    component={TimeframeIcon}
                  />
                  <Box className={styles.label}>
                    {t('contracts.details.workflow.nominationPage.timeframe')} {':'}
                  </Box>

                  <Box className={styles.text}>
                    {startTime
                      ? `${moment(startTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm')} - ${
                          moment(endTime, [moment.ISO_8601, 'HH:mm']).format('HH:mm') || ''
                        }`
                      : t('contracts.details.workflow.deliveryPage.tbd')}
                  </Box>
                </Box>

                <Box className={styles.info_row}>
                  <Box className={styles.label}>
                    {t('contracts.details.workflow.nominationPage.eta')} {':'}
                  </Box>
                  <Box className={styles.text}>{eta ? `${moment(eta).format('L')}` : ''}</Box>
                  <div style={{ width: 20 }} />
                  <Box className={styles.label}>
                    {t('contracts.details.workflow.nominationPage.etd')} {':'}
                  </Box>
                  <Box className={styles.text}>{etd ? `${moment(etd).format('L')}` : ''}</Box>
                </Box>
              </Box>
            )}
            <Box display="flex" alignItems="center" style={{ marginLeft: 'auto' }}>
              {/* {!isMobile && <GetStatusIcon />} */}
              {showNextIcon ? (
                <Box className={styles.next}>
                  <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                </Box>
              ) : (
                <Box className={styles.next}>
                  <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={styles.second_row}>
            <Box className={styles.requirements}>
              {requirements &&
                requirements.map((value, index) => (
                  <Box key={index} className={styles.task_requirement}>
                    {value?.completed === true && <DoneIcon style={{ fontSize: 10 }} />}{' '}
                    {value.requirement}
                  </Box>
                ))}
            </Box>
            {/* {isMobile && <GetStatusIcon />} */}
          </Box>
        </Box>
      </Box>
      <Box style={{ width: '100%' }}>
        <MiniChart subTasks={subTasks} />
      </Box>
      {!showNextIcon && !isMobile && !isTablet && (
        <Controller>
          <div className="section" />
          <div id="trigger" />
          <Scene pin enabled duration={durationPin - 100} classToggle="section" offset={300}>
            <div className={[styles.cont_floating_button, 'sticky'].join(' ')}>
              {renderOptions()}
            </div>
          </Scene>
        </Controller>
      )}
      {!showNextIcon && renderSubtasks()}
      {/* {!showNextIcon && renderNominationStatus()} */}
      {showLoading ? (
        <Box className={styles.loaderItem}>
          <CircularProgress color="inherit" />
        </Box>
      ) : null}
    </Box>
  );
};

export default withTranslation()(TaskItem);
