import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteMaterial from '@material-ui/lab/Autocomplete';
import MaterialTextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

const CustomTextField = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: 5,
    backgroundImage: 'linear-gradient(to right, #fff 20%, #f3f3f5 80%)',
    boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      borderRadius: 5,
      boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
      border: 'solid 1px #000000',
    },
  },
  focused: {},
  disabled: {
    backgroundImage: 'linear-gradient(to bottom, #fff 97%, #f3f3f5 80%)',
    boxShadow: 'inset 0px 0px 0px 0 #fff',
    border: 'solid 0px #fff',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
  },
}))(CustomizedInputs);

function CustomizedInputs(props) {
  const { classes, ...other } = props;
  return (
    <MaterialTextField
      autoComplete="off"
      InputProps={{ classes, disableUnderline: true }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  error: {
    border: '1px solid #d70000',
    boxShadow: 'none',
  },
  input: {
    '&:focus': {
      borderRadius: 5,
      boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
      border: 'solid 1px #000000',
    },
    overflow: 'hidden',
    borderRadius: 5,
    backgroundImage: 'linear-gradient(to right, #fff 20%, #f3f3f5 80%)',
    boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  inputDisabled: {
    backgroundImage: 'linear-gradient(to bottom, #fff 97%, #f3f3f5 80%)',
    boxShadow: 'inset 0px 0px 0px 0 #fff',
    border: 'solid 0px #fff',
    transition: theme.transitions.create(['border-color', 'box-shadow', 'border']),
  },
  inputLabel: {
    '&.disabled': {
      color: '#111',
    },
  },
}));

const Autocomplete = ({
  filled = true,
  error = false,
  maxLength,
  options,
  valueKey,
  displayKey,
  uppercase,
  ...props
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (
      props.value !== 0 &&
      props.value !== '' &&
      props.value !== null &&
      props.value !== undefined
    ) {
      if (props.freeSolo && typeof props.value === 'string') {
        setValue(props.value);
      } else {
        const valueFound = options?.find((option) => {
          const valueResult = valueKey
            .split(',')
            .map((item) => `${option[item]}`)
            .join(' ')
            .trim();

          if (uppercase && typeof valueResult === 'string') {
            return valueResult.toString().toUpperCase() === props.value.toString().toUpperCase();
          }
          return valueResult?.toString() === props?.value?.toString();
        });
        setValue(valueFound);
      }
    }
  }, [props.value]);

  return (
    <ThemeProvider theme={theme}>
      <AutocompleteMaterial
        classes={error ? { root: classes.error } : {}}
        id="select"
        freeSolo={props.freeSolo}
        autoSelect={props.freeSolo}
        name={props.name}
        options={options?.length > 0 ? ['', ...options] : []}
        getOptionSelected={(option) => options.find((item) => item === option)}
        getOptionLabel={(option) => {
          if (option === '') {
            return '';
          }
          if (props.freeSolo && typeof option === 'string') {
            return option;
          }

          const labelValue = displayKey
            .split(',')
            .map((item) => `${option[item]}`)
            .join(' ')
            .trim();
          return labelValue.trim();
        }}
        onChange={(e) => {
          if (e.target.textContent !== '') {
            const newValue = e.target.textContent;
            setValue(newValue);

            props.onChange({
              target: {
                id: props.name,
                value: newValue,
              },
            });
          }
        }}
        renderInput={(params) => {
          const InputProps = { ...params.InputProps };
          InputProps.className = props.disabled ? classes.inputDisabled : classes.input;
          InputProps.endAdornment = null;
          return (
            <CustomTextField
              id="select"
              {...props}
              // {...params}
              classes={error ? { root: classes.error } : {}}
              className={classes.margin}
              style={{ backgroundColor: 'transparent', width: '100%' }}
              variant={filled ? 'filled' : 'standard'}
              inputProps={{
                ...params.inputProps,
                style: !props.disabled
                  ? {
                      boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
                      '&$focused': {
                        boxShadow: 'inset 1px 1px 3px 0 #bdc1d1',
                        border: 'solid 1px #000000',
                      },
                    }
                  : null,
                className: props.disabled ? classes.inputDisabled : classes.input,
                maxLength,
                disabled: props.disabled,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  disabled: 'disabled',
                },
              }}
              InputProps={{
                ...InputProps,
                classes,
                disableUnderline: true,
                disabled: props.disabled,
              }}
              disabled={props.disabled}
              label={props?.label}
              required={props.required}
              helperText={props.helperText}
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
                let valueUpdate = '';
                setValue(newValue);

                if (props.freeSolo && typeof newValue === 'string') {
                  valueUpdate = newValue.toString();
                } else if (newValue) {
                  const valueResult = valueKey.includes(',')
                    ? valueKey
                        .split(',')
                        .map((item) => `${newValue[item]}`)
                        .join(' ')
                        .trim()
                    : newValue[valueKey];

                  if (uppercase && typeof valueResult === 'string') {
                    const uppercaseValue = valueResult;
                    valueUpdate = uppercaseValue;
                  } else {
                    valueUpdate = valueResult;
                  }
                  if (props.freeSolo) {
                    setValue(valueUpdate);
                  }
                } else {
                  valueUpdate = null;
                }

                props.onChange({
                  target: {
                    id: props.name,
                    value: valueUpdate,
                  },
                });
              }}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};

Autocomplete.propTypes = {
  className: PropTypes.object,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  displayKey: PropTypes.string,
  uppercase: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  helperText: PropTypes.object,
  name: PropTypes.string,
  freeSolo: PropTypes.bool,
};

export default Autocomplete;
