import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';

import Button from '../Button';
import ModalView from '../../modals/modalView';

const Popup = ({
  title,
  description,
  subDescription,
  onConfirm,
  txtConfirm,
  onCancel,
  txtCancel,
  onClose,
  icon
}) => {
  return (
    <ModalView
      view={
        <Box>
          {icon && icon()}
          <Box className="title" style={{ marginTop: 60, textAlign: 'center', color: 'white' }}>
            {title}
          </Box>
          <Box
            className="description_container"
            style={{ marginTop: 30, textAlign: 'center', color: 'white' }}
          >
            {description}
          </Box>
          <Box
            className="description_container"
            style={{ marginTop: 30, textAlign: 'center', color: 'white' }}
          >
            {subDescription}
          </Box>
          <Grid
            container
            spacing={6}
            direction="row"
            justify="space-between"
            style={{ marginTop: 60 }}
          >
            <Grid item xs={12} sm={6} container justify="flex-start">
              <Box style={{ width: '100%' }}>
                <Button
                  inverted
                  backgroundImage="linear-gradient(90deg, #ffffff 18%, #757e95 100%)"
                  style={{ whiteSpace: 'nowrap' }}
                  onPress={onConfirm}
                >
                  {txtConfirm}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} container justify="flex-end">
              <Box style={{ width: '100%' }}>
                <Button
                  border="1px solid #000"
                  shading={false}
                  bordered
                  backgroundImage="rgba(255, 255, 255, 0.1)"
                  backgroundColor="transparent"
                  style={{ whiteSpace: 'nowrap', width: '100%' }}
                  onPress={onCancel}
                >
                  {txtCancel}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      inverted
      onClose={onClose}
    />
  );
};

export default withTranslation()(Popup);
