import React from 'react';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import clsx from 'clsx';
import styles from './styles.module.sass';

const ContractBox = ({ t, className, contract, small = false }) => {
  const FullFields = () => (
    <>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.type')}:</Box>
        <Box className={styles.data}>{contract?.packageType || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.contractName')}:</Box>
        <Box className={styles.data}>{contract?.name || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.totalVolume')}:</Box>
        <Box className={styles.data}>{contract?.totalVolume || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.contractCode')}:</Box>
        <Box className={styles.data}>{contract?.contractReference || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.incoTerm')}:</Box>
        <Box className={styles.data}>{contract?.incoTerm || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.packagingType')}:</Box>
        <Box className={styles.data}>{contract?.packageType || ''}</Box>
      </Box>
    </>
  );

  const SmallFields = () => (
    <>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.type')}:</Box>
        <Box className={styles.data}>{contract?.packageType || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.totalVolume')}:</Box>
        <Box className={styles.data}>{contract?.totalVolume || ''} MT</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.sugarSpec')}:</Box>
        <Box className={styles.data}>{contract?.sugarSpec || ''}</Box>
      </Box>
      <Box className={styles.box_row}>
        <Box className={styles.label}>{t('contracts.box.packagingType')}:</Box>
        <Box className={styles.data}>{contract?.packageType || ''}</Box>
      </Box>
    </>
  );

  return (
    <Box className={clsx(styles.container, className)}>
      <Box className={styles.content_box}>
        <Box className={small ? styles.box_data_small : styles.box_data}>
          {small ? <SmallFields /> : <FullFields />}
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(ContractBox);
