import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import NavigationBar from '../../../components/NavigationBar';
import ValidateKey from '../../../components/ValidateKey';

import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const ValidateToken = ({ t, history, match }) => {
  const dispatch = useDispatch();

  const {
    validateTokenLoading,
    validateTokenError,
    validateTokenCalled,
    tokenValid,
    paymentTokenValid,
    emailVerified,
    user,
  } = useSelector((state) => state.auth);

  const [minHeight, setMinHeight] = useState(0);

  const formBox = React.createRef();

  useEffect(() => {
    setMinHeight(formBox?.current?.clientHeight + (isMobile ? 0 : 406));
    if (validateTokenCalled && tokenValid && paymentTokenValid) {
      if (emailVerified === false) {
        history.replace('/email-verification');
      } else if (match?.params?.type === 'payment') {
        history.goBack();
      } else {
        history.push('/home');
      }
      dispatch(AuthActions.resetValidateToken());
    }
  }, [
    dispatch,
    history,
    match,
    validateTokenCalled,
    tokenValid,
    emailVerified,
    paymentTokenValid,
    formBox,
  ]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  function validateKey(token) {
    dispatch(
      AuthActions.validateTotpToken(token, match?.params.type === 'payment' ? 'payment' : 'login')
    );
  }

  function getTokenStatus() {
    if (match?.params?.type === 'payment') {
      return paymentTokenValid;
    }
    return tokenValid;
  }

  return (
    <Box className={styles.container} style={{ minHeight }}>
      <NavigationBar
        title={t('validateToken.title')}
        hasBackButton={!isMobile}
        hasCloseButton={isMobile}
        hasMenuButton={!isMobile}
        hideFirstBar={!isMobile}
        transparent
        history={history}
        customBack={() => {
          if (tokenValid) {
            history.goBack();
          } else {
            history.replace('/login');
          }
        }}
      />
      <Container className={styles.content} ref={formBox}>
        <Box className={styles.container_form}>
          <ValidateKey
            loading={validateTokenLoading}
            disabled={validateTokenLoading}
            tokenIsValid={validateTokenCalled && !validateTokenLoading ? getTokenStatus() : null}
            error={validateTokenError}
            onValidateKey={validateKey}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default withTranslation()(ValidateToken);
