import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { Box, useMediaQuery, Grid, Divider } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';

import clsx from 'clsx';

import NotificationActions from '../../redux/modals/actions';

import Close from '../../assets/images/icons/close-white.svg';
import OffSummary from '../../assets/images/icons/off-summary.svg';
import Terms from '../../assets/images/icons/terms.svg';
import Schedule from '../../assets/images/icons/schedule.svg';
import Payment from '../../assets/images/icons/payment.svg';
import Documents from '../../assets/images/icons/documents.svg';
import Workflow from '../../assets/images/icons/workflow.svg';
import More from '../../assets/images/icons/menu-more.svg';
import OffSummaryBlack from '../../assets/images/icons/off-summary-black.svg';
import TermsBlack from '../../assets/images/icons/terms-black.svg';
import ScheduleBlack from '../../assets/images/icons/schedule-black.svg';
import PaymentBlack from '../../assets/images/icons/payment-black.svg';
import DocumentsBlack from '../../assets/images/icons/documents-black.svg';
import WorkflowBlack from '../../assets/images/icons/workflow-black.svg';
import BackOutline from '../../assets/images/icons/back-outline.svg';
import MoreBlack from '../../assets/images/icons/menu-more-black.svg';
import LogoMenu from '../../assets/images/icons/symbol-logo.png';

import useWindowDimensions from '../../utils/screenSize';
import Logo from '../Logo';

import styles from './styles.module.sass';

const SidebarNav = ({ t, history, openSidebar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const route = history?.location?.pathname;
  const [drawerWidth, setDrawerWidth] = useState(299);
  const [showMore, setShowMore] = useState(false);

  const { contractSelection } = useSelector((state) => state.contracts);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const itemsMenu = !isMobile
    ? [
        {
          label: t(`common.${isTablet ? 'menuShort' : 'menu'}.contractSummary`),
          icon: OffSummary,
          iconBlack: OffSummaryBlack,
          viewBox: '0 0 22 22',
          route: '/contracts/details',
        },
        {
          label: t(`common.${isTablet ? 'menuShort' : 'menu'}.contractTerms`),
          icon: Terms,
          iconBlack: TermsBlack,
          viewBox: '0 0 26 21',
          route: '/contracts/other-terms',
        },
        {
          label: t(`common.${isTablet ? 'menuShort' : 'menu'}.deliverySchedule`),
          icon: Schedule,
          iconBlack: ScheduleBlack,
          viewBox: '0 0 22 22',
          route: '/contracts/off-take-schedule',
        },
        {
          label: t(`common.${isTablet ? 'menuShort' : 'menu'}.paymentInstructions`),
          icon: Payment,
          iconBlack: PaymentBlack,
          viewBox: '0 0 22 22',
          route: '/contracts/payment-instructions',
        },
        {
          label: t(`common.${isTablet ? 'menuShort' : 'menu'}.documents`),
          icon: Documents,
          iconBlack: DocumentsBlack,
          viewBox: '0 0 17 20',
          route: '/contracts/documents/list',
        },
        {
          label: t(`common.${isTablet ? 'menuShort' : 'menu'}.workflow`),
          icon: Workflow,
          iconBlack: WorkflowBlack,
          viewBox: '0 0 28 20',
          route: '/contracts/workflows/off-take-schedule-detail',
        },
      ]
    : [
        {
          label: t('common.menuShort.contractSummary'),
          icon: OffSummary,
          iconBlack: OffSummaryBlack,
          viewBox: '0 0 22 22',
          route: '/contracts/details',
        },
        {
          label: t('common.menuShort.deliverySchedule'),
          icon: Schedule,
          iconBlack: ScheduleBlack,
          viewBox: '0 0 22 22',
          route: '/contracts/off-take-schedule',
        },
        {
          label: t('common.menuShort.workflow'),
          icon: Workflow,
          iconBlack: WorkflowBlack,
          viewBox: '0 0 28 20',
          route: '/contracts/workflows/off-take-schedule-detail',
        },
        {
          label: t('common.menuShort.documents'),
          icon: Documents,
          iconBlack: DocumentsBlack,
          viewBox: '0 0 17 20',
          route: '/contracts/documents/list',
        },
        {
          label: t('common.menuShort.more'),
          icon: More,
          iconBlack: MoreBlack,
          viewBox: '0 0 5 15',
          more: [
            {
              label: t('common.menu.contractTerms'),
              icon: Terms,
              iconBlack: TermsBlack,
              viewBox: '0 0 26 21',
              route: '/contracts/other-terms',
            },
            {
              label: t('common.menu.paymentInstructions'),
              icon: Payment,
              iconBlack: PaymentBlack,
              viewBox: '0 0 22 22',
              route: '/contracts/payment-instructions',
            },
          ],
        },
      ];

  const hasRoute = itemsMenu.filter((item) => item.route === route);

  const toggle = () => {
    dispatch(NotificationActions.setShowSidebar(!openSidebar));
  };

  useEffect(() => {
    const width = isMobile ? 299 : 420;
    setDrawerWidth(width);
  }, [isMobile]);

  // if (isMobile || hasRoute.length < 1) return null;

  return (
    <>
      <Box
        className={clsx(styles.container_sidebar, openSidebar ? '' : styles.collapsed)}
        style={
          isMobile || isTablet
            ? {
                bottom: 10,
                left: 5,
                right: 5,
                boxShadow: '-1px 5px 20px 0 #777777',
                padding: '0px 20px 0px 20px',
              }
            : {
                padding: '0px 0px 20px 20px',
                height: 'calc(100% - 52px)',
                top: 16,
                left: 30,
                width: 250,
                maxWidth: 250,
                boxShadow: '-1px 5px 20px 0 #777777',
              }
        }
      >
        {!isMobile && !isTablet && (
          <>
            <Box className={styles.headerSidebar}>
              {openSidebar ? (
                <Logo white small onClick={() => history.push('/home')} />
              ) : (
                <Box onClick={() => history.push('/home')}>
                  <img src={LogoMenu} className={styles.imgLogo} alt="" />
                </Box>
              )}
              {openSidebar && (
                <Box className={styles.iconClose} onClick={toggle}>
                  <SvgIcon component={Close} />
                </Box>
              )}
            </Box>

            <Box
              className={[styles.infoContract, !openSidebar ? styles.transparent : ''].join(' ')}
            >
              <p className={styles.breadcrumb}>Portfolio //</p>
              <p className={styles.nameContract}>{contractSelection?.name}</p>
              <p className={styles.numRef}>Ref: {contractSelection?.contractReference || ''}</p>
            </Box>
          </>
        )}

        <Grid
          direction={isMobile || isTablet ? 'row' : 'column'}
          justify={isMobile || isTablet ? 'space-around' : 'flex-start'}
          container
          className={styles.listMenu}
          style={
            isMobile || isTablet
              ? { display: 'flex', flexDirection: 'row' }
              : { margin: '30px 0px' }
          }
        >
          {itemsMenu.map((item, index) => (
            <Grid
              item
              xs={isMobile}
              key={`item-menu-${index}`}
              className={[
                styles.itemMenu,
                item.route === route || item.more?.find((element) => element.route === route)
                  ? styles.itemSelected
                  : '',
                item.route !== route && !openSidebar ? styles.itemCollapsed : '',
              ].join(' ')}
              onClick={() => {
                setShowMore(false);
                item.route ? history.push(item.route) : item.more ? setShowMore(true) : null;
              }}
              style={
                isMobile || isTablet
                  ? {
                      height: isMobile ? 'auto' : isTablet ? 86 : 'auto',
                      width: isMobile ? 'auto' : isTablet ? 86 : 'auto',
                      minHeight: isMobile ? (width - 50) / 5 : null,
                      alignItems: 'center',
                      justifyContent: 'center',
                      aspectRatio: '1/1',
                      borderRadius: '50%',
                      display: 'flex',
                      flexDirection: 'column',
                    }
                  : { padding: '12px 18px', borderRadius: '100px 0px 0px 100px' }
              }
            >
              <SvgIcon
                component={
                  item.route === route || item.more?.find((element) => element.route === route)
                    ? item.iconBlack
                    : item.icon
                }
                viewBox={item.viewBox || '0 0 30 20'}
              />
              {openSidebar && (
                <p
                  style={
                    isMobile || isTablet
                      ? { fontSize: 8, textAlign: 'center', opacity: 0.7, marginTop: 7 }
                      : { marginLeft: 6 }
                  }
                >
                  {item.label}
                </p>
              )}
            </Grid>
          ))}
        </Grid>
        {!isMobile && !isTablet && (
          <Box
            className={[styles.buttonBack, !openSidebar ? styles.collapsedBack : ''].join(' ')}
            onClick={() => history.push('/home')}
          >
            <SvgIcon component={BackOutline} />
            <p>Back to portfolio</p>
          </Box>
        )}
      </Box>
      {!openSidebar && (
        <Box className={styles.btShowSidebar} onClick={toggle}>
          <SvgIcon component={Close} />
        </Box>
      )}
      {showMore && (
        <Box className={styles.moreBackground}>
          <Box className={styles.options}>
            {itemsMenu[itemsMenu.length - 1]?.more?.map((item, index) => (
              <>
                <Box
                  key={`item-menu-${index}`}
                  onClick={() => {
                    setShowMore(false);
                    item.route ? history.push(item.route) : null;
                  }}
                  style={{
                    padding: '5px 20px',
                    backgroundColor: item.route === route ? 'black' : 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 20,
                  }}
                >
                  <SvgIcon
                    component={item.route !== route ? item.iconBlack : item.icon}
                    viewBox={item.viewBox || '0 0 30 20'}
                  />
                  <p style={{ marginLeft: 6, color: item.route === route ? 'white' : 'black' }}>
                    {item.label}
                  </p>
                </Box>
                <Divider variant="middle" />
              </>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default withTranslation()(SidebarNav);
