import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import amplitude from 'amplitude-js';

import Box from '@material-ui/core/Box';

import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid, Container, SvgIcon } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import NavigationBar from '../../../components/NavigationBar';
import TextField from '../../../components/TextField';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import INQRadio from '../../../components/Radio';
import MessageModal from '../../../modals/message';
import ModalView from '../../../modals/modalView';
import Loading from '../../../components/loading';
import StatusBox from '../../../components/StatusBox';
import CheckBox from '../../../components/CheckBox';

import FreemiumItem from '../../../assets/images/icons/freemium-item.svg';
import SuccessIcon from '../../../assets/images/icons/success.svg';

import ContractsActions from '../../../redux/contracts/actions';
import AuthActions from '../../../redux/auth/actions';
import TermsActions from '../../../redux/terms/actions';

import styles from './styles.module.sass';
import ContractSpecsCard from '../../../components/ContractSpecsCard';
import HelpButton from '../../../components/HelpButton';
import ContractInfoCard from '../../../components/ContractInfoCard';
import DeniedComment from '../deniedComment';
import SeeComment from '../seeComment';
import Autocomplete from '../../../components/Autocomplete';

const GeneralInformation = ({ t, history, modalView = false, onClose }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    saveUserContractsLoading,
    saveUserContractsError,
    saveUserContractsCalled,
    saveUserContractsSuccess,
    contractSelection,
    updateUserContractLoading,
    getUserContractLoading,
    updateUserContractError,
    updateUserContractCalled,
    getUserContractError,
    userContracts,
    getUserContractCalled,

    filterDateRange,
    filterBuyer,
    filterSeller,
    filterProduct,
    filterSort,

    autocomplete,
  } = useSelector((state) => state.contracts);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(true);
  const [actionRequired, setActionRequired] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [addCounterpart, setAddCounterpart] = useState(null);
  const [commercialExecutive, setCommercialExecutive] = useState(null);
  const [showCounterpartHelp, setShowCounterpartHelp] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [addDisagreeComments, setAddDisagreeComments] = useState(false);
  const [seeDisagreeComments, setSeeDisagreeComments] = useState(false);

  useEffect(() => {
    dispatch(ContractsActions.getAutocomplete());
  }, []);

  useEffect(() => {
    if (!getUserContractCalled && contractSelection) {
      dispatch(ContractsActions.getUserContract(contractSelection?.id));
    }
  }, [getUserContractCalled]);

  useEffect(() => {
    if (contractSelection?.id) {
      setCreating(false);
      if (
        contractSelection?.owner?.id !== contractSelection?.buyer?.id &&
        contractSelection?.owner?.id !== contractSelection?.seller?.id
      ) {
        setCommercialExecutive(true);
      } else {
        setCommercialExecutive(false);
      }

      setEditing(false);
      if (contractSelection?.buyer?.email === user?.email) {
        setUserType('BUY-SIDE');
        if (contractSelection?.status !== 'created') {
          if (contractSelection?.buyer?.agree === null) {
            setActionRequired(true);
          } else {
            setActionRequired(false);
          }
        } else {
          setActionRequired(false);
        }
      } else if (contractSelection?.seller?.email === user?.email) {
        setUserType('SELL-SIDE');
        if (contractSelection?.status !== 'created') {
          if (contractSelection?.seller?.agree === null) {
            setActionRequired(true);
          } else {
            setActionRequired(false);
          }
        } else {
          setActionRequired(false);
        }
      } else {
        setCommercialExecutive(true);
      }

      if (contractSelection?.sellerSide === true) {
        setUserType('SELL-SIDE');
        if (contractSelection?.buyer?.email) {
          setAddCounterpart(true);
        } else {
          setAddCounterpart(false);
        }
      } else if (contractSelection?.sellerSide === false) {
        setUserType('BUY-SIDE');
        if (contractSelection?.seller?.email) {
          setAddCounterpart(true);
        } else {
          setAddCounterpart(false);
        }
      } else if (userType) {
        if (userType === 'BUY-SIDE') {
          if (contractSelection?.seller?.email) {
            setAddCounterpart(true);
          } else {
            setAddCounterpart(false);
          }
        } else if (userType === 'SELL-SIDE') {
          if (contractSelection?.buyer?.email) {
            setAddCounterpart(true);
          } else {
            setAddCounterpart(false);
          }
        }
      }
    } else {
      setCommercialExecutive(false);
      setAddCounterpart(false);
      setUserType('SELL-SIDE');
      setCreating(true);
    }
    if (saveUserContractsCalled && !saveUserContractsLoading && saveUserContractsSuccess) {
      if (!modalView) {
        setShowSuccessModal(true);
      } else {
        dispatch(ContractsActions.resetSaveUserContracts());
        onClose(true);
      }
    }
    // setCreating(false);
    // setEditing(false);
    // setActionRequired(true);
    return () => {};
  }, [
    history,
    dispatch,
    contractSelection,
    saveUserContractsLoading,
    saveUserContractsError,
    saveUserContractsCalled,
  ]);

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE !== 'disable') {
      amplitude.getInstance().logEvent(`page${window.location.pathname}`);
    }
  }, []);

  const validationSchema = Yup.object({
    contractName: Yup.string().required(t('common.requiredField')),
    contractReference: Yup.string().required(t('common.requiredField')),
    counterpartCompany: Yup.string().required(t('common.requiredField')),
    totalVolume: Yup.number()
      .required(t('common.requiredField'))
      .positive()
      .min(
        getCurrentTotalScheduled(),
        t('contracts.generalInformation.minVolume', {
          volumeScheduled: getCurrentTotalScheduled(),
        })
      ),
    sugarSpec: Yup.string().required(t('common.requiredField')),
    packagingType: Yup.string().required(t('common.requiredField')),
    // buyerRepresentativeEmail: Yup.string()
    //   .email(t('common.enterValidEmail'))
    //   .test('validate_type', t('common.requiredField'), function (value) {
    //     if (userType === 'SELL-SIDE') {
    //       if (value === undefined || value === '') {
    //         return false;
    //       }
    //     }
    //     return true;
    //   }),
    // buyerRepresentativeFirstName: Yup.string().test('validate_type', t('common.requiredField'), function (value) {
    //   if (userType === 'SELL-SIDE') {
    //     if (value === undefined || value === '') {
    //       return false;
    //     }
    //   }
    //   return true;
    // }),
    // buyerRepresentativeLastName: Yup.string().test('validate_type', t('common.requiredField'), function (value) {
    //   if (userType === 'SELL-SIDE') {
    //     if (value === undefined || value === '') {
    //       return false;
    //     }
    //   }
    //   return true;
    // }),
    // sellerRepresentativeEmail: Yup.string()
    //   .email(t('common.enterValidEmail'))
    //   .test('validate_type', t('common.requiredField'), function (value) {
    //     if (userType === 'BUY-SIDE') {
    //       if (value === undefined || value === '') {
    //         return false;
    //       }
    //     }
    //     return true;
    //   }),
    // sellerRepresentativeFirstName: Yup.string().test('validate_type', t('common.requiredField'), function (
    //   value
    // ) {
    //   if (userType === 'BUY-SIDE') {
    //     if (value === undefined || value === '') {
    //       return false;
    //     }
    //   }
    //   return true;
    // }),
    // sellerRepresentativeLastName: Yup.string().test('validate_type', t('common.requiredField'), function (value) {
    //   if (userType === 'BUY-SIDE') {
    //     if (value === undefined || value === '') {
    //       return false;
    //     }
    //   }
    //   return true;
    // }),
  });

  function handleSubmit(values) {
    dispatch(ContractsActions.saveUserContractsRequest());
    const counterpartFound = userContracts?.contractInfo?.find((contract) => {
      return contract?.collaborators?.find((collaborator) => {
        return collaborator?.user === user.id && collaborator?.invitedBy !== user.id;
      });
    });

    const newContract = {};
    newContract.name = values.contractName;
    newContract.contractReference = values.contractReference;
    newContract.contractCounterpartReference = values.contractCounterpartReference;
    newContract.counterpartCompany = values.counterpartCompany;
    newContract.totalVolume = values.totalVolume;
    newContract.sugarSpec = values.sugarSpec;
    if (userType === 'SELL-SIDE') {
      newContract.sellerSide = true;
      if (
        addCounterpart === true &&
        values.buyerRepresentativeEmail &&
        values.buyerRepresentativeEmail !== ''
      ) {
        newContract.buyer = {
          email: values.buyerRepresentativeEmail,
          firstName: values.buyerRepresentativeFirstName,
          lastName: values.buyerRepresentativeLastName,
        };
      }

      if (commercialExecutive) {
        newContract.seller = {
          email: values.sellerRepresentativeEmail,
          firstName: values.sellerRepresentativeFirstName,
          lastName: values.sellerRepresentativeLastName,
        };
      } else {
        newContract.seller = {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        };
      }
    } else if (userType === 'BUY-SIDE') {
      newContract.sellerSide = false;
      if (
        addCounterpart === true &&
        values.sellerRepresentativeEmail &&
        values.sellerRepresentativeEmail !== ''
      ) {
        newContract.seller = {
          email: values.sellerRepresentativeEmail,
          firstName: values.sellerRepresentativeFirstName,
          lastName: values.sellerRepresentativeLastName,
        };
      }

      if (commercialExecutive) {
        newContract.buyer = {
          email: values.buyerRepresentativeEmail,
          firstName: values.buyerRepresentativeFirstName,
          lastName: values.buyerRepresentativeLastName,
        };
      } else {
        newContract.buyer = {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        };
      }
    }

    newContract.packageType = values.packagingType;
    dispatch(
      ContractsActions.saveUserContracts(contractSelection?.id || null, newContract, {
        withCounterpartContracts: counterpartFound,
      })
    );
    dispatch(ContractsActions.getTotalStadistics(user?.id));
    dispatch(
      ContractsActions.getUserContracts(user?.id, 9, 1, {
        dateRange: filterDateRange,
        buyer: filterBuyer,
        seller: filterSeller,
        product: filterProduct,
        sort: filterSort,
      })
    );
  }

  function handleAgree() {
    const data = { agree: true };
    dispatch(ContractsActions.updateUserContract(contractSelection?.id, data));
  }

  function showStatusBox() {
    const isSeller = contractSelection?.seller?.id === user?.id;
    if (contractSelection?.status !== 'created') {
      if (contractSelection?.buyer?.agree === true && contractSelection.seller?.agree) {
        return <StatusBox type="confirmed" />;
      }

      if (
        (!isSeller && contractSelection?.buyer?.agree !== true) ||
        (isSeller && contractSelection?.seller?.agree !== true)
      ) {
        if (isSeller) {
          // SELLER
          if (contractSelection.seller?.agree === null) {
            return (
              <StatusBox
                type="review"
                counterpartName={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
              />
            );
          }
          if (contractSelection.seller?.agree === false) {
            return (
              <StatusBox
                type="denied"
                counterpartName={`${contractSelection?.buyer?.firstName} ${contractSelection?.buyer?.lastName}`}
              />
            );
          }
        } else {
          // BUYER
          if (contractSelection?.buyer?.agree === null) {
            return (
              <StatusBox
                type="review"
                counterpartName={`${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`}
              />
            );
          }
          if (contractSelection?.buyer?.agree === false) {
            return (
              <StatusBox
                type="denied"
                counterpartName={`${contractSelection?.seller?.firstName} ${contractSelection?.seller?.lastName}`}
              />
            );
          }
        }
      } else if (
        (!isSeller && contractSelection?.buyer?.agree === true) ||
        (isSeller && contractSelection?.seller?.agree === true)
      ) {
        return (
          <StatusBox
            type="default"
            user={isSeller ? contractSelection?.buyer : contractSelection?.seller}
            userStatus={
              isSeller ? contractSelection?.buyer?.agree : contractSelection.seller?.agree
            }
            onClick={() => {
              let user = {};
              if (isSeller) {
                user = {
                  ...contractSelection?.buyer,
                  agree: contractSelection?.buyer?.agree,
                };
              } else {
                user = {
                  ...contractSelection?.seller,
                  agree: contractSelection.seller?.agree,
                };
              }
              dispatch(ContractsActions.updateSelectedUserDenied(user));
              setSeeDisagreeComments(true);
            }}
          />
        );
      }
    }
  }

  function getCurrentTotalScheduled() {
    let total = 0;
    if (contractSelection?.schedules?.length > 0) {
      total = contractSelection?.schedules?.reduce((prev, next) => prev + next.volume, 0);
    }
    return total;
  }

  const Title = () =>
    !creating
      ? t('contracts.generalInformation.title2').toUpperCase()
      : t('contracts.generalInformation.title');

  const setupMessage = () => (
    <>
      {showSuccessModal && (
        <ModalView
          hideClose
          inverted
          view={
            <Box className={styles.modal_box_content}>
              <SvgIcon
                style={{ marginTop: 10, width: 80, height: 80 }}
                component={SuccessIcon}
                viewBox="0 0 81 81"
              />
              <Box className={styles.title}>{t('common.success').toUpperCase()}</Box>
              <Box className={styles.description}>{t('contracts.generalInformation.success')}</Box>
              <Box className={styles.subtitle}>
                {t('contracts.generalInformation.whatNext.title')}
              </Box>
              <Box>
                <Box className={clsx(styles.item, styles.done_text)}>
                  <DoneIcon style={{ fontSize: 20, marginRight: 14 }} />

                  {t('contracts.generalInformation.whatNext.item1')}
                </Box>
                <Box className={clsx(styles.item, styles.pending_text)}>
                  <SvgIcon
                    component={FreemiumItem}
                    viewBox="0 0 30 30"
                    style={{ height: 15, width: 15, marginRight: 20 }}
                  />{' '}
                  {t('contracts.generalInformation.whatNext.item2')}
                </Box>
                <Box className={clsx(styles.item, styles.pending_text)}>
                  <SvgIcon
                    component={FreemiumItem}
                    viewBox="0 0 30 30"
                    style={{ height: 15, width: 15, marginRight: 20 }}
                  />{' '}
                  {t('contracts.generalInformation.whatNext.item3')}
                </Box>
                <Box className={clsx(styles.item, styles.pending_text)}>
                  <SvgIcon
                    component={FreemiumItem}
                    viewBox="0 0 30 30"
                    style={{ height: 15, width: 15, marginRight: 20 }}
                  />{' '}
                  {t('contracts.generalInformation.whatNext.item4')}
                </Box>
                <Box className={clsx(styles.item, styles.pending_text)}>
                  <SvgIcon
                    component={FreemiumItem}
                    viewBox="0 0 30 30"
                    style={{ height: 15, width: 15, marginRight: 20 }}
                  />{' '}
                  {t('contracts.generalInformation.whatNext.item5')}
                </Box>
              </Box>
              <Grid
                container
                className={styles.buttons_container}
                spacing={2}
                style={!isMobile || isTablet ? { marginTop: 50 } : {}}
              >
                <Grid item xs={12} sm={6}>
                  <Box style={{ padding: '0 20px 0 20px' }}>
                    <Button
                      inverted
                      backgroundImage="linear-gradient(90deg, #ffffff 18%, #757e95 100%)"
                      onPress={() => {
                        setShowSuccessModal(false);
                        history.replace('/contracts/details');
                        dispatch(ContractsActions.resetSaveUserContracts());
                      }}
                    >
                      {t('contracts.generalInformation.goSummary')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box style={{ padding: '0 20px 0 20px' }}>
                    <Button
                      inverted
                      backgroundImage="linear-gradient(90deg, #ffffff 18%, #757e95 100%)"
                      style={{ whiteSpace: 'nowrap' }}
                      onPress={() => {
                        setShowSuccessModal(false);
                        dispatch(TermsActions.resetGetTerms());
                        history.replace('/contracts/other-terms');
                        dispatch(ContractsActions.resetSaveUserContracts());
                      }}
                    >
                      {t('contracts.generalInformation.keepAddingTerms')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          }
        />
      )}
      {saveUserContractsCalled && !saveUserContractsLoading && saveUserContractsError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={saveUserContractsError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetSaveUserContractsError());
          }}
        />
      )}
      {showConfirmation && (
        <MessageModal
          title={t('common.agreeConfirmationTitle')}
          secundaryAction={t('common.no')}
          secundaryEvent={() => {
            setShowConfirmation(false);
          }}
          primaryAction={t('common.yes')}
          primaryEvent={() => {
            setShowConfirmation(false);
            handleAgree();
          }}
        />
      )}
      {updateUserContractCalled && !updateUserContractLoading && updateUserContractError && (
        <MessageModal
          icon={null}
          title={t('common.error')}
          message={updateUserContractError.userMessage}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            dispatch(ContractsActions.resetUpdateUserContractError());
          }}
        />
      )}
      {showCounterpartHelp && (
        <MessageModal
          icon={null}
          title={t('common.help')}
          message={t('contracts.generalInformation.counterpartHelpMessage')}
          primaryAction={t('common.close')}
          primaryEvent={() => {
            setShowCounterpartHelp(false);
          }}
        />
      )}
      {addDisagreeComments && (
        <ModalView
          view={
            <DeniedComment
              isModal
              formType="general-information"
              onClose={() => setAddDisagreeComments(false)}
            />
          }
          onClose={() => setAddDisagreeComments(false)}
        />
      )}

      {seeDisagreeComments && (
        <ModalView
          view={
            <SeeComment
              isModal
              formType="general-information"
              onClose={() => setSeeDisagreeComments(false)}
            />
          }
          onClose={() => setSeeDisagreeComments(false)}
        />
      )}
    </>
  );

  if (modalView) {
    return (
      <Box style={{ width: 800 }}>
        <Loading
          isLoading={
            saveUserContractsLoading || updateUserContractLoading || getUserContractLoading
          }
        />
        <Box className="modal_title">{t('contracts.modifyTerms')}</Box>
        <Box style={{ marginTop: 30, marginBottom: 50 }} className="modal_description">
          {t('contracts.generalInformation.descriptionEdit')}
        </Box>

        {showStatusBox()}
        {!actionRequired && creating === false && editing === false && (
          <Box className={styles.container_propose_changes}>
            <Button
              inverted
              bordered
              className={styles.button_alone}
              minWidth={130}
              maxWidth={270}
              onPress={() => {
                setEditing(true);
              }}
            >
              {t('contracts.proposeChanges')}
            </Button>
          </Box>
        )}

        {userType !== null && !contractSelection?.id && (
          <Box className={styles.container_contract_type}>
            <INQRadio
              title={t('contracts.generalInformation.iam')}
              values={[
                t('contracts.generalInformation.sellSide'),
                t('contracts.generalInformation.buySide'),
              ]}
              defaultValue={userType}
              onChange={(value) => {
                setUserType(value);
              }}
            />
          </Box>
        )}
        {(creating || editing) && (
          <Box className={styles.container_step_description}>
            <Box className="subtitle">{t('contracts.generalInformation.description2')}</Box>
          </Box>
        )}
        <Box>
          <Formik
            initialValues={{
              contractName: contractSelection?.name || '',
              contractReference: contractSelection?.contractReference || '',
              contractCounterpartReference: contractSelection?.contractCounterpartReference || '',
              counterpartCompany: contractSelection?.counterpartCompany || '',
              totalVolume: contractSelection?.totalVolume || '',
              sugarSpec: contractSelection?.sugarSpec || '',
              packagingType: contractSelection?.packageType || '',

              sellerRepresentativeFirstName: contractSelection?.seller?.firstName || '',
              sellerRepresentativeLastName: contractSelection?.seller?.lastName || '',
              sellerRepresentativeEmail: contractSelection?.seller?.email || '',

              buyerRepresentativeFirstName: contractSelection?.buyer?.firstName || '',
              buyerRepresentativeLastName: contractSelection?.buyer?.lastName || '',
              buyerRepresentativeEmail: contractSelection?.buyer?.email || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box className={styles.container_inputs}>
                  {commercialExecutive !== null && (
                    <Box className={contractSelection?.id ? null : styles.container_checkbox}>
                      <Box className={styles.checkbox}>
                        <CheckBox
                          disabled={contractSelection?.id}
                          checked={commercialExecutive}
                          onChange={(event) => {
                            setCommercialExecutive(event.target.checked);
                          }}
                        />
                        {t('contracts.imcCmmercialExecutive')}
                      </Box>
                      <Box
                        style={{
                          overflow: 'hidden',
                          height: !commercialExecutive ? '0px' : '138px',
                          visibility: commercialExecutive ? null : 'hidden',
                          transitionProperty: 'height, visibility',
                          transitionDuration: '1.5s',
                        }}
                      >
                        {userType === 'SELL-SIDE' ? (
                          <Box>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(
                                  styles.container_first_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="sellerRepresentativeFirstName"
                                  value={values.sellerRepresentativeFirstName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.sellerRepresentativeFirstName &&
                                    touched.sellerRepresentativeFirstName &&
                                    errors.sellerRepresentativeFirstName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.sellerRepresentativeFirstName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.sellerRepresentativeFirstName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="firstName"
                                  valueKey="firstName"
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="sellerRepresentativeLastName"
                                  value={values.sellerRepresentativeLastName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.sellerRepresentativeLastName &&
                                    touched.sellerRepresentativeLastName &&
                                    errors.sellerRepresentativeLastName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.sellerRepresentativeLastName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.sellerRepresentativeLastName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="lastName"
                                  valueKey="lastName"
                                />
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <Autocomplete
                                freeSolo
                                name="sellerRepresentativeEmail"
                                value={values.sellerRepresentativeEmail}
                                onChange={handleChange}
                                helperText={
                                  errors.sellerRepresentativeEmail &&
                                  touched.sellerRepresentativeEmail &&
                                  errors.sellerRepresentativeEmail
                                }
                                placeholder={t(
                                  'contracts.generalInformation.sellerRepresentativeEmail'
                                )}
                                label={t(
                                  'contracts.generalInformation.sellerRepresentativeEmail'
                                ).toUpperCase()}
                                disabled={!editing && !creating}
                                options={autocomplete?.counterparts}
                                displayKey="email"
                                valueKey="email"
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(
                                  styles.container_first_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="buyerRepresentativeFirstName"
                                  value={values.buyerRepresentativeFirstName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.buyerRepresentativeFirstName &&
                                    touched.buyerRepresentativeFirstName &&
                                    errors.buyerRepresentativeFirstName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.buyerRepresentativeFirstName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.buyerRepresentativeFirstName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="firstName"
                                  valueKey="firstName"
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="buyerRepresentativeLastName"
                                  value={values.buyerRepresentativeLastName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.buyerRepresentativeLastName &&
                                    touched.buyerRepresentativeLastName &&
                                    errors.buyerRepresentativeLastName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.buyerRepresentativeLastName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.buyerRepresentativeLastName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="lastName"
                                  valueKey="lastName"
                                />
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <Autocomplete
                                freeSolo
                                name="buyerRepresentativeEmail"
                                value={values.buyerRepresentativeEmail}
                                onChange={handleChange}
                                helperText={
                                  errors.buyerRepresentativeEmail &&
                                  touched.buyerRepresentativeEmail &&
                                  errors.buyerRepresentativeEmail
                                }
                                placeholder={t(
                                  'contracts.generalInformation.buyerRepresentativeEmail'
                                )}
                                label={t(
                                  'contracts.generalInformation.buyerRepresentativeEmail'
                                ).toUpperCase()}
                                disabled={!editing && !creating}
                                options={autocomplete?.counterparts}
                                displayKey="email"
                                valueKey="email"
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                  <Box className={styles.container_input}>
                    <TextField
                      name="contractName"
                      value={values.contractName}
                      onChange={handleChange}
                      helperText={
                        errors.contractName && touched.contractName && errors.contractName
                      }
                      placeholder={t('contracts.contractName')}
                      label={t('contracts.contractName').toUpperCase()}
                      disabled={!editing && !creating}
                      required
                    />
                  </Box>
                  <Box className={styles.container_input}>
                    <TextField
                      name="contractReference"
                      value={values.contractReference}
                      onChange={handleChange}
                      helperText={
                        errors.contractReference &&
                        touched.contractReference &&
                        errors.contractReference
                      }
                      placeholder={t('contracts.contractReference')}
                      label={t('contracts.contractReference').toUpperCase()}
                      disabled={!editing && !creating}
                      required
                    />
                  </Box>

                  <Box className={styles.container_input}>
                    <TextField
                      name="contractCounterpartReference"
                      value={values.contractCounterpartReference}
                      onChange={handleChange}
                      helperText={
                        errors.contractCounterpartReference &&
                        touched.contractCounterpartReference &&
                        errors.contractCounterpartReference
                      }
                      placeholder={t('contracts.contractCounterpartReference')}
                      label={t('contracts.contractCounterpartReference').toUpperCase()}
                      disabled={!editing && !creating}
                    />
                  </Box>

                  {userType === 'SELL-SIDE' ? (
                    <Box className={styles.container_input}>
                      <Autocomplete
                        freeSolo
                        name="counterpartCompany"
                        value={values.counterpartCompany}
                        onChange={handleChange}
                        helperText={
                          errors.counterpartCompany &&
                          touched.counterpartCompany &&
                          errors.counterpartCompany
                        }
                        placeholder={t('contracts.generalInformation.buyerCompany')}
                        label={t('contracts.generalInformation.buyerCompany').toUpperCase()}
                        disabled={!editing && !creating}
                        required
                        options={autocomplete?.counterparts}
                        displayKey="companyName"
                        valueKey="companyName"
                      />
                    </Box>
                  ) : (
                    <Box className={styles.container_input}>
                      <Autocomplete
                        freeSolo
                        name="counterpartCompany"
                        value={values.counterpartCompany}
                        onChange={handleChange}
                        helperText={
                          errors.counterpartCompany &&
                          touched.counterpartCompany &&
                          errors.counterpartCompany
                        }
                        placeholder={t('contracts.generalInformation.sellerCompany')}
                        label={t('contracts.generalInformation.sellerCompany').toUpperCase()}
                        disabled={!editing && !creating}
                        required
                        options={autocomplete?.counterparts}
                        displayKey="companyName"
                        valueKey="companyName"
                      />
                    </Box>
                  )}
                  {addCounterpart !== null && (editing || creating) && (
                    <Box
                      className={
                        (!contractSelection?.id ||
                          !contractSelection?.buyer ||
                          !contractSelection?.seller) &&
                        (editing || creating)
                          ? styles.container_checkbox
                          : null
                      }
                    >
                      {(!contractSelection?.id ||
                        !contractSelection?.buyer ||
                        !contractSelection?.seller) && (
                        <Box className={styles.checkbox}>
                          <CheckBox
                            checked={addCounterpart}
                            onChange={(event) => {
                              setAddCounterpart(event.target.checked);
                            }}
                            disabled={!editing && !creating}
                          />
                          {t('contracts.addCounterpart')}
                          <HelpButton
                            onHelp={() => {
                              setShowCounterpartHelp(true);
                            }}
                          />
                        </Box>
                      )}
                      <Box
                        style={{
                          overflow: 'hidden',
                          height: !addCounterpart ? '0px' : '138px',
                          visibility: addCounterpart ? null : 'hidden',
                          transitionProperty: 'height, visibility',
                          transitionDuration: '1.5s',
                        }}
                      >
                        {userType === 'SELL-SIDE' ? (
                          <Box>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(
                                  styles.container_first_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="buyerRepresentativeFirstName"
                                  value={values.buyerRepresentativeFirstName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.buyerRepresentativeFirstName &&
                                    touched.buyerRepresentativeFirstName &&
                                    errors.buyerRepresentativeFirstName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.buyerRepresentativeFirstName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.buyerRepresentativeFirstName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="firstName"
                                  valueKey="firstName"
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="buyerRepresentativeLastName"
                                  value={values.buyerRepresentativeLastName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.buyerRepresentativeLastName &&
                                    touched.buyerRepresentativeLastName &&
                                    errors.buyerRepresentativeLastName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.buyerRepresentativeLastName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.buyerRepresentativeLastName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="lastName"
                                  valueKey="lastName"
                                />
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <Autocomplete
                                freeSolo
                                name="buyerRepresentativeEmail"
                                value={values.buyerRepresentativeEmail}
                                onChange={handleChange}
                                helperText={
                                  errors.buyerRepresentativeEmail &&
                                  touched.buyerRepresentativeEmail &&
                                  errors.buyerRepresentativeEmail
                                }
                                placeholder={t(
                                  'contracts.generalInformation.buyerRepresentativeEmail'
                                )}
                                label={t(
                                  'contracts.generalInformation.buyerRepresentativeEmail'
                                ).toUpperCase()}
                                disabled={!editing && !creating}
                                options={autocomplete?.counterparts}
                                displayKey="email"
                                valueKey="email"
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box className={styles.container_names}>
                              <Box
                                className={clsx(
                                  styles.container_first_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="sellerRepresentativeFirstName"
                                  value={values.sellerRepresentativeFirstName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.sellerRepresentativeFirstName &&
                                    touched.sellerRepresentativeFirstName &&
                                    errors.sellerRepresentativeFirstName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.sellerRepresentativeFirstName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.sellerRepresentativeFirstName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="firstName"
                                  valueKey="firstName"
                                />
                              </Box>
                              <Box
                                className={clsx(
                                  styles.container_last_field,
                                  styles.container_input
                                )}
                              >
                                <Autocomplete
                                  freeSolo
                                  name="sellerRepresentativeLastName"
                                  value={values.sellerRepresentativeLastName}
                                  onChange={handleChange}
                                  helperText={
                                    errors.sellerRepresentativeLastName &&
                                    touched.sellerRepresentativeLastName &&
                                    errors.sellerRepresentativeLastName
                                  }
                                  placeholder={t(
                                    'contracts.generalInformation.sellerRepresentativeLastName'
                                  )}
                                  label={t(
                                    'contracts.generalInformation.sellerRepresentativeLastName'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  options={autocomplete?.counterparts}
                                  displayKey="lastName"
                                  valueKey="lastName"
                                />
                              </Box>
                            </Box>
                            <Box className={styles.container_input}>
                              <Autocomplete
                                freeSolo
                                name="sellerRepresentativeEmail"
                                value={values.sellerRepresentativeEmail}
                                onChange={handleChange}
                                helperText={
                                  errors.sellerRepresentativeEmail &&
                                  touched.sellerRepresentativeEmail &&
                                  errors.sellerRepresentativeEmail
                                }
                                placeholder={t(
                                  'contracts.generalInformation.sellerRepresentativeEmail'
                                )}
                                label={t(
                                  'contracts.generalInformation.sellerRepresentativeEmail'
                                ).toUpperCase()}
                                disabled={!editing && !creating}
                                options={autocomplete?.counterparts}
                                displayKey="email"
                                valueKey="email"
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box>
                  {actionRequired ? (
                    <Box className={styles.container_submit}>
                      <Box className={styles.box_two_options}>
                        <Button
                          loading={updateUserContractLoading}
                          disabled={updateUserContractLoading}
                          inverted
                          bordered
                          className={styles.button}
                          onPress={() => {
                            setAddDisagreeComments(true);
                          }}
                        >
                          {t('common.dontAgree')}
                        </Button>
                        <Box style={{ width: 20 }} />
                        <Button
                          loading={updateUserContractLoading}
                          disabled={updateUserContractLoading}
                          className={styles.button}
                          onPress={() => {
                            setShowConfirmation(true);
                          }}
                        >
                          {t('common.agree')}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      {(editing || creating) && (
                        <Box className={styles.container_submit}>
                          <Button
                            type="submit"
                            disabled={(!editing && !creating) || saveUserContractsLoading}
                            loading={saveUserContractsLoading}
                            className={styles.button_alone}
                            data-info-submit="Submit"
                            minWidth={130}
                            maxWidth={270}
                          >
                            {creating ? t('contracts.createContract') : t('common.submit')}
                          </Button>
                          {modalView && (
                            <Button
                              inverted
                              bordered
                              disabled={saveUserContractsLoading}
                              loading={saveUserContractsLoading}
                              className={styles.button_alone}
                              minWidth={130}
                              maxWidth={270}
                              onPress={() => {
                                onClose();
                              }}
                            >
                              {t('common.cancel')}
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        {setupMessage()}
      </Box>
    );
  }
  return (
    <Box className={styles.container}>
      <Loading
        isLoading={saveUserContractsLoading || updateUserContractLoading || getUserContractLoading}
      />
      <NavigationBar
        titleDescription={
          isTablet
            ? {
                position: 'center',
                title: Title(),
                description: t('contracts.generalInformation.description'),
              }
            : null
        }
        title={isMobile && !isTablet ? Title() : null}
        hideTitle={!isMobile}
        hasMenuButton
        barColor={isTablet ? '#111' : isMobile ? '#fff' : '#111'}
        white={!isMobile || isTablet}
        hasNotificationButton={!isMobile && !isTablet}
        history={history}
        user={user}
        menuList={[
          {
            label: t('common.goHome'),
            action: () => {
              history.replace('/home');
            },
          },
        ]}
        onToggleDrawer={(opened) => {
          setDrawerOpened(opened);
        }}
        onLogout={() => {
          dispatch(AuthActions.logout());
        }}
        pageContent={
          <Grid container className={styles.grid_container} justify="center">
            <Grid item xs={12} sm={12} container justify="center">
              <Container
                maxWidth="md"
                className={styles.content}
                style={
                  isMobile || isTablet ? { marginTop: 0, boxShadow: 'none' } : { marginTop: 120 }
                }
              >
                <Box className={clsx(styles.main_content)}>
                  {!isMobile && (
                    <Box className={styles.container_title}>
                      <Box className="subtitle_container">
                        <Title />
                      </Box>
                      <Box className="description_container">
                        {t('contracts.generalInformation.description')}
                      </Box>
                    </Box>
                  )}
                  {showStatusBox()}
                  {!actionRequired && creating === false && editing === false && (
                    <Box className={styles.container_propose_changes}>
                      <Button
                        inverted
                        bordered
                        className={styles.button_alone}
                        minWidth={130}
                        maxWidth={270}
                        onPress={() => {
                          setEditing(true);
                        }}
                      >
                        {t('contracts.proposeChanges')}
                      </Button>
                    </Box>
                  )}
                  {userType !== null && !contractSelection?.id && (
                    <Box className={styles.container_contract_type}>
                      <INQRadio
                        title={t('contracts.generalInformation.iam')}
                        values={[
                          t('contracts.generalInformation.sellSide'),
                          t('contracts.generalInformation.buySide'),
                        ]}
                        defaultValue={userType}
                        onChange={(value) => {
                          setUserType(value);
                        }}
                      />
                    </Box>
                  )}
                  {(creating || editing) && (
                    <Box className={styles.container_step_description}>
                      <Box className="subtitle">
                        {t('contracts.generalInformation.description2')}
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <Formik
                      initialValues={{
                        contractName: contractSelection?.name || '',
                        contractReference: contractSelection?.contractReference || '',
                        contractCounterpartReference:
                          contractSelection?.contractCounterpartReference || '',
                        counterpartCompany: contractSelection?.counterpartCompany || '',
                        totalVolume: contractSelection?.totalVolume || '',
                        sugarSpec: contractSelection?.sugarSpec || '',
                        packagingType: contractSelection?.packageType || '',

                        sellerRepresentativeFirstName: contractSelection?.seller?.firstName || '',
                        sellerRepresentativeLastName: contractSelection?.seller?.lastName || '',
                        sellerRepresentativeEmail: contractSelection?.seller?.email || '',

                        buyerRepresentativeFirstName: contractSelection?.buyer?.firstName || '',
                        buyerRepresentativeLastName: contractSelection?.buyer?.lastName || '',
                        buyerRepresentativeEmail: contractSelection?.buyer?.email || '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                      enableReinitialize
                    >
                      {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box className={styles.container_inputs}>
                            {commercialExecutive !== null && (
                              <Box
                                className={contractSelection?.id ? null : styles.container_checkbox}
                              >
                                <Box className={styles.checkbox}>
                                  <CheckBox
                                    disabled={contractSelection?.id}
                                    checked={commercialExecutive}
                                    onChange={(event) => {
                                      setCommercialExecutive(event.target.checked);
                                    }}
                                  />
                                  {t('contracts.imcCmmercialExecutive')}
                                </Box>
                                <Box
                                  style={{
                                    overflow: 'hidden',
                                    height: !commercialExecutive ? '0px' : '138px',
                                    visibility: commercialExecutive ? null : 'hidden',
                                    transitionProperty: 'height, visibility',
                                    transitionDuration: '1.5s',
                                  }}
                                >
                                  {userType === 'SELL-SIDE' ? (
                                    <Box>
                                      <Box className={styles.container_names}>
                                        <Box
                                          className={clsx(
                                            styles.container_first_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="sellerRepresentativeFirstName"
                                            value={values.sellerRepresentativeFirstName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.sellerRepresentativeFirstName &&
                                              touched.sellerRepresentativeFirstName &&
                                              errors.sellerRepresentativeFirstName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.sellerRepresentativeFirstName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.sellerRepresentativeFirstName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="firstName"
                                            valueKey="firstName"
                                          />
                                        </Box>
                                        <Box
                                          className={clsx(
                                            styles.container_last_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="sellerRepresentativeLastName"
                                            value={values.sellerRepresentativeLastName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.sellerRepresentativeLastName &&
                                              touched.sellerRepresentativeLastName &&
                                              errors.sellerRepresentativeLastName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.sellerRepresentativeLastName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.sellerRepresentativeLastName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="lastName"
                                            valueKey="lastName"
                                          />
                                        </Box>
                                      </Box>
                                      <Box className={styles.container_input}>
                                        <Autocomplete
                                          freeSolo
                                          name="sellerRepresentativeEmail"
                                          value={values.sellerRepresentativeEmail}
                                          onChange={handleChange}
                                          helperText={
                                            errors.sellerRepresentativeEmail &&
                                            touched.sellerRepresentativeEmail &&
                                            errors.sellerRepresentativeEmail
                                          }
                                          placeholder={t(
                                            'contracts.generalInformation.sellerRepresentativeEmail'
                                          )}
                                          label={t(
                                            'contracts.generalInformation.sellerRepresentativeEmail'
                                          ).toUpperCase()}
                                          disabled={!editing && !creating}
                                          options={autocomplete?.counterparts}
                                          displayKey="email"
                                          valueKey="email"
                                        />
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Box className={styles.container_names}>
                                        <Box
                                          className={clsx(
                                            styles.container_first_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="buyerRepresentativeFirstName"
                                            value={values.buyerRepresentativeFirstName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.buyerRepresentativeFirstName &&
                                              touched.buyerRepresentativeFirstName &&
                                              errors.buyerRepresentativeFirstName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.buyerRepresentativeFirstName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.buyerRepresentativeFirstName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="firstName"
                                            valueKey="firstName"
                                          />
                                        </Box>
                                        <Box
                                          className={clsx(
                                            styles.container_last_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="buyerRepresentativeLastName"
                                            value={values.buyerRepresentativeLastName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.buyerRepresentativeLastName &&
                                              touched.buyerRepresentativeLastName &&
                                              errors.buyerRepresentativeLastName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.buyerRepresentativeLastName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.buyerRepresentativeLastName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="lastName"
                                            valueKey="lastName"
                                          />
                                        </Box>
                                      </Box>
                                      <Box className={styles.container_input}>
                                        <Autocomplete
                                          freeSolo
                                          name="buyerRepresentativeEmail"
                                          value={values.buyerRepresentativeEmail}
                                          onChange={handleChange}
                                          helperText={
                                            errors.buyerRepresentativeEmail &&
                                            touched.buyerRepresentativeEmail &&
                                            errors.buyerRepresentativeEmail
                                          }
                                          placeholder={t(
                                            'contracts.generalInformation.buyerRepresentativeEmail'
                                          )}
                                          label={t(
                                            'contracts.generalInformation.buyerRepresentativeEmail'
                                          ).toUpperCase()}
                                          disabled={!editing && !creating}
                                          options={autocomplete?.counterparts}
                                          displayKey="email"
                                          valueKey="email"
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )}
                            <Box className={styles.container_input}>
                              <TextField
                                name="contractName"
                                value={values.contractName}
                                onChange={handleChange}
                                helperText={
                                  errors.contractName && touched.contractName && errors.contractName
                                }
                                placeholder={t('contracts.contractName')}
                                label={t('contracts.contractName').toUpperCase()}
                                disabled={!editing && !creating}
                                required
                              />
                            </Box>
                            <Box className={styles.container_input}>
                              <TextField
                                name="contractReference"
                                value={values.contractReference}
                                onChange={handleChange}
                                helperText={
                                  errors.contractReference &&
                                  touched.contractReference &&
                                  errors.contractReference
                                }
                                placeholder={t('contracts.contractReference')}
                                label={t('contracts.contractReference').toUpperCase()}
                                disabled={!editing && !creating}
                                required
                              />
                            </Box>

                            <Box className={styles.container_input}>
                              <TextField
                                name="contractCounterpartReference"
                                value={values.contractCounterpartReference}
                                onChange={handleChange}
                                helperText={
                                  errors.contractCounterpartReference &&
                                  touched.contractCounterpartReference &&
                                  errors.contractCounterpartReference
                                }
                                placeholder={t('contracts.contractCounterpartReference')}
                                label={t('contracts.contractCounterpartReference').toUpperCase()}
                                disabled={!editing && !creating}
                              />
                            </Box>
                            {userType === 'SELL-SIDE' ? (
                              <Box className={styles.container_input}>
                                <Autocomplete
                                  freeSolo
                                  name="counterpartCompany"
                                  value={values.counterpartCompany}
                                  onChange={handleChange}
                                  helperText={
                                    errors.counterpartCompany &&
                                    touched.counterpartCompany &&
                                    errors.counterpartCompany
                                  }
                                  placeholder={t('contracts.generalInformation.buyerCompany')}
                                  label={t(
                                    'contracts.generalInformation.buyerCompany'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  required
                                  options={autocomplete?.counterparts}
                                  displayKey="companyName"
                                  valueKey="companyName"
                                />
                              </Box>
                            ) : (
                              <Box className={styles.container_input}>
                                <Autocomplete
                                  freeSolo
                                  name="counterpartCompany"
                                  value={values.counterpartCompany}
                                  onChange={handleChange}
                                  helperText={
                                    errors.counterpartCompany &&
                                    touched.counterpartCompany &&
                                    errors.counterpartCompany
                                  }
                                  placeholder={t('contracts.generalInformation.sellerCompany')}
                                  label={t(
                                    'contracts.generalInformation.sellerCompany'
                                  ).toUpperCase()}
                                  disabled={!editing && !creating}
                                  required
                                  options={autocomplete?.counterparts}
                                  displayKey="companyName"
                                  valueKey="companyName"
                                />
                              </Box>
                            )}
                            {!modalView && (
                              <>
                                <Box className={styles.container_title}>
                                  <Box className="subtitle_container">
                                    {t('contracts.generalInformation.title3')}
                                  </Box>
                                  <Box className="description_container">
                                    {t('contracts.generalInformation.description3')}
                                  </Box>
                                </Box>
                                <Box className={styles.container_names}>
                                  <Box
                                    className={clsx(
                                      styles.container_first_field,
                                      styles.container_input
                                    )}
                                  >
                                    <TextField
                                      name="totalVolume"
                                      value={values.totalVolume}
                                      onChange={handleChange}
                                      helperText={
                                        errors.totalVolume &&
                                        touched.totalVolume &&
                                        errors.totalVolume
                                      }
                                      placeholder={t('contracts.totalVolume')}
                                      label={t('contracts.totalVolume').toUpperCase()}
                                      disabled={!editing && !creating}
                                      required
                                      icon="MT"
                                      type="number"
                                      onKeyDown={(evt) =>
                                        (evt.key === 'e' || evt.key === '+' || evt.key === '-') &&
                                        evt.preventDefault()
                                      }
                                    />
                                  </Box>
                                  <Box
                                    className={clsx(
                                      styles.container_last_field,
                                      styles.container_input
                                    )}
                                  >
                                    <Autocomplete
                                      freeSolo
                                      name="sugarSpec"
                                      value={values.sugarSpec}
                                      onChange={handleChange}
                                      helperText={
                                        errors.sugarSpec && touched.sugarSpec && errors.sugarSpec
                                      }
                                      placeholder={t('contracts.sugarSpec')}
                                      label={t('contracts.sugarSpec').toUpperCase()}
                                      disabled={!editing && !creating}
                                      required
                                      options={autocomplete?.sugarSpecs}
                                      displayKey="sugarSpec"
                                      valueKey="sugarSpec"
                                    />
                                  </Box>
                                </Box>
                                <Box className={styles.container_input}>
                                  <Select
                                    name="packagingType"
                                    values={['50 kg Bag', '1 MT Bag', 'Bulk', 'Other']}
                                    value={values.packagingType}
                                    onChange={(value) => {
                                      values.packagingType = value;
                                      const event = {
                                        persist: () => {},
                                        target: {
                                          type: 'change',
                                          id: 'packagingType',
                                          name: 'packagingType',
                                          value: values.packagingType,
                                        },
                                      };
                                      handleChange(event);
                                    }}
                                    helperText={
                                      errors.packagingType &&
                                      touched.packagingType &&
                                      errors.packagingType
                                    }
                                    placeholder={t('contracts.packagingType')}
                                    label={t('contracts.packagingType').toUpperCase()}
                                    disabled={!editing && !creating}
                                    required
                                  />
                                </Box>
                              </>
                            )}

                            {addCounterpart !== null && (editing || creating) && (
                              <Box
                                className={
                                  (!contractSelection?.id ||
                                    !contractSelection?.buyer ||
                                    !contractSelection?.seller) &&
                                  (editing || creating)
                                    ? styles.container_checkbox
                                    : null
                                }
                              >
                                {(!contractSelection?.id ||
                                  !contractSelection?.buyer ||
                                  !contractSelection?.seller) && (
                                  <Box className={styles.checkbox}>
                                    <CheckBox
                                      checked={addCounterpart}
                                      onChange={(event) => {
                                        setAddCounterpart(event.target.checked);
                                      }}
                                      disabled={!editing && !creating}
                                    />
                                    {t('contracts.addCounterpart')}
                                    <HelpButton
                                      onHelp={() => {
                                        setShowCounterpartHelp(true);
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box
                                  style={{
                                    overflow: 'hidden',
                                    height: !addCounterpart ? '0px' : '138px',
                                    visibility: addCounterpart ? null : 'hidden',
                                    transitionProperty: 'height, visibility',
                                    transitionDuration: '1.5s',
                                  }}
                                >
                                  {userType === 'SELL-SIDE' ? (
                                    <Box>
                                      <Box className={styles.container_names}>
                                        <Box
                                          className={clsx(
                                            styles.container_first_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="buyerRepresentativeFirstName"
                                            value={values.buyerRepresentativeFirstName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.buyerRepresentativeFirstName &&
                                              touched.buyerRepresentativeFirstName &&
                                              errors.buyerRepresentativeFirstName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.buyerRepresentativeFirstName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.buyerRepresentativeFirstName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="firstName"
                                            valueKey="firstName"
                                          />
                                        </Box>
                                        <Box
                                          className={clsx(
                                            styles.container_last_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="buyerRepresentativeLastName"
                                            value={values.buyerRepresentativeLastName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.buyerRepresentativeLastName &&
                                              touched.buyerRepresentativeLastName &&
                                              errors.buyerRepresentativeLastName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.buyerRepresentativeLastName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.buyerRepresentativeLastName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="lastName"
                                            valueKey="lastName"
                                          />
                                        </Box>
                                      </Box>
                                      <Box className={styles.container_input}>
                                        <Autocomplete
                                          freeSolo
                                          name="buyerRepresentativeEmail"
                                          value={values.buyerRepresentativeEmail}
                                          onChange={handleChange}
                                          helperText={
                                            errors.buyerRepresentativeEmail &&
                                            touched.buyerRepresentativeEmail &&
                                            errors.buyerRepresentativeEmail
                                          }
                                          placeholder={t(
                                            'contracts.generalInformation.buyerRepresentativeEmail'
                                          )}
                                          label={t(
                                            'contracts.generalInformation.buyerRepresentativeEmail'
                                          ).toUpperCase()}
                                          disabled={!editing && !creating}
                                          options={autocomplete?.counterparts}
                                          displayKey="email"
                                          valueKey="email"
                                        />
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Box className={styles.container_names}>
                                        <Box
                                          className={clsx(
                                            styles.container_first_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="sellerRepresentativeFirstName"
                                            value={values.sellerRepresentativeFirstName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.sellerRepresentativeFirstName &&
                                              touched.sellerRepresentativeFirstName &&
                                              errors.sellerRepresentativeFirstName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.sellerRepresentativeFirstName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.sellerRepresentativeFirstName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="firstName"
                                            valueKey="firstName"
                                          />
                                        </Box>
                                        <Box
                                          className={clsx(
                                            styles.container_last_field,
                                            styles.container_input
                                          )}
                                        >
                                          <Autocomplete
                                            freeSolo
                                            name="sellerRepresentativeLastName"
                                            value={values.sellerRepresentativeLastName}
                                            onChange={handleChange}
                                            helperText={
                                              errors.sellerRepresentativeLastName &&
                                              touched.sellerRepresentativeLastName &&
                                              errors.sellerRepresentativeLastName
                                            }
                                            placeholder={t(
                                              'contracts.generalInformation.sellerRepresentativeLastName'
                                            )}
                                            label={t(
                                              'contracts.generalInformation.sellerRepresentativeLastName'
                                            ).toUpperCase()}
                                            disabled={!editing && !creating}
                                            options={autocomplete?.counterparts}
                                            displayKey="lastName"
                                            valueKey="lastName"
                                          />
                                        </Box>
                                      </Box>
                                      <Box className={styles.container_input}>
                                        <Autocomplete
                                          freeSolo
                                          name="sellerRepresentativeEmail"
                                          value={values.sellerRepresentativeEmail}
                                          onChange={handleChange}
                                          helperText={
                                            errors.sellerRepresentativeEmail &&
                                            touched.sellerRepresentativeEmail &&
                                            errors.sellerRepresentativeEmail
                                          }
                                          placeholder={t(
                                            'contracts.generalInformation.sellerRepresentativeEmail'
                                          )}
                                          label={t(
                                            'contracts.generalInformation.sellerRepresentativeEmail'
                                          ).toUpperCase()}
                                          disabled={!editing && !creating}
                                          options={autocomplete?.counterparts}
                                          displayKey="email"
                                          valueKey="email"
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box>
                            {actionRequired ? (
                              <Box className={styles.container_submit}>
                                <Box className={styles.box_two_options}>
                                  <Button
                                    loading={updateUserContractLoading}
                                    disabled={updateUserContractLoading}
                                    inverted
                                    bordered
                                    className={styles.button}
                                    onPress={() => {
                                      history.push('/contracts/denied/general-information');
                                    }}
                                  >
                                    {t('common.dontAgree')}
                                  </Button>
                                  <Box style={{ width: 20 }} />
                                  <Button
                                    loading={updateUserContractLoading}
                                    disabled={updateUserContractLoading}
                                    className={styles.button}
                                    onPress={() => {
                                      setShowConfirmation(true);
                                    }}
                                  >
                                    {t('common.agree')}
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                style={{
                                  marginTop: 40,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {(editing || creating) && (
                                  <Grid
                                    style={{ maxWidth: 560 }}
                                    container
                                    spacing={2}
                                    justify="center"
                                  >
                                    <Grid item xs={6}>
                                      <Button
                                        type="submit"
                                        disabled={
                                          (!editing && !creating) || saveUserContractsLoading
                                        }
                                        loading={saveUserContractsLoading}
                                        // className={styles.button_alone}
                                        data-info-submit="Submit"
                                        minWidth={130}
                                        maxWidth={270}
                                      >
                                        {creating
                                          ? t('contracts.createContract')
                                          : t('common.submit')}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        inverted
                                        bordered
                                        disabled={saveUserContractsLoading}
                                        loading={saveUserContractsLoading}
                                        // className={styles.button_alone}
                                        minWidth={130}
                                        maxWidth={270}
                                        onPress={() => {
                                          if (modalView) {
                                            onClose();
                                          } else {
                                            history.goBack();
                                          }
                                        }}
                                      >
                                        {t('common.cancel')}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </Box>
                            )}
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        }
      />

      {setupMessage()}
    </Box>
  );
};

export default withTranslation()(GeneralInformation);
