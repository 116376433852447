import api from './api';

const FormData = require('form-data');

export const getTasks = (id) => {
  return api.get(`tasks/${id}`);
};

export const getTask = (id) => {
  return api.get(`tasks/${id}`);
};

export const getSubtask = (id) => {
  return api.get(`tasks/${id}/details`);
};

export const postTask = (data) => {
  return api.post('tasks', data);
};

export const putTask = (id, data) => {
  delete data.id;
  return api.put(`tasks/${id}`, data);
};

export const deleteTask = (id) => {
  return api.delete(`tasks/${id}`);
};

export const postRequirementsGeneralTask = (id, data) => {
  return api.post(`tasks/general/${id}/requirements`, data);
};

export const postRequirementsNominationTask = (id, data) => {
  return api.post(`tasks/nomination/${id}/requirements`, data);
};

export const postRequirementsDeliveryTask = (id, data) => {
  return api.post(`tasks/delivery/${id}/requirements`, data);
};

export const putRequirementsGeneralTask = (id, data) => {
  return api.put(`tasks/general/${id}/requirements`, data);
};

export const putRequirementsNominationTask = (id, data) => {
  return api.put(`tasks/nomination/${id}/requirements`, data);
};

export const putRequirementsDeliveryTask = (id, data) => {
  return api.put(`tasks/delivery/${id}/requirements`, data);
};

export const deleteRequirementsGeneralTask = (id) => {
  return api.delete(`tasks/general/${id}/requirements`);
};

export const deleteRequirementsNominationTask = (id) => {
  return api.delete(`tasks/nomination/${id}/requirements`);
};

export const deleteRequirementsDeliveryTask = (id) => {
  return api.delete(`tasks/delivery/${id}/requirements`);
};

export const putTaskState = (id, data) => {
  return api.put(`tasks/${id}/approve-reject-changes`, data);
};

export const putDocumentState = (id, data) => {
  return api.put(`documents/verify/${id}`, data);
};

export const deleteDocument = (id) => {
  return api.delete(`documents/${id}`);
};

export const uploadDocument = async (id, data) => {
  const formData = new FormData();
  formData.append('file', data);
  return api.post(`/documents/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
